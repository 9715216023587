<template>
  <div>
    <div
      :class="customLook ? 'title-style' : 'dark-title'"
    >
      {{ $t('time.timeRange') }}
    </div>

    <div
      v-if="!customLook"
      class="icon-position"
    >
      <span
        class="drop-icon fa-stack fa-1x"
      >
        <i
          class="fas fa-caret-up fa-stack-1x"
          style="padding-bottom: 5px"
        />
        <i
          class="fas fa-caret-down fa-stack-1x"
          style="padding-top: 5px"
        />
      </span>
    </div>

    <DateTimePicker
      id="rangeSelector"
      ref="rangeSelector"
      v-model="selectedRange"
      :color="'#82ac48'"
      :locale="locale"
      class="ml-0 mr-1"
      :class="customLook ? 'custom-picker' : 'basic-picker'"
      :style="`--custom-height: ${customHeight || '35px'}; --input-background-color: ${inputBackgroundColor}`"
      :disabled="disabled"
      overlay
      no-clear-button
      enable-button-validate
      :only-date="true"
      :range="true"
      :right="true"
      format="YYYY-MM-DD"
      formatted="YYYY-MM-DD"
      :custom-shortcuts="rangeShortcuts"
      :label="label"
      @is-hidden="updateDates"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    startDate: String,
    endDate: String,
    customHeight: String,
    customLook: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRange: {
        start: null,
        end: null,
      },
      inputBackgroundColor: '#F2F2F2',
    };
  },

  computed: {
    ...mapGetters(['locale', 'fromUtcToPlantTime', 'fromPlantTimeToUtc']),
    rangeShortcuts() {
      return [
        { key: 'today', label: this.$t('selectors.today'), value: 'day' },
        { key: 'thisWeek', label: this.$t('selectors.thisWeek'), value: 'isoWeek' },
        { key: 'lastWeek', label: this.$t('selectors.previousWeek'), value: '-isoWeek' },
        { key: 'thisMonth', label: this.$t('selectors.thisMonth'), value: 'month' },
        { key: 'lastMonth', label: this.$t('selectors.previousMonth'), value: '-month' },
        {
          key: 'all',
          label: this.$t('general.all'),
          value: 'day',
          callback: () => {
            this.selectedRange = {
              start: null,
              end: null,
            };
          },
        },
      ];
    },
    label() {
      if (this.selectedRange.start !== null || this.selectedRange.end !== null) {
        return '';
      }
      return this.$t('general.all');
    },
  },
  methods: {
    updateDates() {
      const toEmit = {
        startDate: this.selectedRange.start !== null
          ? this.fromPlantTimeToUtc(this.selectedRange.start)
          : null,
        endDate: this.selectedRange.end !== null
          ? this.fromPlantTimeToUtc(moment.utc(this.selectedRange.end).add(1, 'days').format())
          : null,
      };
      this.$emit('setDates', toEmit);
    },

  },
  created() {
    this.selectedRange = {
      start: this.fromUtcToPlantTime(this.startDate),
      end: this.fromUtcToPlantTime(this.endDate),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/vars.icss';

  .drop-icon {
    width: 25px;
    height: 25px;
    color: #4D4D4D;
    font-size: 12px;
    padding-top: 2px;
    padding-inline: 20px;
    display: inline-flex;
    position: absolute;
    margin-left: -22px;
    margin-top: 5px;
    z-index: 1001;
  }

  .icon-position {
    text-align: right;
  }

  .custom-picker {
    font-weight: normal;
  }

  .dark-title {
    font-weight: 700;
    font-size: 14px;
  }

  .basic-picker {
    width: 400px;
  }

  .basic-picker::v-deep .field .field-input {
    padding-top: 0 !important;
    border: 1px solid #e8e8e8;
  }

  .custom-picker::v-deep .field .field-input {
    margin-top: 1px;
    background-color: var(--input-background-color);
    border-radius: 4px;
    padding-top: 0 !important;
    text-align: left;
    min-height: var(--custom-height) !important;
    height: var(--custom-height) !important;
    font-family: inherit;
    font-size: 0.8em;
    font-weight: 450;
    border: 0px;
    &:hover {
      background-color: #e5e5e5;
    }
    &::placeholder {
      font-size: 14px;
      color: black;
      opacity: 1;
    }
  }

  .custom-picker::v-deep .datetimepicker > div{
    width: 430px !important;
    min-width: 430px !important;
  }

  .custom-picker::v-deep .shortcuts-container{
    width: 160px;
    max-width: 160px;
    min-width: 160px;
  }

  .title-style {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #848484;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

</style>
