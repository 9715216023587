<template>
  <div>
    <div class="d-flex">
      <h3
        style="font-size: 18px"
        class="mb-0 mr-2"
      >
        <i class="ion ion-md-download pr-2" />
        {{ $t('label.downloadingLabels') }}
      </h3>
      <Loader
        v-if="progress !== 100"
        :size="14"
        color="gray"
      />
    </div>
    <Loader
      v-if="skusPending"
    />
    <div v-else>
      <div
        v-if="generalError"
        class="error-box mt-2"
      >
        {{ generalError }}
      </div>
      <div class="d-flex justify-content-center mt-3 progress-circle">
        <VueCircle
          ref="progressCircle"
          :progress="progress"
          :show-percent="true"
          :fill="progressStyle"
          :start-angle="-Math.PI/2"
        />
      </div>
      <div class="d-flex justify-content-center">
        <div class="text-secondary">
          {{ finishedJobsCount }} / {{ previewJobList.length ?? 0 }}
        </div>
      </div>
      <div class="error-list">
        <div
          v-for="failedJob in failedJobs"
          :key="failedJob.jobId"
          class="error-box mt-1"
        >
          <i class="icon fas fa-exclamation-circle" />
          <div class="message">
            <div class="font-weight-bold">
              SKU {{ failedJob.sku.externalId ?? failedJob.sku.name }}:
            </div>
            <div>
              {{ failedJob.error }}
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div
        v-if="progress !== 100"
        class="d-flex justify-content-center my-1"
      >
        <button
          class="btn btn-secondary mx-2 btn-sm"
          style="width: 120px"
          @click="$emit('close')"
        >
          <i class="ion ion-md-close pr-2" />
          {{ $t('general.cancel') }}
        </button>
      </div>
      <div
        v-else
        class="d-flex justify-content-center my-1"
      >
        <button
          class="btn btn-primary mx-2 btn-sm"
          style="width: 120px"
          @click="$emit('close')"
        >
          <i class="fas fa-check pr-2" />
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueCircle from 'vue2-circle-progress/src/index.vue';
import { mapActions } from 'vuex';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { convertPreviewResultToBlob } from '@/components/label/labelPreviewUtils';
import { getFileNameForSkuOnlyPreview } from '@/utils/previewFileNames';

export default {
  props: {
    skuIds: {
      type: Array,
      default: () => [],
    },
    skuSearch: null,
    label: {
      type: Object,
    },
  },
  data: () => ({
    generalError: null,
    previewJobList: [],
    skus: null,
    skusPending: false,
    statuses: {
      waiting: 'waiting',
      inProgress: 'inProgress',
      completed: 'completed',
      failed: 'failed',
    },
    stopDownload: false,
  }),
  components: {
    VueCircle,
  },
  computed: {
    progressStyle() {
      return { color: this.$color('logicprint5') };
    },
    failedJobs() {
      return this.previewJobList
        .filter(j => j.status === this.statuses.failed);
    },
    finishedJobsCount() {
      return this.previewJobList
        .filter(j => j.status === this.statuses.completed || j.status === this.statuses.failed)
        .length;
    },
    progress() {
      if (this.previewJobList.length === 0) return 0;
      return (this.finishedJobsCount / this.previewJobList.length) * 100;
    },
  },
  watch: {
    progress() {
      this.$refs.progressCircle.updateProgress(Math.round(this.progress));
    },
  },
  methods: {
    ...mapActions([
      'getSkusByIds',
      'getSkusMappedToLabel',
      'createGetLabelPreviewRequest',
    ]),
    generateRequest(jobId) {
      const job = this.previewJobList
        .find(j => j.jobId === jobId);
      job.status = this.statuses.inProgress;
      job.timeout = setTimeout(() => {
        this.timeoutJobIfNeeded(jobId);
      }, 15_000);
      this.createGetLabelPreviewRequest({
        params: {
          skuId: job.sku.id,
          labelId: job.labelId,
          query: {
            communicationRefId: job.jobId,
          },
        },
      }).catch(({ response: { data } }) => {
        job.error = data?.message || this.$t('general.error');
        job.status = this.statuses.failed;
      });
    },
    generateNextRequest() {
      if (this.stopDownload) return;
      const nextJob = this.previewJobList.find(j => j.status === this.statuses.waiting);
      if (nextJob === undefined) {
        return;
      }
      this.generateRequest(nextJob.jobId);
    },
    onGetPreviewResponse({ requestRefId, previewResult }) {
      const job = this.previewJobList
        .find(j => j.jobId === requestRefId);
      if (job === undefined) {
        return;
      }

      this.generateNextRequest();

      if (!previewResult.success) {
        job.status = this.statuses.failed;
        job.error = previewResult.errorMessage ?? this.$t('general.error');
        return;
      }

      const blob = convertPreviewResultToBlob(previewResult.preview);
      if (!blob) {
        job.status = this.statuses.failed;
        job.error = 'Preview does not exist';
        return;
      }

      job.status = this.statuses.completed;
      saveAs(blob, job.fileName);
    },
    timeoutJobIfNeeded(jobId) {
      const job = this.previewJobList
        .find(j => j.jobId === jobId);
      if (job.status !== this.statuses.inProgress) {
        return;
      }
      job.status = this.statuses.failed;
      job.error = this.$t('label.generatingPreviewTookTooLong');
      this.generateNextRequest();
    },
    async loadAllMappedSkus() {
      await this.getSkusMappedToLabel({
        params: {
          labelId: this.label.id,
          query: {
            page: 0,
            count: null,
            search: this.skuSearch,
          },
        },
      }).then(({ data }) => {
        this.skus = data.items || [];
      }).catch(({ response: { data } }) => {
        this.generalError = data.message ?? this.$t('general.error');
      });
    },
    async loadSkus() {
      await this.getSkusByIds({
        params: {
          query: {
            skuIds: this.skuIds ?? [],
          },
        },
      }).then(({ data }) => {
        this.skus = data;
      }).catch(({ response: { data } }) => {
        this.generalError = data.message ?? this.$t('general.error');
      });
    },
  },
  mounted() {
    this.$startDataTransferHub();
    this.$dataTransferHub.$on('label-preview-returned', this.onGetPreviewResponse);
  },
  beforeDestroy() {
    this.stopDownload = true;
  },
  async created() {
    this.skusPending = true;
    if (this.skuIds === null || this.skuIds.length === 0) {
      await this.loadAllMappedSkus();
    } else {
      await this.loadSkus();
    }
    this.skusPending = false;
    this.previewJobList = this.skus.map(sku => (
      {
        jobId: uuidv4(),
        sku,
        labelId: this.label.id,
        status: this.statuses.waiting,
        fileName: getFileNameForSkuOnlyPreview(this.label.name, sku.externalId ?? sku.name),
        error: null,
      }));

    for (let i = 0; i < Math.min(2, this.previewJobList.length); i += 1) {
      this.generateRequest(this.previewJobList[i].jobId);
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .progress-circle::v-deep .circle-percent-text-body {
    top: 0px
  }

  .error-list{
    overflow-y: scroll;
    max-height: 30vh;
  }

  .error-box {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    max-width: 50;
    background-color: #FFEFEE;
    color: #D70819;

    .icon {
      padding-top: 4px;
      width: 20px;
    }

    .message {
      width: 100%;
      word-break: break-all;
    }
  }
</style>
