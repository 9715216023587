import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import moment from 'moment';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getLineLabelingPointsMapping',
    property: 'list',
    path: () => '/lines/labelingpoints/mappings',
  })
  .post({
    action: 'addLabelingPointToLine',
    path: ({ lineId, lpId }) => `line/${lineId}/labelingpoint/${lpId}`,
  })
  .delete({
    action: 'removeLabelingPointFromLine',
    path: ({ lineId, lpId }) => `line/${lineId}/labelingpoint/${lpId}`,
  })
  .getStore();

store.getters = {
  lineLabelingPointMappings(state, getters, rootState) {
    if (!state.list || !rootState.lines.list || !rootState.labelingPoints.list) return [];

    const withName = state.list.map(llp => {
      const line = rootState.lines.list.find(l => l.id === llp.lineId);
      const labelingPoint = rootState.labelingPoints.list.find(lp => lp.id === llp.labelingPointId);
      return {
        ...llp,
        lineName: line ? line.name : '',
        labelingPointName: labelingPoint ? labelingPoint.name : '',
        created: moment.utc(llp.created).local().format('ll LTS'),
      };
    });

    return withName;
  },
};

export default store;
