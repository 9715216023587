<template>
  <div class="mx-3 my-2">
    <h4
      class="mb-0"
    >
      <span v-if="editForm">
        <i class="fas fa-pen mr-1" />
        {{ $t('template.editTemplate') }}
      </span>
      <span v-else>
        <i class="fas fa-plus mr-1" />
        {{ $t('template.addTemplate') }}
      </span>
    </h4>

    <div class="d-flex">
      <div class="col-6 pl-0">
        <div class="my-3">
          <label class="mb-1 table-title">
            {{ $t('template.templateName') }}
          </label>
          <BFormInput
            v-model="templateName"
            :disabled="editForm"
            :state="(templateName ? templateName.length : null)
              && !templateNameError && !templateNameExist"
            :placeholder="`${$t('general.addName')}...`"
          />
        </div>
        <div class="my-3">
          <label class="mb-1 table-title">
            <span>
              {{ $t('template.langSymbol') }}
            </span>
            <span class="optional-text">
              - {{ $t('form.optional') }}
            </span>
          </label>
          <BFormSelect
            v-model="langSymbol"
            :options="cultureOptions"
            :state="langSymbol ? true : null"
          />
        </div>
        <div class="my-3">
          <label class="mb-1 table-title">
            {{ $t('printer.type') }}
          </label>
          <BFormSelect
            v-model="printerType"
            :disabled="savedLabel && savedLabel?.printerType !== null"
            :options="printerTypes"
            :state="printerType ? true : null"
          />
        </div>
      </div>
      <div class="col-6 pr-0">
        <div class="my-3">
          <label class="mb-1 table-title">
            <span>
              {{ $t('general.description') }}
            </span>
            <span class="optional-text">
              - {{ $t('form.optional') }}
            </span>
          </label>
          <BFormInput
            v-model="templateDescription"
            :placeholder="`${$t('general.addDescription')}...`"
            :state="(templateDescription ? templateDescription.length : null)
              && !templateDescError"
          />
        </div>
        <div class="my-3">
          <label class="mb-1 table-title">
            <span>
              {{ $t('labellingPoint.labellingLevel') }}
            </span>
            <span class="optional-text">
              - {{ $t('form.optional') }}
            </span>
          </label>
          <BFormSelect
            v-model="labelingLevel"
            :options="formattedLabelingLevels"
            :state="labelingLevel ? true : null"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="mb-3">
        <div class="d-flex mb-4 mt-2">
          <BFormCheckbox
            id="attachToImportedSKUs"
            v-model="attachToImportedSKUs"
            class="text-left"
            switch
            size="lg"
          />
          <div class="table-title mt-1">
            <label for="attachToImportedSKUs">
              {{ $t('labellingPoint.attachLabelToImportedSKUs') }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div>
      <div>
        <h3
          style="font-size: 18px"
          class="mb-0"
        >
          {{ $t('template.fields') }}
        </h3>
      </div>
      <div
        v-if="fieldList.length"
        class="d-flex my-2 table-title"
      >
        <div class="col-2 pl-1">
          {{ $t('general.number') }}
        </div>
        <div class="col-3">
          {{ $t('general.name') }}
        </div>
        <div class="col-3">
          <span>
            {{ $t('general.description') }}
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </div>
        <div class="col-3">
          <span>
            {{ $t('form.fieldType') }}
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </div>
      </div>
      <div
        ref="listContainer"
        style="max-height: 35vh; overflow-y: auto"
      >
        <div
          v-for="(field, index) in fieldList"
          :key="index"
        >
          <FieldEditor
            class="mb-1"
            :ordinal-number.sync="field.ordinalNumber"
            :name.sync="field.name"
            :description.sync="field.description"
            :type.sync="field.type"
            :field-list="fieldList"
            :field-errors.sync="fieldErrors"
            :edit-form="editForm"
            @deleteField="deleteField(index)"
          />
        </div>
      </div>
      <div class="mt-3">
        <BButton
          size="sm"
          class="rounded-pill px-4"
          @click="addNewField"
        >
          <i class="fas fa-plus mr-1" /> {{ $t('template.addEntry') }}
        </BButton>
      </div>
    </div>
    <div>
      <hr>
      <Error
        v-if="displayedError"
        class="mb-2"
        :message="displayedError"
      />
      <SaveButton
        class="save-buttons"
        :show-remove="false"
        :disabled="!errorCheck"
        @cancel="$emit('close')"
        @save="createOrUpdate"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FieldEditor from '@/components/elements/FieldEditor';

export default {
  props: {
    editForm: {
      type: Boolean,
      default: false,
    },
    label: Object,
    fields: Array,
  },
  data() {
    return {
      fieldList: null,
      templateDescription: null,
      langSymbol: null,
      templateName: null,
      labelingLevel: null,
      attachToImportedSKUs: null,
      printerType: null,
      pending: false,
      fieldErrors: false,
      savedLabel: null,
    };
  },
  components: {
    FieldEditor,
  },
  computed: {
    ...mapGetters([
      'maxInputLength',
      'maxDescriptionLength',
    ]),
    ...mapState({
      list: state => state.labels.templates,
      printerTypes: state => (!state.discovery.dict ? [] : state.discovery.dict.PrinterTypes),
      labelingLevels: state =>
        state.settings.data?.labelingLevels?.sort((a, b) => a.ordinal - b.ordinal) || [],
    }),
    formattedLabelingLevels() {
      const labellingLevelsFormatted = this.labelingLevels?.map(t => ({
        value: t.name,
        text: t.name,
      }));
      const emptyLabellingLevel = [{
        value: null,
        text: '-',
      }];

      return emptyLabellingLevel.concat(labellingLevelsFormatted);
    },
    nameAndNumberTyped() {
      return this.fieldList.filter(field => field.name)?.length === this.fieldList?.length
        && this.fieldList.filter(field => field.ordinalNumber)?.length === this.fieldList?.length;
    },
    templateNameExist() {
      if (!this.templateName || this.editForm) return false;
      return this.list.find(l => l.name?.toUpperCase() === this.templateName?.toUpperCase());
    },
    templateNameError() {
      if (!this.templateName || this.editForm) return false;
      return this.templateName.length >= this.maxInputLength;
    },
    templateDescError() {
      if (!this.templateDescription) return false;
      return this.templateDescription.length >= this.maxDescriptionLength;
    },
    displayedError() {
      if (this.templateDescError) return this.$t('error.descriptionIsTooLong');
      if (this.templateNameError) return this.$t('error.nameIsTooLong');
      if (this.templateNameExist) return this.$t('error.nameAlreadyExists');
      return null;
    },
    errorCheck() {
      if (
        this.templateName
        && !this.templateNameError
        && !this.templateDescError
        && !this.templateNameExist
        && !this.fieldErrors
        && this.nameAndNumberTyped
        && this.printerType
      ) return true;
      return false;
    },
    cultureOptions() {
      return [{ value: null, text: '-' }]
        .concat(
          [
            'PL',
            'EN',
            'FR',
            'ES',
            'DE',
            'FI',
            'LT',
          ].map(x => ({ value: x, text: x })),
        );
    },
  },
  watch: {
    savedLabel() {
      this.fieldList = this.fields || this.savedLabel?.labelProperty || [];
      this.templateDescription = this.savedLabel?.description;
      this.templateName = this.savedLabel?.name;
      this.langSymbol = this.savedLabel?.culture || null;
      this.labelingLevel = this.savedLabel?.labelType || null;
      this.attachToImportedSKUs = this.savedLabel?.attachToImportedSKUs || false;
      this.printerType = this.savedLabel?.printerType;
    },
  },
  methods: {
    ...mapActions([
      'createLabelTemplate',
      'updateLabelTemplate',
    ]),
    createOrUpdate() {
      if (this.editForm) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      this.pending = true;
      this.createLabelTemplate({
        data: {
          name: this.templateName,
          description: this.templateDescription,
          culture: this.langSymbol,
          labelType: this.labelingLevel,
          labelProperty: this.fieldList || [],
          printerType: this.printerType,
          attachToImportedSKUs: this.attachToImportedSKUs,
        },
      }).then(({ data }) => {
        this.savedLabel = data;
        this.$emit('successMsg', this.$t('label.labelCreated'));
      }).catch(({ response: { data } }) => {
        this.$emit('failedMsg', data?.message || this.$t('label.labelCreateFailed'));
      }).finally(() => {
        this.pending = false;
        this.$emit('reload', this.savedLabel);
        this.$emit('close');
      });
    },
    update() {
      this.pending = true;
      this.updateLabelTemplate({
        params: {
          labelId: this.label.id,
        },
        data: {
          name: this.templateName,
          description: this.templateDescription,
          culture: this.langSymbol,
          labelType: this.labelingLevel,
          labelProperty: this.fieldList || [],
          printerType: this.printerType,
          attachToImportedSKUs: this.attachToImportedSKUs,
        },
      }).then(({ data }) => {
        this.savedLabel = data;
        this.$emit('successMsg', this.$t('label.labelUpdated'));
      }).catch(({ response: { data } }) => {
        this.$emit('failedMsg', data?.message || this.$t('label.labelUpdateFailed'));
      }).finally(() => {
        this.pending = false;
        this.$emit('reload', this.savedLabel);
        this.$emit('close');
      });
    },
    scrollToView() {
      const container = this.$refs.listContainer;
      container.scrollTop = container.scrollHeight;
    },
    addNewField() {
      this.fieldList = [
        ...this.fieldList,
        {
          ordinalNumber: null,
          description: null,
          name: null,
          type: null,
        },
      ];
      setTimeout(() => {
        this.scrollToView();
      }, 1);
    },
    deleteField(index) {
      this.fieldList = this.fieldList.slice(0, index).concat(this.fieldList.slice(index + 1));
    },
  },
  created() {
    this.savedLabel = this.label;
    this.fieldList = this.fields || this.savedLabel?.labelProperty || [];
    this.templateDescription = this.savedLabel?.description;
    this.templateName = this.savedLabel?.name;
    this.langSymbol = this.savedLabel?.culture || null;
    this.labelingLevel = this.savedLabel?.labelType || null;
    this.attachToImportedSKUs = this.savedLabel?.attachToImportedSKUs || null;
    this.printerType = this.savedLabel?.printerType;
  },
};
</script>
<style lang="scss" scoped>

  .table-title {
    font-weight: 700;
    font-size: 14px;
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
  }

</style>
