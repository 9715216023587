import { render, staticRenderFns } from "./SkuLabelForm.vue?vue&type=template&id=54f8404d&scoped=true"
import script from "./SkuLabelForm.vue?vue&type=script&lang=js"
export * from "./SkuLabelForm.vue?vue&type=script&lang=js"
import style0 from "./SkuLabelForm.vue?vue&type=style&index=0&id=54f8404d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f8404d",
  null
  
)

export default component.exports