<template>
  <div style="line-height: normal; padding-inline: 5px">
    <div>
      <div
        class="mb-1 mt-3"
        style="font-size: 1em; font-weight: 500 !important"
      >
        <i class="icon-order pr-1" />
        {{ $t('order.order') }}
      </div>
      <div class="py-2 pl-2">
        <div>
          <span class="label">
            {{ $t('general.description') }}:
          </span>
          {{ order.description }}
        </div>
        <div>
          <span class="label">
            {{ $t('order.orderNumber') }}:
          </span>
          {{ order.orderNumber }}
        </div>
        <div>
          <span class="label">
            {{ $t('date.plannedStartDate') }}:
          </span>
          {{ formatDate(order.plannedStartDate) }}
        </div>
        <div>
          <span class="label">
            {{ $t('date.plannedEndDate') }}:
          </span>
          {{ formatDate(order.plannedEndDate) }}
        </div>
        <div>
          <span class="label">
            {{ $t('date.startDate') }}:
          </span>
          {{ formatDate(order.startDate) }}
        </div>
        <div>
          <span class="label">
            {{ $t('date.endDate') }}:
          </span>
          {{ formatDate(order.endDate) }}
        </div>
      </div>
    </div>
    <div>
      <OrderField
        v-if="order.id"
        class="mt-0"
        :order-id="orderId"
        :order-execution-id="orderExecutionId"
      />
    </div>
    <div>
      <SkuFields
        v-if="sku"
        class="mt-0"
        :scrollable="false"
        :read-only="true"
        :sku-properties="sku.skuProperties"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import SkuFields from '@/components/mapper/SkuFields';
import OrderField from '@/components/order/OrderField';

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderId: Number,
    orderExecutionId: {
      type: Number,
      required: false,
      default: null,
    },
    labelingPointId: Number,
    orderInfo: Boolean,
  },
  data: () => ({
    sku: null,
    skuPending: false,
  }),
  components: {
    SkuFields,
    OrderField,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    orderForLabelingPoint() {
      return {
        orderId: this.orderId,
        labelingPointId: this.labelingPointId,
      };
    },
  },
  watch: {
    orderForLabelingPoint() {
      this.refresh();
    },
    orderInfo() {
      this.refresh();
    },
  },
  methods: {
    ...mapActions(['getSku']),
    formatDate(date) {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return date ? moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS') : '-';
    },
    refresh() {
      this.skuPending = true;
      this.getSku({
        params: {
          skuId: this.order.skuId,
        },
      })
        .then(({ data }) => {
          this.sku = data;
        })
        .finally(() => {
          this.skuPending = false;
        });
    },
  },
  created() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>

  .label {
    color: rgba(#333, 0.8);
  }

</style>
