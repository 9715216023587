<template>
  <div>
    <h3
      style="font-size: 18px"
      class="mb-0"
    >
      <i class="fas fa-star pr-2" />
      {{ $t('users.preferences') }}
    </h3>
    <hr>
    <div v-if="!pending">
      <div v-if="areas.length > 0">
        <div
          class="text-left"
        >
          <label class="mb-0 small font-weight-bold required mt-2">
            {{ $t('users.preferredArea') }}
          </label>
          <BFormSelect
            v-model="areaId"
            :options="areasList"
            @change="areaUpdated"
          />
        </div>
      </div>
      <div v-if="lines.length > 0">
        <div
          class="text-left"
        >
          <label class="mb-0 small font-weight-bold required mt-2">
            {{ $t('users.preferredLine') }}
          </label>
          <BFormSelect
            v-model="lineId"
            :options="linesList"
          />
        </div>
      </div>
      <div class="font-weight-bold small mb-3 mt-3">
        <div class="mt-3">
          <SaveButton
            :pending="pending"
            :remove-pending="pending"
            :show-remove="false"
            @cancel="$emit('close')"
            @save="update()"
          />
        </div>
      </div>
    </div>
    <div
      v-else
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    pending: false,
    areaId: null,
    lineId: null,
  }),
  computed: {
    ...mapGetters([
      'defaultAreaId',
      'defaultLineId']),
    ...mapState({
      areas: state => state.areas.list || [],
      lines: state => state.lines.list || [],
    }),
    areasList() {
      const defaulList = [
        {
          text: '-',
          value: null,
        },
      ];
      const areas = this.areas?.map(area => ({
        text: area.description || area.name,
        value: area.id,
      })) || [];

      return defaulList.concat(areas);
    },
    linesList() {
      const defaulList = [
        {
          text: '-',
          value: null,
        },
      ];
      const lines = this.lines
        ?.filter(line => !this.areaId || line.areaId === this.areaId)
        ?.map(line => ({
          text: line.description || line.name,
          value: line.id,
        })) || [];

      return defaulList.concat(lines);
    },
  },
  methods: {
    ...mapActions([
      'setUserSetting',
      'getUserSettings',
    ]),
    showSuccessMsg() {
      this.$bvToast.toast(this.$t('general.description'), {
        title: this.$t('general.saved'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(message) {
      this.$bvToast.toast(message, {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
    areaUpdated() {
      if (!this.areaId || !this.lineId) {
        return;
      }
      const line = this.lines.find(l => l.id === this.lineId);
      if (line.areaId !== this.areaId) {
        this.lineId = null;
      }
    },
    update() {
      this.setUserSetting({
        data: {
          key: 'defaultareaid',
          value: String(this.areaId || ''),
        },
      })
        .then(() => this.setUserSetting({
          data: {
            key: 'defaultlineid',
            value: String(this.lineId || ''),
          },
        }))
        .then(() => {
          this.showSuccessMsg();
          this.$emit('close');
        })
        .catch(({ response: { data } }) => {
          this.showFailedMsg(data.message);
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.areaId = this.defaultAreaId;
    this.lineId = this.defaultLineId;
  },
};
</script>

<style lang="scss" scoped>

</style>
