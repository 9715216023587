<script>
export default {
  name: 'CockpitOffcanvas',
  props: {
    tabsKey: String,
    show: Boolean,
    showAuditButton: Boolean,
    showBackButton: Boolean,
    tabIndex: Number,
    title: String,
    icon: String,
    tabs: Array,
  },
  emits: [
    'update:show',
    'update:tabIndex',
    'audit-button-clicked',
    'back-button-clicked',
  ],
  data: () => ({
    currentTab: 0,
    closeTimeout: null,
    innerShow: false,
  }),
  computed: {
    showActions() {
      return this.$slots.actions
        && this.$slots.actions.filter(s => s.tag).length > 0;
    },
  },
  watch: {
    show: {
      handler(value) {
        clearTimeout(this.closeTimeout);
        this.innerShow = !!value;
      },
    },
    tabs: {
      handler() {
        if (this.currentTab) return;
        this.currentTab = this.tabIndex || 0;
      },
      immediate: true,
    },
    currentTab: {
      handler(value) {
        this.$emit('update:tabIndex', value);
      },
      immediate: true,
    },
    tabIndex: {
      handler(value) {
        this.currentTab = value;
      },
      immediate: true,
    },
  },
  methods: {
    handleUpdateShow(value) {
      if (!value) {
        this.innerShow = false;
        clearTimeout(this.closeTimeout);
        this.closeTimeout = setTimeout(() => {
          this.$emit('update:show', value);
        }, 300);
      } else {
        this.$emit('update:show', value);
      }
    },
  },
};
</script>

<template>
  <Offcanvas
    class="offcanvas"
    :show="innerShow"
    passive
    @update:show="handleUpdateShow"
  >
    <div
      class="inner-canvas"
      :class="{
        'hide-tabs': !tabs || tabs.length === 1,
      }"
    >
      <div
        class="close-button"
        @click="$emit('update:show', false)"
      >
        <i class="fas fa-times" />
      </div>

      <div
        v-if="showAuditButton"
        class="audit-button"
        @click="$emit('audit-button-clicked')"
      >
        <i class="fas fa-clock" />
      </div>

      <div class="title">
        <i
          v-if="icon && !showBackButton"
          class="fas"
          :class="icon"
        />
        <i
          v-if="showBackButton"
          class="fas fa-chevron-circle-left back-button"
          @click="$emit('back-button-clicked')"
        />

        {{ title }}
      </div>
      <BTabs
        :key="tabsKey"
        v-model="currentTab"
        :nav-class="{
          'hide-tabs': !tabs || tabs.length === 1,
        }"
        no-fade
        pills
      >
        <div class="offcanvas-content">
          <BTab
            v-for="(tab, index) in tabs"
            :key="tab"
            :title="tab"
          >
            <slot
              v-if="index === currentTab"
            />
          </BTab>
        </div>
      </BTabs>

      <div
        v-if="showActions"
        class="offcanvas-actions"
      >
        <slot name="actions" />
      </div>
    </div>
  </Offcanvas>
</template>

<style scoped lang="scss">
.inner-canvas {
  width: calc(100% + 3rem);
  height: calc(100% + 3rem);
  margin: -1.5rem;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .close-button, .audit-button {
    position: absolute;
    top: 1.4rem;
    right: 1rem;
    padding: 0.5rem;
    line-height: 0;
    cursor: pointer;
    font-size: 26px;
    color: #979797;

    &.audit-button {
      right: 3.2rem;
      margin-top: 3px;
      font-size: 20px;
    }
  }

  .title {
    padding: 1rem;
    font-size: 22px;
    font-weight: 400;

    i {
      margin-right: 4px;
    }
  }

  .offcanvas-content {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .offcanvas-actions {
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    border-top: 1px solid #ECECEC;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .back-button {
    cursor: pointer;
    font-size: 26px;
    transform: translateY(2px);
    color: #979797;
  }

  &.hide-tabs {
    .title {
      border-bottom: 1px solid #ECECEC;
    }

    &:deep() {
      .tabs {
        margin-bottom: 0;
      }
    }
  }

  &:deep() {
    .tabs {
      height: calc(100% - 185px);
      flex-grow: 1;
      margin-bottom: 40px;

      .tab-content {
        height: 100%;

        .tab-pane {
          padding: 2rem;
        }
      }

      .nav {
        border-bottom: 1px solid #ECECEC;
        margin-bottom: 0;
        font-size: 14px;

        &.hide-tabs {
          display: none;
        }

        .nav-item {
          width: 25%;
          text-align: center;

          .nav-link {
            background: transparent;
            color: rgba(#000, 0.5);
            border-radius: 0;
            margin-bottom: -3px;

            &.active {
              color: black;
              font-weight: 600;
              border-bottom: 5px solid rgba(118, 176, 51, 1);
            }
          }
        }
      }
    }
  }
}
</style>
