import { render, staticRenderFns } from "./CodeEditor.vue?vue&type=template&id=62333624&scoped=true"
import script from "./CodeEditor.vue?vue&type=script&lang=js"
export * from "./CodeEditor.vue?vue&type=script&lang=js"
import style0 from "./CodeEditor.vue?vue&type=style&index=0&id=62333624&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62333624",
  null
  
)

export default component.exports