import Vapi from 'vuex-rest-api';
import Vue from 'vue';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getSkus',
    property: 'data',
    path: () => '/skus',
  })
  .get({
    action: 'getSkusMappedToLabel',
    path: ({ labelId }) => `/label/${labelId}/skus`,
  })
  .get({
    action: 'getSku',
    path: ({ skuId }) => `/sku/${skuId}`,
  })
  .get({
    action: 'getSkusByIds',
    path: () => '/skus/byIds',
  })
  .delete({
    action: 'deleteSkus',
    path: () => '/skus',
  })
  .put({
    action: 'restoreSkus',
    path: () => '/skus/restore',
  })
  .put({
    action: 'updateSku',
    path: ({ skuId }) => `/sku/${skuId}`,
    onSuccess: (s, { data }) => {
      const skuIndex = s.data.items.findIndex(sku => sku.id === data.id);
      if (skuIndex !== -1) {
        Vue.set(s.data.items, skuIndex, data);
      }
    },
  })
  .getStore();

export default store;
