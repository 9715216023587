<template>
  <div class="d-flex flex-column">
    <div class="my-2 pr-2">
      <h3
        style="font-size: 20px"
        class="mb-0"
      >
        <i class="fas fa-table-cells pr-2" />
        {{ $t('template.fields') }}
      </h3>
    </div>

    <div
      v-if="fields"
    >
      <div
        v-for="el in fields"
        :key="el.id"
        class=" d-flex align-items-center justify-content-between"
        :class="{
          selected: el.id === selected,
          'field-row': !readOnly,
          'field-row-read-only': readOnly,
        }"
        @click="$emit('select', el.id)"
      >
        <i
          class="col-1 fas fa-table-cells-large align-self-center px-1"
          style="opacity: 0.7"
        />
        <div
          class="col-7 px-1"
          style="overflow-wrap: break-word"
        >
          <span class="font-weight-bold">
            {{ el.ordinalNumber ? `${el.ordinalNumber} | ` : '' }}{{ el.name }}
          </span>
          <span
            v-if="el.description"
            class="d-block text-secondary desc-style pt-2"
          >
            {{ el.description }}
          </span>
        </div>
        <div class="col-4 text-right">
          <div
            v-if="el.type"
            class="badge text-secondary ml-2 template-pill"
          >
            {{ el.type.toUpperCase() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: Number,
    fields: Array,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .desc-style {
    font-size: 0.7rem;
    opacity: 0.7;
    line-height: 1;
  }

  .field-row-read-only {
    padding-block: 10px;
    margin-block: 10px;
    line-height: 0.8;
  }

  .template-pill {
    opacity: 0.8;
    border-radius: 3px;
    background-color: white;
    font-size: 0.688rem;
    align-self: center;
  }

  .field-row {
    align-items: center;
    padding: 15px;
    margin-block: 10px;
    line-height: 0.8;
    border-radius: 7px;
    background-color: #F5F5F5;
    box-shadow: 0 0 2px rgba(0, 0, 4, 0.1);
    border-color: #333;

    &:hover {
      background-color: rgba(200, 200, 200, 0.3);
      cursor: pointer;
    }
  }

  .selected {
    background-color: rgba(200, 200, 200, 0.5);
  }

</style>
