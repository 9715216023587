<template>
  <div>
    <ProSelect
      v-model="areaId"
      show-all-option
      dropdown-width="175px"
      :hide-dropdown="selected ? true : false"
      :options="areasOptions"
      :label="$t('area.areas')"
      :disabled="pending"
      class="pr-2 mb-3"
      :class="pending ? 'disabled-content' : ''"
      :box-height="boxHeight"
      icon-class="fas fa-border-none"
      icon-color-class="app-color"
      dynamic-showing-list
      :light="light"
      @input="filterUpdated"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    light: Boolean,
    boxHeight: {
      type: String,
      default: '32px',
    },
    pending: {
      type: Boolean,
      default: false,
    },
    selected: Object,
  },
  data() {
    return {
      areaId: null,
    };
  },
  computed: {
    ...mapGetters([
      'selectedFilterAreaId',
      'selectedFilterLineId',
      'linesFromArea',
    ]),
    ...mapState({
      areas: state => state.areas.list || [],
    }),
    areasOptions() {
      return this.areas
        .map(a => ({
          ...a,
          label: a.name,
          value: a.id,
          iconClass: 'fas fa-border-none app-color',
        })) || [];
    },
  },
  watch: {
    selectedFilterAreaId() {
      this.areaId = this.selectedFilterAreaId;
    },
  },
  methods: {
    filterUpdated() {
      this.$store.commit('setFilterAreaId', this.areaId);
      if (!this.areaId || !this.linesFromArea(this.areaId).find(l => l.id === this.selectedFilterLineId)) {
        this.$store.commit('setFilterLineId', null);
      }
    },
  },
  created() {
    this.areaId = this.selectedFilterAreaId;
  },
};
</script>

<style lang="scss" scoped>

</style>
