<template>
  <form @submit.prevent="!sendToPrinter">
    <h5>
      <span class="font-weight-bold pl-3">
        {{ $t('printRequest.sendAgainToPrinter') }}
      </span>
    </h5>
    <OrderExecution
      :key="order.id"
      v-bind="order"
      class="m-4"
    />
    <div v-if="metaData && praseMeta.length">
      <h5>
        <span class="font-weight-bold pl-3">
          {{ $t('form.form') }}
        </span>
      </h5>
      <div class="row">
        <JsonSchemaForm
          v-if="!formPending"
          class="col-7 my-2 ml-5 px-5"
          :data.sync="settlementFormData"
          :schema="metaData"
          @updated:validation="settlementValidationUpdated"
        />
        <div v-if="fieldsWithUrl.length">
          <button
            class="col-5 mr-2 btn btn-block btn-primary manuall-button"
            @click="getValues"
          >
            <i class="fas fa-redo pr-2" />
            {{ $t('form.refresh') }}
          </button>
        </div>
      </div>
      <div
        v-if="error"
        class="text-danger small"
      >
        {{ error }}
      </div>
    </div>
    <div
      style="margin-top: 25px; text-align: center"
    >
      <button
        class="col-3 btn btn-info btn-circle button-style mx-2"
        @click="sendToPrinter()"
      >
        <div>
          <i
            class="icon-print-request"
            style="font-size: 1.8em"
          />
          <br>
          <span class="text-style">
            {{ $t('form.send') }}
          </span>
        </div>
      </button>
      <button
        class="col-3 btn btn-secondary btn-circle button-style mx-2"
        @click="$emit('close')"
      >
        <div>
          <i
            class="fas fa-ban"
            style="font-size: 1.8em"
          />
          <br>
          <span class="text-style">
            {{ $t('general.cancel') }}
          </span>
        </div>
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import axios from 'axios';
import VueFormGenerator from 'vue-form-generator';
import { propertyValueTypes } from '@/utils/dictionary';
import JsonSchemaForm from '@/components/jsonSchemaForm/JsonSchemaForm';
import OrderExecution from './OrderExecution';

export default {
  props: {
    quest: Object,
    formDataValid: Boolean,
    template: Boolean,
    order: Object,
  },
  data: () => ({
    settlementFormData: {},
    error: null,
    metaData: null,
    fieldsWithUrl: null,
    multipleUrl: null,
    scaleInfo: null,
    praseMeta: null,
    formPending: false,
  }),
  components: {
    JsonSchemaForm,
    OrderExecution,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      orderPropertyTypes: state => state.orders.properties || [],
      dictionaries: state => state.dictionaries.list || [],
    }),
    scaleValue() {
      return this.scaleInfo ? (Math.round((this.scaleInfo.value) * 1000) / 1000) : null;
    },
  },
  watch: {
    scaleInfo: {
      immediate: true,
      deep: true,
      handler() {
        this.formPending = true;
        this.$nextTick(() => {
          setTimeout(() => {
          }, 1);
          this.formPending = false;
        });
      },
    },
  },
  methods: {
    ...mapActions(['getLabelingPointWithMeta', 'rePrint']),
    loadOrder() {
      this.getLabelingPointWithMeta({
        params: {
          id: this.order.labelingPointId,
        },
      })
        .then(({ data }) => {
          const parsed = JSON.parse(data.metaData);
          this.metaData = {
            ...parsed,
            fields: parsed.fields
              ?.map(field => {
                const mappedField = { ...field };
                const orderProperty = this.orderPropertyTypes.find(propType => propType.name === field.order);
                const dictionary = this.dictionaries
                  .find(dict => dict.id === (orderProperty?.dictionaryId || mappedField.dictionaryId));
                mappedField.entries = dictionary?.entries || mappedField.entries || [];
                mappedField.values = mappedField.entries.map(entry => entry.key);
                return mappedField;
              }),
          };
          this.praseMeta = this.metaData?.fields;
          this.settlementFormData = VueFormGenerator.schema.createDefaultObject(this.metaData);
          this.fieldsWithUrl = this.metaData?.fields.filter(x => x.inputType === 'number');
          this.multipleUrl = this.fieldsWithUrl.map(y => ({ url: y.url }));
        })
        .finally(() => {
          this.getValues();
        });
    },
    settlementValidationUpdated(isValid) {
      this.settlementFormValid = isValid;
      if (isValid && this.error) {
        this.error = null;
      }
    },
    getInfo(i) {
      axios.get(this.multipleUrl[i].url)
        .then(response => {
          this.scaleInfo = response?.data;
          this.settlementFormData[this.fieldsWithUrl.find(x => x.url === this.multipleUrl[i].url)
            .model] = this.scaleInfo ? this.scaleValue.toString() : null;
        })
        .catch(({ response: { data } }) => {
          this.scaleInfo = null;
          this.$bvToast.toast(data?.title, {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: this.timeOfNotification,
          });
        });
    },
    getValues() {
      for (let i = 0; i < this.multipleUrl?.length; i += 1) {
        this.getInfo(i);
      }
    },
    getValueForDictionaryEntry(entries, key) {
      return entries.find(entry => entry.key === key)?.value ?? '';
    },
    sendToPrinter() {
      this.rePrint({
        data:
          this.praseMeta.map(x => {
            let value = this.settlementFormData[x.model];
            if (x.entries?.length > 0
            // for dictionary type fields backend calculates values
            && x.orderPropertyType !== propertyValueTypes.dictionary) {
              value = Array.isArray(value)
                ? value.map(v => this.getValueForDictionaryEntry(x.entries, v))
                : this.getValueForDictionaryEntry(x.entries, value);
            }
            return {
              name: x.order,
              value: Array.isArray(value)
                ? JSON.stringify(value)
                : value?.toString(),
            };
          }),
        params: {
          id: this.order.id,
          query: { lpId: this.order.labelingPointId },
        },
      })
        .then(() => {
          this.$bvToast.toast(this.$t('printRequest.sendAgainToPrinter'), {
            title: this.$t('general.saved'),
            variant: 'success',
            autoHideDelay: this.timeOfNotification,
          });
          this.$emit('close');
          this.$emit('sent');
        })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data?.message, {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: this.timeOfNotification,
          });
        });
    },
  },
  created() {
    this.loadOrder();
  },
};
</script>

<style lang="scss" scoped>
  h5 {
    display: inline-block;
    line-height: 1.5;
  }

  .form-group {
    margin-top: 8px;

    label {
      margin-bottom: 5px;
    }
  }

  .button-style {
    border-radius: 100%;
    height: 75px;
    width: 75px;
  }

  .manuall-button {
    border-radius: 15px;
    max-width: 175px;
    font-size: 0.8em;
    margin-top: 40px;
  }

  .text-style {
    font-size: 0.8em;
    font-weight: 500;
  }

</style>
