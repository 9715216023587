<template>
  <div id="app">
    <ScreenMeasure @mobile="setMobile" />
    <RouterView />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ScreenMeasure from '@core/utils/screenMeasure';

export default {
  components: {
    ScreenMeasure,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated']),
  },
  watch: {
    oidcIsAuthenticated() {
      this.init();
    },
  },
  methods: {
    ...mapActions([
      'getSystemDictionaries',
    ]),
    async init() {
      if (!this.oidcIsAuthenticated) return;
      this.$store.dispatch('initUserPermissions', ['logicprint5', 'config']);
      this.$store.dispatch('initUserConfig');
    },
    setMobile(v) {
      this.$store.commit('setMobile', v);
    },
  },
  created() {
    this.getSystemDictionaries();
    this.init();
  },
};
</script>
