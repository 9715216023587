<template>
  <div
    style="height: 60vh; overflow-y:auto"
    class="flex-fill"
  >
    <SimpleTable
      class="extended-table"
      :items="list"
      :fields="tableFields"
      :selectable="true"
      :select-mode="'range'"
      @selected="selected = $event"
      @row-clicked="handleRowClick"
    >
      <template #head(left-options)>
        <tr>
          <th variant="secondary">
            <CustomCheckbox
              id="checkbox_for_all"
              :value="checkAll"
              @update:value="checkAllFields"
            />
            <BPopover
              v-if="suggestFor && list.length"
              :show.sync="checkAll"
              container="post-list"
              :target="`checkbox_for_all`"
              custom-class="checkbox-popover"
              triggers=""
              placement="left"
            >
              <div class="bg-white">
                <button
                  class="btn btn-block btn-sm btn-outline-secondary mt-1"
                  @click.stop="editList(showDeleted ? 'restore' : 'delete')"
                >
                  <BSpinner
                    v-if="pending"
                    variant="dark"
                    small
                    class="pr-2"
                  />
                  <i
                    v-else
                    class="pr-2"
                    :class="showDeleted ? 'fas fa-eye' : 'fas fa-eye-slash '"
                  />
                  {{ showDeleted ? $t('general.activate') : $t('general.inactivate') }}
                </button>
              </div>
            </BPopover>
          </th>
        </tr>
      </template>
      <template #cell(left-options)="{item}">
        <CustomCheckbox
          v-if="selectMany"
          :id="`checkbox_for_${item.id}`"
          class="pl-2"
          :value="checked[item.id]"
          @update:value="check(item.id, $event)"
        />
        <BPopover
          v-if="suggestFor && suggestFor == item.id"
          :show.sync="checkboxPopover"
          container="post-list"
          :target="`checkbox_for_${item.id}`"
          custom-class="checkbox-popover"
          triggers=""
          placement="left"
        >
          <div class="bg-white">
            <button
              class="btn btn-block btn-sm btn-outline-secondary mt-1"
              @click.stop="editList(showDeleted ? 'restore' : 'delete')"
            >
              <BSpinner
                v-if="pending"
                variant="dark"
                small
                class="pr-2"
              />
              <i
                v-else
                class="pr-2"
                :class="showDeleted ? 'fas fa-eye' : 'fas fa-eye-slash '"
              />
              {{ showDeleted ? $t('general.activate') : $t('general.inactivate') }}
            </button>
          </div>
        </BPopover>
      </template>
      <template #cell(mappedSku)="{item}">
        <i
          class="fas fa-box-open pr-2"
        />
        {{ item.mappedSku }}
      </template>
      <template #cell(name)="{item}">
        <span>
          {{ item.name }}
        </span>
        <span
          class="d-block text-secondary desc-style"
        >
          {{ item.description }}
        </span>
      </template>
      <template #cell(mappedLabelingPoints)="{item}">
        <i
          class="fas fa-tags pr-2"
        />
        {{ item.mappedLabelingPoints }}
      </template>
      <template
        v-if="!showDeleted"
        #cell(right-options)="{item}"
      >
        <div
          class="ml-auto"
        >
          <BDropdown
            class="m-md-2"
            variant="link"
            right
            no-caret
          >
            <template #button-content>
              <i class="fas fa-ellipsis-h" />
            </template>

            <BDropdownItem
              @click.stop="openCloneForm(item)"
            >
              <i class="fas fa-clone mr-2" />
              {{ $t('general.clone') }}
            </BDropdownItem>
          </BDropdown>
        </div>
      </template>
    </SimpleTable>
    <div>
      <LModal
        :show.sync="cloneForm"
      >
        <TemplateCloneForm
          :clone-element="cloneElement"
          @successMsg="showSuccessMsg()"
          @failedMsg="showFailedMsg()"
          @close="cloneForm = false"
          @reload="reloadLabels()"
        />
      </LModal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SimpleTable from '@/components/tables/SimpleTable';
import TemplateCloneForm from '@/components/label/TemplateCloneForm';

export default {
  props: {
    list: Array,
    tableFields: Array,
    selectMany: Boolean,
    noDataInfo: String,
    showDeleted: Boolean,
    listReloaded: Boolean,
  },
  data: () => ({
    checkboxPopover: false,
    suggestFor: null,
    checked: {},
    cloneForm: false,
    checkAll: false,
    selected: null,
    cloneElement: null,
  }),
  components: {
    SimpleTable,
    TemplateCloneForm,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    checkedIds() {
      return Object.keys(this.checked)
        .filter(id => this.checked[id]);
    },
  },
  watch: {
    showDeleted() {
      this.selected = null;
      this.checked = {};
      this.checkboxPopover = false;
    },
    listReloaded(v) {
      if (!v) {
        this.selected = null;
        this.checked = {};
        this.checkboxPopover = false;
        this.pending = false;
      }
    },
    checkedIds(ids) {
      if (ids.length > 0) {
        this.checkboxPopover = true;
      }
      if (this.list.length && ids.length === this.list.length) {
        this.checkAll = true;
        this.suggestFor = -1;
      } else if (ids.length !== this.list.length) {
        this.checkAll = false;
      }
    },
  },
  methods: {
    ...mapActions([
      'deleteLabelTemplates',
      'restoreLabelTemplates',
    ]),
    showSuccessMsg() {
      this.$bvToast.toast(this.$t('label.labelClone'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: 2000,
      });
    },
    showFailedMsg() {
      this.$bvToast.toast(this.$t('label.labelCloneFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: 2000,
      });
    },
    openCloneForm(item) {
      this.cloneElement = item;
      this.cloneForm = true;
    },
    reloadLabels() {
      this.$emit('reload');
      this.checked = {};
      this.checkboxPopover = false;
    },
    checkAllFields() {
      this.checkAll = !this.checkAll;
      if (this.checkAll === false) {
        this.clearChecked();
      } else {
        for (let i = 1; i <= this.list.length; i += 1) {
          this.$set(this.checked, this.list[i - 1]?.id, true);
        }
        this.suggestFor = -1;
      }
    },
    check(id, v) {
      this.$set(this.checked, id, v);
      if (v) {
        this.suggestFor = id;
      } else if (id === this.suggestFor) {
        [this.suggestFor] = this.checkedIds;
      } else {
        this.suggestFor = null;
      }
    },
    clearChecked() {
      this.checked = {};
      this.checkboxPopover = false;
      this.suggestFor = null;
    },
    async handleRowClick(selected, index, ev) {
      const tagetClassList = ev.target.classList;
      if (
        tagetClassList.contains('square-box')
        || tagetClassList.contains('color-box-container')
        || tagetClassList.contains('clickable')
      ) {
        return;
      }

      this.checkboxPopover = false;
      this.$emit('selected', selected);
    },
    editList(type) {
      const labelId = this.checkedIds
        .map(x => parseInt(x, 10))
        .filter(x => x);
      this.suggestFor = null;
      this.$bvModal.msgBoxConfirm(this.$t('general.areYouSure'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        okTitle: this.$t('general.yes'),
        cancelTitle: this.$t('general.no'),
        centered: true,
      })
        .then(value => {
          if (value) {
            this.pending = true;
            let request = Promise.resolve();
            if (type === 'delete') {
              request = this.deleteLabelTemplates({
                params: {
                  query: {
                    labelId,
                  },
                },
              });
            } else {
              request = this.restoreLabelTemplates({
                params: {
                  query: {
                    labelId,
                  },
                },
              });
            }

            request.then(() => {
              this.$bvToast.toast(this.$t('sku.edit'), {
                title: this.$t('general.saved'),
                variant: 'success',
                autoHideDelay: this.timeOfNotification,
              });
            })
              .catch(({ response: { data } }) => {
                this.$bvToast.toast(data.message, {
                  title: this.$t('general.error'),
                  variant: 'danger',
                  autoHideDelay: this.timeOfNotification,
                });
              })
              .finally(() => {
                this.$emit('reload');
              });
          }
        })
        .finally(() => {
          this.suggestFor = this.selected.id;
          if (this.checkAll) {
            this.suggestFor = -1;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~@/styles/vars.icss";

  .extended-table :deep(th) {
    padding: 0 0.75rem !important;
  }

  .extended-table :deep(td) {
    padding: 0 0.75rem !important;
  }

  .extended-table :deep(tr) {
    transition: 0.2s all;
    cursor: pointer;

    .color-box.square-box {
      transition: 0.2s all;
    }

    &:hover {
      background-color: #ECECEC;

      .color-box.square-box {
        background-color: #D4D4D4 !important;
      }
    }
  }

  .extended-table :deep(thead) {
    tr {
      height: 40px !important;
    }
  }

  .extended-table :deep(tbody) {
    tr {
      height: 50px;
    }
  }

  .extended-table :deep(.left-options) {
    min-width: 40px;
    max-width: 40px;
  }

  .extended-table :deep(.name) {
    text-align: left !important;
  }

  .checkbox-popover {
    z-index: 10;
  }

  .desc-style {
    font-size: 0.7rem;
    opacity: 0.7;
  }

</style>
