<script>
import CockpitMatryoshkaItemHistoryListItem
from '@/components/cockpit/matryoshka/history/CockpitMatryoshkaItemHistoryListItem.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CockpitMatryoshkaItemHistoryList',
  props: {
    labelArchiveId: Number,
  },
  data: () => ({
    loadingDetails: false,
    itemDetails: {},
    history: [],
  }),
  components: {
    CockpitMatryoshkaItemHistoryListItem,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    historyItems() {
      return this.itemDetails?.labelStatusHistory || [];
    },
  },
  watch: {
    labelArchiveId: {
      handler() {
        this.getDetails();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('labelArchive', [
      'getLabelArchiveItem',
    ]),
    async fetchLabelArchiveItem() {
      if (!this.labelArchiveId) return;

      const { plantCode } = this.globalSettings;
      const { data } = await this.getLabelArchiveItem({
        params: {
          id: this.labelArchiveId,
          plantCode,
        },
      });
      this.itemDetails = { ...data };
    },
    async getDetails() {
      this.loadingDetails = true;

      await this.fetchLabelArchiveItem();

      this.loadingDetails = false;
    },
  },
};
</script>

<template>
  <div>
    <Loader
      v-if="loadingDetails"
    />

    <div
      v-else
      class="history-list"
    >
      <CockpitMatryoshkaItemHistoryListItem
        v-for="item in historyItems"
        :key="item.labelStatus"
        :status="item.labelStatus"
        :timestamp="item.timestamp"
        class="history-list-item"
      />

      <div
        v-if="historyItems.length === 0"
        class="text-center mt-5 text-black-50"
      >
        <h2 class="fas fa-ban" />
        <p class="mb-0">
          {{ $t('form.noData') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.history-list {
  .history-list-item {
    border-bottom: 1px solid #F0F1F3;

    &:first-child {
      border-top: 1px solid #F0F1F3;
    }
  }
}
</style>
