export const propertyValueTypes = {
  string: 'STRING',
  decimal: 'DECIMAL',
  integer: 'INT',
  boolean: 'BOOL',
  dictionary: 'DICTIONARY',
};

export const labelPropertyTypes = {
  remote: 'Remote',
  previewText: 'PreviewText',
};

export default {
  propertyValueTypes,
  labelPropertyTypes,
};
