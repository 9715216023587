<template>
  <div class="row modal-container">
    <div class="col-9 main-form p-4">
      <h5
        class="mb-0 mx-3"
      >
        <span>
          <i class="fas fa-code-pull-request pr-2" />
          {{ $t('lineLabellingPoint.labellingPointMapping') }}
        </span>
      </h5>

      <div class="mx-3">
        <div class="col-12 p-0">
          <div class="my-3">
            <label class="mb-1 table-title">
              {{ $t('labellingPoint.labellingPoint') }}
            </label>
            <BFormInput
              :value="name"
              :placeholder="$t('labellingPoint.labellingPoint') + '...'"
              :disabled="true"
            />
          </div>
          <div class="my-3">
            <label class="mb-1 table-title">
              {{ $t('line.lines') }}
            </label>
            <Multiselect
              v-model="linesIds"
              :multiple="true"
              :options="formattedLines"
              :select-options="formattedLines"
              label="text"
              :select-label="''"
              :deselect-label="''"
              :selected-label="''"
              :option-height="20"
              :preserve-search="true"
              class="select-style"
              track-by="text"
              :close-on-select="false"
              :clear-on-select="false"
            >
              <template #option="{ option }">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    :checked="linesIds.map((x) => x.value).includes(option.value)"
                    class="custom-control-input"
                  >
                  <label class="custom-control-label d-flex">
                    <div class="main-selector-text">
                      {{ option.text }}
                    </div>
                  </label>
                </div>
              </template>
            </Multiselect>
          </div>
          <div class="my-3">
            <label class="mb-1 table-title">
              <span>
                {{ $t('printer.printers') }}
              </span>
            </label>
            <Multiselect
              v-model="printersIds"
              :multiple="true"
              :options="formattedPrinters"
              :select-options="formattedPrinters"
              label="text"
              :select-label="''"
              :deselect-label="''"
              :selected-label="''"
              :option-height="20"
              :preserve-search="true"
              class="select-style"
              track-by="text"
              :close-on-select="false"
              :clear-on-select="false"
            >
              <template #option="{ option }">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    :checked="printersIds.map((x) => x.value).includes(option.value)"
                    class="custom-control-input"
                  >
                  <label class="custom-control-label d-flex">
                    <div class="main-selector-text">
                      {{ option.text }}
                    </div>
                    <div
                      v-if="option.printerType"
                      class="secondary-selector-text"
                    >
                      {{ option.printerType }}
                    </div>
                  </label>
                </div>
              </template>
            </Multiselect>
          </div>
          <div
            class="my-3"
          >
            <label class="mb-1 table-title">
              {{ $t('labelTemplates') }}
            </label>
            <Multiselect
              v-model="labelTemplatesIds"
              :multiple="true"
              :options="formattedLabelTemplates"
              :select-options="formattedLabelTemplates"
              label="text"
              :select-label="''"
              :deselect-label="''"
              :selected-label="''"
              :option-height="20"
              :preserve-search="true"
              class="select-style"
              track-by="text"
              :close-on-select="false"
              :clear-on-select="false"
            >
              <template #option="{ option }">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    :checked="labelTemplatesIds.map((x) => x.value).includes(option.value)"
                    class="custom-control-input"
                  >
                  <label class="custom-control-label d-flex">
                    <div class="main-selector-text">
                      {{ option.text }}
                    </div>
                    <div
                      v-if="option.printerType"
                      class="secondary-selector-text"
                    >
                      {{ option.printerType }}
                    </div>
                  </label>
                </div>
              </template>
            </Multiselect>
          </div>
          <div class="d-flex my-3">
            <BFormCheckbox
              v-model="allOrderTypes"
              class="text-left"
              switch
              size="lg"
            />
            <div class="table-title mt-1">
              <span>
                {{ $t('order.allowAllOrderTypes') }}
              </span>
            </div>
          </div>
          <div
            v-if="!allOrderTypes"
            class="my-3"
          >
            <label class="mb-1 table-title">
              {{ $t('order.orderTypes') }}
            </label>
            <Multiselect
              v-model="orderTypesIds"
              :multiple="true"
              :options="formattedOrderTypes"
              :select-options="formattedOrderTypes"
              label="text"
              :select-label="''"
              :deselect-label="''"
              :selected-label="''"
              :option-height="20"
              :preserve-search="true"
              class="select-style"
              track-by="text"
              :close-on-select="false"
              :clear-on-select="false"
            >
              <template #option="{ option }">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    :checked="orderTypesIds.map((x) => x.value).includes(option.value)"
                    class="custom-control-input"
                  >
                  <label class="custom-control-label d-flex">
                    <div class="main-selector-text">
                      {{ option.text }}
                    </div>
                  </label>
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>

      <hr class="mx-3">

      <div>
        <SaveButton
          class="save-buttons"
          :show-remove="false"
          @cancel="$emit('close')"
          @save="save()"
        />
      </div>
    </div>
    <div class="col-3 pt-5">
      <div class="my-3">
        <label class="mb-1 table-title">
          <span>
            {{ $t('labellingPoint.labellingLevels') }}
          </span>
        </label>
        <div class="desc-style">
          {{ labelingLevel }}
        </div>
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          <span>
            {{ $t('general.multiOrderSupport') }}
          </span>
        </label>
        <div class="desc-style">
          {{ multiOrderSupport ? $t('general.yes') : $t('general.no') }}
        </div>
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          <span>
            {{ $t('labellingPoint.armingDevices') }}
          </span>
        </label>
        <div class="desc-style">
          {{ mapping.type ? type : '-' }}
        </div>
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          <span>
            {{ $t('printer.type') }}
          </span>
        </label>
        <div class="desc-style">
          {{ printerType }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  props: {
    labelingPointId: Number,
    name: String,
  },
  data: () => ({
    linesIds: [],
    printersIds: [],
    labelTemplatesIds: [],
    orderTypesIds: [],
    allOrderTypes: true,
    mapping: [],
    type: null,
    multiOrderSupport: false,
    labelingLevel: null,
    printerTypeNotSpecified: '-',
  }),
  components: {
    Multiselect,
  },
  computed: {
    ...mapState({
      lines: state => state.lines.list || [],
      printers: state => state.printers.list || [],
      labelTemplates: state => state.labels.templates,
      orderTypes: state => state.orderTypes.list,
    }),
    formattedLines() {
      return this.lines?.map(l => ({
        value: l.id,
        text: l.name,
      })) || [];
    },
    printerType() {
      if (this.printersIds.length > 0) {
        return this.printers
          .find(p => this.printersIds[0]?.text === p.name)?.printerType || this.printerTypeNotSpecified;
      }
      if (this.labelTemplatesIds.length > 0) {
        return this.labelTemplates
          .find(t => this.labelTemplatesIds[0]?.text === t.name)?.printerType || this.printerTypeNotSpecified;
      }
      return this.printerTypeNotSpecified;
    },
    formattedPrinters() {
      return this.printers?.map(p => ({
        value: p.id,
        text: p.localName,
        printerType: p.printerType,
        $isDisabled: this.printerType !== this.printerTypeNotSpecified && p.printerType !== this.printerType,
      })) || [];
    },
    formattedLabelTemplates() {
      return this.labelTemplates?.map(t => ({
        value: t.id,
        text: t.name,
        printerType: t.printerType,
        $isDisabled: this.printerType !== this.printerTypeNotSpecified && t.printerType !== this.printerType,
      })) || [];
    },
    formattedOrderTypes() {
      return this.orderTypes?.map(t => ({
        value: t.id,
        text: t.name,
      })) || [];
    },
  },
  methods: {
    ...mapActions([
      'getPrinters',
      'getLabelTemplates',
      'getOrderTypes',
      'getLabelingMapping',
      'setLabelingMapping',
    ]),
    save() {
      this.pending = true;
      this.setLabelingMapping({
        data: {
          labelingPointId: this.labelingPointId,
          allowAllOrderTypes: this.allOrderTypes,
          lines: this.linesIds.map(x => x.value) || [0],
          printers: this.printersIds.map(x => x.value) || [0],
          labels: this.labelTemplatesIds.map(x => x.value) || [0],
          orderTypes: this.orderTypesIds.map(x => x.value) || [0],
        },
      })
        .then(() => {
          this.$emit('successMsg');
        })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        })
        .finally(() => {
          this.pending = false;
          this.$emit('close');
        });
    },
    getMapping() {
      this.getLabelingMapping({
        params: {
          query: {
            labelingPointId: this.labelingPointId,
          },
        },
      })
        .then(({ data }) => {
          this.mapping = data;
          this.type = data.type === 'AUTO' ? this.$t('labellingPoint.atTheStartOfTheOrder')
            : this.$t('labellingPoint.atTheUserRequest');
          this.multiOrderSupport = data.multiOrderSupport;
          this.labelingLevel = data.labelingLevel || '-';
          this.allOrderTypes = data.allowAllOrderTypes;
          this.linesIds = data.lines?.map(t => ({
            value: t.id,
            text: t.name,
          }));
          this.printersIds = data.printers?.map(t => ({
            value: t.id,
            text: t.name,
          }));
          this.labelTemplatesIds = data.labels?.map(t => ({
            value: t.id,
            text: t.name,
          }));
          this.orderTypesIds = data.orderTypes?.map(t => ({
            value: t.id,
            text: t.name,
          }));
        });
    },
  },
  created() {
    this.getPrinters();
    this.getLabelTemplates();
    this.getOrderTypes();
    this.getMapping();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .select-style {
    margin-top: 1px;
    margin-bottom: 5px;
    font-size: 15px;
  }

  .select-style::v-deep .multiselect__tag {
    color:black;
    background-color: #F2F2F2;
    border-radius: 2px;
    padding-left: 15px;
    font-weight: 500;
  }

  .select-style::v-deep .multiselect__tag-icon {
    background-color: #ABABAB !important;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    margin-top: 4px;
    margin-right: 4px;
  }

  .select-style::v-deep .multiselect__tag-icon:after {
    color:white !important;
    font-size: 0.75em;
    position: absolute;
    left: 3px;
    bottom: -3.5px;
  }

  .select-style::v-deep .multiselect__option {
    background-color: #fff;
    color: black;
    &:hover {
      background-color: rgb(238, 232, 232);
    }
  }

  .select-style::v-deep .multiselect__option--disabled{
    background-color: white !important;
  }

  .table-title {
    font-weight: 700;
    font-size: 14px;
  }

  .desc-style {
    font-size: 0.8em;
  }

  .modal-container {
    background-color: hsla(0,0%,78.4%,.2);
    border-radius: 15px;
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .main-form {
    background-color: white;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 0 3px rgba(100, 100, 100, 0.2);
  }

  .main-selector-text {
    font-size: 0.9em;
    font-weight: 600;
    margin-top: 4px;
  }

  .secondary-selector-text {
    font-size: 0.8em;
    opacity: 0.7;
    font-weight: 500;
    margin-top: 4px;
    margin-inline: 10px;
    font-style: italic;
  }

</style>
