<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex position-relative align-items-center mb-2"
      style="z-index: 200"
    >
      <h3
        style="font-size: 20px"
        class="mb-0 pt-1"
      >
        {{ $t('order.orderTypes') }}
        <span class="optional-text">
          - {{ $t('form.optional') }}
        </span>
      </h3>
    </div>

    <div
      ref="listContainer"
      style="max-height: 35vh; overflow-y: auto"
    >
      <div
        v-for="(field, index) in fieldList"
        :key="index"
      >
        <OrderTypeField
          class="mb-1"
          :edited="edited"
          :name.sync="field.name"
          :color-code.sync="field.colorCode"
          :types="fieldList"
          @deleteField="deleteField(index)"
          @errorName="$emit('errorName', $event)"
        />
      </div>
    </div>
    <div
      v-if="edited"
      class="mt-3"
    >
      <BButton
        size="sm"
        class="rounded-pill px-4"
        @click="addNewField"
      >
        <i class="fas fa-plus mr-1" /> {{ $t('template.addEntry') }}
      </BButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import colors from '@/utils/colors';
import OrderTypeField from './OrderTypeField';

export default {
  props: {
    edited: Boolean,
  },
  data() {
    return {
      orderTypes: null,
      fieldList: null,
    };
  },
  components: {
    OrderTypeField,
  },
  computed: {
    fieldData() {
      return [
        {
          colorCode: 'green',
          name: null,
        },
      ];
    },
  },
  watch: {
    fieldList(v) {
      this.$emit('orderTypes', v);
    },
  },
  methods: {
    ...mapActions([
      'getOrderTypes',
    ]),
    scrollToView() {
      const container = this.$refs.listContainer;
      container.scrollTop = container.scrollHeight;
    },
    addNewField() {
      this.fieldList = [
        ...this.fieldList,
        {
          colorCode: colors('green'),
          name: null,
        },
      ];
      setTimeout(() => {
        this.scrollToView();
      }, 1);
    },
    deleteField(index) {
      this.fieldList = this.fieldList.slice(0, index).concat(this.fieldList.slice(index + 1));
    },
    getOrderType() {
      this.getOrderTypes().then(({ data }) => {
        this.orderTypes = data;
        this.fieldList = this.orderTypes || this.fieldData;
      });
    },
  },
  created() {
    this.getOrderType();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 40px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
    font-size: 0.8em;
  }

</style>
