<template>
  <div>
    <div class="d-flex align-items-center mb-2 justify-content-between">
      <div class="d-flex row ml-0">
        <div>
          <button
            class="btn btn-secondary icon-btn"
            @click="$emit('back', false)"
          >
            <i class="fas fa-chevron-left" />
          </button>
        </div>
        <div class="ml-3">
          <h4 class="mb-0">
            {{ selectedTemplate.name }}
          </h4>
          <span
            class="d-block text-secondary desc-style"
          >
            {{ selectedTemplate.description }}
          </span>
        </div>
        <span class="mx-3 culture-text-style">
          {{ selectedTemplate.culture }}
        </span>
      </div>
      <div
        class="mx-3"
      >
        <button
          class="btn btn-primary btn-block btn-sm"
          type="submit"
          @click="showEditForm = true"
        >
          <i class="fas fa-pen pr-2" />
          {{ $t('template.editTemplateProperties') }}
        </button>
      </div>
    </div>
    <div
      class="d-flex"
      style="height: 80vh"
    >
      <div
        class="col-4 box-style mr-2 nice-scroll"
        style="overflow-y: scroll"
      >
        <div
          v-if="templateId"
        >
          <div
            class="d-flex justify-content-between flex-wrap mb-3"
          >
            <span class="title pl-2">
              <i class="fas fa-barcode pr-1" />
              {{ $t('label.labelPreview') }}
            </span>
          </div>
          <div
            v-if="skuForPreview"
          >
            <LabelPreview
              :key="skuForPreview.id"
              :sku-id="skuForPreview.id"
              :label-id="templateId"
              :open-modal-with-preview-after-image-click="true"
              class="my-3"
            />
          </div>
          <div v-else-if="!pending">
            <LabelPreview
              :key="templateId"
              :label-id="templateId"
              :open-modal-with-preview-after-image-click="true"
              class="m-2"
            />
          </div>
        </div>
        <hr class="mt-4">
        <div
          v-if="templateId"
          style="overflow-y: scroll; height: 35vh"
        >
          <TemplateFields
            v-if="!pending"
            :selected="fieldId"
            :template-id="templateId"
            :fields="fields"
            @select="fieldId = $event"
          />
          <Loader v-else />
        </div>
      </div>
      <div class="col-4 box-style mr-2">
        <div
          class="d-flex justify-content-between flex-wrap"
        >
          <span class="title pl-2">
            <i class="fas fa-pen pr-1" />
            {{ $t('form.edit') }}
          </span>
          <div
            v-if="fieldId"
            class="upload-style pr-1"
          >
            <button
              class="actions-button btn-outline-secondary btn btn-sm"
              @click="showExampleForm = true"
            >
              <i class="fas fa-upload pr-2" />
              {{ $t('label.uploadTemplate') }}
            </button>
          </div>
        </div>
        <div v-if="fieldId">
          <CodeEditorForm
            :value="fieldCode"
            :field-id="fieldId"
            @reload="refresh()"
            @close="fieldId = null; refresh()"
          />
        </div>
        <div
          v-else
          class="text-center"
          style="margin-top: 25vh"
        >
          <div class="big-icon">
            <i class="fas fa-table-cells" />
          </div>
          <div class="info">
            {{ $t('label.pickFieldToEdit') }}
          </div>
        </div>
      </div>
      <div
        class="col-4 box-style nice-scroll"
        style="overflow-y: scroll"
      >
        <div
          v-if="selectedTemplate"
          class="pt-2"
        >
          <MappedSkuList
            :label="selectedTemplate"
            style="margin-top: -20px; padding-top: 20px"
            @show="showSkuPreview"
            @clear="skuForPreview = null"
          />
        </div>
        <div
          v-if="selectedTemplate"
          class="pt-2"
        >
          <hr class="mb-4">
          <MappedLabelingPointsList
            :list="labelingPointsList"
            :label="selectedTemplate"
            style="margin-top: -20px; padding-top: 20px"
          />
        </div>
      </div>
    </div>
    <LModal :show.sync="showTemplateForm">
      <LabelForm
        :template-id="templateId"
        @close="showTemplateForm = false"
      />
    </LModal>
    <LModal
      :show.sync="showExampleForm"
    >
      <RuleSearch
        @close="showExampleForm = false"
        @load="fieldCode = $event"
      />
    </LModal>
    <LModal
      size="lg"
      :show.sync="showEditForm"
    >
      <CreateAndEditLabelTemplate
        :label="selectedTemplate"
        :fields="fields"
        :edit-form="true"
        @successMsg="showSuccessMsg($event)"
        @failedMsg="showFailedMsg($event)"
        @reload="selectedTemplate = $event; showCreateForm = false"
        @close="showEditForm = false"
      />
    </LModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TemplateFields from '@/components/mapper/TemplateFields';
import CodeEditorForm from '@/components/mapper/CodeEditorForm';
import LabelForm from '@/components/label/LabelForm';
import LabelPreview from '@/components/label/LabelPreview';
import MappedLabelingPointsList from '@/components/label/MappedLabelingPointsList';
import MappedSkuList from '@/components/sku/MappedSkuList';
import CreateAndEditLabelTemplate from '@/components/label/CreateAndEditLabelTemplate';
import RuleSearch from './RuleSearch';

export default {
  props: {
    selected: Object,
  },
  data: () => ({
    templateId: null,
    fieldId: null,
    fieldCode: '',
    skuForPreview: null,
    fields: [],
    propertiesPreview: [],
    showTemplateForm: false,
    pending: false,
    showExampleForm: false,
    showEditForm: false,
    selectedTemplate: {},
    pendingSkus: false,
  }),
  components: {
    CodeEditorForm,
    TemplateFields,
    LabelPreview,
    MappedSkuList,
    LabelForm,
    MappedLabelingPointsList,
    CreateAndEditLabelTemplate,
    RuleSearch,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    labelsLabellingPointsMappings() {
      return this.$store.getters.labelsLabellingPointsMappings || [];
    },
    labelingPointsList() {
      if (!this.labelsLabellingPointsMappings) return [];
      return this.labelsLabellingPointsMappings.filter(m => m.labelId === this.templateId).map(x => ({
        ...x,
        category: x.category || '-',
      })) || [];
    },
  },
  watch: {
    selectedTemplate() {
      this.fields = this.selectedTemplate.labelProperty;
    },
    fieldId(fid) {
      const field = this.fields.find(f => f.id === fid);
      this.fieldCode = field?.rule || '';
    },
    templateId(id) {
      this.getLabelProperties({ params: { labelId: id } })
        .then(({ data }) => {
          this.fields = data;
        });

      this.fieldId = null;
      this.skuForPreview = null;
      this.propertiesPreview = [];
    },
  },
  methods: {
    ...mapActions([
      'getLabelProperties',
      'getOrdersProperties',
      'getSkuProperties',
      'getLabelTemplate',
    ]),
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.saved'), {
        title: this.$t('general.saved'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('request.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
    refresh() {
      setTimeout(() => {
        this.reloadTemplate();
      }, 100);
    },
    reloadTemplate() {
      this.pending = true;
      this.getLabelTemplate({ params: { labelId: this.templateId } })
        .then(({ data }) => {
          this.selectedTemplate = data;
          this.fields = this.selectedTemplate.labelProperty;
        }).finally(() => {
          this.pending = false;
        });
    },
    showSkuPreview(sku) {
      if (!this.templateId || !sku) return;
      this.skuForPreview = sku;
      this.getSkuProperties({
        params: {
          skuId: sku?.id,
        },
      })
        .then(({ data }) => {
          this.propertiesPreview = data;
        });
    },
  },
  created() {
    this.selectedTemplate = this.selected;
    this.templateId = this.selectedTemplate?.id;
    this.getOrdersProperties();
  },
};
</script>

<style lang="scss" scoped>

  .culture-text-style {
    font-size: 1.2em;
    font-weight: 625;
    opacity: 0.5;
  }

  .big-icon {
    font-size: 4em;
    opacity: 0.3;
  }

  .title {
    font-size: 1.2em;
    font-weight: 625;
  }

  .upload-style {
    font-size: 0.7em !important;
    font-weight: 625;
    opacity: 0.5;
  }

  .info {
    opacity: 0.6;
    font-size: 1.2em;
  }

  .desc-style {
    font-size: 0.8rem;
    opacity: 0.7;
  }

  .nice-scroll::-webkit-scrollbar {
    display: none;
  }

</style>
