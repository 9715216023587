<template>
  <div>
    <div>
      <div
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label
            class="title-style"
          >
            {{ $t('order.orderId') }}
          </label>
          <div class="w-100 d-flex align-items-center">
            <BFormInput
              v-model="externalId"
              :disabled="disabled"
              :placeholder="$t('order.orderId')"
              trim
            />
          </div>
        </div>
      </div>

      <div
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label class="title-style">
            <span>
              {{ $t('general.description') }}
            </span>
            <span class="optional-text">
              - {{ $t('form.optional') }}
            </span>
          </label>
          <div class="w-100 d-flex align-items-center">
            <BFormInput
              v-model="description"
              :disabled="disabled"
              :placeholder="$t('general.description')"
              trim
            />
          </div>
        </div>
      </div>

      <div
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label
            class="title-style"
          >
            {{ $t('line.line') }}
          </label>
          <BFormSelect
            v-if="lines"
            v-model="lineId"
            :options="lineList"
            :disabled="disabled"
          />
          <div v-else>
            <div
              class="d-flex flex-row justify-content-center ml-2"
              style="line-height: 40px"
            >
              <Loader
                size="15px"
                class="mr-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label
            class="title-style"
          >
            {{ $t('sku.sku') }}
          </label>
          <BFormSelect
            v-if="skus || pendingSkus"
            v-model="skuId"
            :options="skuList"
            :disabled="disabled"
          />
          <div v-else>
            <div
              class="d-flex flex-row justify-content-center ml-2"
              style="line-height: 40px"
            >
              <Loader
                size="15px"
                class="mr-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="row form-row mt-2"
      >
        <div
          class="col-md-8"
        >
          <label class="title-style">
            <span>
              {{ $t('order.orderType') }}
            </span>
            <span class="optional-text">
              - {{ $t('form.optional') }}
            </span>
          </label>
          <BFormSelect
            v-if="orderTypes"
            v-model="orderTypeId"
            :options="orderTypesList"
            :disabled="disabled"
          />
          <div v-else>
            <div
              class="d-flex flex-row justify-content-center ml-2"
              style="line-height: 40px"
            >
              <Loader
                size="15px"
                class="mr-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  props: {
    activity: Object,
    type: String,
    disabled: Boolean,
  },
  data() {
    return {
      lineId: null,
      skuId: null,
      orderTypeId: null,
      skus: [],
      orderTypes: [],
      externalId: '',
      description: '',
      pendingSkus: false,
    };
  },
  computed: {
    ...mapState({
      lines: state => state.lines.list || [],
    }),
    lineList() {
      return this.lines?.map(line => ({
        text: line.name,
        value: line.id,
      })) || [];
    },
    skuList() {
      return this.skus?.map(sku => ({
        text: sku.name,
        value: sku.id,
      })) || [];
    },
    orderTypesList() {
      return this.orderTypes?.map(type => ({
        text: type.name,
        value: type.id,
      })) || [];
    },
    errorCheck() {
      if (this.externalId
          && this.lineId
          && this.skuId
      ) return true;
      return false;
    },
  },
  watch: {
    lineId(val) {
      this.$emit('lineId', val);
    },
    externalId(val) {
      this.$emit('externalId', val);
    },
    description(val) {
      this.$emit('description', val);
    },
    skuId(val) {
      this.$emit('skuId', val);
    },
    orderTypeId(val) {
      this.$emit('orderTypeId', val);
    },
    errorCheck(val) {
      this.$emit('error', val);
    },
  },
  methods: {
    ...mapActions([
      'getSkus',
      'getLines',
      'getOrderTypes',
    ]),
    getOrderType() {
      this.getOrderTypes().then(({ data }) => {
        this.orderTypes = data;
      });
    },
    reloadSkus() {
      this.pendingSkus = true;

      this.getSkus({
        params: {
          query: {
            count: 1000000,
          },
        },
      }).then(({ data }) => {
        this.skus = data.items || [];
      }).finally(() => {
        this.pendingSkus = false;
      });
    },
    externalIdCheck(v) {
      return !!v;
    },
  },
  created() {
    this.getLines();
    this.reloadSkus();
    this.getOrderType();
    if (this.disabled) {
      this.skuId = this.activity.skuId;
      this.lineId = this.activity.lineId;
      this.externalId = this.activity.externalId;
      this.description = this.activity.description;
      this.orderTypeId = this.activity.orderTypeId;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .activity-box {
    border-radius: 5px;
    border: 2.5px solid;
    background-color: white;
    padding-top: 35px;
    padding-inline: 10px;
    margin-right: 5px;
    height: 125px;
    width: 145px;
    text-align: center;
    opacity: 0.8;
    color: gray;
    cursor: pointer;

    &.selected {
      color: $ilabo;
    }

    &:hover {
      opacity: 1;
    }

    &.disabled {
      opacity: 0.8;
      cursor: default;
    }
  }

  .title-style {
    font-weight: 500;
    font-size: 1.1em;
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
  }

</style>
