<template>
  <div class="d-flex flex-column">
    <LModal
      :show.sync="form"
    >
      <OrderFieldEditor
        v-bind.sync="selected"
        @successMsg="showSuccessMsg($event)"
        @failedMsg="showFailedMsg($event)"
        @close="form = false"
      />
    </LModal>

    <div class="row justify-content-between mx-0">
      <h3 style="font-size: 1.75em">
        <i class="icon-orders pr-2" />
        {{ $t('order.fields') }}
      </h3>
      <div class="align-self-center pr-2">
        <button
          class="btn btn-primary btn-sm"
          @click="form = true, setSelected(null)"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('order.addField') }}
        </button>
      </div>
    </div>

    <div
      style="min-height: 70vh; overflow:auto"
      class="box-style flex-fill mt-2"
    >
      <div class="form-group py-1">
        <SearchInput @input="search = $event" />
      </div>
      <SimpleTable
        class="table-style"
        :items="searchedOrderFields"
        :fields="columns"
        @row-clicked="setSelected($event)"
      >
        <template #cell(valueType)="{item}">
          <div
            :style="{ 'background-color': colorCode(item.valueType) }"
            class="badge badge-success text-white"
            style="width: 100px"
          >
            <div
              class="px-2 text-uppercase badge-style"
            >
              {{ item.valueType }}
            </div>
          </div>
        </template>
        <template #cell(modified)="{item}">
          {{ formatData(item.modified) }}
        </template>
      </SimpleTable>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import SimpleTable from '@/components/tables/SimpleTable';
import createSearch from '@core/utils/search';
import OrderFieldEditor from './OrderFieldEditor';

export default {
  data() {
    return {
      form: false,
      selected: null,
      search: null,
    };
  },
  components: {
    OrderFieldEditor,
    SimpleTable,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      properties: state => state.orders.properties || [],
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    searchedOrderFields() {
      const search = createSearch(this.search);
      return this.properties
        .filter(({ name, description }) => search(name) || search(description))
        .sort((a, b) => (b.modified).localeCompare(a.modified));
    },
    columns() {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('general.name'),
          class: 'text-left',
          sortable: true,
        },
        {
          key: 'description',
          label: this.$t('general.description'),
          class: 'text-left',
        },
        {
          key: 'valueType',
          label: this.$t('general.valueType'),
          sortable: true,
        },
        {
          key: 'modified',
          label: this.$t('general.modified'),
          sortable: true,
        },
      ];
    },
    badges() {
      return [
        {
          key: 'string',
          color: '#FF7448',
        },
        {
          key: 'decimal',
          color: '#3684C7',
        },
        {
          key: 'int',
          color: '#FFAE48',
        },
        {
          key: 'bool',
          color: '#A985DE',
        },
        {
          key: 'dictionary',
          color: '#FFCC0B',
        },
      ];
    },
  },
  methods: {
    ...mapActions(['getOrdersProperties']),
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.saved'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('request.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
    colorCode(v) {
      return this.badges.find(x => x.key === v?.toLowerCase())?.color || 'green';
    },
    formatData(date) {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return date ? moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS') : '-';
    },
    setSelected(event) {
      this.selected = event;
      this.form = true;
    },
  },
  created() {
    this.getOrdersProperties();
  },
};
</script>

<style lang="scss" scoped>
  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 30px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .search {
    border-radius: .25rem;
  }

</style>
