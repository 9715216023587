<template>
  <div>
    <h5
      class="mb-0 mx-3"
    >
      <span v-if="!propertyType">
        <i class="ion ion-md-add pr-2" />
        {{ $t('form.addField') }} {{ $t('sku.sku') }}
      </span>

      <span v-else>
        <i class="fas fa-pen pr-2" />
        {{ $t('form.edit') }} {{ $t('sku.sku') }}
      </span>
    </h5>

    <div class="mx-3">
      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('general.name') }}
        </label>
        <BFormInput
          v-model="localName"
          :disabled="!!propertyType"
          :placeholder="$t('general.name')"
          :state="stateOfNameField"
        />
      </div>

      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('general.description') }}
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </label>
        <BFormInput
          v-model="localDescription"
          :placeholder="$t('general.description')"
          :state="stateOfDescriptionField"
        />
      </div>

      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('general.valueType') }}
          <span
            v-if="propertyType && valueType !== propertyType.valueType.toUpperCase()"
            class="text-danger"
          >
            - {{ $t('mapper.thisCanBreakTheRules') }}
          </span>
        </label>
        <BFormSelect
          v-model="valueType"
          :options="valueTypes"
          :placeholder="$t('general.valueType')"
          :state="valueType && !propertyType ? true : null"
        />
      </div>

      <div
        v-if="valueType === propertyValueTypes.dictionary"
        class="my-3"
      >
        <label class="mb-1 table-title">
          {{ $t('dictionary.dictionaryName') }}
          <span
            v-if="propertyType
              && propertyType.valueType === propertyValueTypes.dictionary
              && dictionaryId !== propertyType.dictionaryId"
            class="text-danger"
          >
            - {{ $t('mapper.thisCanBreakTheRules') }}
          </span>
        </label>
        <BFormSelect
          v-model="dictionaryId"
          :options="dictionariesToDisplay"
          value-field="id"
          text-field="name"
          :placeholder="$t('dictionary.addDictionary')"
          :state="dictionaryId && !propertyType ? true : null"
        />
      </div>
      <hr>
    </div>

    <div>
      <Error
        v-if="displayedError"
        class="mb-2 ml-3"
        :message="displayedError"
      />
      <SaveButton
        :disabled="(!propertyType && !errorCheck) || displayedError"
        @cancel="$emit('close')"
        @save="submit()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { propertyValueTypes } from '@/utils/dictionary';

export default {
  props: {
    propertyType: Object,
  },
  data: () => ({
    localName: '',
    localDescription: '',
    valueType: propertyValueTypes.string,
    dictionaryId: null,
    propertyValueTypes,
  }),
  computed: {
    ...mapState({
      propertyTypes: state => state.skuProperties.types,
      dictionaries: state => state.dictionaries.list || [],
    }),
    ...mapGetters([
      'maxInputLength',
      'maxDescriptionLength',
    ]),
    valueTypes() {
      return [
        propertyValueTypes.string,
        propertyValueTypes.decimal,
        propertyValueTypes.integer,
        propertyValueTypes.boolean,
        propertyValueTypes.dictionary,
      ];
    },
    skuPropertyNameExist() {
      if (!this.localName) return false;
      return this.propertyTypes.find(l => l.name?.toUpperCase() === this.localName?.toUpperCase()) ? true : null;
    },
    stateOfNameField() {
      if (this.skuPropertyNameExist && !this.propertyType) return false;
      if (this.skuPropertyNameExist && this.propertyType && this.propertyType?.name !== this.localName) return false;
      if (this.skuPropertyNameError) return false;
      return this.localName && !this.propertyType ? true : null;
    },
    stateOfDescriptionField() {
      if (this.skuPropertyDescError) return false;
      if (this.localDescription && this.localDescription.length > 0) return true;
      return null;
    },
    skuPropertyNameError() {
      if (!this.localName) return false;
      return this.localName.length >= this.maxInputLength;
    },
    skuPropertyDescError() {
      if (!this.localDescription) return false;
      return this.localDescription.length >= this.maxDescriptionLength;
    },
    displayedError() {
      if (this.skuPropertyNameExist && !this.propertyType) return this.$t('error.nameAlreadyExists');
      if (this.skuPropertyNameExist && this.propertyType
        && this.propertyType.name !== this.localName) return this.$t('error.nameAlreadyExists');
      if (this.skuPropertyDescError) return this.$t('error.descriptionIsTooLong');
      return this.skuPropertyNameError ? this.$t('error.nameIsTooLong') : null;
    },
    errorCheck() {
      if (
        this.localName
        && this.valueType
        && !this.skuPropertyNameExist
        && !this.skuPropertyNameError
        && !this.skuPropertyDescError
        && (this.valueType !== propertyValueTypes.dictionary || this.dictionaryId)
      ) return true;
      return false;
    },
    dictionariesToDisplay() {
      return this.dictionaries.slice().sort((a, b) => (a.name).localeCompare(b.name));
    },
  },
  methods: {
    ...mapActions([
      'addSkuPropertyType',
      'updateSkuPropertyType',
    ]),
    submit() {
      if (this.propertyType) {
        this.update();
      } else {
        this.add();
      }
    },
    add() {
      this.addSkuPropertyType({
        data: {
          name: this.localName,
          description: this.localDescription,
          valueType: this.valueType,
          dictionaryId: this.valueType === propertyValueTypes.dictionary ? this.dictionaryId : null,
        },
      })
        .then(() => {
          this.$emit('successMsg', this.$t('form.skuFieldSuccessfullyCreated'));
          this.$emit('close');
        })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    update() {
      this.updateSkuPropertyType({
        params: {
          typeId: this.propertyType.id,
        },
        data: {
          name: this.localName,
          description: this.localDescription,
          valueType: this.valueType,
          dictionaryId: this.valueType === propertyValueTypes.dictionary ? this.dictionaryId : null,
        },
      })
        .then(() => {
          this.$emit('successMsg', this.$t('general.modified'));
          this.$emit('close');
        })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
  },
  created() {
    if (this.propertyType) {
      this.localName = this.propertyType.name;
      this.localDescription = this.propertyType.description;
      this.valueType = this.propertyType.valueType.toUpperCase();
      this.dictionaryId = this.propertyType.dictionaryId;
    }
  },
};
</script>

<style lang="scss" scoped>

  .table-title {
    font-weight: 700;
    font-size: 14px;
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
  }

</style>
