<template>
  <div
    class="col-6 col-md-12 px-0"
    style="max-height: 100%"
  >
    <div class="d-flex position-relative align-items-center mb-3">
      <span>
        <h3
          style="font-size: 1.5em"
          class="my-2"
        >
          <i class="icon-orders pr-1" />
          {{ $t('order.orders') }}
        </h3>
      </span>

      <div class="ml-3">
        <button
          class="btn btn-primary icon-btn btn-sm"
          @click="showOrderCreate = true"
        >
          <i class="ion ion-md-add" />
        </button>
      </div>
    </div>
    <div class="box-style mt-3">
      <div class="d-flex justify-content-between row">
        <div class="row ml-3">
          <div
            class="pr-3 mb-1"
            :style="displayedError ? 'margin-top: -24px' : ''"
            style="align-self: center; flex: auto"
          >
            <span
              v-if="displayedError"
              class="label-style"
            >
              {{ displayedError }}!
            </span>
            <BFormInput
              ref="search"
              v-model="searchText"
              size="sm"
              style="min-width: 225px; height: 26px; font-size: 0.8em"
              :placeholder="`${$t('form.searchByOrderNumber')}...`"
              type="search"
            />
          </div>

          <AreaFilterSelector
            v-if="useAreas"
            :selected="selectedOrder"
            :pending="pendingOrders"
            :box-height="'26px'"
          />

          <LineFilterSelector
            :selected="selectedOrder"
            :pending="pendingOrders"
            :box-height="'26px'"
          />

          <ProSelect
            v-model="status"
            show-all-option
            dropdown-width="175px"
            :hide-dropdown="selectedOrderId ? true : false"
            :options="statuses"
            :label="$t('general.statuses')"
            :disabled="pendingOrders"
            class="pr-2 mb-3"
            :class="pendingOrders ? 'disabled-content' : ''"
            :box-height="'26px'"
            icon-class="fas fa-layer-group"
            icon-color-class="app-color"
            dynamic-showing-list
          />
        </div>
        <div class="pr-4 mb-3">
          <DatePicker
            ref="dateItemPicker"
            :custom-look="true"
            :custom-height="'26px'"
            :start-date="dates.startDate"
            :end-date="dates.endDate"
            @setDates="dates = $event"
          />
        </div>
      </div>

      <hr>

      <Loader
        v-if="pendingOrders"
        style="height: 50vh; padding-top: 20vh"
      />
      <div
        v-else
        class="order-list-style"
      >
        <div
          v-for="order in formatedOrders"
          :key="order.orderId"
        >
          <div
            @click.stop.prevent="form = true; selectedOrderId = order?.id"
          >
            <OrderDetailsBox
              :order="order"
              :selected="selectedOrder"
            />
          </div>
        </div>
      </div>

      <div class="mt-4">
        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          style="justify-content: center"
          pills
          limit="5"
          first-number
          last-number
        />
      </div>
    </div>

    <BModal
      v-model="showOrderCreate"
      hide-header
      hide-footer
      content-class="modal-fullscreen"
    >
      <OrderCreateForm
        :activity="selectedOrder"
        @close="showOrderCreate = false; refresh()"
      />
    </BModal>

    <Offcanvas
      :show="form"
      no-bottom-padding
      passive
      @update:show="form = $event"
    >
      <OrderActionsModal
        v-if="form"
        :selected="selectedOrder"
        @refresh="refresh()"
        @close="form = false"
      />
    </Offcanvas>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import DatePicker from '@/components/selectors/DatePicker';
import OrderDetailsBox from '@/components/order/OrderDetailsBox';
import OrderActionsModal from '@/components/order/OrderActionsModal';
import AreaFilterSelector from '@/components/selectors/AreaFilterSelector';
import LineFilterSelector from '@/components/selectors/LineFilterSelector';
import OrderCreateForm from '@/components/order/create/OrderCreateForm.vue';

export default {
  data() {
    return {
      form: false,
      selectedOrderId: null,
      searchText: null,
      pending: {},
      pendingOrders: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 100,
      status: null,
      defaultStartDateInPlantTimeZone: moment.utc().startOf('day').format(),
      defaultEndDateInPlantTimeZone: moment.utc().endOf('day').format(),
      dates: {},
      showOrderCreate: false,
    };
  },
  components: {
    DatePicker,
    OrderCreateForm,
    OrderDetailsBox,
    OrderActionsModal,
    AreaFilterSelector,
    LineFilterSelector,
  },
  computed: {
    ...mapGetters([
      'orderStatuses',
      'fromPlantTimeToUtc',
      'selectedFilterAreaId',
      'selectedFilterLineId',
      'linesFromArea',
      'maxDescriptionLength',
    ]),
    ...mapState({
      useAreas: state => state.settings.data?.useAreas || false,
      lines: state => state.lines.list || [],
      pager: state => state.orders.data.pager || [],
      orders: state => state.orders.data?.orders || [],
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    selectedOrder() {
      if (this.selectedOrderId === null) {
        return null;
      }
      return this.orders.find(o => o.id === this.selectedOrderId);
    },
    searchLengthError() {
      if (!this.searchText) return false;
      return this.searchText.length >= this.maxDescriptionLength;
    },
    displayedError() {
      return this.searchLengthError ? this.$t('error.orderIdNumberIsTooLong') : null;
    },
    filters() {
      return {
        selectedFilterAreaId: this.selectedFilterAreaId,
        selectedFilterLineId: this.selectedFilterLineId,
        status: this.status,
      };
    },
    statuses() {
      return this.orderStatuses.map(s => ({
        ...s,
        label: s.name,
        value: s.status.toLowerCase(),
        iconClass: s.icon,
        color: s.color,
      })) || [];
    },
    filteredLines() {
      const selectedLines = this.linesFromArea(this.selectedFilterAreaId);
      return selectedLines.length ? selectedLines : this.lines;
    },
    formatedOrders() {
      if (!this.filteredLines || !this.orders) return [];

      return this.orders.map(order => {
        const line = this.filteredLines.find(l => l.id === order.lineId);
        const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();

        return {
          ...order,
          lineName: line ? line.name : '',
          startDate: order.startDate ? moment.utc(order.startDate).add(plantUtcOffset, 'minutes').format('ll LTS') : '',
          endDate: order.endDate ? moment.utc(order.endDate).add(plantUtcOffset, 'minutes').format('ll LTS') : '',
          plannedStartDate: order.plannedStartDate
            ? moment.utc(order.plannedStartDate).add(plantUtcOffset, 'minutes').format('ll LTS') : '',
          plannedEndDate: order.plannedEndDate
            ? moment.utc(order.plannedEndDate).add(plantUtcOffset, 'minutes').format('ll LTS') : '',
        };
      });
    },
  },
  watch: {
    currentPage() {
      this.refresh();
    },
    searchText() {
      this.refresh();
    },
    dates() {
      this.refresh();
    },
    filters() {
      this.refresh();
    },
    form(v) {
      if (!v) {
        this.selectedOrderId = null;
      }
    },
  },
  methods: {
    ...mapActions([
      'getOrders',
      'getOrderTypes',
      'getOrderTypeMappingsOnLabellingPoints',
      'getAllLabelingMappings',
      'getPrinters',
    ]),
    async refresh() {
      this.$emit('refresh');
      this.pendingOrders = true;
      await new Promise(r => setTimeout(r, 500));
      this.reloadOrders();
      this.$forceUpdate();
    },
    reloadOrders() {
      this.$set(this.pending, 'order', true);
      const lines = this.selectedFilterAreaId && !this.selectedFilterLineId
        ? this.linesFromArea(this.selectedFilterAreaId).map(l => l.id)
        : [this.selectedFilterLineId];
      const query = {};

      if (this.status) {
        this.$set(query, 'statuses', this.status);
      }

      if (this.dates?.startDate) {
        this.$set(query, 'startDate', this.dates.startDate);
      }

      if (this.dates?.endDate) {
        this.$set(query, 'endDate', this.dates.endDate);
      }

      if (this.searchText) {
        this.$set(query, 'search', this.searchText);
      }

      this.getOrders({
        params: {
          query: {
            page: this.currentPage - 1,
            count: this.perPage,
            lineIds: lines || [],
            ...query,
          },
        },
      })
        .then(({ data }) => {
          this.totalRows = data.pager.totalRows;
        })
        .finally(() => {
          this.$set(this.pending, 'order', false);
          this.pendingOrders = false;
        });
    },
  },
  created() {
    this.dates = {
      startDate: this.fromPlantTimeToUtc(this.defaultStartDateInPlantTimeZone),
      endDate: this.fromPlantTimeToUtc(this.defaultEndDateInPlantTimeZone),
    };

    this.getPrinters();
    this.getOrderTypes();
    this.getOrderTypeMappingsOnLabellingPoints();
    this.getAllLabelingMappings();
  },
};
</script>

<style lang="scss" scoped>

  .order-list-style {
    height: calc(100vh - 300px);
    overflow-y: auto;
  }

  .label-style {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #dc3545;
    white-space: nowrap;
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

</style>
