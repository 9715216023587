import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueTippy, { TippyComponent } from 'vue-tippy';
import infiniteScroll from 'vue-infinite-scroll';
import DateTimePicker from 'vue-ctk-date-time-picker';
import vClickOutside from 'v-click-outside';
import LogixCore from '@core';
import App from './App';
import router from './router';
import store from './store';
import start from './start';
import i18n from './lang';
import vars from './styles/vars.icss.scss';
import './styles/app.scss';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import NotificationsHub from './notificationsHub';
import DataTransferHub from './dataTransferHub';
import { init } from './api/axios';

Vue.config.productionTip = false;

Vue.use(LogixCore, { colors: vars });
Vue.component('Tippy', TippyComponent);
Vue.component('DateTimePicker', DateTimePicker);
Vue.use(BootstrapVue);
Vue.use(VueTippy);
Vue.use(infiniteScroll);
Vue.use(NotificationsHub);
Vue.use(DataTransferHub);
Vue.use(vClickOutside);
init(store);

start({
  store,
  vue: {
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App),
  },
});
