<script>
import { mapState } from 'vuex';

export default {
  name: 'MarkingsTypeSelectButtons',
  props: {
    value: Number,
  },
  emits: [
    'input',
  ],
  data: () => ({
    types: [
      'Palety',
      'Kartony',
      'Kartoniki',
      'Wszystkie',
    ],
  }),
  computed: {
    ...mapState({
      units: state => state.packingSettings?.units || [],
    }),
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    units: {
      handler() {
        if (!this.units.length) return;
        if (this.innerValue) return;
        this.innerValue = this.units[0].id;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<template>
  <div class="markings-type-select-buttons">
    <div
      v-for="unit in units"
      :key="unit.id"
      class="type-select-button"
      :class="{
        selected: innerValue === unit.id,
      }"
      @click="innerValue = unit.id"
    >
      {{ unit.name.toUpperCase() }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/vars.icss";

.markings-type-select-buttons {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  max-width: calc(100vw - 80px - 3.5rem);
  gap: 8px;

  .type-select-button {
    min-width: 160px;
    height: 30px;
    background-color: white;
    border: 2px solid #EFEFEF;
    font-size: 11px;
    display: flex;
    align-items: center;
    border-radius: 9px;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 4px;

    &:hover {
      color: $logicprint5;
    }

    &.selected {
      color: $logicprint5;
      border: 2px solid $logicprint5;
      font-weight: 600;
    }
  }
}
</style>
