<template>
  <div>
    <div
      class="d-flex justify-content-between flex-wrap mb-4"
    >
      <span class="title pl-2">
        <i class="fas fa-barcode pr-1" />
        {{ $t('label.labelPreview') }}
      </span>
      <div
        v-if="selected && !onlyDeleted"
        class="pr-1"
      >
        <button
          class="btn btn-primary btn-block btn-sm"
          @click="$emit('openEditor', true)"
        >
          <i class="fas fa-pen-to-square pr-2" />
          {{ $t('label.modifyTemplate') }}
        </button>
      </div>
    </div>
    <div
      v-if="selected"
      class="m-2"
    >
      <LabelPreview
        :key="selected.id"
        :label-id="selected.id"
        :img-only="true"
        :open-modal-with-preview-after-image-click="true"
      />
    </div>
    <div
      v-if="selected"
      class="pt-2 m-2"
      style="overflow-y: scroll; height: 40vh"
    >
      <TemplateFields
        v-if="!pending"
        :template-id="selected.id"
        :fields="fields"
        :read-only="true"
      />
      <Loader v-else />
    </div>
    <div
      v-if="!selected"
      class="info"
    >
      {{ $t('label.pickLabelInfo') }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LabelPreview from '@/components/label/LabelPreview';
import TemplateFields from '@/components/mapper/TemplateFields';

export default {
  props: {
    selected: Object,
    onlyDeleted: Boolean,
  },
  data: () => ({
    pending: false,
    fields: [],
  }),
  components: {
    LabelPreview,
    TemplateFields,
  },
  watch: {
    selected(v) {
      if (v) {
        this.pending = true;
        this.getLabelProperties({ params: { labelId: v.id } })
          .then(({ data }) => {
            this.fields = data;
          }).finally(() => {
            this.pending = false;
          });
      }
    },
  },
  methods: {
    ...mapActions([
      'getLabelProperties',
    ]),
  },
};
</script>

<style lang="scss" scoped>

  .title {
    font-size: 1.2em;
    font-weight: 625;
  }

  .info {
    text-align: center;
    margin-top: 10vh;
    opacity: 0.6;
    font-size: 1.1em;
  }

</style>
