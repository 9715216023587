import Vapi from 'vuex-rest-api';
import axios from '../api/axios';

export const resetDefaultValues = s => {
  const defaultArea = s.userSettings.find(setting => setting.key === 'defaultareaid')?.value;
  if (defaultArea !== null && defaultArea !== '') {
    s.defaultAreaId = Number(defaultArea);
  } else {
    s.defaultAreaId = null;
  }
  s.selectedFilterAreaId = s.defaultAreaId;

  const defaultLine = s.userSettings.find(setting => setting.key === 'defaultlineid')?.value;
  if (defaultLine !== null && defaultLine !== '') {
    s.defaultLineId = Number(defaultLine);
  } else {
    s.defaultLineId = null;
  }
  s.selectedFilterLineId = s.defaultLineId;
};

const store = new Vapi({
  axios,
  state: {
    userSettings: [],
    defaultAreaId: null,
    defaultLineId: null,
    selectedFilterAreaId: null,
    selectedFilterLineId: null,
  },
})
  .get({
    action: 'getUserSettings',
    path: () => 'user/me/settings',
    onSuccess: (s, { data }) => {
      s.userSettings = data;
      resetDefaultValues(s);
    },
  })
  .put({
    action: 'setUserSetting',
    path: () => 'user/me/setting',
    onSuccess(s, { data }) {
      s.userSettings = s.userSettings.filter(setting => setting.key !== data.key);
      s.userSettings.push(data);
      resetDefaultValues(s);
    },
  })
  .getStore();

store.getters = {
  userSettings: s => s.userSettings,
  defaultAreaId: (s, g, rs) => {
    let { defaultAreaId } = s;
    if (defaultAreaId && !rs.areas.list.find(a => a.id === defaultAreaId)) {
      defaultAreaId = null;
    }
    return defaultAreaId;
  },
  defaultLineId: (s, g, rs) => {
    let { defaultLineId } = s;
    if (defaultLineId && !rs.lines.list.find(a => a.id === defaultLineId)) {
      defaultLineId = null;
    }
    return defaultLineId;
  },
  selectedFilterAreaId: (s, g, rs) => {
    let { selectedFilterAreaId } = s;
    if (selectedFilterAreaId && !rs.areas.list.find(a => a.id === selectedFilterAreaId)) {
      selectedFilterAreaId = null;
    }
    return selectedFilterAreaId;
  },
  selectedFilterLineId: (s, g, rs) => {
    let { selectedFilterLineId } = s;
    if (selectedFilterLineId && !rs.lines.list.find(a => a.id === selectedFilterLineId)) {
      selectedFilterLineId = null;
    }
    return selectedFilterLineId;
  },
};

store.mutations.setFilterAreaId = (s, v) => {
  s.selectedFilterAreaId = v;
};

store.mutations.setFilterLineId = (s, v) => {
  s.selectedFilterLineId = v;
};

store.actions.initUserConfig = (ctx, scope) =>
  ctx.dispatch('getUserSettings', {
    params: { query: { scope } },
  });

export default store;
