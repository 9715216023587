<template>
  <div style="min-height: 25vh">
    <div v-if="!pending">
      <div v-if="!error">
        <div
          v-if="!imgOnly"
          style="margin-top: -4px; margin-bottom: 15px"
        >
          <LabelPreviewActionButtons
            @save="save"
            @rotateLeft="rotateLeft"
            @rotateRight="rotateRight"
          />
        </div>
        <div style="text-align: center">
          <div
            class="label-box"
            :style="{ 'cursor': openModalWithPreviewAfterImageClick ? 'pointer' : 'default' }"
          >
            <img
              class="image"
              :src="imgUrl"
              @click="modalPreview = !modalPreview && openModalWithPreviewAfterImageClick"
            >
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-danger text-center"
      >
        <i
          class="fas fa-times"
          style="font-size: 26px"
        />
        <div>
          {{ $t('label.previewNotAvailable') }}
        </div>
        <div class="text-secondary">
          {{ error }}
        </div>
      </div>
    </div>
    <div
      v-else
      class="pt-5"
    >
      <Loader />
    </div>
    <LModal
      :show.sync="modalPreview"
      size="lg"
    >
      <LabelPreviewActionButtons
        @save="save"
        @rotateLeft="rotateLeft"
        @rotateRight="rotateRight"
      />
      <div class="preview-modal">
        <img
          class="image"
          :src="imgUrl"
        >
      </div>
    </LModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { blobToUrl } from '@/api/transforms';
import {
  getFileNameForPreview,
  getFileNameForSkuOnlyPreview,
  getFileNameForEmptyPreview,
} from '@/utils/previewFileNames';
import { convertPreviewResultToBlob } from './labelPreviewUtils';
import LabelPreviewActionButtons from './LabelPreviewActionButtons';

export default {
  props: {
    printRequestId: {
      type: Number,
      default: null,
    },
    orderId: {
      type: Number,
      default: null,
    },
    labelingPointId: {
      type: Number,
      default: null,
    },
    skuId: {
      type: Number,
      default: null,
    },
    labelId: {
      type: Number,
      default: null,
    },
    imgOnly: {
      type: Boolean,
      default: false,
    },
    openModalWithPreviewAfterImageClick: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    communicationRefId: null,
    imgUrl: null,
    error: null,
    blob: null,
    rotateImage: false,
    pending: true,
    modalPreview: false,
    fileName: null,
  }),
  components: {
    LabelPreviewActionButtons,
  },
  watch: {
    rotate() {
      this.rotateImage = true;
    },
    async blob() {
      this.imgUrl = await blobToUrl(this.blob);
    },
  },
  methods: {
    ...mapActions([
      'createGetLabelPreviewRequest',
      'createGetLabelPreviewRequestForPrintRequest',
      'createGetLabelPreviewRequestForLastInOrder',
      'createGetLabelPreviewTemplateRequest',
    ]),
    requestForPrintRequest() {
      this.communicationRefId = uuidv4();
      this.createGetLabelPreviewRequestForPrintRequest({
        params: {
          prId: this.printRequestId,
          query: {
            communicationRefId: this.communicationRefId,
          },
        },
      }).then(({ data }) => {
        this.fileName = getFileNameForPreview(data.labelName,
          data.skuExternalId ?? data.skuNumber,
          data.orderNumber ?? data.orderDescription);
      }).catch(() => {
        this.error = this.$t('general.error');
        this.pending = false;
      });
    },
    requestForLastInOrder() {
      this.communicationRefId = uuidv4();
      this.createGetLabelPreviewRequestForLastInOrder({
        params: {
          orderId: this.orderId,
          labelingPointId: this.labelingPointId,
          query: {
            communicationRefId: this.communicationRefId,
          },
        },
      }).then(({ data }) => {
        this.fileName = getFileNameForPreview(data.labelName,
          data.skuExternalId ?? data.skuNumber,
          data.orderNumber ?? data.orderDescription);
      }).catch(() => {
        this.error = this.$t('general.error');
        this.pending = false;
      });
    },
    requestForLabelWithSku() {
      this.communicationRefId = uuidv4();
      this.createGetLabelPreviewRequest({
        params: {
          skuId: this.skuId,
          labelId: this.labelId,
          query: {
            communicationRefId: this.communicationRefId,
          },
        },
      }).then(({ data }) => {
        this.fileName = getFileNameForSkuOnlyPreview(data.labelName,
          data.skuExternalId ?? data.skuNumber);
      }).catch(() => {
        this.error = this.$t('general.error');
        this.pending = false;
      });
    },
    requestForTemplate() {
      this.communicationRefId = uuidv4();
      this.createGetLabelPreviewTemplateRequest({
        params: {
          labelId: this.labelId,
          query: {
            communicationRefId: this.communicationRefId,
          },
        },
      }).then(({ data }) => {
        this.fileName = getFileNameForEmptyPreview(data.labelName);
      }).catch(() => {
        this.error = this.$t('general.error');
        this.pending = false;
      });
    },
    onGetPreviewResponse({ requestRefId, previewResult }) {
      if (this.communicationRefId !== requestRefId) { return; }
      this.processResponse(previewResult);
    },
    processResponse(previewResult) {
      if (!previewResult.success) {
        if (previewResult.errorMessage) {
          this.error = previewResult.errorMessage;
        } else {
          this.error = this.$t('general.error');
        }
        this.pending = false;
        return;
      }
      this.blob = convertPreviewResultToBlob(previewResult.preview);
      if (!this.blob) {
        this.error = 'Preview does not exist';
      }
      this.pending = false;
    },
    rotateRight() {
      this.rotate('right');
    },
    rotateLeft() {
      this.rotate('left');
    },
    rotate(direction) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      image.src = this.imgUrl;
      image.onload = () => {
        canvas.width = image.height;
        canvas.height = image.width;
        if (direction === 'right') {
          ctx.rotate((90 * Math.PI) / 180);
          ctx.translate(0, -canvas.width);
        } else {
          ctx.rotate((-90 * Math.PI) / 180);
          ctx.translate(-canvas.height, 0);
        }

        ctx.drawImage(image, 0, 0);
        this.imgUrl = canvas.toDataURL();
      };
    },
    save() {
      saveAs(this.blob, this.fileName);
    },
  },
  mounted() {
    this.$startDataTransferHub();
    this.$dataTransferHub.$on('label-preview-returned', this.onGetPreviewResponse);
  },
  async created() {
    this.pending = true;
    if (this.printRequestId) {
      this.requestForPrintRequest();
    } else if (this.labelId && this.skuId) {
      this.requestForLabelWithSku();
    } else if (this.orderId && this.labelingPointId) {
      this.requestForLastInOrder();
    } else if (this.labelId) {
      this.requestForTemplate();
    }
  },
};
</script>

<style lang="scss" scoped>
  .image {
    max-height: 100%;
    max-width: 100%;
    box-shadow: 0 0 4px rgba(100, 100, 100, 0.5);
  }

  .label-box {
    display: inline-flex;
    align-items: center;
  }

  .preview-modal {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

</style>
