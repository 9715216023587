<template>
  <div class="d-flex flex-column h-100">
    <div class="mb-3 pr-2">
      <h3
        style="font-size: 20px"
        class="mb-0"
      >
        <i class="fas fa-tags pr-2" />
        {{ $t('labellingPoint.mappedLabellingPoints') }}
      </h3>
    </div>
    <div class="mt-2">
      <SimpleTable
        :items="list"
        :fields="LabelingPointsColumns"
      />
    </div>
  </div>
</template>

<script>
import SimpleTable from '@/components/tables/SimpleTable';

export default {
  props: {
    list: Array,
    label: Object,
  },
  components: {
    SimpleTable,
  },
  computed: {
    LabelingPointsColumns() {
      return [
        {
          key: 'labelingPointName',
          label: this.$t('labellingPoint.labellingPoint'),
        },
        {
          key: 'category',
          label: this.$t('general.category'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
