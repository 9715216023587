<template>
  <div
    class="order-execution"
  >
    <div class="d-flex justify-content-between">
      <div
        class="col-5 sku-name"
      >
        <span class="title-style">
          {{ order.sku.externalId || ' ' }}
        </span>
        <span class="px-1">
          |
        </span>
        <span class="title-style">
          {{ order.sku.name || ' ' }}
        </span>
      </div>
      <div
        v-if="!noLabelingPoint"
        class="col-5 labeling-name title-style"
      >
        {{ $t('labellingPoint.labellingPoint') }}:
        {{ labelingPointName || ' ' }}
      </div>
      <div
        class="order-status"
        :style="{ 'color': status ? status.color : '' }"
        :class="!noLabelingPoint ? 'col-2' : 'col-3'"
      >
        <div
          class="px-2"
          style="padding-block: 2px"
        >
          <span
            v-tippy="{ placement : 'top-end' }"
            :content="$t('general.orderStatus')"
          >
            <i
              :class="status ? status.icon : null"
              style="padding-right: 5px"
            />
            {{ status ? status.name : '-' }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="d-flex pt-3"
      style="color: #595959"
    >
      <div class="flex-grow-1">
        <div
          :style="{ 'background-color': badgeColor(orderType ? orderType.colorCode : null) }"
          class="badge badge-success text-white mr-2"
        >
          <div
            class="px-2 text-uppercase font-weight-bold"
            style="padding-block: 1px; font-size: 0.7em;"
          >
            {{ orderType ? orderType.displayName : $t('order.order') }}
          </div>
        </div>
        <span class="text-style">
          {{ $t('order.orderNumber') }}:
        </span>
        <span class="text-style">
          {{ order.orderNumber || '-' }}
        </span>
      </div>
      <div>
        <span class="text-style">
          {{ $t('date.startDate') }}:
        </span>
        <span style="font-size: 0.6em">
          {{ formatData(startDate) || formatData(order.startDate) || '-' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';

export default {
  props: {
    id: Number,
    lineId: Number,
    order: Object,
    startDate: String,
    endDate: String,
    noLabelingPoint: Boolean,
    labelingPointId: Number,
  },
  computed: {
    ...mapGetters(['orderStatuses', 'orderBadge']),
    ...mapState({
      labelingPoints: state => state.labelingPoints.list || [],
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    labelingPointName() {
      const lp = this.labelingPoints.find(x => x.id === this.labelingPointId);
      return lp ? lp.description : '';
    },
    orderType() {
      return this.$store.getters.OrderTypeByName(this.order.type);
    },
    status() {
      return this.orderStatuses.find(x => x.status.toUpperCase() === this.order.status.toUpperCase());
    },
  },
  methods: {
    formatData(date) {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return date ? moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS') : '';
    },
    badgeColor(color) {
      return color ?? this.orderBadge.find(o => o.status === 'ORDER').color;
    },
  },
};
</script>

<style lang="scss" scoped>
  .order-execution {
    padding-block: 15px;
    padding-inline: 20px;
    margin-block: 5px;
    line-height: 0.7;
    background-color: white;
    border-radius: 7px;
    box-shadow: 0 0 5px rgba(0, 0, 4, 0.1);
  }

  .badge {
    text-transform: uppercase;
    vertical-align: middle;
  }

  .text-style {
    font-size: 0.6em;
    font-weight: 700;
  }

  .title-style {
    font-size: 0.9em;
    font-weight: 600;
  }

  .order-status {
    font-size: 0.8em;
    white-space: pre-wrap;
    text-align: right;
    padding-inline: 0px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .labeling-name {
    line-height: 1;
    text-align: right;
    padding-inline: 0px;
  }

  .sku-name {
    line-height: 1;
    padding-inline: 0px;
    font-weight: bold;
    flex-grow: 1;
  }

</style>
