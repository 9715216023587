<script>
import CockpitLineVerticalSelectorItem
from '@/components/cockpit/lineSelector/CockpitLineVerticalSelectorItem.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CockpitLineVerticalSelector',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    linesCollapsed: Boolean,
  },
  emits: [
    'input',
    'update:linesCollapsed',
  ],
  data: () => ({
    collapsed: false,
  }),
  components: { CockpitLineVerticalSelectorItem },
  computed: {
    ...mapGetters(['selectedFilterLineId', 'selectedFilterAreaId']),
    ...mapState({
      lines: state => state.lines.list || [],
    }),
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    linesFilteredBySelectedArea() {
      return this.lines.filter(l => !this.selectedFilterAreaId
        || l.areaId === this.selectedFilterAreaId);
    },
    lineOptions() {
      return this.linesFilteredBySelectedArea
        .map(l => ({
          ...l,
          label: l.name,
          value: l.id,
          iconClass: 'fas fa-layer-group app-color',
        })) || [];
    },
  },
  watch: {
    lineOptions: {
      handler() {
        if (!this.lineOptions.length) return;
        if (this.innerValue) return;
        this.innerValue = this.lineOptions[0].id;
      },
      immediate: true,
    },
    collapsed: {
      handler(value) {
        this.$emit('update:linesCollapsed', value);
      },
      immediate: true,
    },
  },
  methods: {
    handleLineSelect(lineId) {
      this.innerValue = lineId;
    },
  },
};
</script>

<template>
  <div class="cockpit-line-vertical-selector-root">
    <div
      class="collapse-toggle"
      @click="collapsed = !collapsed"
    >
      <i
        class="fas"
        :class="{
          'fa-chevron-left': !collapsed,
          'fa-chevron-right': collapsed,
        }"
      />
    </div>

    <div
      class="lines-list"
      :class="{
        collapsed
      }"
    >
      <CockpitLineVerticalSelectorItem
        v-for="line in lineOptions"
        :key="line.lineId"
        :line="line"
        :selected="line.id === innerValue"
        class="line-item"
        @select="handleLineSelect(line.id)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.cockpit-line-vertical-selector-root {
  position: relative;

  .lines-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: visible;
    height: 100%;
    gap: 4px;
    padding: 0.75rem 0;
    min-width: 82px;
    width: 82px;
    background-color: #F6F6F6;
    border-right: 1px solid #ECECEC;

    &.collapsed {
      width: 20px;
      min-width: 20px;

      .line-item {
        z-index: -1;
        opacity: 0;
      }
    }
  }

  .collapse-toggle {
    position: absolute;
    top: 0.75rem;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border: 1px solid #D7D7D7;
    background-color: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #F0F0F0;
    }

    i {
      font-size: 0.6rem;
    }
  }
}
</style>
