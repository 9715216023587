<template>
  <div class="d-flex flex-column">
    <LModal
      size="lg"
      :show.sync="form"
    >
      <LabelingPointEditor
        v-bind.sync="selected"
        @successMsg="showSuccessMsg($event)"
        @failedMsg="showFailedMsg($event)"
        @close="form = false"
      />
    </LModal>

    <div class="row justify-content-between mx-0">
      <h3 style="font-size: 1.75em">
        <i class="icon-orders pr-2" />
        {{ $t('labellingPoint.labellingPoints') }}
      </h3>
      <div class="align-self-center pr-2">
        <button
          class="btn btn-primary btn-sm"
          @click="form = true, setSelected(null)"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('labellingPoint.addLabellingPoint') }}
        </button>
      </div>
    </div>

    <div
      style="min-height: 70vh; overflow:auto"
      class="box-style flex-fill mt-2"
    >
      <div class="form-group py-1">
        <SearchInput @input="search = $event" />
      </div>
      <SimpleTable
        class="table-style"
        :items="searchedLabelingPoints"
        :fields="columns"
        @row-clicked="setSelected($event)"
      >
        <template #cell(labelingLevel)="{item}">
          {{ item.labelingLevel ? item.labelingLevel : '-' }}
        </template>
      </SimpleTable>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import SimpleTable from '@/components/tables/SimpleTable';
import createSearch from '@core/utils/search';
import LabelingPointEditor from './LabelingPointEditor';

export default {
  data() {
    return {
      form: false,
      selected: null,
      search: null,
    };
  },
  components: {
    LabelingPointEditor,
    SimpleTable,
  },
  computed: {
    ...mapState({
      labelingPoints: state => state.labelingPoints.list || [],
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    ...mapGetters(['timeOfNotification']),
    formatedLabelingPoints() {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return this.labelingPoints.map(p => ({
        ...p,
        modified: moment.utc(p.modified).add(plantUtcOffset, 'minutes').format('ll LTS'),
        created: moment.utc(p.created).add(plantUtcOffset, 'minutes').format('ll LTS'),
        multiOrderSupportWithTranslate: p.multiOrderSupport ? this.$t('general.yes') : this.$t('general.no'),
      }));
    },
    searchedLabelingPoints() {
      const search = createSearch(this.search);
      return this.formatedLabelingPoints
        .filter(({ name, description }) => search(name) || search(description));
    },
    columns() {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('general.name'),
          class: 'text-left',
          sortable: true,
        },
        {
          key: 'description',
          label: this.$t('general.shortName'),
          class: 'text-left',
        },
        {
          key: 'labelingLevel',
          label: this.$t('labellingPoint.labellingLevel'),
          class: 'text-left',
        },
        {
          key: 'type',
          label: this.$t('general.type'),
          class: 'text-left',
          sortable: true,
        },
      ];
    },
  },
  methods: {
    setSelected(event) {
      this.selected = event;
      this.form = true;
    },
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.saved'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('request.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 30px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .search {
    border-radius: .25rem;
  }

</style>
