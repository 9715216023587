import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getSkuProperties',
    property: 'list',
    path: ({ skuId }) => `/sku/${skuId}/properties`,
  })
  .get({
    action: 'getSkuPropertyTypes',
    property: 'types',
    path: '/sku/property/types',
  })
  .post({
    action: 'addSkuPropertyType',
    path: '/sku/property/type',
    onSuccess: (s, { data }) => {
      s.types.push(data);
    },
  })
  .put({
    action: 'updateSkuPropertyType',
    path: ({ typeId }) => `/sku/property/type/${typeId}`,
    onSuccess: (s, { data }) => {
      const i = s.types.findIndex(x => x.id === data.id);
      if (i > -1) {
        Vue.set(s.types, i, data);
      }
    },
  })
  .getStore();

export default store;
