import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import lang from '@/lang';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getOrders',
    property: 'data',
    path: () => '/orders',
  })
  .get({
    action: 'getProdOrders',
    property: 'prod',
    path: () => '/orders/prod',
  })
  .get({
    action: 'getOrdersBaseProps',
    property: 'baseProps',
    path: () => '/orders/properties/base',
  })
  .get({
    action: 'getOrder',
    path: ({ orderId }) => `/order/${orderId}`,
  })
  .get({
    action: 'getOrderDetails',
    path: ({ orderId }) => `/order/${orderId}/details`,
  })
  .get({
    action: 'getOrderComments',
    path: ({ orderId }) => `/order/${orderId}/comments`,
  })
  .delete({
    action: 'deleteOrder',
    path: ({ orderId }) => `/order/${orderId}`,
  })
  .post({
    action: 'createOrder',
    path: () => '/order',
  })
  .put({
    action: 'updateOrderDescription',
    path: ({ orderId }) => `/order/${orderId}/description`,
  })
  .post({
    action: 'startOrder',
    path: ({ orderId }) => `/order/${orderId}/start`,
  })
  .post({
    action: 'startAllOrders',
    path: ({ orderId }) => `/order/${orderId}/startAll`,
  })
  .post({
    action: 'stopOrder',
    path: ({ orderId }) => `/order/${orderId}/hold`,
  })
  .post({
    action: 'completeOrder',
    path: ({ orderId }) => `/order/${orderId}/complete`,
  })
  .get({
    action: 'getOrderPropertiesValues',
    property: 'propertiesValues',
    path: ({ orderId }) => `/order/${orderId}/properties/values`,
  })
  .get({
    action: 'getOrdersProperties',
    property: 'properties',
    path: () => '/orders/properties',
  })
  .post({
    action: 'createOrderProperty',
    path: () => '/orders/property',
  })
  .put({
    action: 'updateOrderProperty',
    path: ({ name }) => `/orders/property/${name}`,
  })
  .delete({
    action: 'deleteOrderProperty',
    path: ({ name }) => `orders/property/${name}`,
  })
  .put({
    action: 'setOrderPropertyValue',
    path: ({ orderId, name }) => `order/${orderId}/property/${name}`,
  })
  .put({
    action: 'setOrderPropertiesValues',
    path: ({ orderId }) => `order/${orderId}/properties/values`,
  })
  .get({
    action: 'createGetLabelPreviewRequestForLastInOrder',
    path: ({ orderId, labelingPointId }) =>
      `labeling/preview/order/${orderId}/${labelingPointId}`,
  })
  .put({
    action: 'rePrint',
    path: ({ id }) => `order/prod/${id}/reprint`,
  })
  .getStore();

store.getters = {
  orderStatuses: () => [
    {
      status: 'READY',
      icon: 'fas fa-list',
      name: `${lang.t('order.status.READY')}`,
      color: '#FFAE48',
    },
    {
      status: 'INPROGRESS',
      icon: 'fas fa-play',
      name: `${lang.t('order.status.INPROGRESS')}`,
      color: '#3E8DC0',
    },
    {
      status: 'ONHOLD',
      icon: 'fas fa-stop-circle',
      name: `${lang.t('order.status.ONHOLD')}`,
      color: '#838383',
    },
    {
      status: 'COMPLETED',
      icon: 'fas fa-check-circle',
      name: `${lang.t('order.status.COMPLETED')}`,
      color: '#39CB81',
    },
  ],
  orderExecutionStatuses: () => [
    {
      status: 'ACTIVE',
      icon: 'fas fa-play',
      name: `${lang.t('order.executionStatus.ACTIVE')}`,
      color: '#3E8DC0',
    },
    {
      status: 'INACTIVE',
      icon: 'fas fa-stop-circle',
      name: `${lang.t('order.executionStatus.INACTIVE')}`,
      color: '#838383',
    },
  ],
  orderBadge: () => [
    {
      status: 'ORDER',
      name: `${lang.t('order.order')}`,
      color: '#82ac48',
    },
  ],
};

export default store;
