<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex position-relative align-items-center mb-2"
      style="z-index: 200"
    >
      <h3
        style="font-size: 20px"
        class="mb-0 pt-1"
      >
        {{ $t('labellingPoint.labellingLevels') }}
        <span class="optional-text">
          - {{ $t('form.optional') }}
        </span>
      </h3>
    </div>

    <div
      ref="listContainer"
      style="max-height: 35vh; overflow-y: auto"
    >
      <Draggable
        v-model="draggables"
        :scroll-sensitivity="100"
        :scroll-speed="30"
        :animation="400"
        class="noselect"
      >
        <div
          v-for="(field, index) in draggables"
          :key="index"
          style="cursor: grab"
        >
          <LabelingLevelField
            class="mb-1"
            :edited="edited"
            :name.sync="field.name"
            :levels="draggables"
            @deleteField="deleteField(index)"
            @errorName="$emit('errorName', $event)"
          />
        </div>
      </Draggable>
    </div>
    <div
      v-if="edited"
      class="mt-3"
    >
      <BButton
        size="sm"
        class="rounded-pill px-4"
        @click="addNewField"
      >
        <i class="fas fa-plus mr-1" /> {{ $t('template.addEntry') }}
      </BButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Draggable from 'vuedraggable';
import LabelingLevelField from './LabelingLevelField';

export default {
  props: {
    edited: Boolean,
  },
  data() {
    return {
      fieldList: null,
      labelingLevels: [],
    };
  },
  components: {
    Draggable,
    LabelingLevelField,
  },
  computed: {
    draggables: {
      get() {
        return this.fieldList;
      },
      set(f) {
        this.fieldList = f.map((el, index) => ({
          ...el,
          ordinal: index,
        }));
        this.moveField();
      },
    },
  },
  watch: {
    fieldList(v) {
      this.$emit('labelingLevels', v);
    },
  },
  methods: {
    ...mapActions([
      'getGlobalSettings',
    ]),
    scrollToView() {
      const container = this.$refs.listContainer;
      container.scrollTop = container.scrollHeight;
    },
    addNewField() {
      this.fieldList = [
        ...this.fieldList,
        {
          name: null,
        },
      ];
      setTimeout(() => {
        this.scrollToView();
      }, 1);
    },
    deleteField(index) {
      this.fieldList = this.fieldList.slice(0, index).concat(this.fieldList.slice(index + 1));
    },
    moveField() {
      this.fieldList.reduce((acc, curr, index) => { acc[curr.id] = index; return acc; }, {});
    },
    getlabelingLevels() {
      this.getGlobalSettings().then(({ data }) => {
        this.labelingLevels = data.labelingLevels?.sort((a, b) => a.ordinal - b.ordinal);
        this.fieldList = this.labelingLevels || [];
      });
    },
  },
  created() {
    this.getlabelingLevels();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 40px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
    font-size: 0.8em;
  }

</style>
