<template>
  <div class="px-2">
    <template
      v-for="b in breadcrumb"
    >
      <div
        v-if="b.key !== 'general'"
        :key="`${b.key}line`"
        class="nav-style"
        :class="{
          selected: selected.index >= b.index,
          filled: isStepFilled(b),
        }"
      />
      <div
        :key="`${b.key}title`"
        class="row"
        style="align-items: center"
        @click="goTo(b)"
      >
        <div
          class="circle-box position-relative"
          :class="{
            selected: selected.index >= b.index,
            filled: isStepFilled(b),
          }"
        />
        <div
          class="name-style"
          :class="{
            color: selected.index >= b.index,
            filled: isStepFilled(b),
            selected: selected.index === b.index,
          }"
        >
          {{ b.title }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    selected: Object,
    breadcrumb: Array,
    activity: Object,
  },
  methods: {
    goTo(page) {
      const {
        externalId,
      } = this.activity;

      if ((page.key === 'fields' && externalId)
          || (page.key === 'general' && externalId)) {
        this.$emit('selectedPage', page);
      }
    },
    isStepFilled(b) {
      if (b.index === this.selected.index) return false;

      const {
        externalId,
      } = this.activity;

      switch (b.key) {
        case 'general':
          return externalId;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

  .name-style {
    font-size: 1.1rem;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    color: gray;
    opacity: 0.75;

    &.selected {
      text-decoration: underline;
    }

    &.color, &.filled {
      color: black;
    }

    &:hover, &.selected {
      opacity: 1;
    }
  }

  .nav-style {
    border-left: 3px solid;
    height: 60px;
    margin-left: -9px;
    opacity: 0.8;
    color: gray;

    &.selected, &.filled {
      color: $ilabo;
    }
  }

  .circle-box {
    margin-right: 7px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    opacity: 0.8;
    background-color: gray;

    &.selected, &.filled {
      background-color: $ilabo;
    }
  }

</style>
