<script>
import OrderHistoryTab from '@/components/order/tabs/OrderHistoryTab.vue';

export default {
  name: 'CockpitActiveOrderHistoryTab',
  props: {
    activeOrder: Object,
  },
  components: {
    OrderHistoryTab,
  },
};
</script>

<template>
  <div class="order-history">
    <OrderHistoryTab
      :selected="activeOrder.order"
    />
  </div>
</template>

<style scoped lang="scss">
.order-history {
  &:deep() {
    .broadcast-box {
      font-size: 12px;
    }
  }
}
</style>
