<script>
import MarkingsTypeSelectButtons from '@/components/markings/MarkingsTypeSelectButtons.vue';
import MarkingsFilters from '@/components/markings/MarkingsFilters.vue';
import MarkingsList from '@/components/markings/list/MarkingsList.vue';
import CockpitOffcanvas from '@/components/cockpit/CockpitOffcanvas.vue';
import CockpitMatryoshkaItemContentsTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemContentsTab.vue';
import CockpitMatryoshkaItemDetailsTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemDetailsTab.vue';
import CockpitMatryoshkaItemLabelTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemLabelTab.vue';
import CockpitMatryoshkaItemHistoryTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemHistoryTab.vue';
import CockpitOffcanvasTypes from '@/components/cockpit/CockpitOffcanvasTypes';
import CockpitMatryoshkaItemActionButtons
from '@/components/cockpit/matryoshka/offcanvas/CockpitMatryoshkaItemActionButtons.vue';
import CockpitMatryoshkaItemAuditTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemAuditTab.vue';
import CockpitMatryoshkaItemOperationsTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemOperationsTab.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Cockpit',
  data: () => ({
    initLoading: false,
    selectedUnitId: 0,
    offcanvasVisible: false,
    offcanvasTabIndex: 0,
    pendingMarkingList: false,
    markingsList: [],
    loadingParents: false,
    markingParentsList: [],
    compatibilitySerialNumbers: [],
    filters: {
      searchQuery: '',
      orderId: null,
      status: null,
      lineId: null,
      skuId: null,
      startDate: null,
      endDate: null,
    },
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    selectedLabelArchiveId: null,
    CockpitOffcanvasTypes,
    fetchLabelArchiveDebounce: null,
  }),
  components: {
    CockpitMatryoshkaItemOperationsTab,
    CockpitMatryoshkaItemAuditTab,
    CockpitMatryoshkaItemActionButtons,
    CockpitMatryoshkaItemHistoryTab,
    CockpitMatryoshkaItemLabelTab,
    CockpitMatryoshkaItemDetailsTab,
    CockpitMatryoshkaItemContentsTab,
    CockpitOffcanvas,
    MarkingsList,
    MarkingsFilters,
    MarkingsTypeSelectButtons,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    ...mapGetters('packingSettings', [
      'getHigherLevelUnit',
      'getLowerLevelUnit',
      'getUnitById',
    ]),
    upperLevelUnit() {
      const first = this.markingsList[0];
      if (!first) return null;

      return this.getHigherLevelUnit(
        first.unitId,
        first.skuId,
      );
    },
    lowerLevelUnit() {
      const first = this.markingsList[0];
      if (!first) return null;

      return this.getLowerLevelUnit(
        first.unitId,
        first.skuId,
      );
    },
    allParentIds() {
      return this.markingsList
        .map(m => m.containers
          .find(c => c.containerUnitId === this.upperLevelUnit?.id)
          ?.containerId)
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter(v => v);
    },
    offcanvasTabs() {
      switch (this.offcanvasVisible) {
        case CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS:
          return [
            'Ogólne',
            'Zawartość',
            'Historia',
            'Etykieta',
          ];
        case CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT:
          return [
            'Audyt',
          ];
        case CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS:
          return [
            'Operacje',
          ];
        default:
          return [];
      }
    },
    selectedLabelArchiveObject() {
      return [
        ...this.markingsList,
        ...this.markingParentsList,
      ].find(m => m.id === this.selectedLabelArchiveId);
    },
    selectedLabelArchiveUnit() {
      return this.getUnitById(this.selectedLabelArchiveObject?.unitId);
    },
    offcanvasTitle() {
      const unitName = (this.selectedLabelArchiveUnit?.name || '').toUpperCase();
      switch (this.offcanvasVisible) {
        case CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS:
          return `Szczegóły - ${unitName}`;
        case CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT:
          return `Audyt - ${unitName}`;
        case CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS:
          return `Dostępne operacje - ${unitName}`;
        default:
          return '';
      }
    },
    isCompatibilityView() {
      return this.compatibilitySerialNumbers.length > 0;
    },
    offcanvasIcon() {
      return 'fa-info-circle';
    },
    fetchParams() {
      if (this.isCompatibilityView) {
        return {
          plantCode: this.globalSettings.plantCode,
          compatibilitySerialNumbers: this.compatibilitySerialNumbers,
          query: {
            unitId: this.selectedUnitId,
            orderId: this.filters.orderId,
            skuId: this.filters.skuId,
            labelStatus: this.filters.status,
            from: this.filters.startDate,
            to: this.filters.endDate,
            skip: (this.currentPage - 1) * this.perPage,
            take: 10000,
          },
        };
      }

      return {
        plantCode: this.globalSettings.plantCode,
        query: {
          serialNumber: this.filters.searchQuery,
          orderId: this.filters.orderId,
          skuId: this.filters.skuId,
          unitId: this.selectedUnitId,
          labelStatus: this.filters.status,
          from: this.filters.startDate,
          to: this.filters.endDate,
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
        },
      };
    },
    sortedMarkingsList() {
      if (this.isCompatibilityView) {
        return [...this.markingsList]
          .sort((a, b) => {
            const aIndex = this.compatibilitySerialNumbers.indexOf(a.serialNumber);
            const bIndex = this.compatibilitySerialNumbers.indexOf(b.serialNumber);

            return aIndex - bIndex;
          });
      }
      return this.markingsList;
    },
  },
  watch: {
    filters: {
      handler() {
        this.currentPage = 1;
      },
      deep: true,
    },
    fetchParams: {
      handler() {
        this.fetchLabelArchive();
      },
      deep: true,
    },
    allParentIds: {
      handler() {
        this.fetchMarkingsParents();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('labelArchive', [
      'getLabelArchive',
      'getLabelArchiveByIds',
      'getLabelArchiveByBarcodes',
    ]),
    ...mapActions('packingSettings', [
      'getUnits',
      'getPackingSettings',
    ]),
    async fetchLabelArchive() {
      const { plantCode } = this.globalSettings;
      if (!plantCode) return;
      this.pendingMarkingList = true;

      if (this.fetchLabelArchiveDebounce) {
        clearTimeout(this.fetchLabelArchiveDebounce);
      }

      this.fetchLabelArchiveDebounce = setTimeout(async () => {
        if (this.isCompatibilityView) {
          const { data } = await this.getLabelArchiveByBarcodes({
            params: {
              plantCode,
              query: {
                exactBarCodes: this.compatibilitySerialNumbers,
              },
            },
          });

          this.markingsList = data
            .filter((v, i) => data.findIndex(t => (t.id === v.id)) === i);
        } else {
          const { data: markingsListData } = await this.getLabelArchive({
            params: {
              plantCode,
              query: {
                serialNumber: this.filters.searchQuery,
                unitId: this.selectedUnitId,
                orderId: this.filters.orderId,
                skuId: this.filters.skuId,
                labelStatus: this.filters.status,
                from: this.filters.startDate,
                to: this.filters.endDate,
                skip: (this.currentPage - 1) * this.perPage,
                take: this.perPage,
              },
            },
          });

          this.markingsList = markingsListData.items;
          this.totalRows = markingsListData?.totalCount || 0;
        }
        this.pendingMarkingList = false;
      }, 500);
    },
    async fetchMarkingsParents() {
      const { plantCode } = this.globalSettings;
      if (!plantCode) return;

      this.loadingParents = true;
      const { data: markingsParentsListData } = await this.getLabelArchiveByIds({
        params: {
          plantCode,
          query: {
            ids: this.allParentIds,
          },
        },
      });
      this.markingParentsList = markingsParentsListData;
      this.loadingParents = false;
    },
    handleSelectItem(id) {
      this.selectedLabelArchiveId = id;
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS;
    },
    handleSelectItemContents(id) {
      this.selectedLabelArchiveId = id;
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS;
      this.offcanvasTabIndex = 1;
    },
    handleAuditButtonClicked() {
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT;
    },
    handleBackButtonClicked() {
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS;
    },
    handleOpenOperations() {
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS;
    },
    handleSerialNumberScan(serialNumber) {
      if (this.compatibilitySerialNumbers.includes(serialNumber)) return;
      this.compatibilitySerialNumbers = [
        ...this.compatibilitySerialNumbers,
        serialNumber,
      ];
    },
    removeCompatibilitySerialNumber(serialNumber) {
      this.compatibilitySerialNumbers = this.compatibilitySerialNumbers
        .filter(n => n !== serialNumber);
    },
    handleClickEnter() {
      this.handleSerialNumberScan(this.filters.searchQuery.trim());
      this.filters.searchQuery = '';
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        this.handleClickEnter();
      } else {
        const isNumber = /^[0-9]$/;
        const isLetter = /^[a-zA-Z]$/;
        const isSemiColon = /^;$/;
        const isDash = /^-$/;
        if (
          isNumber.test(event.key)
          || isLetter.test(event.key)
          || isSemiColon.test(event.key)
          || isDash.test(event.key)
        ) {
          this.filters.searchQuery += event.key;
        }
      }
    },
    initKeyDownListener() {
      document.addEventListener(
        'keydown',
        this.handleKeyDown,
      );
    },
    removeKeyDownListener() {
      document.removeEventListener(
        'keydown',
        this.handleKeyDown,
      );
    },
  },
  async created() {
    const { plantCode } = this.globalSettings;
    this.initLoading = true;
    this.initKeyDownListener();
    await this.getUnits({
      params: {
        plantCode,
      },
    });
    await this.getPackingSettings({
      params: {
        plantCode,
      },
    });
    await this.fetchLabelArchive();
    this.initLoading = false;
  },
  beforeDestroy() {
    this.removeKeyDownListener();
  },
};
</script>

<template>
  <Loader v-if="initLoading" />
  <div
    v-else
    class="w-100"
  >
    <div class="markings-root w-100">
      <MarkingsTypeSelectButtons
        v-if="!isCompatibilityView"
        v-model="selectedUnitId"
      />

      <div class="markings-card">
        <MarkingsFilters
          v-if="!isCompatibilityView"
          v-model="filters"
          @serial-number-scan="handleSerialNumberScan"
        />

        <div class="line" />

        <div
          v-if="isCompatibilityView"
          class="compatibility-comparison-numbers"
        >
          <span class="label">
            <i class="fas fa-scanner" />
            Porównanie zgodności dla numerów seryjnych:
          </span>

          <div class="numbers">
            <div
              v-for="(number, index) in compatibilitySerialNumbers"
              :key="`number-${number}-${index}`"
              class="number"
            >
              {{ number }}
              <i
                class="fas fa-times"
                @click.stop.prevent="removeCompatibilitySerialNumber(number)"
              />
            </div>
          </div>
        </div>

        <div class="list">
          <BOverlay :show="pendingMarkingList">
            <div
              v-if="!markingsList.length"
              class="p-5"
            />
            <MarkingsList
              v-else
              :list="sortedMarkingsList"
              :loading-parents="loadingParents"
              :parents-list="markingParentsList"
              :is-compatibility-view="isCompatibilityView"
              @select-item="handleSelectItem"
              @select-item-contents="handleSelectItemContents"
            />
          </BOverlay>
        </div>

        <div
          v-if="!isCompatibilityView"
          class="list-pagination"
        >
          <BPagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            size="sm"
            pills
            limit="5"
            first-number
            last-number
          />
        </div>
      </div>
    </div>

    <CockpitOffcanvas
      :show="!!offcanvasVisible"
      :tabs-key="offcanvasVisible || ''"
      :tab-index.sync="offcanvasTabIndex"
      :tabs="offcanvasTabs"
      :title="offcanvasTitle"
      :icon="offcanvasIcon"
      :show-audit-button="
        offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS"
      :show-back-button="
        offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
          || offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT
      "
      @audit-button-clicked="handleAuditButtonClicked"
      @back-button-clicked="handleBackButtonClicked"
      @update:show="offcanvasVisible = $event"
    >
      <template #default>
        <!--    MATRIOSZKA    -->
        <CockpitMatryoshkaItemDetailsTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 0
          "
          :label-archive-id="selectedLabelArchiveId"
        />
        <CockpitMatryoshkaItemContentsTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 1
          "
          :label-archive-id="selectedLabelArchiveId"
          @connections-updated="fetchLabelArchive"
        />
        <CockpitMatryoshkaItemHistoryTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 2
          "
          :label-archive-id="selectedLabelArchiveId"
        />
        <CockpitMatryoshkaItemLabelTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 3
          "
          :label-archive-id="selectedLabelArchiveId"
        />

        <!--    MATRIOSZKA OPERATIONS    -->
        <CockpitMatryoshkaItemOperationsTab
          v-if="offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
            && offcanvasTabIndex === 0"
        />

        <!--    MATRIOSZKA AUDIT    -->
        <CockpitMatryoshkaItemAuditTab
          v-if="offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT
            && offcanvasTabIndex === 0"
        />
      </template>

      <template #actions>
        <!--    MATRIOSZKA    -->
        <CockpitMatryoshkaItemActionButtons
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 0
          "
          @open-operations="handleOpenOperations"
        />
      </template>
    </CockpitOffcanvas>
  </div>
</template>

<style scoped lang="scss">
.markings-card {
  background-color: white;
  height: calc(100vh - 85px - 2.5rem);
  overflow: hidden;
  margin-top: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 9px 9px 0 0;
  display: flex;
  flex-direction: column;

  .line {
    width: 100%;
    border-bottom: 1px solid #F0F1F3;
  }

  .list {
    margin-top: 1rem;
    flex: 1;
    overflow-y: auto;
    padding-right: 1rem;
  }

  .compatibility-comparison-numbers {
    border: 1px solid #ECECEC;
    padding: 0.75rem;
    margin-top: 1rem;

    .label {
      font-size: 12px;
      font-weight: 500;
      color: #7F7F7F;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      i {
        font-size: 20px;
      }
    }

    .numbers {
      display: flex;
      gap: 0.5rem;
      margin-top: 0.5rem;
      overflow-x: scroll;
      padding-bottom: 0.5rem;

      .number {
        padding: 0.25rem 0.5rem;
        border: 1px solid #ECECEC;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 12px;
        color: #000000;
        font-weight: 500;

        i {
          cursor: pointer;
          color: #818181;
          font-size: 14px;
          transition: 0.3s all;

          &:hover {
            color: #000000;
            scale: 1.3;
          }
        }
      }
    }
  }

  .list-pagination {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    margin-bottom: -0.75rem;
    border-top: 1px solid #F0F1F3;
  }
}
</style>
