<template>
  <div>
    <div
      v-if="phrasedStatusDetails"
      :class="status.status === 'FAILED' ? 'error-box' : 'warning-box'"
    >
      <i class="icon fas fa-exclamation-circle" />
      <div class="message">
        <span class="font-weight-bold">
          Info:
        </span>
        <span>
          {{ phrasedStatusDetails }}
        </span>
      </div>
    </div>
    <div
      class="properties"
    >
      <div class="properties-column">
        <div class="prop-value-row">
          <span class="prop-text">
            Id:
          </span>
          <span class="value-text">
            {{ request.id || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Type:
          </span>
          <span>
            <div
              :style="{ 'background-color': type.color }"
              class="badge badge-danger text-white"
            >
              <div class="status-badge">
                <i
                  :class="type.icon"
                  class="pr-1"
                />
                {{ type.text || '-' }}
              </div>
            </div>
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Status:
          </span>
          <span>
            <div
              :style="{ 'background-color': status.color }"
              class="badge badge-success text-white"
            >
              <div class="status-badge">
                <i
                  :class="status.icon"
                  class="pr-1"
                />
                {{ status.text || '-' }}
              </div>
            </div>
          </span>
        </div>

        <div
          v-if="hadWarmingUp"
          class="prop-value-row"
        >
          <span class="prop-text">
            Warming Up:
          </span>
          <span>
            <div
              :style="{ 'background-color': '#FCBA03' }"
              class="badge badge-success text-white"
            >
              <div class="status-badge">
                <i
                  class="fas fa-angles-up pr-1"
                />
                {{ $t('general.yes') }}
              </div>
            </div>
          </span>
        </div>

        <hr>

        <div
          v-if="useAreas"
          class="prop-value-row"
        >
          <span class="prop-text">
            Area Id:
          </span>
          <span class="value-text">
            {{ areaId || '-' }}
          </span>
        </div>

        <div
          v-if="useAreas"
          class="prop-value-row"
        >
          <span class="prop-text">
            Area Name:
          </span>
          <span class="value-text">
            {{ areaName || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Line Id:
          </span>
          <span class="value-text">
            {{ request.lineId || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Line Name:
          </span>
          <span class="value-text">
            {{ request.lineName || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Labelling Point Id:
          </span>
          <span class="value-text">
            {{ request.labellingPointId || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Labelling Point Name:
          </span>
          <span class="value-text">
            {{ request.labellingPointName || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Printer Id:
          </span>
          <span class="value-text">
            {{ request.printerId || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Printer Name:
          </span>
          <span class="value-text">
            {{ request.printerName || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Label Id:
          </span>
          <span class="value-text">
            {{ request.labelId || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Label Name:
          </span>
          <span class="value-text">
            {{ request.labelName || '-' }}
          </span>
        </div>
      </div>

      <div class="properties-column">
        <div class="prop-value-row">
          <span class="prop-text">
            Order Id:
          </span>
          <span class="value-text">
            {{ request.orderId || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Order Number:
          </span>
          <span class="value-text">
            {{ request.orderNumber || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Order Description:
          </span>
          <span class="value-text">
            {{ request.orderDescription || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Order Execution Id:
          </span>
          <span class="value-text">
            {{ phrasedOrderExecutionId || '-' }}
          </span>
        </div>

        <hr>

        <div class="prop-value-row">
          <span class="prop-text">
            Sku Id:
          </span>
          <span class="value-text">
            {{ request.skuId || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Sku Number:
          </span>
          <span class="value-text">
            {{ request.skuNumber || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Sku Description:
          </span>
          <span class="value-text">
            {{ request.skuDescription || '-' }}
          </span>
        </div>

        <hr>

        <div class="prop-value-row">
          <span class="prop-text">
            Start:
          </span>
          <span class="value-text">
            {{ request.created || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            End:
          </span>
          <span class="value-text">
            {{ request.completed || '-' }}
          </span>
        </div>

        <div class="prop-value-row">
          <span class="prop-text">
            Duration:
          </span>
          <span class="value-text">
            {{ request.duration || '-' }}
          </span>
        </div>

        <hr>

        <div class="prop-value-row">
          <span class="prop-text">
            Issuer Id:
          </span>
          <span class="value-text">
            {{ request.issuerId || '-' }}
          </span>
        </div>

        <div
          v-if="user !== null"
          class="prop-value-row"
        >
          <span class="prop-text">
            Issuer Name:
          </span>
          <span class="value-text">
            {{ user.email || '-' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  CONFIG_TENANT_ADMIN,
} from '../../../utils/permissionsDictionary';

export default {
  props: {
    request: Object,
    type: Object,
    status: Object,
  },
  data: () => ({
    user: null,
  }),
  computed: {
    ...mapGetters(['canAny']),
    ...mapState({
      useAreas: state => state.settings.data?.useAreas || false,
      areas: state => state.areas.list || [],
    }),
    areaId() {
      return this.areas.find(a => a.lineIds?.find(l => l === this.request.lineId))?.id;
    },
    areaName() {
      return this.areas.find(a => a.lineIds?.find(l => l === this.request.lineId))?.name;
    },
    phrasedStatusDetails() {
      if (this.request?.requestStatusDetails) return this.request?.requestStatusDetails;
      const parsedSummary = JSON.parse(this.request?.responseDetails);
      return parsedSummary?.ErrorMessage ?? parsedSummary?.Message;
    },
    phrasedOrderExecutionId() {
      return JSON.parse(this.request?.requestDetails)?.OrderExecutionId;
    },
    hadWarmingUp() {
      return this.request?.properties
        ?.find(prop => prop.key === 'RequestWithWarmingUp')
        ?.value?.toUpperCase() === 'TRUE';
    },
  },
  methods: {
    ...mapActions([
      'getUser',
    ]),
    getRequesterDetails() {
      if (!this.canAny(CONFIG_TENANT_ADMIN) || this.request.issuerId == null) {
        return;
      }
      this.getUser({
        params: {
          userId: this.request.issuerId,
        },
      })
        .then(({ data }) => {
          this.user = data;
        });
    },
  },
  created() {
    this.getRequesterDetails();
  },
};
</script>

<style lang="scss" scoped>
  .warning-box, .error-box {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    max-width: 50;

    .icon {
      padding-top: 4px;
      width: 20px;
    }

    .message {
      width: 100%;
      word-break: break-all;
    }
  }

  .warning-box {
    background-color: #FCBA03;
    color: #ffffff;
  }

  .error-box {
    background-color: #FFEFEE;
    color: #D70819;
  }

  .properties {
    display: flex;

    .properties-column {
      padding-right: 20px;
      width: 50%;
    }
  }

  .prop-value-row {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .prop-text {
      width: 40%;
      font-size: 0.8rem;
      font-weight: 500;
      color: #979797;
    }

    .value-text {
      width: 60%;
      margin-left: 5px;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }

  .status-badge {
    padding-block: 1px;
    font-size: 1.1em;
    width: 100px;
    line-height: 1.3em;
    white-space: pre-wrap;
    text-transform: uppercase;
  }

</style>
