import Vapi from 'vuex-rest-api';
import moment from 'moment';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getGlobalSettings',
    property: 'data',
    path: () => '/settings',
  })
  .get({
    action: 'getLabelingMapping',
    path: () => '/labelingpointmappings',
  })
  .get({
    action: 'getAllLabelingMappings',
    property: 'list',
    path: () => '/labelingpointmappings/all',
  })
  .put({
    action: 'setGlobalSettings',
    path: () => '/settings',
  })
  .put({
    action: 'setLabelingMapping',
    path: () => '/labelingpointmappings',
  })
  .getStore();

store.getters = {
  globalSettings: s => s.data,
  maxInputLength: () => 40,
  maxDescriptionLength: () => 100,
  fromPlantTimeToUtc: (_, g) => dateTime => {
    if (!dateTime) return null;
    const plantUtcOffset = moment.tz(dateTime, g.globalSettings.plantTimeZone).utcOffset();
    return moment.utc(dateTime).subtract(plantUtcOffset, 'minutes').format('YYYY-MM-DD HH:mm:ss');
  },
  fromUtcToPlantTime: (_, g) => dateTime => {
    if (!dateTime) return null;
    const plantUtcOffset = moment.tz(dateTime, g.globalSettings.plantTimeZone).utcOffset();
    return moment.utc(dateTime).add(plantUtcOffset, 'minutes').format('YYYY-MM-DD HH:mm:ss');
  },
};

export default store;
