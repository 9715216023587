<template>
  <div class="d-flex flex-column">
    <LModal
      size="lg"
      :show.sync="form"
    >
      <LineEditor
        v-bind.sync="selected"
        @successMsg="showSuccessMsg($event)"
        @failedMsg="showFailedMsg($event)"
        @close="form = false"
      />
    </LModal>

    <div class="row justify-content-between mx-0">
      <h3 style="font-size: 1.75em">
        <i class="ion ion-ios-apps pr-2" />
        {{ $t('line.lines') }}
      </h3>
      <div class="align-self-center pr-2">
        <button
          class="btn btn-primary btn-sm"
          @click="form = true, setSelected(null)"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('line.addLine') }}
        </button>
      </div>
    </div>

    <div
      style="min-height: 70vh; overflow:auto"
      class="box-style flex-fill mt-2"
    >
      <div class="form-group py-1">
        <SearchInput @input="search = $event" />
      </div>
      <SimpleTable
        class="table-style"
        :items="searchedLines"
        :fields="columns"
        @row-clicked="setSelected($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import SimpleTable from '@/components/tables/SimpleTable';
import createSearch from '@core/utils/search';
import LineEditor from './LineEditor';

export default {
  data() {
    return {
      form: false,
      selected: null,
      search: null,
    };
  },
  components: {
    LineEditor,
    SimpleTable,
  },
  computed: {
    ...mapState({
      useAreas: state => state.settings.data?.useAreas || false,
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
      areas: state => (state.areas.list ? state.areas.list : []),
      lines: state => (state.lines.list ? state.lines.list : []),
    }),
    ...mapGetters(['timeOfNotification']),
    phrasedLines() {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return this.lines.map(l => ({
        ...l,
        modified: moment.utc(l.modified).add(plantUtcOffset, 'minutes').format('ll LTS'),
        created: moment.utc(l.created).add(plantUtcOffset, 'minutes').format('ll LTS'),
        areaName: this.areas?.find(a => a.id === l.areaId)?.name,
      }));
    },
    searchedLines() {
      const search = createSearch(this.search);
      return this.phrasedLines
        .filter(({ name, description }) => search(name) || search(description));
    },
    columns() {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
          condition: true,
        },
        {
          key: 'name',
          label: this.$t('general.name'),
          class: 'text-left',
          condition: true,
          sortable: true,
        },
        {
          key: 'code',
          label: this.$t('general.code'),
          class: 'text-left',
          condition: true,
          sortable: true,
        },
        {
          key: 'description',
          label: this.$t('general.description'),
          class: 'text-left',
          condition: true,
        },
        {
          key: 'areaName',
          label: this.$t('area.area'),
          class: 'text-left',
          condition: this.useAreas,
          sortable: true,
        },
      ].filter(x => x.condition);
    },
  },
  methods: {
    setSelected(event) {
      this.selected = event;
      this.form = true;
    },
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.saved'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('request.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 30px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .search {
    border-radius: .25rem;
  }

</style>
