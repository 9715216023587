import Vue from 'vue';
import lang from '@/lang';
import {
  LOGICPRINT5_LABELLING,
  LOGICPRINT5_LABELS,
  LOGICPRINT5_ORDERS,
  LOGICPRINT5_REQUESTS,
  LOGICPRINT5_SERIALIZATION_AND_AGGREGATION,
  LOGICPRINT5_SETTINGS,
  LOGICPRINT5_SKU,
} from '@/utils/permissionsDictionary';

export default {
  state: {
    mobile: false,
    hubUrl: null,
    applicationVersion: null,
  },
  getters: {
    hubUrl: s => s.hubUrl,
    applicationVersion: s => s.applicationVersion,
    sidebar: () => [
      {
        key: 'cockpit',
        route: '/cockpit',
        icon: 'fas fa-play-circle',
        text: lang.t('sidebar.cockpit'),
        permittedFor: [LOGICPRINT5_SERIALIZATION_AND_AGGREGATION],
      },
      {
        key: 'labelling',
        route: '/',
        icon: 'fas fa-barcode',
        text: lang.t('sidebar.labelling'),
        permittedFor: [LOGICPRINT5_LABELLING],
      },
      {
        key: 'orders',
        route: '/orders',
        icon: 'icon-orders',
        text: lang.t('sidebar.orders'),
        permittedFor: [LOGICPRINT5_ORDERS],
      },
      {
        key: 'sku',
        route: '/sku',
        icon: 'icon-sku',
        text: lang.t('sidebar.sku'),
        permittedFor: [LOGICPRINT5_SKU],
      },
      {
        key: 'markings',
        route: '/markings',
        icon: 'fas fa-barcode',
        text: lang.t('sidebar.markings'),
        permittedFor: [LOGICPRINT5_SERIALIZATION_AND_AGGREGATION],
      },
      {
        key: 'labelsMapper',
        route: '/labelsMapper',
        icon: 'fas fa-code-compare',
        text: lang.t('sidebar.labels'),
        permittedFor: [LOGICPRINT5_LABELS],
      },
      {
        key: 'requests',
        route: '/requests',
        icon: 'fas fa-list-check',
        text: lang.t('sidebar.requests'),
        permittedFor: [LOGICPRINT5_REQUESTS],
      },
      {
        key: 'settings',
        text: lang.t('sidebar.settings'),
        icon: 'fas fa-cog',
        route: '/settings/plant/generalSettings',
        permittedFor: [LOGICPRINT5_SETTINGS],
      },
    ],
    permittedRoutes: (s, g) => g.sidebar.filter(x => !x.permittedFor
      || x.permittedFor.some(permission => g.canAny(permission))),
  },
  mutations: {
    setMobile(s, v) {
      s.mobile = v;
    },
    setConfig(s, config) {
      Object.keys(config).forEach(key => {
        Vue.set(s, key, config[key]);
      });
    },
  },
};
