<template>
  <div class="d-flex">
    <Transition name="slide-right">
      <SettingsMenu
        v-show="show"
        :selected="selected"
        @selected="selected = $event"
        @page="page = $event"
        @close="showMenu = false"
      />
    </Transition>
    <div class="flex-grow-1 pt-3">
      <div v-if="isMobile">
        <button
          class="btn btn-outline-secondary icon-btn"
          @click="showMenu = true"
        >
          <i class="ion ion-ios-arrow-back" />
        </button>
      </div>
      <div
        v-if="page"
        :class="{ mobile: isMobile }"
        class="title"
      >
        <i :class="page.icon" />
        {{ page.text }}
        <hr>
      </div>
      <RouterView
        @selected="selected = $event"
      />
    </div>
  </div>
</template>

<script>
import SettingsMenu from '@/components/settings/SettingsMenu';

export default {
  data: () => ({
    selected: null,
    page: null,
    showMenu: true,
    isMobile: false,
  }),
  components: {
    SettingsMenu,
  },
  computed: {
    show() {
      return this.showMenu || !this.isMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
  .title {
    font-size: 26px;
    font-weight: 500;
    i {
      padding-right: 6px;
      color: rgba(100, 100, 100, 0.8);
      text-shadow: 0 0 5px rgba(100, 100, 100, 0.5);
    }
    hr {
      max-width: 300px;
      margin-left: 0;
      margin-top: 5px;
    }

    &.mobile {
      text-align: center;
      hr {
        margin-left: auto;
      }
    }
  }
</style>
