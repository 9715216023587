import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from 'moment';
import plLocale from '@/utils/plMomentLocale';

// translations
import PortalVue from 'portal-vue';
import en from './en/index';
import pl from './pl/index';
import lt from './lt/index';
import ro from './ro/index';

// load necessary moment locales
require('moment/locale/pl');
require('moment/locale/en-gb');
require('moment/locale/lt');
require('moment/locale/ro');

Vue.use(VueI18n);
Vue.use(PortalVue);

moment.updateLocale('pl', plLocale);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    pl,
    lt,
    ro,
  },
});

export default i18n;
