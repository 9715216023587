<template>
  <div class="d-flex flex-column">
    <LModal
      :show.sync="form"
    >
      <SkuPropertyTypeForm
        :property-type="edited"
        @successMsg="showSuccessMsg($event)"
        @failedMsg="showFailedMsg($event)"
        @close="form = false"
      />
    </LModal>

    <div class="row justify-content-between mx-0">
      <h3 style="font-size: 1.75em">
        <i class="fas fa-boxes pr-2" />
        {{ $t('sku.fields') }}
      </h3>
      <div class="align-self-center pr-2">
        <button
          class="btn btn-primary btn-sm"
          @click="edited = null; form = true"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('form.addField') }} {{ $t('sku.sku') }}
        </button>
      </div>
    </div>

    <div
      style="min-height: 70vh; overflow:auto"
      class="box-style flex-fill mt-2"
    >
      <div class="form-group py-1">
        <SearchInput @input="search = $event" />
      </div>
      <SimpleTable
        class="table-style"
        :items="searchedPropertyTypes"
        :fields="columns"
        @row-clicked="editClick($event)"
      >
        <template #cell(valueType)="data">
          <div
            :style="{ 'background-color': colorCode(data.item.valueType) }"
            class="badge badge-success text-white"
            style="width: 100px"
          >
            <div
              class="px-2 text-uppercase badge-style"
            >
              {{ data.item.valueType }}
            </div>
          </div>
        </template>
      </SimpleTable>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SimpleTable from '@/components/tables/SimpleTable';
import createSearch from '@core/utils/search';
import SkuPropertyTypeForm from './SkuPropertyTypeForm';

export default {
  data: () => ({
    form: false,
    edited: null,
    search: null,
  }),
  components: {
    SkuPropertyTypeForm,
    SimpleTable,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      propertyTypes: state => state.skuProperties.types,
    }),
    sortedPropertyTypes() {
      return this.propertyTypes?.slice().sort((a, b) => (a.name).localeCompare(b.name)) || [];
    },
    searchedPropertyTypes() {
      const search = createSearch(this.search);
      return this.sortedPropertyTypes
        .filter(({ name, description }) => search(name) || search(description));
    },
    columns() {
      return [
        {
          key: 'name',
          label: this.$t('general.name'),
          sortable: true,
          groupable: true,
        },
        {
          key: 'description',
          label: this.$t('general.description'),
          class: 'text-left',
        },
        {
          key: 'valueType',
          label: this.$t('general.valueType'),
          sortable: true,
          groupable: true,
        },
        {
          key: 'type',
          label: this.$t('general.type'),
          class: 'text-left',
        },
      ];
    },
    badges() {
      return [
        {
          key: 'string',
          color: '#FF7448',
        },
        {
          key: 'decimal',
          color: '#3684C7',
        },
        {
          key: 'int',
          color: '#FFAE48',
        },
        {
          key: 'bool',
          color: '#A985DE',
        },
        {
          key: 'dictionary',
          color: '#FFCC0B',
        },
      ];
    },
  },
  methods: {
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.saved'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('request.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
    colorCode(v) {
      return this.badges.find(x => x.key === v?.toLowerCase())?.color || 'green';
    },
    editClick(v) {
      this.edited = v;
      this.form = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .table::v-deep .list-content {
    font-size: 14px;
  }

  .table {
    height: 100%;
  }

  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 30px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .search {
    border-radius: .25rem;
  }

</style>
