<script>
import DatePicker from '@/components/selectors/DatePicker.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'MarkingsFilters',
  props: {
    value: Object,
  },
  data: () => ({
    orderOptions: [],
    skuOptions: [],
  }),
  components: { DatePicker },
  emits: [
    'input',
    'serial-number-scan',
  ],
  computed: {
    ...mapState({
      lines: state => state.lines.list || [],
    }),
    ...mapGetters('labelArchive', [
      'labelArchiveStatuses',
    ]),
    lineOptions() {
      return this.lines
        .map(l => ({
          ...l,
          label: l.name,
          value: l.id,
          iconClass: 'fas fa-layer-group app-color',
        })) || [];
    },
    statusOptions() {
      return this.labelArchiveStatuses.map(s => ({
        ...s,
        label: s.name,
        value: s.id,
        iconClass: s.icon,
      }));
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dates() {
      return {
        start: this.innerValue.startDate,
        end: this.innerValue.endDate,
      };
    },
  },
  watch: {
    dates: {
      handler() {
        this.fetchOrderOptions();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'getOrders',
    ]),
    handleScanSerialNumber(number) {
      this.$emit('serial-number-scan', number || this.innerValue.searchQuery);
      this.innerValue.searchQuery = '';
    },
    handleSetDates(dates) {
      this.innerValue = {
        ...this.innerValue,
        startDate: dates.startDate,
        endDate: dates.endDate,
      };
    },
    async fetchOrderOptions() {
      const { data } = await this.getOrders({
        params: {
          query: {
            startDate: this.innerValue.startDate,
            endDate: this.innerValue.endDate,
            count: 1000,
          },
        },
      });
      this.skuOptions = data.orders
        .map(o => o.sku)
        .filter((v, i, a) => a.findIndex(() => v.id) === i)
        .map(l => ({
          ...l,
          label: `${l.name || l.description} (${l.externalId})`,
          value: l.id,
          iconClass: 'fas fa-layer-group app-color',
        })) || [];
      this.orderOptions = data.orders
        .map(l => ({
          ...l,
          label: `${l.description}`,
          value: l.id,
          iconClass: 'fas fa-layer-group app-color',
        })) || [];
    },
  },
  created() {
    this.fetchOrderOptions();
  },
};
</script>

<template>
  <div class="markings-filters">
    <div class="search-input-box">
      <BInput
        v-model="innerValue.searchQuery"
        size="sm"
        placeholder="Zeskanuj lub wyszukaj numer seryjny..."
        class="search-input"
        @keydown.enter.stop.prevent="handleScanSerialNumber()"
      />
    </div>

    <div>
      <ProSelect
        v-model="innerValue.orderId"
        :options="orderOptions"
        show-all-option
        label="ID zlecenia"
        :box-height="'26px'"
        :dropdown-width="'300px'"
        icon-class="fas fa-layer-group"
        icon-color-class="app-color"
      />
    </div>

    <div>
      <ProSelect
        v-model="innerValue.skuId"
        :options="skuOptions"
        show-all-option
        label="SKU"
        :box-height="'26px'"
        :dropdown-width="'300px'"
        icon-class="fas fa-layer-group"
        icon-color-class="app-color"
      />
    </div>

    <div>
      <ProSelect
        v-model="innerValue.lineId"
        :options="lineOptions"
        show-all-option
        label="Linia"
        :box-height="'26px'"
        :dropdown-width="'150%'"
        icon-class="fas fa-layer-group"
        icon-color-class="app-color"
      />
    </div>

    <div>
      <ProSelect
        v-model="innerValue.status"
        :options="statusOptions"
        show-all-option
        label="Status"
        :box-height="'26px'"
        :dropdown-width="'150%'"
        icon-class="fas fa-layer-group"
        icon-color-class="app-color"
      />
    </div>

    <div class="ml-auto">
      <DatePicker
        ref="dateItemPicker"
        :custom-look="true"
        :custom-height="'26px'"
        :start-date="innerValue.startDate"
        :end-date="innerValue.endDate"
        @setDates="handleSetDates"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.markings-filters {
  display: flex;
  gap: 8px;
  margin-bottom: 1rem;
  align-items: flex-end;

  .search-input-box {
    width: 350px;
  }

  .search-input {
    border: 1px solid #CED3D8;
    padding: 14px 8px;
    font-size: 13px;
    width: 100%;
    min-width: 20px;
  }
}
</style>
