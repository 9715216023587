<template>
  <div>
    <Tippy
      ref="popup"
      :placement="placement || 'right'"
      :distance="7"
      theme="light"
      interactive
      append-to="parent"
      trigger="click"
      arrow
      :on-show="onShow"
    >
      <template #trigger>
        <slot>
          <div
            class="select-box bg-white"
            :style="rightState ? 'border-color: #75af34' : ''"
            :class="rightState ? 'row justify-content-between mx-0' : ''"
          >
            <div>
              <div class="label">
                {{ label }}
              </div>

              <div
                v-for="el in selected"
                :key="el.id"
                class="selected-row font-weight-bold d-flex justify-content-between align-items-center"
                style="font-size:15px"
              >
                <div>
                  <i
                    v-if="icon"
                    :class="`${icon} pr-1`"
                  />
                  {{ el.name }}
                </div>
                <div
                  v-if="hasUnit(value)"
                  style="max-width: 75px; position: relative"
                  @click.stop
                >
                  <BFormInput
                    :ref="`input${el.id}`"
                    :value="value[el.id]"
                    size="sm"
                    style="border-radius: 10px"
                    @input="updateValue($event, el.id)"
                    @click="$refs[`input${el.id}`][0].focus(); $refs[`input${el.id}`][0].select()"
                  />
                  <span class="unit">
                    {{ unit }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="rightState"
              style="align-self: center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="#75af34"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07
                  1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324
                  8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093
                  3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </div>
          </div>
        </slot>
      </template>

      <div class="popover-container text-left">
        <div class="d-flex justify-content-between align-items-center pb-1">
          <div
            style="line-height: 1.1"
            class="text-secondary"
          >
            {{ label }}
          </div>
          <div
            class="close"
            @click="close"
          >
            <span aria-hidden="true">×</span>
          </div>
        </div>
        <div style="margin-left: -5px; margin-right: -5px">
          <BFormInput
            v-if="searchable.length > 0"
            ref="search"
            v-model="search"
            size="sm"
            :placeholder="`${$t('general.search')}...`"
          />
          <div
            class="mt-2"
            style="height: 300px; overflow: auto"
          >
            <div
              v-for="w in filteredList"
              :key="w.id"
              class="value-row position-relative"
              :class="{ selected: isSelected(w.id) }"
              @click="select(w.id)"
            >
              <slot
                name="valueRow"
                :row="w"
                :selected="isSelected(w.id)"
              >
                <span
                  v-if="isSelected(w.id)"
                  style="margin-left: -8px;"
                >
                  <i class="ion ion-md-checkmark pr-1" />
                </span>
                <i
                  v-else-if="icon"
                  :class="`${icon} pr-1`"
                />
                {{ w.name }}
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Tippy>
  </div>
</template>

<script>
import createSearch from '../utils/search';

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: [Array, Object, Number, String],
    icon: String,
    label: String,
    single: Boolean,
    unit: String,
    searchable: {
      type: Array,
      default: () => [],
    },
    placement: String,
    rightState: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    popover: false,
    search: '',
  }),
  computed: {
    filteredList() {
      if (this.searchable.length === 0) return this.list;
      const search = createSearch(this.search);

      return this.list.filter(x => this.searchable.some(key => x[key] && search(x[key])));
    },
    valueList() {
      if (!this.value) return [];
      if (Array.isArray(this.value)) return this.value;
      if ((typeof this.value) === 'number' || (typeof this.value) === 'string') return [this.value];
      return Object.keys(this.value);
    },
    selected() {
      return this.valueList.map(x => this.list.find(y => y.id === x))
        .filter(x => x);
    },
  },
  methods: {
    hasUnit(value) {
      if (Array.isArray(value)) return false;
      if ((typeof this.value) === 'number' || (typeof this.value) === 'string') return false;
      return true;
    },
    hidePopup() {
      this.popover = false;
    },
    onShow() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
          this.$refs.search.select();
        }
      });
    },
    close() {
      this.$refs.popup.tippy().hide();
    },
    updateValue(v, id) {
      if (this.single) {
        this.$emit('update:value', v);
      }
      this.$emit('update:value', {
        ...this.value,
        [id]: v,
      });
    },
    isSelected(x) {
      return this.valueList.some(id => id === x);
    },
    select(id) {
      if (this.isSelected(id)) {
        if (this.single) {
          this.$emit('update:value', '');
          this.close();
        } else if (Array.isArray(this.value)) {
          this.$emit('update:value', this.value.filter(x => x !== id));
        } else {
          const res = { ...this.value };
          this.$delete(res, id);
          this.$emit('update:value', res);
        }
      } else if (this.single) {
        this.$emit('update:value', id);
        this.close();
      } else if (Array.isArray(this.value)) {
        this.$emit('update:value', this.value.concat([id]));
      } else {
        this.$emit('update:value', {
          ...this.value,
          [id]: 1,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .selected-row {
    line-height: 1.2;
  }

  #owner-select {
    .box {
      border: 1px solid #ced4da;
      border-radius: .2rem;
      padding: 2px 10px;
      cursor: pointer;

      &:hover {
        border: 1px solid rgba(100, 100, 100, 0.5);
      }
    }
  }

  .unit {
    position: absolute;
    top: 3px;
    right: 5px;
    font-size: 12px;
    color: rgb(100, 100, 100);
  }

  .popover-container {
    min-width: 180px;
    margin-top: -2px;
  }

  .value-row {
    padding: 2px 15px;
    cursor: pointer;
    line-height: 1.6;

    &:hover {
      background-color: rgba(39, 33, 33, 0.1);
    }

    &.selected {
      background-color: $ilabo;
      color: white;
      &:hover {
        background-color: rgba($ilabo, 0.8);
      }
    }
  }

  .close {
    cursor: pointer;
    font-size: 20px;
    position: relative;
    top: -2px;
  }
</style>
