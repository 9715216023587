<script>
export default {
  name: 'CockpitMatryoshkaPrintManuallyTab',
};
</script>

<template>
  <div class="is-placeholder">
    <p class="text-center p-5">
      Zakładka drukowania ręcznego
    </p>
  </div>
</template>

<style scoped lang="scss">

</style>
