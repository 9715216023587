import { render, staticRenderFns } from "./DictionaryEntryEditor.vue?vue&type=template&id=11d0af94&scoped=true"
import script from "./DictionaryEntryEditor.vue?vue&type=script&lang=js"
export * from "./DictionaryEntryEditor.vue?vue&type=script&lang=js"
import style0 from "./DictionaryEntryEditor.vue?vue&type=style&index=0&id=11d0af94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11d0af94",
  null
  
)

export default component.exports