<script>
export default {
  name: 'CockpitLineVerticalSelectorItem',
  props: {
    line: Object,
    selected: Boolean,
  },
  emits: ['select'],
};
</script>

<template>
  <div
    class="cockpit-line-item"
    :class="{
      selected
    }"
    @click="$emit('select', line.lineId)"
  >
    {{ line.name }}
  </div>
</template>

<style scoped lang="scss">
.cockpit-line-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  min-height: 46px;
  width: 70px;
  max-width: 70px;
  min-width: 70px;
  background-color: #EDEDED;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: #e5e5e5;
  }

  &.selected {
    background-color: #D9D9D9;
  }
}
</style>
