<template>
  <div style="max-height: 100%">
    <h3
      style="font-size: 1.5em"
      class="my-3 mb-4"
    >
      <i class="icon-sku pr-2" />
      {{ $t('sku.sku') }}
    </h3>
    <div class="box-style">
      <div class="row">
        <OptionsSelector
          class="pl-3"
          style="flex: auto"
          :view="selectedView"
          @updateView="selectedView = $event"
          @updateSearch="search = $event"
        />
        <div
          class="row ml-3 mr-4 mt-3"
          style="align-self: center"
        >
          <BFormCheckbox
            v-model="showOnlyWithoutMappedLabel"
            switch
          />
          <div
            class="filter-checkbox-label"
            @click="showOnlyWithoutMappedLabel = !showOnlyWithoutMappedLabel;"
          >
            {{ $t('sku.onlyWithoutLabels') }}
          </div>
        </div>
      </div>
      <div
        class="pt-2"
        @click.stop.prevent
      >
        <Loader
          v-if="pendingSkus"
          style="height: 65vh; padding-top: 20vh"
        />
        <ExtendedSkuTable
          v-else
          ref="skuTable"
          :list="skuWithLabel"
          :table-fields="skuColumns"
          :show-deleted="onlyDeleted"
          :no-data-info="$t('sku.noSku')"
          select-many
          :checked-ids.sync="selectMany"
          @selected="selectSku($event)"
          @reload="reloadSkus()"
          @checkboxClicked="showOffcanvas = false"
          @successMsg="showSuccessMsg($event)"
          @failedMsg="showFailedMsg($event)"
        />
      </div>
      <div class="row justify-content-between align-items-center mx-0 mt-2">
        <span class="footer-text ml-1">
          {{ $t('form.showing') }}
          <span style="font-weight: 600">
            {{ skus.length ? 1 + (currentPage - 1)*perPage : 0 }}
            - {{ currentPage*perPage > totalRows ? totalRows : currentPage*perPage }}
          </span>
          {{ $t('general.of') }}
          <span style="font-weight: 600">
            {{ totalRows }}
          </span>
          {{ $t('form.results' ).toLowerCase() }}
        </span>
        <span class="pt-4">
          <BPagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            style="justify-content: center"
            pills
            limit="5"
            first-number
            last-number
          />
        </span>
        <span class="row align-items-center mr-2">
          <span class="row-text mr-2">
            {{ $t('form.rowsOnThePage') }}
          </span>
          <BFormSelect
            v-model="perPage"
            :options="perPageOptions"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            style="width: 75px"
            size="sm"
          />
        </span>
      </div>
    </div>
    <Offcanvas
      :show="showOffcanvas"
      passive
      no-bottom-padding
      @update:show="showOffcanvas = $event"
    >
      <SkuDetails
        v-if="showOffcanvas"
        :key="selected.id"
        :sku-id="selected.id"
        @close="showOffcanvas = false"
      />
    </Offcanvas>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import OptionsSelector from '@/components/selectors/OptionsSelector';
import ExtendedSkuTable from '@/components/sku/ExtendedSkuTable';
import SkuDetails from '@/components/sku/SkuDetails';

export default {
  data: () => ({
    showOffcanvas: false,
    selected: null,
    selectMany: {},
    onlyDeleted: false,
    template: [],
    selectedView: 'active',
    search: null,
    showOnlyWithoutMappedLabel: false,
    currentPage: 1,
    totalRows: 0,
    perPage: 20,
    debouncedRequestTimer: null,
    skus: [],
    pendingSkus: false,
    perPageOptions: [
      { item: 20, name: '20' },
      { item: 50, name: '50' },
      { item: 100, name: '100' },
    ],
  }),
  components: {
    OptionsSelector,
    ExtendedSkuTable,
    SkuDetails,
  },
  computed: {
    ...mapState({
      labelMappings: state => state.labels.mappings,
      labels: state => state.labels.templates || [],
    }),
    ...mapGetters(['timeOfNotification']),
    selectedList() {
      return Object.keys(this.selectMany).filter(k => this.selectMany[k]);
    },
    skuWithLabel() {
      return this.skus
        .map(s => ({
          ...s,
          description: s.description || '-',
          labelCount: this.filteredMapping(s.id).length,
          labelName: this.filteredMapping(s.id).length > 1
            ? this.filteredMapping(s.id).map(f => f.name) : [this.filteredMapping(s.id)[0]?.name] || null,
        }));
    },
    skuColumns() {
      return [
        {
          key: 'left-options',
          label: '',
          class: 'left-options',
          thStyle: {
            width: '5%',
          },
        },
        {
          key: 'externalId',
          label: 'SKU Id',
          class: 'text-left',
          thStyle: {
            width: '20%',
          },
        },
        {
          key: 'name',
          label: this.$t('general.name'),
          class: 'text-left',
          thStyle: {
            width: '25%',
          },
        },
        {
          key: 'modified',
          label: this.$t('general.modified'),
          class: 'text-left',
          thStyle: {
            width: '20%',
          },
        },
        {
          key: 'labelName',
          label: this.$t('label.labels'),
          class: 'text-left',
          thStyle: {
            width: '30%',
          },
        },
      ];
    },
  },
  watch: {
    selectedList() {
      this.template = [];
    },
    selectedView(v) {
      if (v === 'active') {
        this.onlyDeleted = false;
        this.reloadSkus();
      } else {
        this.onlyDeleted = true;
        this.reloadSkus();
      }
    },
    perPage() {
      this.reloadSkus();
    },
    currentPage() {
      this.reloadSkus();
    },
    search() {
      this.reloadSkusDebounced();
    },
    showOnlyWithoutMappedLabel() {
      this.reloadSkus();
    },
    showOffcanvas(show) {
      if (!show) {
        this.clearSelected();
      }
    },
    skus() {
      this.restoreSelectionInTable();
    },
    labelMappings() {
      this.restoreSelectionInTable();
    },
  },
  methods: {
    ...mapActions([
      'getSkus',
      'getLabelTemplates',
      'getLabelsSkusMappings',
    ]),
    showSuccessMsg(message) {
      this.$bvToast.toast(message, {
        title: this.$t('general.saved'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(message) {
      this.$bvToast.toast(message, {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
    selectSku(event) {
      if (!event.deleted) {
        this.showOffcanvas = true;
        this.selected = event;
      }
    },
    clearSelected() {
      this.selected = null;
      this.$refs.skuTable
        ?.$refs.simpleTable
        ?.$refs.table
        ?.clearSelected();
    },
    filteredMapping(id) {
      const mapping = this.labelMappings.filter(m => m.skuId === id);

      return this.labels.filter(el => mapping.some(f => f.labelId === el.id));
    },
    reloadSkusDebounced() {
      if (this.debouncedRequestTimer) {
        clearTimeout(this.debouncedRequestTimer);
      }
      this.debouncedRequestTimer = setTimeout(() => {
        this.reloadSkus();
      }, 500);
    },
    restoreSelectionInTable() { // when data in table is updated selection is lost
      if (!this.selected) {
        return;
      }
      const tableWithSku = this.$refs.skuTable
        ?.$refs.simpleTable
        ?.$refs.table;
      setTimeout(() => {
        tableWithSku.selectRow(tableWithSku.selectedLastRow);
      }, 200);
    },
    reloadSkus() {
      this.pendingSkus = true;
      this.selectMany = {};

      const query = {};

      if (this.search) {
        this.$set(query, 'search', this.search);
      }

      this.getSkus({
        params: {
          query: {
            onlyDeleted: this.onlyDeleted,
            onlyWithoutMappedLabels: this.showOnlyWithoutMappedLabel,
            page: this.currentPage - 1,
            count: this.perPage,
            ...query,
          },
        },
      }).then(({ data }) => {
        this.skus = data.items || [];
        this.totalRows = data.totalCount;
        this.getLabelsSkusMappings({
          params: {
            query: {
              skuIds: this.skus.map(sku => sku.id),
            },
          },
        }).finally(() => {
          this.pendingSkus = false;
        });
      });
    },
  },
  created() {
    this.onlyDeleted = false;
    this.reloadSkus();
    this.getLabelTemplates();
  },
};
</script>

<style lang="scss" scoped>

  .footer-text {
    font-size: 0.8em;
    color: #595959;
  }

  .filter-checkbox-label {
    font-weight: 430;
    font-size: 0.9em;
    color: #4D4D4D;
    cursor: default;
  }

</style>
