<template>
  <div style="max-height: 100%">
    <div v-if="openEditor">
      <TemplateEditor
        :selected="selected"
        @reload="refresh()"
        @back="backToTemplates($event)"
      />
    </div>
    <div v-else>
      <div class="row">
        <h3
          style="font-size: 1.5em"
          class="m-3"
        >
          <i class="fas fa-clipboard pr-2" />
          {{ $t('labelTemplates') }}
        </h3>
        <div
          class="m-3"
        >
          <button
            class="btn btn-primary btn-block btn-sm"
            type="submit"
            :disabled="waitWithCreatingNextRequest"
            @click="onRefreshLabelsButtonClick"
          >
            <BSpinner
              v-if="waitWithCreatingNextRequest"
              variant="light"
              small
              class="pr-2"
            />
            <i
              v-else
              class="ion ion-md-refresh pr-2"
            />
            {{ $t('label.refreshLabelTemplates') }}
          </button>
        </div>
      </div>
      <div
        class="d-flex"
        style="min-height: 60vh"
      >
        <div class="col-8 box-style mr-2">
          <div class="row">
            <OptionsSelector
              :view="selectedView"
              class="col-9"
              @updateView="selectedView = $event"
              @updateSearch="search = $event"
            />
            <div
              v-if="!onlyDeleted"
              class="col-3 pt-3"
              style="align-self: center; text-align: right"
            >
              <button
                class="btn btn-primary btn-sm"
                @click="showCreateForm = true"
              >
                <i class="fas fa-add pr-2" />
                {{ $t('template.addTemplate') }}
              </button>
            </div>
          </div>
          <div class="pt-2">
            <ExtendedLabelsTable
              :list="filteredList"
              :table-fields="labelColumns"
              :show-deleted="onlyDeleted"
              :no-data-info="$t('sku.noSku')"
              select-many
              :checked-ids.sync="selectMany"
              :list-reloaded="pendingReload"
              @selected="selectLabel($event)"
              @reload="refresh()"
            />
          </div>
        </div>
        <div class="col-4 box-style mx-2">
          <div>
            <LabelTemplatePreview
              :selected="selected"
              :only-deleted="onlyDeleted"
              @openEditor="openEditor = $event"
            />
          </div>
        </div>
      </div>
    </div>
    <LModal
      size="lg"
      :show.sync="showCreateForm"
    >
      <CreateAndEditLabelTemplate
        @successMsg="showSuccessMsg($event)"
        @failedMsg="showFailedMsg($event)"
        @reload="refresh()"
        @close="showCreateForm = false"
      />
    </LModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import createSearch from '@core/utils/search';
import LabelTemplatePreview from '@/components/mapper/LabelTemplatePreview';
import OptionsSelector from '@/components/selectors/OptionsSelector';
import ExtendedLabelsTable from '@/components/mapper/ExtendedLabelsTable';
import TemplateEditor from '@/components/mapper/TemplateEditor';
import CreateAndEditLabelTemplate from '@/components/label/CreateAndEditLabelTemplate';

export default {
  data: () => ({
    page: 'skuList',
    selected: null,
    selectMany: {},
    onlyDeleted: false,
    template: [],
    selectedView: 'active',
    search: null,
    openEditor: false,
    pendingReload: false,
    waitWithCreatingNextRequest: false,
    showCreateForm: false,
    labelsSkus: [],
  }),
  components: {
    LabelTemplatePreview,
    OptionsSelector,
    ExtendedLabelsTable,
    TemplateEditor,
    CreateAndEditLabelTemplate,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      list: state => state.labels.templates,
    }),
    labelsLabellingPointsMappings() {
      return this.$store.getters.labelsLabellingPointsMappings || [];
    },
    selectedList() {
      return Object.keys(this.selectMany).filter(k => this.selectMany[k]);
    },
    filteredList() {
      let list = this.list || [];
      if (this.search) {
        const search = createSearch(this.search);
        list = this.list.filter(el => search(el.name));
      }

      return list.map(s => ({
        ...s,
        mappedSku: this.getSkuIdsList(s.id).length || '-',
        mappedLabelingPoints: this.getLabelingPointsList(s.id).length || '-',
        culture: s.culture?.toUpperCase() || '-',
        labelType: s.labelType || '-',
      })).sort((a, b) => (b.modified).localeCompare(a.modified));
    },
    labelColumns() {
      return [
        {
          key: 'left-options',
          label: '',
          class: 'left-options',
        },
        {
          key: 'name',
          label: this.$t('general.name'),
          groupable: true,
          class: 'text-left',
        },
        {
          key: 'mappedSku',
          label: this.$t('mapper.mappedSKU'),
          groupable: true,
          width: '100px',
        },
        {
          key: 'mappedLabelingPoints',
          label: this.$t('labellingPoint.mappedLabellingPoints'),
          groupable: true,
          width: '100px',
        },
        {
          key: 'printerType',
          label: this.$t('printer.type'),
          class: 'text-left',
        },
        {
          key: 'labelType',
          label: this.$t('general.category'),
          class: 'text-left',
        },
        {
          key: 'culture',
          label: this.$t('label.label'),
          groupable: true,
          width: '175px',
          class: 'text-left',
        },
        {
          key: 'right-options',
          label: '',
          class: 'right-options',
        },
      ];
    },
  },
  watch: {
    selectedList() {
      this.template = [];
    },
    selectedView(v) {
      if (v === 'active') {
        this.onlyDeleted = false;
        this.refresh();
      } else {
        this.onlyDeleted = true;
        this.refresh();
      }
      this.selected = null;
    },
  },
  methods: {
    ...mapActions([
      'getLabelTemplates',
      'getLabelsLabellingPointsMappings',
      'getSkusLabelsLists',
      'getOrdersProperties',
      'createGetLabelsRequest',
      'importLabelsFromModeller',
    ]),
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.saved'), {
        title: this.$t('general.saved'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('request.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
    onGetLabelsResponse() {
      this.refresh();
    },
    onRefreshLabelsButtonClick() {
      this.createRequest();
      this.importLabelsFromModeller()
        .then(() => {
          this.refresh();
        });
    },
    createRequest() {
      this.waitWithCreatingNextRequest = true;
      const communicationRefId = uuidv4();
      this.createGetLabelsRequest({
        params: {
          query: {
            communicationRefId,
          },
        },
      });
      setTimeout(() => {
        this.waitWithCreatingNextRequest = false;
      }, 10 * 1000);
    },
    selectLabel(event) {
      if (!event.deleted) {
        this.selected = this.list.find(l => l.id === event.id);
      }
    },
    select(id) {
      this.$emit('select', id);
    },
    backToTemplates(event) {
      this.openEditor = event;
      this.selected = null;
      this.refresh();
    },
    getSkuIdsList(id) {
      return this.labelsSkus.find(m => m.labelId === id)?.skuIds ?? [];
    },
    getLabelingPointsList(id) {
      if (!this.labelsLabellingPointsMappings) return [];
      return this.labelsLabellingPointsMappings.filter(m => m.labelId === id);
    },
    refresh() {
      setTimeout(() => {
        this.refreshLabels();
      }, 100);
    },
    refreshLabels() {
      this.pendingReload = true;
      this.getLabelTemplates({
        params: {
          query: {
            onlyDeleted: this.onlyDeleted,
          },
        },
      }).finally(() => {
        this.pendingReload = false;
      });

      this.getSkusLabelsLists()
        .then(({ data }) => {
          this.labelsSkus = data;
        });
    },
  },
  mounted() {
    this.$startDataTransferHub();
    this.$dataTransferHub.$on('get-labels-returned', this.onGetLabelsResponse);
  },
  created() {
    this.onlyDeleted = false;
    this.refresh();
    this.getLabelsLabellingPointsMappings();
    this.getOrdersProperties();
  },
};
</script>

<style lang="scss" scoped>

</style>
