<script>
export default {
  name: 'CockpitActiveOrderActionButtons',
};
</script>

<template>
  <div class="action-buttons is-placeholder">
    <BButton class="action-button">
      <i class="fas fa-pause" />
      Wstrzymaj
    </BButton>

    <BButton class="action-button">
      <i class="fas fa-stop" />
      Zakończ
    </BButton>

    <BButton
      class="action-button"
      variant="primary"
    >
      <i class="fas fa-print" />
      Drukuj ręcznie
    </BButton>
  </div>
</template>

<style scoped lang="scss">
.action-buttons {
  display: flex;
  gap: 10px;

  .action-button {
    padding: 4px 30px;
    font-size: 15px;

    i {
      margin-right: 4px;
    }
  }
}
</style>
