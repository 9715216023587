<template>
  <div class="d-flex flex-column sku-style">
    <div
      class="d-flex justify-content-between flex-wrap"
    >
      <div
        class="mb-1 mt-3"
        style="font-size: 1em; font-weight: 500 !important"
      >
        <i class="fas fa-boxes-stacked pr-1" />
        {{ $t('mapper.skuFields') }}
      </div>
    </div>

    <div
      v-if="!pending"
      :style="{ overflow: `${scrollable ? 'auto' : 'visible'}` }"
      style="position: relative"
      class="py-2 pl-2"
    >
      <div
        v-for="prop in propertyTypes"
        :key="prop.id"
        class="d-flex align-items-center"
      >
        <div>
          <span class="prop-name">
            {{ prop.name }}
          </span>
          <span
            v-if="hasValues"
            class="prop-name"
          >
            :
          </span>
          <span
            v-if="hasValues"
            class="props-style"
          > {{ propertyValue(prop.name) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    skuProperties: {
      type: Array,
      default: () => [],
    },
    labelId: {
      type: Number,
    },
    readOnly: Boolean,
    scrollable: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    showAllFields: true,
    pending: false,
  }),
  computed: {
    ...mapState({
      propertyTypes: state => state.skuProperties.types || [],
    }),
    hasValues() {
      return this.skuProperties.length > 0;
    },
  },
  methods: {
    propertyValue(name) {
      const propertyType = this.propertyTypes
        .find(propType => propType.name.toLowerCase() === name.toLowerCase());
      const skuProperty = this.skuProperties
        .find(prop => prop.skuPropertyTypeId === propertyType.id);
      return skuProperty ? skuProperty.value : '';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";
  .prop-name {
    color: rgba(#333, 0.8);
  }

  .sku-style {
    max-height: 100%;
    margin-top: -20px;
  }

  .props-style {
    font-size: 1em;
    font-weight: 500;
    color: $ilabo;
  }

</style>
