<template>
  <div>
    <div class="label-value-row mt-2">
      <div class="label">
        {{ $t('general.description') }}
      </div>
      <div class="value">
        <BFormInput
          v-if="editForm"
          v-model="description"
          class="value-value"
          style="margin-block: -20px"
        />
        <div
          v-else
        >
          {{ selected?.description || '-' }}
        </div>
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('order.order') }} {{ $t('general.id') }}
      </div>
      <div class="value">
        {{ selected?.orderNumber || selected?.id || '-' }}
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('general.status') }}
      </div>
      <div
        class="value"
        style="font-size: 13px"
      >
        <div
          :style="{ 'background-color': status?.color || 'green' }"
          class="badge badge-danger text-white"
        >
          <div
            class="status-text-style px-1"
          >
            <i
              :class="status?.icon || ''"
              class="pr-1"
            />
            {{ status?.name || '-' }}
          </div>
        </div>
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('order.orderType') }}
      </div>
      <div
        class="value"
        style="font-size: 13px"
      >
        <div
          class="justify-content-center"
          style="align-self: center"
        >
          <span
            class="badge badge-success text-white mr-2"
            style="border-radius: 10px"
            :style="{ 'background-color': badgeColor(orderType ? orderType.colorCode : null) }"
          >
            {{ orderType ? orderType.displayName : $t('order.order') }}
          </span>
        </div>
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('date.plannedStartDate') }}
      </div>
      <div class="value">
        {{ formatDate(selected?.plannedStartDate) || '-' }}
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('date.plannedEndDate') }}
      </div>
      <div class="value">
        {{ formatDate(selected?.plannedEndDate) || '-' }}
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('date.startDate') }}
      </div>
      <div class="value">
        {{ formatDate(selected?.startDate) || '-' }}
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('date.endDate') }}
      </div>
      <div class="value">
        {{ formatDate(selected?.endDate) || '-' }}
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('general.created') }}
      </div>
      <div class="value">
        {{ formatDate(selected?.created) || '-' }}
      </div>
    </div>

    <div class="label-value-row">
      <div class="label">
        {{ $t('general.modified') }}
      </div>
      <div class="value">
        {{ formatDate(selected?.modified) || '-' }}
      </div>
    </div>

    <hr>

    <OrderFieldsList
      :order-properties="orderFields"
      :edit-form="editForm"
      :header="$t('order.fields')"
      :unfolded-by-default="false"
    />

    <hr>

    <OrderFieldsList
      :order-properties="skuFields"
      :edit-form="editForm"
      :header="$t('sku.fields')"
      :unfolded-by-default="false"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapState } from 'vuex';
import OrderFieldsList from './OrderFieldsList';

export default {
  props: {
    editForm: Boolean,
    selected: Object,
  },
  data: () => ({
    allOrderProperties: [],
    order: [],
    sku: [],
  }),
  components: {
    OrderFieldsList,
  },
  computed: {
    ...mapState({
      orderPropertyTypes: state => state.orders.properties || [],
      orderPropertiesValue: state => state.orders.propertiesValues || [],
      skuPropertyTypes: state => state.skuProperties.types || [],
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    ...mapGetters(['orderStatuses', 'orderBadge']),
    status() {
      return this.orderStatuses?.find(x => x.status?.toUpperCase() === this.selected?.status?.toUpperCase());
    },
    orderType() {
      return this.$store.getters.OrderTypeByName(this.order.type);
    },
    orderFields() {
      return this.orderPropertyTypes
        .map(order => ({
          ...order,
          value: this.orderPropertyValue(order.name),
        }));
    },
    skuFields() {
      return this.skuPropertyTypes
        .map(sku => ({
          ...sku,
          value: this.skuPropertyValue(sku.name),
        }));
    },
    skuPropertiesNotUsedInLabels() {
      return this.allOrderProperties
        .filter(p => !this.isPropertyTypeUsedInAnySkuLabel(p.skuPropertyTypeId));
    },
  },
  watch: {
    selected() {
      this.debouncedRefreshPropertiesValues();
      this.getSkuInfo();
    },
  },
  methods: {
    ...mapActions([
      'getSku',
      'getOrder',
      'getOrderComments',
      'getOrdersProperties',
      'getOrderPropertiesValues',
    ]),
    getSkuInfo() {
      this.getSku({
        params: {
          skuId: this.selected?.skuId,
        },
      })
        .then(({ data }) => {
          this.sku = data;
        });
    },
    debouncedRefreshPropertiesValues() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.pending = true;
      this.timeout = setTimeout(() => {
        this.getOrderPropertiesValues({
          params: {
            orderId: this.selected.id,
          },
        }).then(({ data }) => {
          this.localValues = data;
        }).finally(() => {
          this.pending = false;
        });
      }, 100);
    },
    skuPropertyValue(name) {
      const propertyType = this.skuPropertyTypes
        ?.find(propType => propType.name.toLowerCase() === name.toLowerCase());
      const skuProperty = this.sku?.skuProperties
        ?.find(prop => prop.skuPropertyTypeId === propertyType.id);
      return skuProperty ? skuProperty.value : '';
    },
    orderPropertyValue(name) {
      const p = this.orderPropertiesValue
        ?.find(x => x.name.toLowerCase() === name.toLowerCase());

      return p ? p.value : '';
    },
    badgeColor(badgeColor) {
      return badgeColor ?? this.orderBadge?.find(o => o.status === 'ORDER')?.color;
    },
    formatDate(date) {
      if (!date) return '-';
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return date ? moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS') : '-';
    },
  },
  created() {
    this.getOrdersProperties();
    this.getSkuInfo();
  },
};
</script>

<style lang="scss" scoped>

  .label-value-row {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .label {
      width: 30%;
      font-size: 12px;
      font-weight: 450;
      color: #979797;
    }

    .value {
      display: flex;
      align-items: center;
      width: 60%;

      .value-value {
        padding-left: 10px;
        width: 90%;
      }
    }
  }

</style>
