import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getPrinters',
    property: 'list',
    path: () => '/printers',
  })
  .post({
    action: 'createReloadPrintersRequest',
    path: () => '/printers/reload',
  })
  .get({
    action: 'getPrinter',
    path: ({ id }) => `/printers/${id}`,
  })
  .post({
    action: 'addPrinter',
    path: () => '/printer',
  })
  .delete({
    action: 'deletePrinter',
    path: ({ id }) => `/printer/${id}`,
  })
  .put({
    action: 'updatePrinter',
    path: ({ id }) => `/printer/${id}`,
  })
  .get({
    action: 'getPrinterTypeOptions',
    path: ({ printerType }) => `/printer/type/${printerType}/options`,
  })
  .getStore();

export default store;
