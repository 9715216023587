<script>
import MarkingsListItem from '@/components/markings/list/MarkingsListItem.vue';

export default {
  name: 'MarkingsList',
  emits: [
    'select-item',
    'select-item-contents',
  ],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    isCompatibilityView: Boolean,
    loadingParents: Boolean,
    parentsList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    MarkingsListItem,
  },
  computed: {
    everyItemHasTheSameParent() {
      const parentId = this.list[0]?.containers?.[0]?.containerId;
      return this.list.every(item => item.containers?.length > 0
        && item.containers.find(({ containerId }) => containerId === parentId));
    },
  },
};
</script>

<template>
  <div class="markings-list">
    <MarkingsListItem
      v-for="item in list"
      :key="item.id"
      :item="item"
      :is-compatibility-view="isCompatibilityView"
      :is-compatibility-correct="everyItemHasTheSameParent"
      :loading-parents="loadingParents"
      :parents-list="parentsList"
      @select-item="$emit('select-item', $event)"
      @select-item-contents="$emit('select-item-contents', $event)"
    />
  </div>
</template>

<style scoped lang="scss">
.markings-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
