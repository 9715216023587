import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getLabelingPoints',
    property: 'list',
    path: () => '/labelingpoints',
  })
  .get({
    action: 'getOrderTypeMappingsOnLabellingPoints',
    property: 'data',
    path: () => 'labelingPoints/orderTypes',
  })
  .get({
    action: 'getBusyLabelingPoints',
    path: ({ lineId }) => `/labelingpoints/line/${lineId}/busy`,
  })
  .get({
    action: 'getInProgressOrderLabelingPoints',
    path: ({ orderId }) => `/labelingpoints/order/${orderId}/busy`,
  })
  .post({
    action: 'createLabelingPoint',
    path: () => '/labelingpoint',
  })
  .put({
    action: 'updateLabelingPointWithMeta',
    path: ({ id }) => `/labelingpoint/${id}/metadata`,
  })
  .get({
    action: 'getLabelingPointWithMeta',
    path: ({ id }) => `/labelingpoint/${id}`,
  })
  .put({
    action: 'updateLabelingPoint',
    path: ({ id }) => `/labelingpoint/${id}`,
  })
  .delete({
    action: 'deleteLabelingPoint',
    path: ({ id }) => `/labelingpoint/${id}`,
  })
  .getStore();

export default store;
