<template>
  <div class="d-flex flex-column">
    <LModal
      size="lg"
      :show.sync="form"
    >
      <DictionaryEditForm
        :dictionary="selectedDictionary"
        @close="form = false"
        @successMsg="showSuccessMsg($event)"
        @failedMsg="showFailedMsg($event)"
      />
    </LModal>
    <div class="row justify-content-between mx-0">
      <h3 style="font-size: 1.75em">
        <i class="fas fa-book pr-2" />
        {{ $t('dictionary.dictionaries') }}
      </h3>
      <div class="align-self-center pr-2">
        <button
          class="btn btn-primary btn-sm"
          @click="selectedDictionary = null; form = true"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('dictionary.addDictionary') }}
        </button>
      </div>
    </div>
    <div
      style="min-height: 70vh; overflow: auto"
      class="box-style flex-fill mt-2"
    >
      <div
        v-for="(dictionary, index) in dictionariesToDisplay"
        :key="dictionary.id"
        class="dictionary-panel"
        @click="selectedDictionary = dictionary; form = true"
      >
        <div class="my-2">
          <span class="title">{{ dictionary.name }}</span>
        </div>
        <SimpleTable
          v-if="dictionary.entries?.length > 0"
          class="table-style"
          :items="dictionary.entries"
          :fields="columns"
          :selectable="false"
        />
        <div
          v-else
          class="text-center info"
        >
          {{ $t('dictionary.noEntriesInDictionary') }}
        </div>
        <hr v-if="index < dictionaries.length - 1">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SimpleTable from '@/components/tables/SimpleTable';
import DictionaryEditForm from './DictionaryEditForm';

export default {
  data: () => ({
    selectedDictionary: null,
    form: false,
  }),
  components: {
    DictionaryEditForm,
    SimpleTable,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      dictionaries: state => state.dictionaries.list || [],
    }),
    dictionariesToDisplay() {
      return this.dictionaries.slice().sort((a, b) => (a.name).localeCompare(b.name));
    },
    columns() {
      return [
        {
          key: 'key',
          label: this.$t('dictionary.key'),
          class: 'text-left',
          thStyle: { width: '50%' },
        },
        {
          key: 'value',
          label: this.$t('general.value'),
          class: 'text-left',
          thStyle: { width: '50%' },
        },
      ];
    },
  },
  methods: {
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.saved'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('request.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .title {
    font-size: 1.1em;
    font-weight: 500;
  }
  .dictionary-panel {
    min-width: 200px;
    max-width: 800px;
    cursor: pointer;
  }

  .info {
    opacity: 0.6;
    font-size: 1.2em;
  }
</style>
