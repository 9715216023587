<template>
  <div>
    <h5
      class="mb-0 mx-3"
    >
      <span v-if="!areaId">
        <i class="ion ion-md-add pr-2" />
        {{ $t('area.addArea') }}
      </span>

      <span v-else>
        <i class="fas fa-pen pr-2" />
        {{ $t('area.editArea') }}
      </span>
    </h5>

    <div class="mx-3">
      <div
        v-if="areaId"
        class="my-3"
      >
        <label class="mb-1 table-title">
          {{ $t('general.id') }}
        </label>
        <BFormInput
          v-model="areaId"
          disabled
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('general.name') }}
        </label>
        <BFormInput
          v-model="localName"
          :placeholder="$t('general.name') +'...'"
          :disabled="!!areaId"
          :state="stateOfNameField"
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          <span>
            {{ $t('general.description') }}
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </label>
        <BFormInput
          id="textarea"
          v-model="localDescription"
          :placeholder="$t('general.description') +'...'"
          rows="1"
          :state="((localDescription && !id ? localDescription.length : null)
            && !areaDescError) || (!areaDescError && id?.length)"
        />
      </div>
      <hr v-if="areaId">
      <div
        v-if="areaId"
        class="d-flex row my-3"
      >
        <div class="col-6">
          <label class="mb-1 table-title">
            {{ $t('general.created') }}
          </label>
          <BFormInput
            disabled
            :value="created"
          />
        </div>
        <div class="col-6">
          <label class="mb-1 table-title">
            {{ $t('general.modified') }}
          </label>
          <BFormInput
            disabled
            :value="modified"
          />
        </div>
      </div>
      <hr>
    </div>

    <div>
      <Error
        v-if="displayedError"
        class="mb-2 ml-3"
        :message="displayedError"
      />
      <SaveButton
        class="save-buttons"
        :pending="pending"
        :show-remove="!!areaId"
        :disabled="(!id && !errorCheck) || (displayedError ? true : false)"
        @cancel="$emit('close')"
        @save="!areaId ? create() : update()"
        @remove="remove()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    id: Number,
    name: String,
    description: String,
    modified: String,
    created: String,
  },
  data: () => ({
    pending: false,
    areaId: null,
    localName: '',
    localDescription: '',
  }),
  computed: {
    ...mapState({
      areas: state => state.areas.list || [],
    }),
    ...mapGetters([
      'timeOfNotification',
      'maxInputLength',
      'maxDescriptionLength',
    ]),
    areaNameExist() {
      if (!this.localName) return false;
      return this.areas.find(l => l.name?.toUpperCase() === this.localName?.toUpperCase()) ? true : null;
    },
    stateOfNameField() {
      if (this.areaNameExist && !this.id) return false;
      if (this.areaNameExist && this.id && this.name !== this.localName) return false;
      if (this.areaNameError) return false;
      return this.localName && !this.id ? true : null;
    },
    areaNameError() {
      if (!this.localName) return false;
      return this.localName.length >= this.maxInputLength;
    },
    areaDescError() {
      if (!this.localDescription) return false;
      return this.localDescription.length >= this.maxDescriptionLength;
    },
    displayedError() {
      if (this.areaNameExist && !this.id) return this.$t('error.nameAlreadyExists');
      if (this.areaNameExist && this.id && this.name !== this.localName) return this.$t('error.nameAlreadyExists');
      if (this.areaDescError) return this.$t('error.descriptionIsTooLong');
      return this.areaNameError ? this.$t('error.nameIsTooLong') : null;
    },
    errorCheck() {
      if (
        this.localName
        && !this.areaNameExist
        && !this.areaNameError
        && !this.areaDescError
      ) return true;
      return false;
    },
  },
  methods: {
    ...mapActions([
      'getAreas',
      'createArea',
      'updateArea',
      'deleteArea',
    ]),
    create() {
      this.createArea({
        data: {
          name: this.localName,
          description: this.localDescription,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('form.areaSuccessfullyCreated'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    remove() {
      this.deleteArea({
        params: {
          id: this.areaId,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('form.areaSuccessfullyRemoved'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    update() {
      this.updateArea({
        params: {
          id: this.areaId,
        },
        data: {
          description: this.localDescription,
          name: this.localName,
        },
      }).then(() => {
        this.$emit('successMsg', this.$t('general.modified'));
        this.request();
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    request() {
      this.getAreas();
    },
  },
  created() {
    if (this.id) {
      this.localName = this.name;
      this.localDescription = this.description;
      this.areaId = this.id;
    }
  },
};
</script>

<style lang="scss" scoped>

  .table-title {
    font-weight: 700;
    font-size: 14px;
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
  }

</style>
