<script>
export default {
  name: 'CockpitStatusBadge',
  props: {
    icon: String,
    label: String,
    color: String,
    textColor: String,
  },
};
</script>

<template>
  <div>
    <div
      class="cockpit-badge"
      :style="{
        backgroundColor: color,
        color: textColor,
      }"
    >
      <i
        v-if="icon"
        class="fas"
        :class="icon"
      />
      {{ label }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.cockpit-badge {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 16px;
  border-radius: 4px;
  background-color: black;
  font-size: 10px;
  color: white;
  font-weight: 500;

  &.type-badge {
    color: black;
    background-color: #F0F1F3;
  }

  i {
    font-size: 12px;
    margin-right: 2px;
  }
}
</style>
