<template>
  <span />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['timeOfNotification']),
  },
  methods: {
    onNewNotification({ title, message }) {
      this.$bvToast.toast(message, {
        title: title === 'error' ? this.$t('general.error') : title,
        variant: title === 'error' ? 'danger' : 'info',
        autoHideDelay: this.timeOfNotification,
      });
    },
    connectionStatus({ message }) {
      const translatedMessage = this.$t(`connection.${message}`).toString();
      this.$bvToast.toast(translatedMessage, {
        title: this.$t('connection.status'),
        solid: true,
        variant: 'secondary',
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: this.timeOfNotification,
      });
    },
  },
  mounted() {
    this.$startNotificationHub();
    this.$notificationHub.$on('new-notification', this.onNewNotification);
    this.$notificationHub.$on('connection-status', this.connectionStatus);
  },
};
</script>

<style scoped>

</style>
