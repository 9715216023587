<template>
  <div class="activity-wrapper">
    <div>
      <h3
        class="title mb-4"
      >
        <i class="fas fa-plus pr-2" />
        {{ $t('order.createOrder') }}
      </h3>
    </div>
    <div class="order-container">
      <div>
        <button
          type="button"
          class="close modal-close"
          @click="back"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="row h-100 main-row">
        <div class="col-3 h-100">
          <div class="pb-4">
            <button
              class="btn btn-secondary icon-btn"
              @click="back"
            >
              <i class="fas fa-chevron-left" />
            </button>
          </div>
          <div>
            <FormNavigation
              class="pt-4"
              :breadcrumb="pages"
              :type="type"
              :activity="activityToSave"
              :selected="selectedPage"
              @selectedPage="selectedPage = $event"
            />
          </div>
        </div>
        <div
          class="col-9 h-100"
        >
          <div class="h-100 d-flex flex-column">
            <div>
              <div class="secondary-text-style">
                {{ $t('order.step') }} {{ selectedPage.index }}
              </div>
              <h3>
                {{ selectedPage.title }}
              </h3>
              <hr>
            </div>

            <div>
              <GeneralPage
                v-if="selectedPage.key === 'general'"
                ref="generalPageRef"
                :activity="activityToSave"
                :type="type"
                :disabled="newOrder.id"
                @error="errorCheck = $event"
                @selectedType="type = $event"
                @skuId="activityToSave.skuId = $event"
                @lineId="activityToSave.lineId = $event"
                @externalId="activityToSave.externalId = $event"
                @description="activityToSave.description = $event"
                @orderTypeId="activityToSave.orderTypeId = $event"
              />
              <FieldsPage
                v-if="selectedPage.key === 'fields'"
                ref="fieldsPageRef"
                lass="mt-0"
                :order-id="newOrder.id"
                @values="propertyValuesUpdated"
              />
            </div>

            <div class="mt-auto">
              <Error
                v-if="error"
                :message="error.detail"
              />
              <hr class="mt-5">

              <button
                v-if="selectedPage.key !== 'general'"
                class="buttons btn btn-secondary px-4 mr-4 mt-2"
                @click="goToRightPage('back')"
              >
                <i class="fas fa-arrow-left" />
                {{ $t('general.back') }}
              </button>

              <button
                v-if="selectedPage.key === 'general'"
                class="buttons btn btn-secondary px-4 mr-4 mt-2"
                @click="back"
              >
                <i
                  class="ion ion-md-close pr-2"
                />
                {{ $t('general.cancel') }}
              </button>

              <button
                v-if="selectedPage.key === 'general' && !newOrder.id"
                class="buttons btn btn-primary px-4 mr-4 mt-2"
                :disabled="!errorCheck"
                @click="create()"
              >
                <BSpinner
                  v-if="pendingCreate"
                  variant="light"
                  small
                  class="mr-1"
                />
                <i
                  v-else
                  class="fas fa-check pr-2"
                />
                {{ $t('order.add') }}
              </button>

              <button
                v-if="selectedPage.key === 'fields'"
                class="buttons btn btn-primary px-4 mr-4 mt-2"
                :disabled="!propertyValuesValid"
                @click="saveValues()"
              >
                <BSpinner
                  v-if="pending"
                  variant="light"
                  small
                  class="mr-1"
                />
                <i
                  v-else
                  class="fas fa-check pr-2"
                />
                {{ $t('order.saveOrder') }}
              </button>

              <button
                v-if="selectedPage.key === 'general'"
                class="buttons btn btn-primary px-4 mr-4 mt-2"
                :disabled="
                  !errorCheck ||
                    !newOrder.id ||
                    selectedPage.key === 'fields'
                "
                @click="goToRightPage('next')"
              >
                {{ $t('order.fillOrderFields') }}
                <i class="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import FormNavigation from './FormNavigation';
import GeneralPage from './GeneralPage';
import FieldsPage from './FieldsPage';

export default {
  props: {
    activity: Object,
  },
  data() {
    return {
      selectedPage: null,
      errorCheck: false,
      error: null,
      localValues: [],
      propertyValuesValid: true,
      type: null,
      pending: false,
      pendingCreate: false,
      activityToSave: {
        externalId: null,
        description: null,
        skuId: null,
        lineId: null,
        orderTypeId: null,
      },
      newOrder: [],
    };
  },
  components: {
    FormNavigation,
    GeneralPage,
    FieldsPage,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    pages() {
      let nextIndex = 1;
      const pages = [
        {
          key: 'general',
          title: this.$t('general.general'),
          index: nextIndex,
        },
      ];
      nextIndex += 1;
      pages.push({
        key: 'fields',
        title: this.$t('order.fields'),
        index: nextIndex,
      });
      return pages;
    },
  },
  methods: {
    ...mapActions([
      'createOrder',
      'setOrderPropertiesValues',
    ]),
    propertyValuesUpdated(values) {
      this.localValues = values;
      this.propertyValuesValid = !this.localValues.some(v => v.isValid === false);
    },
    saveValues() {
      this.pending = true;
      this.setOrderPropertiesValues({
        params: {
          orderId: this.newOrder?.id,
        },
        data: this.localValues,
      }).then(() => {
        this.$bvToast.toast(this.$t('order.orderFieldsUpdated'), {
          title: this.$t('general.success'),
          variant: 'success',
          autoHideDelay: this.timeOfNotification,
        });
      })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.message, {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .finally(() => {
          this.pending = false;
        });
    },
    updateOrder(order) {
      this.newOrder = order;
    },
    create() {
      this.pendingCreate = true;
      this.createOrder({
        data: {
          orderNumber: this.activityToSave?.externalId,
          description: this.activityToSave?.description,
          skuId: this.activityToSave.skuId,
          lineId: this.activityToSave?.lineId,
          type: this.activityToSave?.orderTypeId,
        },
      })

        .then(({ data }) => {
          this.$bvToast.toast(this.$t('form.orderSuccessfullyCreated'), {
            title: this.$t('general.success'),
            variant: 'success',
            autoHideDelay: this.timeOfNotification,
          });
          this.$emit('created');
          this.updateOrder(data);
          this.goToRightPage('next');
        })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.message, {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .finally(() => {
          this.pendingCreate = false;
        });
    },
    back() {
      this.$emit('close');
    },
    goToRightPage(goTo) {
      const value = goTo === 'next' ? 1 : -1;
      const currentIndex = this.pages.find(x => x.key === this.selectedPage.key).index;
      this.selectedPage = this.pages.find(x => x.index === currentIndex + value);
    },
  },
  created() {
    this.selectedPage = this.pages.find(x => x.key === 'general');
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

  .activity-wrapper {
    background-color: #f4f4f4;
    padding-top: 25px;
    padding-bottom: 75px;
    padding-inline: 300px;
    min-height: 100%;

    .main-row {
      @media screen and (max-width: 900px) {
        height: 85vh !important;
        overflow-y: scroll;
      }
    }

    @media screen and (max-width: 900px) {
      padding-inline: 5%;
      margin: -1rem;
      min-height: calc(100% + 2rem);
      padding-bottom: 0;
    }
  }

  .order-container {
    border-radius: 15px;
    background-color: white;
    padding-block: 30px;
    padding-inline: 25px;
    @media screen and (max-width: 900px) {
      min-height: 90vh;
    }
  }

  .buttons {
    width: 175px;
  }

  .secondary-text-style {
    opacity: 0.3;
    font-weight: 600;
    font-size: 1.1em;
  }

</style>
