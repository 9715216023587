<template>
  <div>
    <div
      v-for="labellingLevelTemplates in labelsGroupedByLabellingLevels"
      :key="labellingLevelTemplates.labellingLevelName"
      class="labelling-level-labels-row"
    >
      <div class="labelling-level">
        {{ labellingLevelTemplates.labellingLevelName }}
      </div>
      <div class="labels">
        <div
          v-for="labelTemplate in labellingLevelTemplates.labelTemplates.filter(temp => temp.isMapped)"
          :key="labelTemplate.id"
        >
          <div class="labels-row">
            <div class="labels-row-icon">
              <i class="fas fa-clipboard-list" />
            </div>

            <div class="labels-row-text">
              {{ labelTemplate.name }}
            </div>

            <div
              class="labels-row-action-icon"
              @click="removeLabelTemplate(labelTemplate.id)"
            >
              <i class="ion ion-md-close" />
            </div>

            <div
              class="labels-row-action-icon"
              @click="preview = true; previewTemplateId = labelTemplate.id"
            >
              <i class="pr-1 fas fa-eye" />
            </div>

            <div
              v-if="!downloadIdPending"
              class="labels-row-action-icon"
              @click="startDownloadingLabel(labelTemplate.id)"
            >
              <i class="fa-solid fa-download" />
            </div>
            <div
              v-else
              class="labels-row-action-icon"
            >
              <Loader
                v-if="downloadIdPending === labelTemplate.id"
                :size="14"
                color="gray"
              />
            </div>
          </div>
        </div>
        <button
          v-if="openedLabelSelect !== labellingLevelTemplates.labellingLevelName"
          class="btn btn-light add-template-button"
          style="text-align: center"
          type="button"
          @click="openLabelSelect(labellingLevelTemplates.labellingLevelName)"
        >
          <i class="fa-solid fa-plus" />
          {{ $t('template.addTemplate') }}
        </button>
        <Multiselect
          v-show="openedLabelSelect === labellingLevelTemplates.labellingLevelName"
          :ref="`label-select-${labellingLevelTemplates.labellingLevelName}`"
          class="select-style"
          :options="getOptionsForSelect(labellingLevelTemplates.labelTemplates)"
          :show-labels="false"
          placeholder=""
          label="text"
          @close="closeLabelSelect()"
          @select="handleTemplateSelect($event.value)"
        >
          <template #option="{ option }">
            <div class="labels-row">
              <div class="labels-row-icon">
                <i class="fas fa-clipboard-list" />
              </div>
              <div class="labels-row-text">
                {{ option.text }}
              </div>
            </div>
          </template>
          <template #noResult>
            {{ $t('sku.noMatchingLabels') }}
          </template>
          <template #noOptions>
            {{ $t('sku.noMatchingLabels') }}
          </template>
        </Multiselect>
      </div>
    </div>
    <LModal
      :show.sync="preview"
      size="lg"
      style="display: inline-grid"
    >
      <LabelPreview
        :key="sku.id"
        :sku-id="sku.id"
        :label-id="previewTemplateId"
        class="my-3"
      />
    </LModal>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import { mapActions, mapGetters, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import { convertPreviewResultToBlob } from '@/components/label/labelPreviewUtils';
import LabelPreview from '@/components/label/LabelPreview';
import { getFileNameForSkuOnlyPreview } from '@/utils/previewFileNames';

export default {
  props: {
    sku: Object,
  },
  data: () => ({
    preview: false,
    previewTemplateId: null,
    mappedLabelTemplateIds: [],
    openedLabelSelect: null,
    downloadIdPending: null,
    downloadCommunicationRefId: null,
  }),
  components: {
    LabelPreview,
    Multiselect,
  },
  computed: {
    ...mapState({
      labelTemplates: state => state.labels.templates || [],
      allLabelMappings: state => state.labels.mappings,
      labelingLevels: state => state.settings.data?.labelingLevels || [],
    }),
    ...mapGetters(['timeOfNotification']),
    mappedLabels() {
      const skuMapping = this.allLabelMappings?.filter(m => m.skuId === this.sku.id);
      return this.labelTemplates?.filter(temp => skuMapping?.some(f => f.labelId === temp.id));
    },
    labelsGroupedByLabellingLevels() {
      const templates = this.labelTemplates
        .map(temp => ({
          ...temp,
          isMapped: this.mappedLabelTemplateIds.includes(temp.id),
        }))
        .sort((a, b) => (a.name).localeCompare(b.name));

      const byLevels = this.labelingLevels
        .map(lev => ({
          labellingLevelName: lev.name,
          ordinal: lev.ordinal,
          labelTemplates: templates
            .filter(temp => temp.labelType?.toUpperCase() === lev.name.toUpperCase()),
        }))
        .sort((a, b) => a.ordinal - b.ordinal);

      const labelsWithoutLabellingLevel = templates
        .filter(temp => !this.labelingLevels.some(lev => temp.labelType?.toUpperCase() === lev.name.toUpperCase()));
      return [{
        labellingLevelName: this.$t('general.uncategorized'),
        labelTemplates: labelsWithoutLabellingLevel,
      }].concat(byLevels);
    },
  },
  watch: {
    description() {
      this.$emit('update:description', this.description);
    },
  },
  methods: {
    ...mapActions([
      'createGetLabelPreviewRequest',
      'addLabelMapping',
      'removeLabelMapping',
    ]),
    openLabelSelect(labellingLevelName) {
      this.openedLabelSelect = labellingLevelName;
      const multiselectRefId = `label-select-${labellingLevelName}`;
      this.$refs[multiselectRefId][0].activate();
    },
    closeLabelSelect() {
      this.openedLabelSelect = null;
    },
    handleTemplateSelect(input) {
      this.pending = true;
      this.addLabelMapping({
        params: {
          labelId: input,
          skuId: this.sku.id,
        },
      })
        .then(() => {
          this.mappedLabelTemplateIds.push(input);
          this.$bvToast.toast(this.$t('sku.labelTemplateAssignedToSku'), {
            title: this.$t('general.saved'),
            variant: 'success',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.message, {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .finally(() => {
          this.pending = false;
          this.$emit('reload');
        });
    },
    removeLabelTemplate(labelTemplateId) {
      const mapping = this.allLabelMappings.find(map => map.labelId === labelTemplateId && map.skuId === this.sku.id);
      this.pending = true;
      this.removeLabelMapping({
        params: {
          skuLabelMappingId: mapping.id,
        },
      })
        .then(() => {
          this.mappedLabelTemplateIds = this.mappedLabelTemplateIds.filter(id => id !== labelTemplateId);
          this.$bvToast.toast(this.$t('sku.labelTemplateRemovedFromSku'), {
            title: this.$t('general.saved'),
            variant: 'success',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.message, {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .finally(() => {
          this.pending = false;
          this.$emit('reload');
        });
    },
    getOptionsForSelect(labelTemplates) {
      return labelTemplates
        .filter(temp => !temp.isMapped)
        .map(temp => ({
          value: temp.id,
          text: temp.name,
          iconClass: 'fas fa-clipboard-list app-color',
        }));
    },
    startDownloadingLabel(id) {
      this.downloadIdPending = id;
      this.downloadCommunicationRefId = uuidv4();
      this.createGetLabelPreviewRequest({
        params: {
          skuId: this.sku.id,
          labelId: id,
          query: {
            communicationRefId: this.downloadCommunicationRefId,
          },
        },
      }).catch(({ response: { data } }) => {
        this.downloadIdPending = null;
        this.downloadCommunicationRefId = null;
        this.$bvToast.toast(data?.message, {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      });
    },
    onGetPreviewResponse({ requestRefId, previewResult }) {
      if (this.downloadCommunicationRefId !== requestRefId) {
        return;
      }

      if (!previewResult.success) {
        this.$bvToast.toast(previewResult?.errorMessage ?? this.$t('general.error'), {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
        this.downloadIdPending = null;
        this.downloadCommunicationRefId = null;
        return;
      }

      const labelData = convertPreviewResultToBlob(previewResult.preview);
      if (!labelData) {
        this.$bvToast.toast('Preview does not exist', {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      } else {
        const labelName = this.labelTemplates.find(l => l.id === this.downloadIdPending)?.name;
        const skuName = this.sku.externalId ?? this.sku.name;
        const fileName = getFileNameForSkuOnlyPreview(labelName, skuName);
        saveAs(labelData, fileName);
      }
      this.downloadIdPending = null;
      this.downloadCommunicationRefId = null;
    },
  },
  mounted() {
    this.$startDataTransferHub();
    this.$dataTransferHub.$on('label-preview-returned', this.onGetPreviewResponse);
  },
  created() {
    this.mappedLabelTemplateIds = this.allLabelMappings
      ?.filter(m => m.skuId === this.sku.id)
      .map(m => m.labelId);
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .labelling-level-labels-row {
    display: flex;
    margin-bottom: 3rem;

    .labelling-level {
      width: 30%;
      font-size: 12px;
      font-weight: 450;
      color: #979797;
    }

    .labels {
      width: 60%;
      font-size: 14px;

      .labels-row {
        display: flex;
        align-items: center;
        padding-top: 4px;
        padding-bottom: 8px;

        .labels-row-icon {
          width: 15px;
          color: $logicprint5;
        }

        .labels-row-text {
          padding-left: 4px;
          width: 100%;
        }

        .labels-row-action-icon {
          width: 15px;
          color: #7F7F7F;
          cursor: pointer;
          margin-left: 10px;

          &:hover {
            color: #333;
          }
        }
      }
    }
  }

  .add-template-button {
    max-width: 300px;
    border-radius: 18px;
    color: #7F7F7F;
    background-color: #EFEFEF;
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .select-style {
    margin-top: 1px;
    margin-bottom: 5px;
    font-size: 14px;

    .labels-row {
      display: flex;
      align-items: center;

      .labels-row-icon {
        width: 15px;
        color: $logicprint5;
      }

      .labels-row-text {
        padding-left: 4px;
      }
    }
  }

  .select-style::v-deep .multiselect__option {
    background-color: #fff;
    color: black;
    padding-top: 3px;
    padding-bottom: 1px;
    &:hover {
      background-color: rgb(238, 232, 232);
    }
  }

</style>
