import { render, staticRenderFns } from "./MarkingsAssignList.vue?vue&type=template&id=ed08ab52&scoped=true"
import script from "./MarkingsAssignList.vue?vue&type=script&lang=js"
export * from "./MarkingsAssignList.vue?vue&type=script&lang=js"
import style0 from "./MarkingsAssignList.vue?vue&type=style&index=0&id=ed08ab52&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed08ab52",
  null
  
)

export default component.exports