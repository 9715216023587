<script>
import CockpitMatryoshkaItemOperations
from '@/components/cockpit/matryoshka/operations/CockpitMatryoshkaItemOperations.vue';

export default {
  name: 'CockpitMatryoshkaItemOperationsTab',
  components: { CockpitMatryoshkaItemOperations },
};
</script>

<template>
  <div class="is-placeholder">
    <CockpitMatryoshkaItemOperations />
  </div>
</template>

<style scoped lang="scss">

</style>
