<template>
  <div class="d-flex flex-wrap justify-content-end">
    <div
      v-for="s in colors"
      :key="s.key"
      class="color-sel"
      @click="select(s)"
    >
      <div
        :style="{ 'background-color': s.color }"
        :class="{ 'active': color === s.color }"
        class="color-box my-1"
      >
        <i
          v-if="color === s.color"
          class="ion ion-md-checkmark"
          style="padding-top: 5px; color: white"
        />
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/utils/colors';

export default {
  props: {
    color: String,
  },
  data: () => ({
    editor: false,
    selectable: [
      'blue',
      'orange',
      'red',
      'yellow',
      'teal',
      'purple',
      'green',
      'grey',
    ],
  }),
  computed: {
    colors() {
      return this.selectable.map(key => ({
        key,
        color: colors(key),
      }));
    },
  },
  methods: {
    getColor: c => colors(c),
    select(c) {
      this.$emit('select', c.color);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .color-sel {
    cursor: pointer;
  }

  .color-box {
    margin-right: 10px;
    margin-top: 2px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    position: relative;
  }
  .color-box .pointer {
    position: absolute;
    top: -16px;
    left: 4px;
    color: #CED3D8;
  }

  .color-box:hover {
    box-shadow: 0 0 5px rgb(90,90,90);
  }

</style>
