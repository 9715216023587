<template>
  <div>
    <label
      class="name-style mb-0"
    >
      {{ propertyName }}
    </label>
    <div class="d-flex align-items-center">
      <BFormSelect
        v-if="propertyType.valueType === propertyValueTypes.dictionary"
        v-model="editedValue"
        :options="dictionaryValues"
        value-field="value"
        text-field="text"
        @change="valueUpdated()"
      />
      <BFormCheckbox
        v-else-if="propertyType.valueType === propertyValueTypes.boolean"
        v-model="editedValue"
        switch
        size="md"
        @change="valueUpdated()"
      />
      <BFormInput
        v-else
        v-model="editedValue"
        :placeholder="$t('form.enterValue')"
        trim
        :state="isValueValid ? null : false"
        @input="valueUpdated()"
      />
      <div
        v-if="propertyType.valueType === propertyValueTypes.dictionary
          && editedValue !== null && editedValue?.length > 0"
        class="icon-style ml-2"
        @click="clearValue()"
      >
        <div
          v-tippy="{
            arrow: true,
            placement: 'top',
          }"
          :content="$t('general.clear')"
        >
          <i class="fa-regular fa-circle-xmark" />
        </div>
      </div>
    </div>
    <Error
      v-if="!isValueValid"
      style="font-size: 0.7vw;"
      :message="`${$t('error.valueIsInNotExpectedType')} ${propertyType.valueType}`"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { propertyValueTypes } from '@/utils/dictionary';

export default {
  props: {
    propertyName: {
      type: String,
    },
    propertyValue: {
      type: String,
    },
  },
  data: () => ({
    propertyValueTypes,
    editedValue: null,
  }),
  computed: {
    ...mapState({
      ordersPropertyTypes: state => state.orders.properties || [],
      dictionaries: state => state.dictionaries.list || [],
    }),
    propertyType() {
      return this.ordersPropertyTypes
        .find(type => type.name.toLowerCase() === this.propertyName.toLowerCase());
    },
    dictionaryValues() {
      if (!this.propertyType.valueType === propertyValueTypes.dictionary) {
        return [];
      }
      const dictionaryEntries = this.dictionaries
        .find(dict => dict.id === this.propertyType.dictionaryId)?.entries || [];
      return dictionaryEntries.map(x => ({
        value: x.key,
        text: x.key,
      }));
    },
    isValueValid() {
      if (this.editedValue === null || this.editedValue === '') {
        return true;
      }

      if (this.propertyType.valueType === propertyValueTypes.integer) {
        return /^-?(0|[1-9]\d*)$/.test(this.editedValue);
      }

      if (this.propertyType.valueType === propertyValueTypes.decimal) {
        return /^-?(0|[1-9]\d*)((\.|,)\d+)?$/.test(this.editedValue);
      }

      return true;
    },
  },
  methods: {
    valueUpdated() {
      this.$emit('update:propertyValue', { value: this.editedValue?.toString(), isValid: this.isValueValid });
    },
    clearValue() {
      this.editedValue = null;
      this.valueUpdated();
    },
  },
  created() {
    this.editedValue = this.propertyValue;
  },

};
</script>

<style lang="scss" scoped>

  .name-style {
    font-weight: 500;
    font-size: 1.1em;
  }

  .value-style {
    width: 100%;
  }

  .icon-style {
    color: #979797;
    cursor: pointer;
    font-size: 14px;
  }

</style>
