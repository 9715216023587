<template>
  <div>
    <LModal
      size="lg"
      :show.sync="form"
    >
      <OrderForm
        v-bind="selectedForm"
        :order="selectedOrder"
        @close="form = false"
        @sent="request(true)"
      />
    </LModal>

    <div class="d-flex row">
      <div class="row ml-3">
        <AreaFilterSelector
          v-if="useAreas"
          light
          :box-height="'35px'"
        />
        <LineFilterSelector
          light
          :box-height="'35px'"
        />
      </div>
    </div>

    <div
      class="d-flex mr-3"
      style="min-height: 70vh"
    >
      <div class="col-9 d-flex flex-column px-4 py-3 my-3 mr-3 orders-box-style">
        <div>
          <div class="title pb-2">
            <i class="fas fa-caret-square-right pr-1" />
            {{ $t('order.activeOrders') }}
          </div>
          <div class="row">
            <div
              class="px-2"
              :class="selectedOrder ? 'col-8' : 'col-12'"
            >
              <p
                v-if="!filteredOrders.length"
                class="text-center mt-5 info"
              >
                {{ $t('form.noData') }}
              </p>
              <Loader v-if="pendingOrderExecutions" />
              <div v-if="!pendingOrderExecutions">
                <div
                  v-if="selectedOrder"
                  class="orders-list"
                  style="height: 45vh"
                >
                  <OrderExecution
                    v-for="o in filteredOrders"
                    :key="o.id"
                    v-bind="o"
                    class="hoverable"
                    :class="o.id === selected ? 'selected' : ''"
                    @click.native="selected === o.id ? selected = null : (selected = o.id, refresh())"
                  />
                </div>
                <div
                  v-else
                  class="orders-list"
                  style="max-height: 70vh"
                >
                  <TransitionGroup
                    name="full-order-list"
                  >
                    <OrderExecution
                      v-for="o in filteredOrders"
                      :key="o.id"
                      v-bind="o"
                      class="hoverable"
                      @click.native="selected === o.id ? selected = null : (selected = o.id, refresh())"
                    />
                  </TransitionGroup>
                </div>
              </div>
              <div
                v-if="selectedOrder"
                style="margin-bottom: 10px"
              >
                <div class="label-box-style">
                  <span class="title">
                    <i class="fas fa-barcode pr-1" />
                    {{ $t('label.label') }}:
                    <span class="pl-1">
                      {{ labelName }}
                    </span>
                  </span>
                  <LabelPreview
                    :key="selectedOrder.labelingPointId"
                    style="margin-top: 10px"
                    :order-id="selectedOrder.orderId"
                    :labeling-point-id="selectedOrder.labelingPointId"
                    :open-modal-with-preview-after-image-click="true"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="selectedOrder"
              class="col-4"
            >
              <div class="action-style">
                <span class="title">
                  <i class="fas fa-list pb-3" />
                  {{ $t('order.actions') }}
                </span>
                <div>
                  <div
                    class="row"
                    style="justify-content: center"
                  >
                    <div
                      v-tippy="{
                        arrow: true,
                        placement: 'bottom',
                        trigger: orderActionsDisabled ? 'mouseenter' : ''
                      }"
                      :content="$t('request.pendingBlockingRequests')"
                    >
                      <button
                        class="btn btn-info btn-circle button-style mx-2 mt-2"
                        :disabled="orderActionsDisabled"
                        @click="form = true, setSelected(null)"
                      >
                        <div>
                          <i
                            class="icon-print-request"
                            style="font-size: 1.8em"
                          />
                          <br>
                          <span class="text-style">
                            {{ $t('form.send') }}
                          </span>
                        </div>
                      </button>
                    </div>
                    <div
                      v-tippy="{
                        arrow: true,
                        placement: 'bottom',
                        trigger: orderActionsDisabled ? 'mouseenter' : ''
                      }"
                      :content="$t('request.pendingBlockingRequests')"
                    >
                      <button
                        class="btn btn-secondary btn-circle button-style mx-2 mt-2"
                        :disabled="orderActionsDisabled"
                        @click="stopOrderButton"
                      >
                        <div>
                          <i
                            class="fas fa-pause"
                            style="font-size: 1.8em"
                          />
                          <br>
                          <span class="text-style">
                            {{ $t('order.hold') }}
                          </span>
                          <div />
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="manaulPrintActivated"
                    style="text-align: -webkit-center"
                  >
                    <button
                      class="mr-2 mt-3 btn btn-block btn-warning manuall-button"
                      @click="form = true, setSelected(null)"
                    >
                      <i class="icon-print-request pr-2" />
                      {{ $t('printer.printManually') }}
                    </button>
                  </div>
                </div>
                <hr>
                <span style="font-size: 0.8em; font-weight: 600">
                  {{ $t('order.details') }}
                </span>
                <Loader v-if="pendingOrderExecutions" />
                <div
                  v-if="!pendingOrderExecutions"
                  style="max-height: 40vh; overflow-y: auto"
                >
                  <OrderInfo
                    v-if="selectedOrder.order"
                    :order-id="selectedOrder.orderId"
                    :order-execution-id="selectedOrder.id"
                    :order="selectedOrder.order"
                    :style="'font-size: 0.6em'"
                    :refresh-info="form"
                    :labeling-point-id="selectedOrder.labelingPointId"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-3 d-flex flex-column my-3 px-4 py-3 print-request-style"
        style="max-height: 80vh"
      >
        <span class="title">
          <i class="icon-print-request pr-1 pb-3" />
          {{ $t('printRequest.printRequests') }}
        </span>
        <div
          class="py-1"
          style="overflow-y: auto"
        >
          <Loader v-if="pendingPrintRequests" />
          <TransitionGroup
            v-else
            name="list-complete"
          >
            <EdgeRequest
              v-for="pr in printRequests"
              :key="pr.id"
              v-bind="pr"
              :is-first="pr.id === printRequests[0].id"
              class="print-request-item"
            />
          </TransitionGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import loopRequest from '@core/mixins/loopRequest';
import calcDuration from '@/utils/calcDuration';
import OrderExecution from '@/components/order/OrderExecution';
import EdgeRequest from '@/components/edgeRequest/EdgeRequest';
import OrderInfo from '@/components/order/OrderInfo';
import OrderForm from '@/components/order/OrderForm';
import LabelPreview from '@/components/label/LabelPreview';
import AreaFilterSelector from '@/components/selectors/AreaFilterSelector';
import LineFilterSelector from '@/components/selectors/LineFilterSelector';

export default {
  data: () => ({
    orders: [],
    printRequests: [],
    pendingOrderExecutions: true,
    pendingPrintRequests: true,
    selected: null,
    selectedForm: null,
    labelName: null,
    form: false,
    manaulPrintActivated: false, // property to delete when manual printing is finished
    activeRequestsOnSelectedLabelingPoint: [],
  }),
  components: {
    OrderExecution,
    EdgeRequest,
    OrderInfo,
    OrderForm,
    LabelPreview,
    AreaFilterSelector,
    LineFilterSelector,
  },
  mixins: [loopRequest('request', 5000)],
  computed: {
    ...mapState({
      useAreas: state => state.settings.data?.useAreas || false,
      lines: state => state.lines.list || [],
    }),
    ...mapGetters(['timeOfNotification',
      'selectedFilterAreaId',
      'selectedFilterLineId',
      'linesFromArea']),
    filters() {
      return {
        selectedFilterAreaId: this.selectedFilterAreaId,
        selectedFilterLineId: this.selectedFilterLineId,
      };
    },
    filteredLines() {
      const selectedLines = this.linesFromArea(this.selectedFilterAreaId);
      return selectedLines.length ? selectedLines : this.lines;
    },
    selectedOrder() {
      return this.orders.find(x => x.id === this.selected);
    },
    filteredOrders() {
      const linesIds = this.filteredLines.map(x => x.id);
      const areaFiltered = this.orders.filter(order => linesIds.includes(order.lineId));
      const lineFiltered = this.orders.filter(o =>
        o.lineId === this.filteredLines.find(f => f.id === this.selectedFilterLineId)?.id);
      return (lineFiltered.length || this.selectedFilterLineId) ? lineFiltered : areaFiltered;
    },
    orderActionsDisabled() {
      return this.activeRequestsOnSelectedLabelingPoint !== null
        && this.activeRequestsOnSelectedLabelingPoint.length > 0;
    },
  },
  watch: {
    filters() {
      if (this.selected) {
        this.selected = null;
        return;
      }
      this.getPrintRequests(true);
    },
    selected() {
      this.getPrintRequests(true);
      if (this.selected !== null) {
        this.getActiveRequestsOnSelectedLabelingPoint();
      } else {
        this.activeRequestsOnSelectedLabelingPoint = [];
      }
    },
  },
  methods: {
    calcDuration,
    ...mapActions([
      'getProdOrders',
      'getRequests',
      'getBlockingRequests',
      'getPrinters',
      'stopOrder',
      'getLabelBySkuId',
    ]),
    setSelected(event) {
      this.selectedForm = event;
    },
    getLabelName() {
      this.getLabelBySkuId({
        params: {
          skuId: this.selectedOrder?.order.skuId,
        },
      })
        .then(({ data }) => {
          this.labelName = data.name;
        })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.message, {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: this.timeOfNotification,
          });
        });
    },
    getActiveRequestsOnSelectedLabelingPoint() {
      if (this.selectedOrder?.labelingPointId == null) {
        return;
      }
      this.getBlockingRequests({
        params: {
          query: {
            labelingPointIds: [this.selectedOrder.labelingPointId],
          },
        },
      })
        .then(({ data }) => {
          this.activeRequestsOnSelectedLabelingPoint = data;
        });
    },
    refresh() {
      this.getLabelName();
    },
    stopOrderButton() {
      this.$bvModal.msgBoxConfirm(this.$t('general.areYouSure'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        okTitle: this.$t('general.yes'),
        cancelTitle: this.$t('general.no'),
        centered: true,
      })
        .then(value => {
          if (value) {
            this.stopOrder({
              params: {
                orderId: this.selectedOrder.orderId,
                query: { where: this.selectedOrder.labelingPointId },
              },
            })
              .then(() => {
                this.$bvToast.toast(this.$t('order.stop'), {
                  title: this.$t('general.saved'),
                  variant: 'success',
                  autoHideDelay: this.timeOfNotification,
                });
                this.request();
              })
              .catch(({ response: { data } }) => {
                this.$bvToast.toast(data.message, {
                  title: this.$t('general.error'),
                  variant: 'danger',
                  autoHideDelay: this.timeOfNotification,
                });
              });
          }
        });
    },
    getPrintRequests(showPending = false) {
      if (showPending) {
        this.pendingPrintRequests = true;
      }
      const labellingPointIds = this.selectedOrder
        ? [this.selectedOrder.labelingPointId]
        : this.filteredOrders.map(ordEx => ordEx.labelingPointId);
      const orderIds = this.selectedOrder
        ? [this.selectedOrder.orderId]
        : this.filteredOrders.map(ordEx => ordEx.orderId);
      this.getRequests({
        params: {
          query: {
            types: ['Print'],
            page: 0,
            count: 20,
            labellingPointIds: [...new Set(labellingPointIds)],
            orderIds: [...new Set(orderIds)],
          },
        },
      })
        .then(({ data }) => {
          if (data && data.items) {
            this.printRequests = data.items
              .filter(pr => this.filteredOrders.some(ordEx => ordEx.orderId === pr.orderId
                && ordEx.labelingPointId === pr.labellingPointId))
              .map(pr => ({
                ...pr,
                duration: calcDuration(moment.utc(pr.created).unix()),
              }));
          }
        })
        .finally(() => {
          this.pendingPrintRequests = false;
        });
    },
    request(showPending = false) {
      if (showPending) {
        this.pendingOrderExecutions = true;
        this.pendingPrintRequests = true;
      }
      const orderExecutionsPromise = this.getProdOrders()
        .then(({ data }) => {
          this.orders = data;
          this.getPrintRequests();
        });

      const blockingRequestsPromise = this.getActiveRequestsOnSelectedLabelingPoint();

      return Promise.all([orderExecutionsPromise, blockingRequestsPromise])
        .finally(() => {
          this.pendingOrderExecutions = false;
        });
    },
  },
  mounted() {
    this.getPrinters();
    this.request(true);
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .title {
    font-size: 1em;
    font-weight: 625;
  }

  .info {
    opacity: 0.6;
    font-size: 1.2em;
  }

  .print-request-item {
    transition: 300ms all;
    width: 100%;
    left: 0;
  }

  .orders-box-style {
    margin-top: 10px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .print-request-style {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .action-style {
    padding: 20px;
    margin-top: 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .label-box-style {
    padding: 15px;
    margin-block: 10px;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  .text-style {
    font-size: 0.9em;
    font-weight: 500;
  }

  .btn-warning,
  .btn-warning:active,
  .btn-warning:visited,
  .btn-warning:focus {
      background-color: #999999;
      border-color: #999999;
  }

  .btn-warning:hover {
    background-color: gray;
  }

  .button-style {
    border-radius: 100%;
    height: 95px;
    width: 95px;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  .manuall-button {
    border-radius: 20px;
    min-width: 100px;
    max-width: 175px;
    font-size: 0.8em;
    font-weight: 500
  }

  .orders-list {
    padding-inline: 2px;
    overflow-y: auto;
  }

  .hoverable {
    cursor: pointer;
    margin-block: 10px;

    &:hover {
      background-color: rgba(200, 200, 200, 0.1);
    }

    &.selected {
      background-color: #EBEBEB;
      border-color: #333;
    }
  }
</style>
