<template>
  <div class="order-details-offcanvas">
    <div
      class="d-flex justify-content-between align-items-center mb-2"
      :style="editForm ? 'padding-bottom: 40px' : ''"
    >
      <h3
        class="align-self-center mb-0"
        style="font-size: 1.7em"
      >
        <i
          class="pr-2"
          :class="editForm ? 'fas fa-pen' : 'fas fa-info-circle'"
        />
        <span>
          {{ editForm ? $t('order.editOrder') : $t('order.orderDetails') }}
        </span>
      </h3>
      <hr>
      <div class="h3 mb-0 text-black-50">
        <i
          class="fas fa-times cursor-pointer p-1"
          @click="$emit('close'); tab = 0"
        />
      </div>
    </div>

    <BTabs
      v-model="tab"
      pills
    >
      <BTab
        key="details"
        :title="$t('general.general')"
        :title-item-class="editForm ? 'd-none' : ''"
      >
        <OrderGeneralTab
          :edit-form="editForm"
          :selected="selected"
          @properties="orderProperties = $event"
          @update:show="editForm = $event"
        />
      </BTab>

      <BTab
        key="history"
        :title="$t('general.activationHistory')"
        :title-item-class="editForm ? 'd-none' : ''"
      >
        <OrderHistoryTab
          :selected="selected"
          @failed-msg="$emit('failedMsg', $event)"
        />
      </BTab>

      <BTab
        key="requests"
        :title="$t('sidebar.requests')"
        :title-item-class="editForm ? 'd-none' : ''"
      >
        <OrderRequestTab
          :selected="selected"
          :current-page="currentPage"
          :per-page="perPage"
          @pending-disabled="pendingPallets = $event"
          @current-page="currentPage = $event"
          @failed-msg="$emit('failedMsg', $event)"
          @rows="totalRows = $event"
          @update:pallet="selectPallet($event)"
        />
      </BTab>
    </BTabs>

    <div
      v-if="tab === 0"
      class="offcanvas-footer d-flex align-items-center justify-content-center mt-2"
    >
      <div
        v-if="!editForm"
      >
        <BButton
          v-if="selected.status !== 'COMPLETED'"
          pill
          class="px-5 mr-3"
          size="sm"
          variant="primary"
          @click="startForm = true"
        >
          <i
            class="fas fa-play pr-2"
          />
          {{ $t('order.start') }}
        </BButton>

        <BButton
          v-if="selected.status === 'INPROGRESS'"
          pill
          class="px-5 mr-3"
          size="sm"
          @click="stopForm = true"
        >
          <i
            class="fas fa-pause pr-2"
          />
          {{ $t('order.hold') }}
        </BButton>

        <BButton
          v-if="selected.status === 'INPROGRESS' || selected.status === 'ONHOLD'"
          pill
          class="px-5 mr-3"
          size="sm"
          variant="secondary"
          @click="completeForm = true"
        >
          <i
            class="fas fa-stop pr-2"
          />
          {{ $t('order.finish') }}
        </BButton>
      </div>

      <div v-else>
        <BButton
          pill
          class="px-5 mr-3"
          size="sm"
          @click="editForm = false"
        >
          <i
            class="ion ion-md-close pr-2 text-bold"
          />
          {{ $t('general.cancel') }}
        </BButton>

        <BButton
          pill
          class="px-5 mr-3"
          :disabled="pendingUpdateOrder"
          size="sm"
          variant="primary"
          @click="updateSelectedOrder"
        >
          <BSpinner
            v-if="pendingUpdateOrder"
            variant="light"
            small
            class="mr-1"
          />
          <i
            v-else
            class="fas fa-check pr-2"
          />
          {{ $t('general.save') }}
        </BButton>
      </div>
    </div>

    <div
      v-if="tab === 2 && totalRows > 0"
      class="offcanvas-footer row justify-content-between align-items-center mx-2"
      :class="pendingPallets ? 'disabled-content' : ''"
    >
      <span class="pt-4">
        <BPagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          :disabled="pendingPallets"
          size="sm"
          pills
          limit="5"
          first-number
          last-number
        />
      </span>
      <span class="row align-items-center mr-2">
        <span class="row-text mr-2">
          {{ $t('general.rowsOnThePage') }}
        </span>

        <BFormSelect
          v-model="perPage"
          :options="options"
          value-field="item"
          text-field="name"
          disabled-field="notEnabled"
          style="width: 75px"
          size="sm"
        />
      </span>
    </div>

    <LModal
      class="modal-dialog mw-100 w-100"
      :show="startForm"
      size="lg"
      @update:show="startForm=$event"
    >
      <OrderStart
        :order-id="selected?.id"
        :order="selected"
        :line-id="selected?.lineId"
        @refresh="$emit('refresh')"
        @close="startForm=false"
      />
    </LModal>

    <LModal
      class="modal-dialog mw-100 w-100"
      :show="stopForm"
      size="lg"
      @update:show="stopForm=$event"
    >
      <OrderStop
        :order-id="selected?.id"
        :order="selected"
        :line-id="selected?.lineId"
        @refresh="$emit('refresh')"
        @close="stopForm=false"
      />
    </LModal>

    <LModal
      class="modal-dialog mw-100 w-100"
      :show="completeForm"
      size="lg"
      @update:show="completeForm=$event"
    >
      <OrderComplete
        :order-id="selected?.id"
        :order="selected"
        :line-id="selected?.lineId"
        @refresh="$emit('refresh')"
        @close="completeForm=false"
      />
    </LModal>
  </div>
</template>

<script>
import OrderGeneralTab from '@/components/order/tabs/OrderGeneralTab';
import OrderHistoryTab from '@/components/order/tabs/OrderHistoryTab';
import OrderRequestTab from '@/components/order/tabs/OrderRequestTab';
import OrderStart from '@/components/order/OrderStart';
import OrderStop from '@/components/order/OrderStop';
import OrderComplete from '@/components/order/OrderComplete';

export default {
  props: {
    selected: Object,
    view: Number,
  },
  data: () => ({
    tab: 0,
    editForm: false,
    orderProperties: [],
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    options: [
      { item: 10, name: '10' },
      { item: 20, name: '20' },
      { item: 50, name: '50' },
      { item: 100, name: '100' },
    ],
    selectedPallet: null,
    showOffcanvas: false,
    pendingPallets: false,
    startForm: false,
    stopForm: false,
    completeForm: false,
  }),
  components: {
    OrderGeneralTab,
    OrderHistoryTab,
    OrderRequestTab,
    OrderStart,
    OrderStop,
    OrderComplete,
  },
  computed: {
    completedHeight() {
      return '240px';
    },
  },
  watch: {
    selected() {
      this.tab = 0;
      this.editForm = false;
      this.selectedPallet = null;
    },
  },
  methods: {
    selectPallet(pallet) {
      this.selectedPallet = pallet;
      this.showOffcanvas = true;
    },
  },
  created() {
    this.tab = this.view;
  },
};
</script>

<style lang="scss" scoped>
  .order-details-offcanvas {
    position: relative;
    font-size: 12px;
    font-weight: 600;

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.4;
      font-size: 1.75rem;
      cursor: pointer;
    }

    .refresh-button {
      position: absolute;
      top: 7.5rem;
      right: 2rem;
      border-radius: 30px;
      padding: 3px 13px;
      background: #EFEFEF;
      cursor: pointer;
    }

    .offcanvas-footer {
      border-top: 1px solid #ECECEC;
      min-height: 60px;
      max-height: 60px;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    .bar-chart-params {
      font-size: 10px;
      opacity: 0.65;
    }

    &:deep() {
      .tabs {
        .tab-content {
          height: calc(100vh - v-bind(completedHeight));
          overflow-y: auto;
        }

        .nav {
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          border-bottom: 1px solid #ECECEC;
          margin-bottom: 1rem;
          font-size: 14px;

          .nav-item {
            width: 25%;
            text-align: center;

            .nav-link {
              background: transparent;
              color: rgba(#000, 0.5);
              border-radius: 0;
              margin-bottom: -3px;

              &.active {
                color: black;
                font-weight: 600;
                border-bottom: 5px solid rgba(118, 176, 51, 1);
              }
            }
          }
        }
      }
    }
  }
</style>
