var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"properties-type-header",on:{"click":function($event){_vm.unfolded=!_vm.unfolded}}},[_c('div',[_vm._v(" "+_vm._s(_vm.header)+" ")]),_c('div',{staticClass:"arrow"},[(!_vm.unfolded)?_c('i',{staticClass:"fa-solid fa-angle-down"}):_c('i',{staticClass:"fa-solid fa-angle-up"})])]),(_vm.unfolded)?_c('div',[_vm._l((_vm.skuProperties),function(property){return _c('div',{key:property.id,staticClass:"label-value-row"},[_c('div',{staticClass:"label"},[_c('div',[_vm._v(" "+_vm._s(property.name)+" ")]),_c('div',{staticClass:"label-description"},[_vm._v(" "+_vm._s(property.description)+" ")])]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"value-value"},[(_vm.editForm)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              placement: 'bottom',
              trigger: !property.editable ? 'mouseenter' : ''
            }),expression:"{\n              arrow: true,\n              placement: 'bottom',\n              trigger: !property.editable ? 'mouseenter' : ''\n            }"}],attrs:{"content":_vm.$t('sku.toEditTurnOffSync')}},[(property.valueType === _vm.propertyValueTypes.dictionary)?_c('BFormSelect',{attrs:{"disabled":!property.editable,"options":_vm.getDictionaryEntriesForBox(property.dictionaryId),"value-field":"value","text-field":"text"},on:{"change":function($event){return _vm.propertyUpdated(property.id)}},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}}):(property.valueType === _vm.propertyValueTypes.boolean)?_c('BFormCheckbox',{attrs:{"disabled":!property.editable,"switch":"","size":"md"},on:{"change":function($event){return _vm.propertyUpdated(property.id)}},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}}):_c('BFormInput',{attrs:{"disabled":!property.editable,"state":_vm.isValueValid(property.valueType, property.value) ? null : false},on:{"input":function($event){return _vm.propertyUpdated(property.id)}},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}}),(!_vm.isValueValid(property.valueType, property.value))?_c('Error',{staticClass:"mt-1",attrs:{"message":`${_vm.$t('error.valueIsInNotExpectedType')} ${property.valueType}`}}):_vm._e()],1):_c('span',[(property.valueType === _vm.propertyValueTypes.boolean)?_c('BFormCheckbox',{attrs:{"disabled":"","switch":"","size":"md"},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}}):_c('div',[_vm._v(" "+_vm._s(property.value || '-')+" ")])],1)]),(_vm.editForm && property.valueType === _vm.propertyValueTypes.dictionary
            && property.value !== null && property.value?.length > 0)?_c('div',{staticClass:"value-icon",staticStyle:{"cursor":"pointer","font-size":"14px"},on:{"click":function($event){return _vm.propertyClearClicked(property.id)}}},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              placement: 'top',
            }),expression:"{\n              arrow: true,\n              placement: 'top',\n            }"}],attrs:{"content":_vm.$t('general.clear')}},[_c('i',{staticClass:"fa-regular fa-circle-xmark"})])]):_vm._e(),(property.type.toUpperCase() === 'IMPORT')?_c('div',{staticClass:"value-icon",style:(_vm.editForm ? 'cursor: pointer' : 'cursor: default'),on:{"click":function($event){return _vm.propertySyncClicked(property.id)}}},[(!property.editable)?_c('i',{staticClass:"fa-solid fa-link"}):_c('i',{staticClass:"fa-solid fa-link-slash",staticStyle:{"color":"rgba(200, 20, 20, 0.9)"}})]):_c('div',{staticClass:"value-icon"},[_c('i',{staticClass:"fa-solid fa-user-pen"})])])])}),(!_vm.skuProperties.length)?_c('div',{staticClass:"no-properties-info"},[_vm._v(" "+_vm._s(_vm.$t('general.none'))+" ")]):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }