<template>
  <div>
    <h5
      class="mb-0 mx-3"
    >
      <span v-if="!id">
        <i class="ion ion-md-add pr-2" />
        {{ $t('labellingPoint.addLabellingPoint') }}
      </span>

      <span v-else>
        <i class="fas fa-pen pr-2" />
        {{ $t('labellingPoint.editLabellingPoint') }}
      </span>
    </h5>

    <div class="d-flex mx-3">
      <div class="col-6 pl-0">
        <div class="my-3">
          <label class="mb-1 table-title">
            {{ $t('general.name') }}
          </label>
          <BFormInput
            v-model="localName"
            :placeholder="$t('general.name') + '...'"
            :state="stateOfNameField"
          />
        </div>
        <div class="my-3">
          <label class="mb-1 table-title">
            <span>
              {{ $t('labellingPoint.labellingLevel') }}
            </span>
            <span class="optional-text">
              - {{ $t('form.optional') }}
            </span>
          </label>
          <BFormSelect
            v-model="localLabelingLevel"
            :options="formattedLabelingLevels"
            :state="localLabelingLevel && !id ? true : null"
          />
        </div>
      </div>
      <div class="col-6 pr-0">
        <div class="my-3">
          <label class="mb-1 table-title">
            <span>
              {{ $t('general.shortName') }}
            </span>
          </label>
          <BFormInput
            id="textarea"
            v-model="localShortName"
            :placeholder="$t('general.shortName') + '...'"
            :state="((localShortName && !id ? localShortName.length : null)
              && !labelShortNameError) || (!labelShortNameError && id?.length)"
            rows="1"
            max-rows="6"
          />
        </div>
        <div class="my-3">
          <label class="mb-1 table-title">
            <span>
              {{ $t('labellingPoint.armingDevices') }}
            </span>
            <i
              v-if="localMultiOrderSupport"
              v-tippy="{ arrow: true }"
              :content="$t('settings.multiOrderInfo')"
              class="fas fa-info-circle mx-2"
            />
          </label>
          <BFormSelect
            v-model="localType"
            :state="localType && !id ? true : null"
          >
            <option
              v-for="t in types"
              :key="t.value"
              :value="t.value"
              :disabled="t.value === 'AUTO' && localMultiOrderSupport"
              :class="t.value === 'AUTO' && localMultiOrderSupport ? 'disabled-style' : ''"
            >
              {{ t.text }}
            </option>
          </BFormSelect>
        </div>
      </div>
    </div>

    <div class="d-flex mx-3">
      <div class="col-6 pr-0">
        <div class="d-flex my-3">
          <BFormCheckbox
            v-model="localMultiOrderSupport"
            :disabled="localType === 'AUTO'"
            class="text-left"
            switch
            size="lg"
          />
          <div class="table-title mt-1">
            <span>
              {{ $t('general.multiOrderSupport') }}
            </span>
            <i
              v-if="localType === 'AUTO'"
              v-tippy="{ arrow: true }"
              :content="$t('settings.multiOrderInfo')"
              class="fas fa-info-circle mx-2"
            />
          </div>
        </div>
        <div class="d-flex my-3">
          <BFormCheckbox
            v-model="localAutomaticCleaning"
            class="text-left"
            switch
            size="lg"
          />
          <div class="table-title mt-1">
            <span>
              {{ $t('labellingPoint.automaticPrintersCleaning') }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-6 pr-0">
        <div class="d-flex my-3">
          <BFormCheckbox
            v-model="localAutomaticFinishing"
            class="text-left"
            switch
            size="lg"
          />
          <div class="table-title mt-1">
            <span>
              {{ $t('labellingPoint.automaticFinishingOnPrinters') }}
            </span>
          </div>
        </div>
        <div class="d-flex my-3">
          <BFormCheckbox
            v-model="localReportGeneration"
            class="text-left"
            switch
            size="lg"
          />
          <div class="table-title mt-1">
            <span>
              {{ $t('labellingPoint.reportGeneration') }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <hr class="mx-3">

    <div class="font-weight-bold pt-0 mx-3">
      {{ $t('form.dataForm') }}
    </div>
    <div
      id="order-plan"
      class="d-flex m-3"
    >
      <div class="col-12 col-md-6 d-flex flex-column justify-content-between bg-white order-card">
        <div>
          <div class="mt-2">
            <div class="d-flex justify-content-between mb-2">
              <div class="pl-2 font-weight-bold">
                {{ $t('form.activeFileds') }} ({{ fields.length }})
              </div>
            </div>
            <Draggable
              v-model="draggables"
              :scroll-sensitivity="100"
              :scroll-speed="30"
              :animation="400"
              handle=".handle"
              class="noselect"
            >
              <div
                v-for="field in draggables"
                :key="field.id"
                class="w-100 mt-2 handle"
                style="cursor: move"
              >
                <div
                  :class="editedField === field.id ? 'selected' : ''"
                >
                  <div
                    :class="selectedField && selectedField.id === field.id && active ? 'background' : ''"
                    class="d-flex p-2"
                    @mouseenter="editField(field.id)"
                    @mouseleave="editField(field.id)"
                  >
                    <div
                      class="flex-grow-1 pl-2 pt-1"
                      style="min-height: 50px"
                    >
                      <div
                        v-if="field.type !== 'checkbox'"
                        class="d-flex justify-content-between"
                      >
                        <div
                          :class="field.featured ? 'font-weight-bold' : ''"
                          class="mb-2"
                          style="font-size: 15px;"
                        >
                          <span>
                            {{ field.label }}
                            <span
                              v-if="field.required"
                              class="text-danger"
                            >
                              *
                            </span>
                          </span>
                        </div>
                        <div>
                          <button
                            v-if="editedField === field.id"
                            class="btn btn-outline-secondary btn-sm icon-btn"
                            @click="selectField(field)"
                          >
                            <i class="ion ion-md-create" />
                          </button>
                        </div>
                      </div>

                      <div v-if="field.type === 'label'">
                        {{ field.default }}
                      </div>
                      <div v-if="field.type === 'input'">
                        <BFormInput
                          :type="field.inputType"
                          :readonly="field.readonly"
                        />
                      </div>
                      <div v-if="field.type === 'select'">
                        <BFormSelect
                          :options="field.values"
                        />
                      </div>
                      <div v-if="field.type === 'textArea'">
                        <BFormTextarea
                          :readonly="field.readonly"
                        />
                      </div>
                      <div
                        v-if="field.type === 'checkbox'"
                      >
                        <div class="text-right">
                          <button
                            v-if="editedField === field.id"
                            class="btn btn-outline-secondary btn-sm icon-btn"
                            @click="selectField(field)"
                          >
                            <i class="ion ion-md-create" />
                          </button>
                        </div>
                        <BFormCheckbox
                          v-model="field.default"
                          class="d-inline"
                        >
                          {{ field.label }}
                        </BFormCheckbox>
                      </div>
                      <div
                        v-if="field.type === 'checklist'"
                        style="margin-bottom: -10px"
                      >
                        <BFormGroup>
                          <BFormCheckboxGroup
                            :options="field.values"
                            stacked
                            class="ml-2"
                          />
                        </BFormGroup>
                      </div>
                      <div v-if="field.type === 'radios'">
                        <BFormRadioGroup
                          style="font-size: 8px"
                          size="sm"
                          :options="field.values"
                          stacked
                        />
                      </div>
                      <div class="pl-1 hint">
                        {{ field.hint }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Draggable>
          </div>
        </div>
        <button
          class="btn btn-primary btn-sm mx-auto mt-3"
          style="width: 130px"
          type="button"
          @click="addField()"
        >
          <i class="ion ion-md-add pr-2" />
          {{ $t('form.addField') }}
        </button>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex flex-column justify-content-between">
          <div
            v-if="!fields || fields.length === 0 || !selectedField"
            class="font-weight-light mx-auto mt-5"
          >
            {{ fields.length === 0 ? $t('form.startField') : $t('form.editField') }}
          </div>
          <div
            v-if="active && selectedField"
            class="list-complete-item w-100 mt-5"
          >
            <FormFields
              :key="selectedField.id"
              class="flex-grow-1"
              :all-fields="fields"
              :field.sync="selectedField"
              @update:field="updateField($event)"
              @remove="removeField()"
            />
          </div>
        </div>
      </div>
    </div>

    <hr
      v-if="id"
      class="mx-3"
    >

    <div
      v-if="id"
      class="d-flex"
    >
      <div class="col-6 pr-0">
        <div class="my-3">
          <label class="mb-1 table-title">
            <span>
              {{ $t('general.created') }}
            </span>
          </label>
          <BFormInput
            disabled
            :value="created"
          />
        </div>
      </div>
      <div class="col-6 pr-0">
        <div class="my-3">
          <label class="mb-1 table-title">
            <span>
              {{ $t('general.modified') }}
            </span>
          </label>
          <BFormInput
            disabled
            :value="modified"
          />
        </div>
      </div>
    </div>

    <hr class="mx-3">

    <div>
      <Error
        v-if="displayedError"
        class="mb-2 ml-3"
        :message="displayedError"
      />
      <SaveButton
        class="save-buttons"
        :pending="pending"
        :show-remove="true"
        :disabled="(!id && !errorCheck) || (displayedError ? true : false)"
        @cancel="$emit('close')"
        @save="!id ? create() : update()"
        @remove="remove()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Draggable from 'vuedraggable';
import { propertyValueTypes } from '@/utils/dictionary';
import FormFields from './FormFields';

export default {
  props: {
    id: Number,
    name: String,
    description: String,
    modified: String,
    created: String,
    multiOrderSupport: Boolean,
    automaticPrintersCleaning: Boolean,
    automaticFinishingOnPrinters: Boolean,
    reportGeneration: Boolean,
    type: String,
    labelingLevel: String,
  },
  data: () => ({
    pending: false,
    localName: '',
    localShortName: '',
    selectedField: null,
    localMultiOrderSupport: false,
    fields: [],
    nextId: 0,
    active: false,
    editedField: '',
    localType: '',
    localAutomaticCleaning: false,
    localAutomaticFinishing: false,
    localReportGeneration: false,
    localLabelingLevel: null,
  }),
  components: {
    FormFields,
    Draggable,
  },
  computed: {
    ...mapState({
      labelingPoints: state => state.labelingPoints.list || [],
      labelingLevels: state =>
        state.settings.data?.labelingLevels?.sort((a, b) => a.ordinal - b.ordinal) || [],
      orderPropertyTypes: state => state.orders.properties || [],
      dictionaries: state => state.dictionaries.list || [],
    }),
    ...mapGetters([
      'timeOfNotification',
      'maxInputLength',
      'maxDescriptionLength',
    ]),
    types() {
      return [
        { value: 'AUTO', text: this.$t('labellingPoint.atTheStartOfTheOrder') },
        { value: 'MANUAL', text: this.$t('labellingPoint.atTheUserRequest') },
      ];
    },
    formattedLabelingLevels() {
      const labelingPoint = this.labelingLevels?.map(t => ({
        value: t.name,
        text: t.name,
      }));
      const emptyLabelingPoint = [{
        value: null,
        text: '-',
      }];

      return emptyLabelingPoint.concat(labelingPoint);
    },
    labelNameExist() {
      if (!this.localName) return false;
      return this.labelingPoints.find(l => l.name?.toUpperCase() === this.localName?.toUpperCase()) ? true : null;
    },
    stateOfNameField() {
      if (this.labelNameExist && !this.id) return false;
      if (this.labelNameExist && this.id && this.name !== this.localName) return false;
      if (this.labelNameError) return false;
      return this.localName && !this.id ? true : null;
    },
    labelNameError() {
      if (!this.localName) return false;
      return this.localName.length >= this.maxInputLength;
    },
    labelShortNameError() {
      if (!this.localShortName) return false;
      return this.localShortName.length >= 12;
    },
    displayedError() {
      if (this.labelNameExist && !this.id) return this.$t('error.nameAlreadyExists');
      if (this.labelNameExist && this.id && this.name !== this.localName) return this.$t('error.nameAlreadyExists');
      if (this.labelShortNameError) return this.$t('error.shortNameIsTooLong');
      return this.labelNameError ? this.$t('error.nameIsTooLong') : null;
    },
    errorCheck() {
      if (
        this.localName
        && this.localType
        && this.localShortName
        && !this.labelNameExist
        && !this.labelNameError
        && !this.labelShortNameError
      ) return true;
      return false;
    },
    schema() {
      return {
        fields: this.fields.map(f => {
          const field = { ...f };
          field.entries = field.orderPropertyType === propertyValueTypes.dictionary || field.dictionaryId !== null
            ? [] // entries are stored in dictionary definition
            : field.entries;
          field.dictionaryId = field.orderPropertyType === propertyValueTypes.dictionary
            ? null // dictionaryId is linked to order property
            : field.dictionaryId;
          delete field.values;
          delete field.id;
          delete field.ordinal;
          return field;
        }),
      };
    },
    draggables: {
      get() {
        return this.fields;
      },
      set(f) {
        this.fields = f.map((el, index) => ({
          ...el,
          ordinal: index,
        }));
        this.moveField();
      },
    },
  },
  methods: {
    ...mapActions([
      'getLabelingPoints',
      'createLabelingPoint',
      'updateLabelingPointWithMeta',
      'updateLabelingPoint',
      'deleteLabelingPoint',
      'getLabelingPointWithMeta',
      'getGlobalSettings',
      'getOrdersProperties',
    ]),
    editField(id) {
      if (id === this.editedField) {
        this.editedField = '';
      } else {
        this.editedField = id;
      }
    },
    moveField() {
      this.fields.reduce((acc, curr, index) => { acc[curr.id] = index; return acc; }, {});
    },
    removeEmptyField() {
      this.fields = this.fields.filter(f => f.type && f.label);
    },
    selectField(f) {
      if (this.selectedField && this.selectedField.id === f.id && this.active) {
        this.active = false;
        this.selectedField = null;
      } else {
        this.selectedField = f;
        this.active = true;
      }
    },
    updateField(f) {
      const fieldIndex = this.fields
        .findIndex(field => field.id === f.id);
      if (fieldIndex !== -1) {
        this.$set(this.fields, fieldIndex, f);
      }
    },
    removeField() {
      this.fields = this.fields.filter(f => f.model !== this.selectedField.model);
      this.active = false;
      this.selectedField = null;
    },
    addField() {
      this.fields.push({
        id: this.getId(),
      });
      this.selectedField = this.fields.find(f => f.id === this.nextId);
      this.active = true;
    },
    getId() {
      this.nextId += 1;
      return this.nextId;
    },
    create() {
      this.createLabelingPoint({
        data: {
          name: this.localName,
          description: this.localShortName,
          multiOrderSupport: this.localMultiOrderSupport,
          automaticPrintersCleaning: this.localAutomaticCleaning,
          automaticFinishingOnPrinters: this.localAutomaticFinishing,
          reportGeneration: this.localReportGeneration,
          metaData: JSON.stringify(this.schema),
          type: this.localType,
          labelingLevel: this.localLabelingLevel,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('form.labellingPointSuccessfullyCreated'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    remove() {
      this.deleteLabelingPoint({
        params: {
          id: this.id,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('form.labellingPointSuccessfullyRemoved'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    update() {
      this.updateLabelingPoint({
        params: {
          id: this.id,
        },
        data: {
          name: this.localName,
          description: this.localShortName,
          multiOrderSupport: this.localMultiOrderSupport,
          automaticPrintersCleaning: this.localAutomaticCleaning,
          automaticFinishingOnPrinters: this.localAutomaticFinishing,
          reportGeneration: this.localReportGeneration,
          type: this.localType,
          labelingLevel: this.localLabelingLevel,
        },
      }).then(() => {
        this.updateMetaData();
        this.request();
        this.$emit('successMsg', this.$t('general.modified'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    updateMetaData() {
      this.updateLabelingPointWithMeta({
        params: {
          id: this.id,
        },
        data: {
          fields: this.schema.fields,
        },
      }).catch(({ response: { data } }) => {
        this.$emit('failedMsg', data?.message);
      });
    },
    request() {
      this.getLabelingPoints();
    },
    metaRequest() {
      this.pending = true;
      this.getOrdersProperties()
        .then(() => {
          this.getLabelingPointWithMeta({
            params: {
              id: this.id,
            },
          })
            .then(({ data }) => {
              this.fields = JSON.parse(data.metaData)
                .fields.map(f => {
                  const mappedField = { ...f, id: f.model };
                  const orderProperty = this.orderPropertyTypes
                    .find(propType => propType.name === f.order);
                  const dictionary = this.dictionaries
                    .find(dict => dict.id === (orderProperty?.dictionaryId || mappedField.dictionaryId));
                  if (dictionary) {
                    mappedField.dictionaryId = dictionary.id;
                    mappedField.entries = dictionary.entries;
                  }
                  mappedField.values = mappedField.entries?.map(entry => entry.key);
                  return mappedField;
                });
            })
            .catch(({ response: { data } }) => {
              this.$bvToast.toast(data.message, {
                title: this.$t('general.error'),
                variant: 'danger',
                autoHideDelay: this.timeOfNotification,
              });
            })
            .finally(() => {
              this.pending = false;
            });
        });
    },
  },
  created() {
    if (this.id) {
      this.metaRequest();
      this.localName = this.name;
      this.localShortName = this.description;
      this.localMultiOrderSupport = this.multiOrderSupport;
      this.localAutomaticCleaning = this.automaticPrintersCleaning;
      this.localAutomaticFinishing = this.automaticFinishingOnPrinters;
      this.localReportGeneration = this.reportGeneration;
      this.localType = this.type;
      this.localLabelingLevel = this.labelingLevel;
    }
    this.getGlobalSettings();
  },
};
</script>

<style lang="scss" scoped>
  #order-plan {
    margin: -15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 15px;
    background-color: rgba(200, 200, 200, 0.2);
  }

  .center-box :deep(.form-row) {
    align-items: center;
  }

  .order-card {
    border-radius: 15px;
    margin-top: -15px;
    margin-bottom: -15px;
    padding: 15px;
    box-shadow: 0 0 5px rgba(100,100,100,0.2);
  }

  .border {
    box-shadow: 0 0 3px rgba(100, 100, 100, 0.5);
    border-radius: 15px;
    padding: 4px 10px;
    text-align: left;
    transition: transform 300ms, box-shadow 300ms;
    margin-top: 10px;

    &.sm {
      padding: 3px 10px;
    }

    &.hoverable {
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 4px rgba(100, 100, 100, 0.7);
        transform: translateX(-3px);
      }
    }
  }

  .background {
    background-color: rgb(237, 237, 237);
    border-radius: 15px;
  }

  .hint {
    font-size: 13px;
    margin-top: 5px;
    margin-left: -5px;
  }

  .selected {
    box-shadow: 0 0 3px rgba(150, 150, 150, 0.5);
    border-radius: 15px;
    transition: all 500ms;
    &:hover {
      transform: translateY(-5px);
    }
  }

  .table-title {
    font-weight: 700;
    font-size: 14px;
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
  }

  .disabled-style {
    background-color: #e9ecef;
    opacity: 0.7;
  }

</style>
