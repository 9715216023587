<template>
  <CodeMirror
    ref="editor"
    class="code-editor"
    :value="code"
    :options="cmOptions"
    @update="update"
    @cursorActivity="cursor"
  />
</template>

<script>
import { mapState } from 'vuex';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/clike/clike';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint';

export default {
  props: {
    value: String,
    skuFields: Array,
    ordersFileds: Array,
    baseProperties: Array,
    readOnly: [Boolean, String],
  },
  data: () => ({
    code: '',
    cmOptions: {
      tabSize: 4,
      indentUnit: 4,
      mode: 'text/x-csharp',
      lineNumbers: true,
      line: true,
    },
  }),
  components: {
    CodeMirror: codemirror,
  },
  computed: {
    ...mapState({
      dictionaries: state => state.dictionaries.list || [],
    }),
  },
  watch: {
    value(v) {
      const doc = this.$refs.editor.codemirror.getDoc();
      const cursor = doc.getCursor();
      doc.setValue(v);
      doc.setCursor(cursor);
    },
  },
  methods: {
    update({ doc }) {
      this.$emit('update:value', doc.getValue());
    },
    cursor(editor) {
      // Definition of autocompletion
      const showOn = {
        'sku.': this.skuFields.map(x => x.name) || [],
        'order.': this.ordersFileds.map(x => x.name) || [],
        'order.BaseProperties.': this.baseProperties.map(x => x.name) || [],
        '.GetDictionaryAsync(': this.dictionaries.map(x => `"${x.name}"`) || [],
      };

      let cursor = editor.getDoc().getCursor();
      cursor = editor.getDoc().getCursor();

      const stringInLineBeforeCursor = editor.doc.getRange(
        {
          line: cursor.line,
          ch: 0,
        },
        {
          line: cursor.line,
          ch: cursor.ch,
        },
      );

      let closestToTheCursorKey = null;
      let closestToTheCursorIndex = -1;
      Object.keys(showOn).forEach(key => {
        const keyIndex = stringInLineBeforeCursor.lastIndexOf(key);
        if (keyIndex >= 0 && keyIndex >= closestToTheCursorIndex) {
          closestToTheCursorIndex = keyIndex;
          closestToTheCursorKey = key;
        }
      });
      if (closestToTheCursorKey === null) {
        return;
      }

      const stringAfterClosestKey = stringInLineBeforeCursor
        .substring(closestToTheCursorIndex + closestToTheCursorKey.length);

      const listOfHints = showOn[closestToTheCursorKey]
        .filter(x => x.startsWith(stringAfterClosestKey))
        .map(x => ({ text: x.substring(stringAfterClosestKey.length, x.length), displayText: x }));

      if (listOfHints.length === 0) {
        return;
      }

      const options = {
        completeSingle: false,
        hint() {
          return {
            from: editor.getDoc().getCursor(),
            to: editor.getDoc().getCursor(),
            list: listOfHints,
          };
        },
      };
      editor.showHint(options);
    },
  },
  created() {
    this.code = this.value;
    if (this.readOnly) {
      this.cmOptions.readOnly = this.readOnly;
    }
  },
};
</script>

<style lang="scss" scoped>

  .code-editor {
    box-shadow: 0px 0 5px rgba(100, 100, 100, 0.2);
  }

  .code-editor :deep(.CodeMirror-hints) {
    height: 50px;
  }

</style>
