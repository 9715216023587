<template>
  <div>
    <div class="payload-style">
      {{ prettyJson || $t('form.noData') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    request: Object,
  },
  computed: {
    prettyJson() {
      const parsedJson = JSON.parse(this.request?.requestDetails);
      if (!parsedJson) return null;
      return JSON.stringify(parsedJson, null, '\t');
    },
  },

};
</script>

<style lang="scss" scoped>
  .payload-style {
    font-size: 0.9rem;
    font-weight: 420;
    padding: 10px;
    color: #8b8b8b;
    background-color: #f0f2f4;
    border: 1.5px solid #ced4da;
    border-radius: 5px;
    overflow-wrap: break-word;
    overflow-y: scroll;
    height: 70vh;
    white-space: pre;
  }
</style>
