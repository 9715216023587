<template>
  <div>
    <h5
      class="mb-3 mx-3"
    >
      <i class="fas fa-clone pr-2" />
      {{ $t('template.cloneTemplate') }}
    </h5>
    <div class="px-3">
      <label class="mb-1 box-title">
        {{ $t('template.clonedTemplateName') }}
      </label>
      <BFormGroup>
        <BInputGroup>
          <BFormInput
            v-model="cloneTemplateName"
            :disabled="true"
          />
        </BInputGroup>
      </BFormGroup>
    </div>
    <div class="px-3">
      <label class="mb-0 box-title">
        {{ $t('template.newTemplateName') }}
      </label>
      <BFormGroup class="mb-1">
        <BInputGroup>
          <BFormInput
            v-model="newTemplateName"
            :state="errorCheck"
            :placeholder="$t('general.addName') +'...'"
          />
        </BInputGroup>
      </BFormGroup>
    </div>
    <div class="row mx-3 my-3">
      <BFormCheckbox
        v-model="labelRules"
      >
        <span class="font-weight-bold">
          {{ $t('label.cloneLabelRules') }}
        </span>
      </BFormCheckbox>
    </div>
    <div>
      <hr>
      <Error
        v-if="errorCheck === false"
        class="mb-2"
        :message="$t('error.nameAlreadyExists')"
      />
      <SaveButton
        class="save-buttons"
        :show-remove="false"
        :disabled="!errorCheck"
        @cancel="$emit('close')"
        @save="cloneTemplate"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    cloneElement: Object,
  },
  data() {
    return {
      newTemplateName: null,
      labelRules: true,
      pending: false,
    };
  },
  computed: {
    ...mapState({
      list: state => state.labels.templates,
    }),
    errorCheck() {
      if (!this.newTemplateName) return null;
      return !this.list.find(l => l.name === this.newTemplateName);
    },
  },
  methods: {
    ...mapActions(['cloneLabelTemplates']),
    cloneTemplate() {
      this.pending = true;
      this.cloneLabelTemplates({
        params: {
          labelId: this.cloneElement?.id,
          query: {
            newLabelName: this.newTemplateName,
            cloneLabelRules: this.labelRules,
          },
        },
      }).then(() => {
        this.$emit('successMsg');
      }).catch(() => {
        this.$emit('failedMsg');
      }).finally(() => {
        this.pending = false;
      });
      this.$emit('reload');
      this.$emit('close');
    },
  },
  created() {
    this.cloneTemplateName = this.cloneElement?.name;
  },
};
</script>
<style lang="scss" scoped>

  .box-title {
    font-weight: 700;
    font-size: 14px;
  }

</style>
