<template>
  <div class="col-12 pt-2">
    <hr v-if="orderHistoryList.length">
    <div
      v-if="pendingHistory"
      class="text-center mt-5"
    >
      <BSpinner
        variant="secondary"
        large
      />
    </div>
    <div v-else>
      <div
        style="height: 90vh"
      >
        <div
          class="d-flex flex-column py-1"
        >
          <TransitionGroup
            name="list-complete"
          >
            <EdgeRequest
              v-for="pr in filteredEdgeRequests"
              :key="pr.id"
              v-bind="pr"
              :is-first="pr.id === filteredEdgeRequests[0].id"
              class="edge-request-style mx-3"
            />
          </TransitionGroup>
        </div>
        <div
          v-if="edgeRequests.length === 0"
          class="text-center mt-5 text-black-50"
        >
          <h2 class="fas fa-ban" />
          <p class="mb-0">
            {{ $t('form.noData') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import calcDuration from '@/utils/calcDuration';
import EdgeRequest from '@/components/edgeRequest/EdgeRequest';

export default {
  props: {
    selected: Object,
  },
  data: () => ({
    pendingHistory: false,
    edgeRequests: [],
  }),
  components: {
    EdgeRequest,
  },
  computed: {
    filteredEdgeRequests() {
      const phrasedEdgeRequests = this.edgeRequests?.map(pr => ({
        ...pr,
        duration: calcDuration(moment.utc(pr.created).unix()),
      }));
      return phrasedEdgeRequests;
    },
    orderHistoryList() {
      return this.ordersHistory || [];
    },
  },
  watch: {
    selected() {
      this.requestOrderRequest();
    },
  },
  methods: {
    calcDuration,
    ...mapActions([
      'getRequests',
    ]),
    requestOrderRequest() {
      this.getRequests({
        params: {
          query: {
            orderIds: [this.selected.id],
          },
        },
      }).then(({ data }) => {
        this.edgeRequests = data?.items;
      });
    },
  },
  created() {
    this.requestOrderRequest();
  },
};
</script>

<style lang="scss" scoped>

  .edge-request-style {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.2);
  }

  hr {
    margin-top: 1px;
    margin-bottom: 1px;
  }

</style>
