var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative order-data"},[(false)?_c('div',{staticClass:"refresh-button"},[_c('i',{staticClass:"fas fa-refresh"}),_vm._v(" Odśwież ")]):_vm._e(),(true)?_c('OrderGeneralTab',{attrs:{"selected":_vm.activeOrder.order}}):_vm._e(),(false)?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Stan zlecenia ")]),_c('div',{staticClass:"value"},[_c('CockpitStatusBadge',{attrs:{"label":"STARTED","icon":"fas fa-play","color":"#5886C1"}})],1)]),_c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Postęp ")]),_c('div',{staticClass:"value order-progress"},[_c('OrderProgressBar')],1)]),_c('div',{staticClass:"line"}),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" ID zlecenia ")]),_c('div',{staticClass:"value"},[_vm._v(" 1101100 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Opis ")]),_c('div',{staticClass:"value"},[_vm._v(" Zlecenie nr. 1101100 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" SKU ")]),_c('div',{staticClass:"value"},[_vm._v(" 13774 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Linia ")]),_c('div',{staticClass:"value"},[_vm._v(" Linia 2 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Sublinia ")]),_c('div',{staticClass:"value"},[_vm._v(" Sublinia C2_2 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Batch ID ")]),_c('div',{staticClass:"value"},[_vm._v(" 12788 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" BBD ")]),_c('div',{staticClass:"value"},[_vm._v(" 14.02.2025 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Linia pakowania ")]),_c('div',{staticClass:"value"},[_vm._v(" Line 1_C1 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Zaplanowana ilość ")]),_c('div',{staticClass:"value"},[_vm._v(" 13 000 pcs ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Wyprodukowana ilość ")]),_c('div',{staticClass:"value"},[_vm._v(" 12 453 pcs ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Wyprodukowane palety ")]),_c('div',{staticClass:"value"},[_vm._v(" 20 pal ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-row"},[_c('div',{staticClass:"label"},[_vm._v(" Termin ważności ")]),_c('div',{staticClass:"value"},[_vm._v(" 30 Sep 2025, 12:00:00 ")])])
}]

export { render, staticRenderFns }