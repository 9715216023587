<template>
  <div
    class="header"
    :style="'background-color: #f4f4f4'"
  >
    <div class="logo position-relative">
      <SystemSelect
        class="logix"
        :base-url="authUrl"
        :hub-url="hubUrl"
        :current="system"
        :token="oidcAccessToken"
        style="max-width: 200px"
      />
      <div
        class="plant-select text-center d-flex justify-content-center"
      />

      <RightMenu
        class="right-menu"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SystemSelect from '@core/components/SystemSelect';
import RightMenu from './RightMenu';

export default {
  props: {
    authUrl: String,
    hubUrl: String,
    system: String,
  },
  components: {
    RightMenu,
    SystemSelect,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcAccessToken']),
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .logo {
    width: 100%;
    position: relative;
    min-height: 60px;
    text-align: left;
    background: url(~@/assets/header.svg);
    background-repeat: no-repeat;
    background-size: 100% 90%;
    z-index: 1001;

    img.bg {
      min-height: 60px;
      position: absolute;
      min-width: 100%;
      z-index: 0;
      left: 0;
      top: 0;
    }

    .hamburger {
      position: absolute;
      right: 15px;
      top: 5px;
    }

    .logix {
      max-height: 40px;
      margin-left: 8px;
    }
  }

  .right-menu {
    position: absolute;
    right: 0;
    top: 0;
  }

  .navigation {
    position: absolute;
    top: 0px;
    height: 38px;
    right: 10px;
  }

  .header {
    .btn-outline-ilabo {
      background-color: white;
      height: 31px;
      width: 31px;
      line-height: 31px;
      i {
        font-size: 20px;
      }
      &:hover {
        background-color: $ilabo;
      }
    }
  }

  .plant-select {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .logo img {
    position: relative;
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    .logo .logix {
      max-height: 30px;
      margin-top: 6px;
    }
  }
</style>
