import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
})
  .get({
    action: 'getStartOrderOnLineRules',
    path: ({ lineId }) => `/startOrderOnLineRules/line/${lineId}`,
  })
  .post({
    action: 'createStartOrderOnLineRules',
    path: () => '/startOrderOnLineRules',
  })
  .put({
    action: 'updateStartOrderOnLineRules',
    path: ({ ruleId }) => `/startOrderOnLineRules/${ruleId}`,
  })
  .delete({
    action: 'deleteStartOrderOnLineRules',
    path: ({ ruleId }) => `/startOrderOnLineRules/${ruleId}`,
  })
  .getStore();

export default store;
