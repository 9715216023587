<template>
  <div>
    <div>
      <div>
        <div
          class="box-item pr-3"
        >
          <div class="d-flex">
            <BInput
              v-model="idEdited"
              class="col-2 mr-2"
              :state="(idEdited ? idEdited : null)
                && !idExist"
            />

            <BInput
              v-model="nameEdited"
              :placeholder="$t('general.addName')"
              class="col-3 mr-2"
              :state="(nameEdited ? nameEdited.length : null)
                && !nameError && !nameExist"
            />

            <BInput
              v-model="descriptionEdited"
              class="col-3 mr-2"
              :placeholder="$t('general.addDescription')"
              :state="(descriptionEdited ? descriptionEdited.length : null)
                && !descError"
            />

            <BSelect
              v-model="typeEdited"
              :placeholder="$t('form.selectFieldType')"
              class="col-3"
              :options="fieldTypeOptions"
              :state="typeEdited ? true : null"
            />
            <div
              class="delete"
            >
              <i
                class="far fa-times-circle"
                style="cursor: pointer;"
                @click="$emit('deleteField')"
              />
            </div>
          </div>
          <Error
            v-if="displayedError"
            class="mt-1"
            :message="displayedError"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { labelPropertyTypes } from '@/utils/dictionary';

export default {
  props: {
    id: Number,
    name: String,
    description: String,
    type: String,
    fieldList: Array,
    ordinalNumber: [String, Number],
    editForm: Boolean,
  },
  data: () => ({
    idEdited: null,
    descriptionEdited: null,
    nameEdited: null,
    typeEdited: null,
  }),
  computed: {
    ...mapGetters(['maxInputLength', 'maxDescriptionLength']),
    fieldTypeOptions() {
      return [
        {
          value: labelPropertyTypes.remote,
          text: labelPropertyTypes.remote.toUpperCase(),
        },
        {
          value: labelPropertyTypes.previewText,
          text: labelPropertyTypes.previewText.toUpperCase(),
        },
      ];
    },
    displayedError() {
      if (this.descError === false) return this.$t('error.descriptionIsTooLong');
      if (this.nameError === false) return this.$t('error.nameIsTooLong');
      if (this.idExist) return this.$t('error.numberAlreadyExists');
      if (this.nameExist) return this.$t('error.nameAlreadyExists');
      return null;
    },
    nameError() {
      return this.nameEdited?.length >= this.maxInputLength ? false : null;
    },
    descError() {
      return this.descriptionEdited?.length >= this.maxDescriptionLength ? false : null;
    },
    idExist() {
      if (!this.idEdited) return false;
      return this.fieldList.filter(l => l.ordinalNumber === this.idEdited)?.length >= 2;
    },
    nameExist() {
      if (!this.nameEdited) return false;
      return this.fieldList.filter(l => l.name?.toUpperCase() === this.nameEdited?.toUpperCase())?.length >= 2;
    },
  },
  watch: {
    idEdited() {
      this.$emit('update:ordinalNumber', this.idEdited);
    },
    nameEdited() {
      this.$emit('update:name', this.nameEdited);
    },
    descriptionEdited() {
      this.$emit('update:description', this.descriptionEdited);
    },
    typeEdited() {
      this.$emit('update:type', this.typeEdited);
    },
    displayedError() {
      this.$emit('update:fieldErrors', this.displayedError);
    },
    ordinalNumber() {
      this.idEdited = this.ordinalNumber || null;
    },
    name() {
      this.nameEdited = this.name;
    },
    description() {
      this.descriptionEdited = this.description;
    },
    type() {
      this.typeEdited = this.type;
    },
  },
  mounted() {
    this.idEdited = this.ordinalNumber;
    this.nameEdited = this.name;
    this.descriptionEdited = this.description;
    this.typeEdited = this.type;
  },
};
</script>

<style lang="scss" scoped>
  .box-item {
    background: #F4F4F4;
    padding: 5px;
    padding-right: 50px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: relative;

    .form-control {
      &:disabled {
        background-color: #F4F4F4 !important;
      }
    }

    &:hover {
      background: #ececec !important;
    }

    &.disabled {
      padding: 8px;
      cursor: default !important;

      &:hover {
        background: #F4F4F4!important;
      }
    }

    .delete {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateX(50%) translateY(-50%);
      color: #A6A6A6;
    }
  }
</style>
