<script>
import CockpitMatryoshkaColumnQueueItem
from '@/components/cockpit/matryoshka/CockpitMatryoshkaColumnQueueItem.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CockpitMatryoshkaColumn',
  props: {
    title: String,
    unit: Object,
    activeOrder: Object,
  },
  data: () => ({
    loading: false,
    labelArchive: [],
  }),
  components: {
    CockpitMatryoshkaColumnQueueItem,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    queueLength() {
      return this.labelArchive.length || 0;
    },
    palletsColumn() {
      return this.unit.name === 'paleta';
    },
    fetchParams() {
      const { plantCode } = this.globalSettings;
      return {
        plantCode,
        query: {
          skuId: this.activeOrder.order.skuId,
          unitId: this.unit.id,
          pageSize: 10000,
        },
      };
    },
    filteredLabelArchive() {
      return this.labelArchive;
    },
  },
  emits: [
    'select-item',
    'print-manually',
  ],
  watch: {
    fetchParams: {
      handler() {
        this.fetchLabelArchive();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('labelArchive', [
      'getLabelArchive',
    ]),
    async fetchLabelArchive() {
      this.loading = true;
      const { data } = await this.getLabelArchive({
        params: {
          ...this.fetchParams,
        },
      });
      this.labelArchive = data.items;
      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="column">
    <div class="column-title">
      {{ title }}
    </div>

    <div class="line" />

    <div class="column-queue-length">
      <Loader v-if="loading" />
      <span v-else>
        {{ queueLength }}
      </span>
    </div>

    <div
      class="print-manually-button"
      @click.stop="$emit('print-manually')"
    >
      <i class="fas fa-print" />
      Drukuj ręcznie
    </div>

    <div class="line" />

    <div class="queue-heading">
      Kolejka

      <div class="clear-queue-button">
        wyczyść kolejkę
      </div>
    </div>

    <div class="line" />

    <Loader v-if="loading" />
    <div
      v-else
      class="queue-list-wrapper"
    >
      <CockpitMatryoshkaColumnQueueItem
        v-for="labelArchiveItem in filteredLabelArchive"
        :key="labelArchiveItem.id"
        :item="labelArchiveItem"
        :pallets-column="palletsColumn"
        @select-item="$emit('select-item', $event)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/vars.icss";

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 250px;
  max-height: 100%;
  max-height: max-content;
  background-color: white;
  border-radius: 9px;
  padding: 16px;

  .line {
    border-bottom: 1px solid #F0F1F3;
  }

  .column-title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 12px;
    padding-left: 1px;
  }

  .column-queue-length {
    font-size: 61px;
    line-height: 90px;
    font-weight: 500;
    text-align: center;
    margin-top: 0.5rem;
  }

  .print-manually-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    background-color: #EFEFEF;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 30px;
    width: 124px;
    margin: 0 auto;
    color: #7F7F7F;
    margin-bottom: 1.5rem;

    &:hover {
      background-color: #E0E0E0;
    }

    i {
      margin-right: 5px;
    }
  }

  .queue-heading {
    font-size: 14px;
    font-weight: 500;
    color: black;
    padding: 0.75rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .clear-queue-button {
      color: $logicprint5;
      font-weight: 400;
      font-size: 11px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .queue-list-wrapper {
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 12px;
    padding-right: 12px;
  }
}
</style>
