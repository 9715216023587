<template>
  <div class="modal-style">
    <div class="order-style">
      <OrderExecution
        :key="orderId"
        :order="order"
        :no-labeling-point="true"
      />
    </div>
    <div class="pt-4 box-style">
      <h5 style="text-align: center">
        <span class="font-weight-bold pl-3">
          {{ $t('order.runOrderOn') }}
        </span>
      </h5>
      <div
        v-if="!pending"
      >
        <div
          v-if="labelingPointsList.length"
          class="parent"
        >
          <div
            v-if="labelingPointsForOrder.length"
            v-tippy="{
              arrow: true,
              placement: 'bottom',
              trigger: blockedByRequestsLabelingPoints.length > 0 ? 'mouseenter' : ''
            }"
            class="px-3 all-start mb-4"
            :content="$t('request.pendingBlockingRequests')"
          >
            <button
              class="btn btn-primary btn-circle btn-xl"
              style="margin: 10px; width: 100px; height: 100px; border-radius: 60px;"
              :disabled="isBlockedAndBusyInAll"
              @click="startAll"
            >
              <i
                class="fa fa-play"
                style="font-size: 2.5em"
              />
              <br>
              <span class="button-text">
                {{ $t('general.all') }}
              </span>
            </button>
          </div>
          <div
            v-for="lp in labelingPointsForOrder"
            :key="lp.id"
            class="px-3 mb-4"
          >
            <div
              v-tippy="{
                arrow: true,
                placement: 'bottom',
                trigger: !busyLabelingPoints.includes(lp.id) && blockedByRequestsLabelingPoints.includes(lp.id)
                  ? 'mouseenter'
                  : ''
              }"
              :content="$t('request.pendingBlockingRequests')"
            >
              <button
                class="btn btn-success btn-circle btn-xl"
                style="margin: 10px; width: 100px; height: 100px; border-radius: 60px;"
                :disabled="busyLabelingPoints.includes(lp.id)
                  || blockedByRequestsLabelingPoints.includes(lp.id)"
                @click="start(lp.id)"
              >
                <i
                  class="fa fa-play"
                  style="font-size: 2.5em"
                />
                <br>
                <span class="button-text">
                  {{ lp.description }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="error"
          class="error-box"
        >
          <i class="icon fas fa-exclamation-circle" />
          <div class="message">
            <span class="font-weight-bold">
              {{ $t('general.error') }}:
            </span>
            <span>
              {{ error }}
            </span>
          </div>
        </div>
        <div class="text-center py-2 mx-2 mb-3">
          <div
            v-if="!isOrderMapped"
            class="info-box row"
          >
            <div>
              <i class="fas fa-info-circle mr-1" />
              <span class="font-weight-bold">
                {{ $t('general.info') }}:
              </span>
              <span>
                {{ $t('settings.checkMappedOrders', {value1: order.lineName, value2: order.type}) }}.
              </span>
            </div>
          </div>
          <div
            v-else-if="!labelingPointsForOrder.length"
            class="info-box row"
          >
            <div>
              <i class="fas fa-info-circle mr-1" />
              <span class="font-weight-bold">
                {{ $t('general.info') }}:
              </span>
              <span>
                {{ $t('settings.checkLabellingPoints', {value: order.lineName}) }}.
              </span>
            </div>
          </div>
          <div
            v-else-if="!labelingPointsList.length"
            class="info-style"
          >
            <span>
              {{ $t('settings.check') }}
            </span>
          </div>
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import loopRequest from '@core/mixins/loopRequest';
import OrderExecution from './OrderExecution';

export default {
  props: {
    orderId: Number,
    lineId: Number,
    order: Object,
  },
  data: () => ({
    where: [],
    labelingPointsForOrder: [],
    busyLabelingPoints: [],
    blockedByRequestsLabelingPoints: [],
    pending: false,
    error: null,
  }),
  components: {
    OrderExecution,
  },
  mixins: [loopRequest('getActiveRequests', 5000)],
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      mappings: state => state.settings.list || [],
      orderTypes: state => state.orderTypes.list,
      labelingPointsList: state => state.labelingPoints.list || [],
      orderTypeMappingsOnLabellingPoints: state => state.labelingPoints.data || [],
    }),
    isOrderMapped() {
      const orderTypeId = this.orderTypes?.find(o => o.name === this.order.type)?.id;

      const isOrderTypeMapped = this.orderTypeMappingsOnLabellingPoints
        ?.find(m => m.orderTypeIds.find(o => o === orderTypeId))
        && (!this.mappings?.find(m => m.lines.find(l => l.id === this.lineId))
        || this.labelingPointsForOrder?.length);

      return this.order.type ? isOrderTypeMapped : true;
    },
    isBlockedAndBusyInAll() {
      const labelingPointsIds = this.labelingPointsForOrder.map(x => x.id).sort((a, b) => a - b);
      const busyLP = [...new Set(this.busyLabelingPoints)] || [];
      const blockedLP = [...new Set(this.blockedByRequestsLabelingPoints)] || [];

      return labelingPointsIds.every(v => busyLP.includes(v))
        || labelingPointsIds.every(v => blockedLP.includes(v));
    },
  },
  methods: {
    ...mapActions([
      'startOrder',
      'startAllOrders',
      'getBlockingRequests',
      'getLineLabelingPoint',
      'getBusyLabelingPoints',
    ]),
    startAll() {
      if (this.orderId) {
        this.pending = true;
        this.error = null;
        this.startAllOrders({
          params: {
            orderId: this.orderId,
          },
        })
          .then(() => {
            this.$bvToast.toast(this.$t('order.start'), {
              title: this.$t('general.saved'),
              variant: 'success',
              autoHideDelay: this.timeOfNotification,
            });
            this.$emit('refresh');
            this.$emit('close');
          })
          .catch(({ response: { data } }) => {
            this.error = data.message;
          }).finally(() => {
            this.pending = false;
          });
      }
    },
    start(lpId) {
      if (this.orderId) {
        this.pending = true;
        this.error = null;
        this.startOrder({
          params: {
            orderId: this.orderId,
            query: {
              where: [lpId],
            },
          },
        })
          .then(() => {
            this.$bvToast.toast(this.$t('order.start'), {
              title: this.$t('general.saved'),
              variant: 'success',
              autoHideDelay: this.timeOfNotification,
            });
            this.$emit('refresh');
            this.$emit('close');
          }).catch(({ response: { data } }) => {
            this.error = data.message;
          }).finally(() => {
            this.pending = false;
          });
      }
    },
    getActiveRequests() {
      this.getBlockingRequests({
        params: {
          query: {
            labelingPointIds: [this.labelingPointsForOrder
              .filter(lp => lp.type === 'AUTO')
              .map(x => x.id)],
          },
        },
      })
        .then(({ data }) => {
          this.blockedByRequestsLabelingPoints = [...new Set(data
            .map(req => req.labellingPointId).sort((a, b) => a - b))];
        });
    },
  },
  created() {
    if (this.lineId) {
      this.pending = true;

      const p1 = this.getLineLabelingPoint({
        params: {
          lineId: this.lineId,
          query: {
            orderTypeName: this.order.type,
          },
        },
      }).then(({ data }) => {
        this.labelingPointsForOrder = data;
        this.getActiveRequests();
      }).catch(({ response: { data } }) => {
        this.$bvToast.toast(data.message, {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      });

      const p2 = this.getBusyLabelingPoints({
        params: {
          lineId: this.lineId,
          query: {
            orderId: this.orderId,
          },
        },
      }).then(({ data }) => {
        this.busyLabelingPoints = data.map(lp => lp.id).sort((a, b) => a - b);
      }).catch(({ response: { data } }) => {
        this.$bvToast.toast(data.message, {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      });

      Promise.all([p1, p2])
        .finally(() => {
          this.pending = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>

  .parent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .button-text {
    font-size: 1.1em;
    font-weight: 500;
  }

  .all-start {
    border-right: 1px solid;
    border-color: rgb(216, 212, 212) !important;
  }

  .info-box {
    padding: 10px;
    margin: 50px 25px;
    border-radius: 7px;
    background-color: #DDECFF;
    color: #3D69A3;
    flex-flow: row;
  }

  .error-box {
    display: flex;
    padding: 10px;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    background-color: #FFEFEE;
    color: #D70819;

    .icon {
      padding-top: 4px;
      width: 20px;
    }

    .message {
      width: 100%;
    }
  }

  .info-style {
    height: 200px;
    opacity: 60%;
    font-weight: bold;
    font-size: 1.2em;
    padding-top: 50px;
    text-align: center;
  }

  .order-style {
    padding-inline: 60px;
    padding-block: 20px
  }

  .modal-style {
    background-color: #F4F4F4;
    border-radius: 15px;
  }

</style>
