var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-style"},[_c('div',{staticClass:"order-style"},[_c('OrderExecution',{key:_vm.orderId,attrs:{"order":_vm.order,"no-labeling-point":true}})],1),_c('div',{staticClass:"pt-4 box-style"},[_c('h5',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-bold pl-3"},[_vm._v(" "+_vm._s(_vm.$t('order.runOrderOn'))+" ")])]),(!_vm.pending)?_c('div',[(_vm.labelingPointsList.length)?_c('div',{staticClass:"parent"},[(_vm.labelingPointsForOrder.length)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            arrow: true,
            placement: 'bottom',
            trigger: _vm.blockedByRequestsLabelingPoints.length > 0 ? 'mouseenter' : ''
          }),expression:"{\n            arrow: true,\n            placement: 'bottom',\n            trigger: blockedByRequestsLabelingPoints.length > 0 ? 'mouseenter' : ''\n          }"}],staticClass:"px-3 all-start mb-4",attrs:{"content":_vm.$t('request.pendingBlockingRequests')}},[_c('button',{staticClass:"btn btn-primary btn-circle btn-xl",staticStyle:{"margin":"10px","width":"100px","height":"100px","border-radius":"60px"},attrs:{"disabled":_vm.isBlockedAndBusyInAll},on:{"click":_vm.startAll}},[_c('i',{staticClass:"fa fa-play",staticStyle:{"font-size":"2.5em"}}),_c('br'),_c('span',{staticClass:"button-text"},[_vm._v(" "+_vm._s(_vm.$t('general.all'))+" ")])])]):_vm._e(),_vm._l((_vm.labelingPointsForOrder),function(lp){return _c('div',{key:lp.id,staticClass:"px-3 mb-4"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              placement: 'bottom',
              trigger: !_vm.busyLabelingPoints.includes(lp.id) && _vm.blockedByRequestsLabelingPoints.includes(lp.id)
                ? 'mouseenter'
                : ''
            }),expression:"{\n              arrow: true,\n              placement: 'bottom',\n              trigger: !busyLabelingPoints.includes(lp.id) && blockedByRequestsLabelingPoints.includes(lp.id)\n                ? 'mouseenter'\n                : ''\n            }"}],attrs:{"content":_vm.$t('request.pendingBlockingRequests')}},[_c('button',{staticClass:"btn btn-success btn-circle btn-xl",staticStyle:{"margin":"10px","width":"100px","height":"100px","border-radius":"60px"},attrs:{"disabled":_vm.busyLabelingPoints.includes(lp.id)
                || _vm.blockedByRequestsLabelingPoints.includes(lp.id)},on:{"click":function($event){return _vm.start(lp.id)}}},[_c('i',{staticClass:"fa fa-play",staticStyle:{"font-size":"2.5em"}}),_c('br'),_c('span',{staticClass:"button-text"},[_vm._v(" "+_vm._s(lp.description)+" ")])])])])})],2):_vm._e(),(_vm.error)?_c('div',{staticClass:"error-box"},[_c('i',{staticClass:"icon fas fa-exclamation-circle"}),_c('div',{staticClass:"message"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('general.error'))+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.error)+" ")])])]):_vm._e(),_c('div',{staticClass:"text-center py-2 mx-2 mb-3"},[(!_vm.isOrderMapped)?_c('div',{staticClass:"info-box row"},[_c('div',[_c('i',{staticClass:"fas fa-info-circle mr-1"}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('general.info'))+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('settings.checkMappedOrders', {value1: _vm.order.lineName, value2: _vm.order.type}))+". ")])])]):(!_vm.labelingPointsForOrder.length)?_c('div',{staticClass:"info-box row"},[_c('div',[_c('i',{staticClass:"fas fa-info-circle mr-1"}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('general.info'))+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('settings.checkLabellingPoints', {value: _vm.order.lineName}))+". ")])])]):(!_vm.labelingPointsList.length)?_c('div',{staticClass:"info-style"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('settings.check'))+" ")])]):_vm._e()])]):_c('Loader')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }