<script>
export default {
  name: 'CockpitMatryoshkaItemActionButtons',
  emits: [
    'open-operations',
    'delete',
  ],
};
</script>

<template>
  <div class="action-buttons is-placeholder">
    <BButton
      class="action-button"
      variant="primary"
      @click="$emit('open-operations')"
    >
      <i class="fas fa-list" />
      Operacje
    </BButton>

    <BButton
      class="action-button"
      @click="$emit('delete')"
    >
      <i class="fas fa-trash" />
      Usuń
    </BButton>
  </div>
</template>

<style scoped lang="scss">
.action-buttons {
  display: flex;
  gap: 10px;

  .action-button {
    padding: 4px 30px;
    font-size: 15px;

    i {
      margin-right: 4px;
    }
  }
}
</style>
