<template>
  <div class="mx-2">
    <div
      class="d-flex justify-content-between flex-wrap"
    >
      <span class="title">
        <i
          class="pr-1"
          :class="dictionary ? 'fas fa-pen' : 'fas fa-plus'"
        />
        {{ dictionary ? $t('dictionary.editDictionary') : $t('dictionary.addDictionary') }}
      </span>
    </div>
    <div class="my-3">
      <label>{{ $t('dictionary.dictionaryName') }}</label>
      <BFormInput
        v-model="dictionaryName"
        :disabled="!!dictionary"
        :placeholder="$t('dictionary.dictionaryName')"
        :state="(!dictionary && dictionaryName !== null ? true : null)
          && !dictionaryNameExists && !dictionaryNameTooLongError
          && !dictionaryNameIsRequiredError && !dictionaryNameIsInIncorrectFormatError"
      />
    </div>
    <hr>
    <div
      class="d-flex my-1"
    >
      <div class="flex-fill">
        <label>{{ $t('dictionary.key') }}</label>
      </div>
      <div class="flex-fill pr-2">
        <label>{{ $t('general.value') }}</label>
      </div>
    </div>
    <div
      ref="entriesContainer"
      style="max-height: 40vh; overflow-y: auto"
    >
      <div
        v-for="(entry, index) in entriesList"
        :key="entry.localIndex"
      >
        <DictionaryEntryEditor
          class="mb-1"
          :entry-key.sync="entry.key"
          :entry-value.sync="entry.value"
          :entry-errors.sync="entryErrors"
          :can-key-be-edited="entry.newlyCreated"
          :all-dictionary-entries="entriesList"
          @deleteEntry="deleteEntry(index)"
        />
      </div>
    </div>
    <div class="mt-3">
      <BButton
        size="sm"
        class="rounded-pill px-4"
        @click="addNewEntry"
      >
        <i class="fas fa-plus mr-1" /> {{ $t('template.addEntry') }}
      </BButton>
    </div>
    <Error
      v-if="displayedError"
      class="mt-1"
      :message="displayedError"
    />
    <SaveButton
      :pending="pending"
      :show-remove="!!dictionary"
      :disabled="!allDataValid"
      @save="createOrUpdate"
      @remove="remove"
      @cancel="$emit('close')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DictionaryEntryEditor from './DictionaryEntryEditor';

export default {
  props: {
    dictionary: Object,
  },
  data: () => ({
    pending: false,
    entryErrors: false,
    dictionaryName: null,
    entriesList: [],
  }),
  components: {
    DictionaryEntryEditor,
  },
  computed: {
    ...mapGetters(['maxInputLength']),
    ...mapState({
      dictionaries: state => state.dictionaries.list || [],
    }),
    allDataValid() {
      return !this.entryErrors
        && this.dictionaryName
        && this.entriesList.every(entry => entry.key)
        && !this.dictionaryNameTooLongError
        && !this.dictionaryNameIsRequiredError
        && !this.dictionaryNameExists
        && !this.dictionaryNameIsInIncorrectFormatError;
    },
    displayedError() {
      if (this.dictionaryNameTooLongError) return this.$t('error.nameIsTooLong');
      if (this.dictionaryNameIsRequiredError) return this.$t('error.nameIsRequired');
      if (this.dictionaryNameExists) return this.$t('error.nameAlreadyExists');
      if (this.dictionaryNameIsInIncorrectFormatError) return this.$t('error.nameIsInIncorrectFormat');
      return null;
    },
    dictionaryNameTooLongError() {
      return this.dictionaryName?.length >= this.maxInputLength ? true : null;
    },
    dictionaryNameIsInIncorrectFormatError() {
      return !/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(this.dictionaryName);
    },
    dictionaryNameIsRequiredError() {
      return this.dictionaryName?.length === 0;
    },
    dictionaryNameExists() {
      if (this.dictionary || !this.dictionaryName) return false;
      return this.dictionaries
        .filter(l => l.name?.toUpperCase() === this.dictionaryName?.toUpperCase())?.length >= 1;
    },
  },
  methods: {
    ...mapActions([
      'createDictionary',
      'updateDictionary',
      'deleteDictionary',
    ]),
    scrollToEntriesEnd() {
      const container = this.$refs.entriesContainer;
      container.scrollTop = container.scrollHeight;
    },
    addNewEntry() {
      this.entriesList = [
        ...this.entriesList,
        {
          key: null,
          value: null,
          newlyCreated: true,
          localIndex: this.entriesList.length > 0 ? Math.max(...this.entriesList.map(x => x.localIndex)) + 1 : 0,
        },
      ];
      setTimeout(() => {
        this.scrollToEntriesEnd();
      }, 1);
    },
    deleteEntry(index) {
      this.entriesList.splice(index, 1);
    },
    createOrUpdate() {
      if (this.dictionary) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      this.pending = true;
      this.createDictionary({
        data: {
          name: this.dictionaryName,
          entries: this.entriesList || [],
        },
      }).then(() => {
        this.$emit('successMsg', this.$t('dictionary.dictionaryCreated'));
        this.$emit('close');
      }).catch(({ response: { data } }) => {
        this.$emit('failedMsg', data?.message || this.$t('dictionary.dictionaryCreateFailed'));
      }).finally(() => {
        this.pending = false;
      });
    },
    update() {
      this.pending = true;
      this.updateDictionary({
        params: {
          id: this.dictionary.id,
        },
        data: {
          name: this.dictionaryName,
          entries: this.entriesList || [],
        },
      }).then(() => {
        this.$emit('successMsg', this.$t('dictionary.dictionaryUpdated'));
        this.$emit('close');
      }).catch(({ response: { data } }) => {
        this.$emit('failedMsg', data?.message || this.$t('dictionary.dictionaryUpdateFailed'));
      }).finally(() => {
        this.pending = false;
      });
    },
    remove() {
      this.pending = true;
      this.deleteDictionary({
        params: {
          id: this.dictionary.id,
        },
      }).then(() => {
        this.$emit('successMsg', this.$t('dictionary.dictionaryDeleted'));
        this.$emit('close');
      }).catch(({ response: { data } }) => {
        this.$emit('failedMsg', data?.message || this.$t('dictionary.dictionaryDeleteFailed'));
      }).finally(() => {
        this.pending = false;
      });
    },
  },
  created() {
    this.dictionaryName = this.dictionary?.name ?? null;
    this.entriesList = this.dictionary?.entries ?? [];
    this.entriesList = this.entriesList
      .map((a, index) => ({
        ...a,
        newlyCreated: false,
        localIndex: index,
      }));
    if (this.entriesList.length === 0) {
      this.addNewEntry();
    }
  },
};
</script>

<style lang="scss" scoped>

  label {
    font-weight: bold;
    font-size: 0.9em;
    margin-bottom: 2px;
  }

  .title {
    font-size: 1.2em;
    font-weight: 625;
  }

</style>
