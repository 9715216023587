<template>
  <div class="panel-body">
    <VueFormGenerator
      :schema="schema"
      :model="data"
      :options="formOptions"
      @model-updated="modelUpdated"
      @validated="onValidated"
    />
  </div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator';
import 'vue-form-generator/dist/vfg.css';

export default {
  props: {
    schema: Object,
    data: Object,
  },
  data() {
    return {
      formOptions: {
        validateAfterChanged: true,
        validateAsync: true,
      },
      valid: null,
    };
  },
  components: {
    VueFormGenerator: VueFormGenerator.component,
  },
  methods: {
    modelUpdated(data, schema) {
      this.$emit('updated:field', { name: schema, value: data });
    },
    onValidated(isValid) {
      if (isValid !== this.valid) {
        this.valid = isValid;
        this.$emit('updated:validation', isValid);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/vars.icss";

.vue-form-generator {
  font-size: 0.8em;
  .field-radios {
    margin-top: 5px;

    .field-wrap {
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
  .field-checkbox {
    display: flex;
    position: relative;
    margin-bottom: 30px;

    .field-wrap {
      margin-top: 4px;
      position: relative;
    }
    .hint {
      position: absolute;
      top: 15px;
    }
  }
  .field-checklist {
    .form-control {
      border: none;
      box-shadow: none;
    }
    .hint {
      margin-top: 0;
    }
  }
  .form-group {
    margin-top: 8px;
    label {
      margin-bottom: 5px;
    }
  }
  .form-control {
    border-radius: 10px;
  }
  .hint {
    font-style: normal;
    font-size: 14px;
    margin-top: 8px;
  }
  .field-label {
    label {
      margin-left: -12px;
    }
  }
}
</style>
