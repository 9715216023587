<template>
  <div
    class="d-flex flex-column"
    style="max-height: 100%;
    margin-top: -20px;
    padding-top: 20px;"
  >
    <div class="d-flex justify-content-between align-items-center">
      <span style="font-size: 1em; font-weight: 500">
        <i class="icon-orders pr-1" />
        {{ $t('order.fields') }}
        <button
          v-if="editable && inEdit === false"
          style="margin-left: 5px"
          class="btn btn-secondary icon-btn btn-xs"
          @click="inEdit = true"
        >
          <i class="ion ion-md-create" />
        </button>
        <button
          v-if="inEdit === true"
          style="margin-left: 5px"
          class="btn btn-danger icon-btn btn-xs"
          @click="inEdit = false"
        >
          <i class="ion ion-md-close" />
        </button>
        <button
          v-if="editable && inEdit"
          style="margin-left: 5px"
          class="btn btn-primary icon-btn btn-xs"
          @click="saveValues()"
        >
          <i class="ion ion-md-save" />
        </button>
      </span>
    </div>
    <div
      v-if="!pending"
      style="overflow: auto; position: relative"
      class="py-2 pl-2"
    >
      <div v-if="!inEdit">
        <div
          v-for="prop in propertyTypes"
          :key="prop.id"
          style="line-height: 1.1; padding-top: 2px;"
          class="d-flex align-items-center"
        >
          <span
            class="prop-name"
          >
            {{ prop.name }}
          </span>
          <span
            v-if="prop.description && !hasValues"
            class="small"
          >
            - {{ prop.description }}
          </span>
          <span
            v-else-if="hasValues"
            class="text-ilabo"
            style="text-shadow: 0 0 4px rgba(100, 100, 100, 0.3);"
          >: {{ propertyValue(prop.name) }}
          </span>
        </div>
      </div>
      <div v-else>
        <div
          v-for="prop in localValues"
          :key="prop.id"
          style="line-height: 1.1; padding-top: 2px;"
          class="d-flex align-items-center"
        >
          <BForm inline>
            <label class="mr-sm-2 prop-name">{{ prop.name }}:</label>
            <BFormInput
              v-if="!isDictionary(prop.name)"
              v-model="prop.value"
              inline
              class="small"
              size="sm"
              style="max-height: 17px; font-size: x-small"
              label-align-sm="left"
            />
            <BoxWithPopup
              v-else
              :label="$t('dictionary.dictionaryEntry')"
              icon="ion ion-ios-bookmark"
              :single="true"
              :searchable="['id', 'value']"
              :value="prop.value"
              :list="getDictionaryEntriesForBox(prop.name)"
              placement="left"
              @update:value="setDictionaryValue(prop, $event)"
            />
          </BForm>
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import BoxWithPopup from '@core/components/BoxWithPopup';
import { propertyValueTypes } from '@/utils/dictionary';

export default {
  props: {
    orderId: {
      type: Number,
    },
    orderExecutionId: {
      type: Number,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
    },
    showEdit: {
      type: Boolean,
    },
  },
  data: () => ({
    pending: false,
    inEdit: false,
    localValues: [],
    propertyValueTypes,
  }),
  components: {
    BoxWithPopup,
  },
  computed: {
    ...mapState({
      propertyTypes: state => state.orders.properties || [],
      propertiesValue: state => state.orders.propertiesValues || [],
      dictionaries: state => state.dictionaries.list || [],
    }),
    ...mapGetters(['timeOfNotification']),
    hasValues() {
      return this.propertiesValue.length > 0;
    },
  },
  watch: {
    orderId() {
      this.debouncedRefreshPropertiesValues();
    },
    orderExecutionId() {
      this.debouncedRefreshPropertiesValues();
    },
  },
  methods: {
    ...mapActions([
      'getOrdersProperties',
      'getOrderPropertiesValues',
      'setOrderPropertiesValues',
    ]),
    propertyValue(name) {
      const p = this.propertiesValue
        .find(x => x.name.toLowerCase() === name.toLowerCase());

      return p ? p.value : '';
    },
    isDictionary(propertyName) {
      const propertyType = this.propertyTypes.find(propType => propType.name === propertyName);
      return propertyType?.valueType === propertyValueTypes.dictionary;
    },
    setDictionaryValue(prop, value) {
      const localPropertyValue = this.localValues.find(val => val.name === prop.name);
      localPropertyValue.value = value;
    },
    getDictionaryEntriesForBox(propertyName) {
      const propertyType = this.propertyTypes.find(propType => propType.name === propertyName);
      const dictionaryEntries = this.dictionaries.find(dict => dict.id === propertyType?.dictionaryId)?.entries || [];
      return dictionaryEntries.map(x => ({
        id: x.key,
        name: x.key,
        type: x.value,
      }));
    },
    saveValues() {
      this.pending = true;
      this.setOrderPropertiesValues({
        params: {
          orderId: this.orderId,
        },
        data: this.localValues,
      }).then(() => {
        this.$bvToast.toast('', {
          title: this.$t('general.saved'),
          variant: 'success',
          autoHideDelay: this.timeOfNotification,
        });
        this.inEdit = false;
      })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.message, {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .finally(() => {
          this.pending = false;
        });
    },
    debouncedRefreshPropertiesValues() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.pending = true;
      this.timeout = setTimeout(() => {
        this.getOrderPropertiesValues({
          params: {
            orderId: this.orderId,
            query: {
              orderExecutionId: this.orderExecutionId,
            },
          },
        }).then(({ data }) => {
          this.localValues = data;
        }).finally(() => {
          this.pending = false;
        });
      }, 100);
    },
  },
  created() {
    this.inEdit = this.showEdit;
    this.getOrdersProperties();
    this.debouncedRefreshPropertiesValues();
  },

};
</script>

<style lang="scss" scoped>
  .prop-name {
    color: rgba(#333, 0.8);
  }
</style>
