<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MarkingStatusBadge',
  props: {
    status: String,
  },
  computed: {
    ...mapGetters('labelArchive', [
      'labelArchiveStatuses',
    ]),
    statusColor() {
      const status = this.labelArchiveStatuses.find(s => s.id === this.status);
      return status?.color || '#171717';
    },
    statusIcon() {
      const status = this.labelArchiveStatuses.find(s => s.id === this.status);
      return status?.icon || 'fas fa-question';
    },
  },
};
</script>

<template>
  <div
    class="status-badge"
    :style="{
      backgroundColor: statusColor,
    }"
  >
    <i :class="statusIcon" />
    {{ status || '---' }}
  </div>
</template>

<style scoped lang="scss">
.status-badge {
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  margin-bottom: 6px;

  i {
    margin-right: 4px;
  }
}
</style>
