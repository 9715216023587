import axios from 'axios';
import { paramsSerializer, setHeaders } from './axiosSetup';

export const lp5 = axios.create({ paramsSerializer });

export const init = store => {
  setHeaders(lp5, store);
};

export default lp5;
