<template>
  <div class="d-flex flex-column">
    <LModal
      :show.sync="form"
    >
      <PrinterEditor
        v-bind.sync="selected"
        @successMsg="showSuccessMsg($event)"
        @failedMsg="showFailedMsg($event)"
        @close="form = false"
      />
    </LModal>

    <div class="row justify-content-between mx-0">
      <div class="ml-0 row">
        <h3
          class="mr-3"
          style="font-size: 1.75em"
        >
          <i class="icon-print-request pr-2" />
          {{ $t('printer.printers') }}
        </h3>
        <div class="align-self-center mx-3">
          <button
            class="btn btn-primary btn-sm"
            :disabled="waitWithCreatingNextRequest"
            @click="createRequest"
          >
            <BSpinner
              v-if="waitWithCreatingNextRequest"
              variant="light"
              small
              class="pr-2"
            />
            <i
              v-else
              class="ion ion-md-refresh pr-2"
            />
            {{ $t('printer.refreshPrinters') }}
          </button>
        </div>
      </div>
      <div class="align-self-center pr-2">
        <button
          class="btn btn-primary btn-sm"
          @click="form = true, setSelected(null)"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('printer.addPrinter') }}
        </button>
      </div>
    </div>

    <div
      style="min-height: 70vh; overflow:auto"
      class="box-style flex-fill mt-2"
    >
      <div class="form-group py-1">
        <SearchInput @input="search = $event" />
      </div>
      <SimpleTable
        class="table-style"
        :items="searchedPrinters"
        :fields="columns"
        @row-clicked="setSelected($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import SimpleTable from '@/components/tables/SimpleTable';
import createSearch from '@core/utils/search';
import PrinterEditor from './PrinterEditor';

export default {
  data() {
    return {
      form: false,
      selected: null,
      search: null,
      pendingReload: false,
      waitWithCreatingNextRequest: false,
    };
  },
  components: {
    PrinterEditor,
    SimpleTable,
  },
  computed: {
    ...mapState({
      printers: state => state.printers.list || [],
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    ...mapGetters(['timeOfNotification']),
    formattedPrinters() {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();

      return this.printers.map(p => ({
        ...p,
        modified: moment.utc(p.modified).add(plantUtcOffset, 'minutes').format('ll LTS'),
        created: moment.utc(p.created).add(plantUtcOffset, 'minutes').format('ll LTS'),
      }));
    },
    searchedPrinters() {
      const search = createSearch(this.search);
      return this.formattedPrinters
        .filter(({ name, description }) => search(name) || search(description));
    },
    columns() {
      return [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('general.name'),
          class: 'text-left',
          sortable: true,
        },
        {
          key: 'ip',
          label: 'IP',
          class: 'text-left',
        },
        {
          key: 'printerType',
          label: this.$t('printer.type'),
          class: 'text-left',
          sortable: true,
        },
        {
          key: 'description',
          label: this.$t('general.description'),
          class: 'text-left',
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      'getPrinters',
      'createReloadPrintersRequest',
    ]),
    showSuccessMsg(msg) {
      this.$bvToast.toast(msg || this.$t('general.saved'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('request.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
    onGetPrintersResponse() {
      this.refresh();
    },
    createRequest() {
      this.waitWithCreatingNextRequest = true;
      const communicationRefId = uuidv4();
      this.createReloadPrintersRequest({
        params: {
          query: {
            communicationRefId,
          },
        },
      });
      setTimeout(() => {
        this.waitWithCreatingNextRequest = false;
      }, 10 * 1000);
    },
    refresh() {
      setTimeout(() => {
        this.refreshPrinters();
      }, 100);
    },
    refreshPrinters() {
      this.pendingReload = true;
      this.getPrinters()
        .finally(() => {
          this.pendingReload = false;
        });
    },
    setSelected(event) {
      this.selected = event;
      this.form = true;
    },
  },
  mounted() {
    this.$startDataTransferHub();
    this.$dataTransferHub.$on('get-printers-returned', this.onGetPrintersResponse);
  },
  created() {
    this.getPrinters();
  },
};
</script>

<style lang="scss" scoped>
  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 30px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .search {
    border-radius: .25rem;
  }

</style>
