<template>
  <div>
    <ProSelect
      v-model="lineId"
      show-all-option
      dropdown-width="175px"
      :hide-dropdown="selected ? true : false"
      :options="lineOptions"
      :label="$t('line.lines')"
      :disabled="pending"
      class="pr-2 mb-3"
      :class="pending ? 'disabled-content' : ''"
      :box-height="boxHeight"
      icon-class="fas fa-pallet"
      icon-color-class="app-color"
      dynamic-showing-list
      :light="light"
      @input="filterUpdated"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    light: Boolean,
    boxHeight: {
      type: String,
      default: '32px',
    },
    pending: {
      type: Boolean,
      default: false,
    },
    selected: Object,
  },
  data() {
    return {
      lineId: null,
    };
  },
  computed: {
    ...mapGetters(['selectedFilterLineId', 'selectedFilterAreaId']),
    ...mapState({
      lines: state => state.lines.list || [],
    }),
    linesFilteredBySelectedArea() {
      return this.lines.filter(l => !this.selectedFilterAreaId
        || l.areaId === this.selectedFilterAreaId);
    },
    lineOptions() {
      return this.linesFilteredBySelectedArea
        .map(l => ({
          ...l,
          label: l.name,
          value: l.id,
          iconClass: 'fas fa-layer-group app-color',
        })) || [];
    },
  },
  watch: {
    selectedFilterLineId() {
      this.lineId = this.selectedFilterLineId;
    },
  },
  methods: {
    filterUpdated() {
      this.$store.commit('setFilterLineId', this.lineId);
    },
  },
  created() {
    this.lineId = this.selectedFilterLineId;
  },
};
</script>

<style lang="scss" scoped>

</style>
