<template>
  <div class="d-flex flex-column">
    <div class="info-box">
      PAGE UNDER DEVELOPMENT - DISPLAY ONLY
    </div>
    <div class="row justify-content-between mx-0">
      <h3 style="font-size: 1.75em">
        <i class="fas fa-check pr-2" />
        {{ $t('settings.setupIssues') }}
      </h3>
    </div>

    <div
      style="max-height: 70vh; overflow:auto"
      class="box-style flex-fill mt-2"
    >
      <div
        v-for="category in categories"
        :key="category.severity"
      >
        <div
          class="font-weight-bold h4 py-2"
          :style="{ color: category.color }"
        >
          <i :class="`${category.icon} pr-2`" />
          {{ category.severity }}
        </div>

        <BListGroup class="mb-3">
          <BListGroupItem
            v-for="(issue, index) in category.issues"
            :key="index"
            variant="danger"
          >
            <div
              class="row"
              style="font-size: 0.9rem"
            >
              <div
                class="col-6 col-xl-2"
              >
                <span class="font-weight-bold">
                  {{ issue.name }}
                </span>
              </div>
              <div
                class="col-6 col-xl-4"
              >
                {{ issue.type }}
              </div>
              <div
                class="col-9 col-xl-5"
                style="line-height: 1.2"
              >
                {{ issue.message }}
              </div>
            </div>
          </BListGroupItem>
        </BListGroup>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    categories() {
      return [
        {
          severity: 'Critical',
          color: this.$color('pink'),
          icon: 'fas fa-radiation',
        },
        {
          severity: 'Error',
          color: this.$color('red'),
          icon: 'fas fa-exclamation-circle',
        },
        {
          severity: 'Warning',
          color: this.$color('orange'),
          icon: 'fas fa-wrench',
        },
      ].map(x => ({
        ...x,
        issues: [
          {
            name: 'Line 1',
            type: 'IllegalDefaultUnit',
            message: 'Design performance for Machine Capper is missing',
          },
          {
            name: 'Line 2',
            type: 'IllegalDefaultUnit',
            message: 'Machine FillerF4 should not have a DefaultUnit set',
          },
          {
            name: 'Line 3',
            type: 'IllegalDefaultUnit',
            message: 'Missing short performance for machine machine1',
          },
        ],
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 40px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .info-box {
    padding: 10px;
    margin: 10px 15px;
    border-radius: 7px;
    background-color: #DDECFF;
    color: #3D69A3;
    flex-flow: row;
  }

</style>
