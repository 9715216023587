<template>
  <div>
    <GlobalNotification />
    <Layout v-if="!pagePending && accessToRoutedView">
      <RouterView />
    </Layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Layout from '@/layout/Layout';
import GlobalNotification from '@/components/notifications/GlobalNotification';

export default {
  data: () => ({
    pagePending: true,
    accessToRoutedView: false,
  }),
  components: {
    Layout,
    GlobalNotification,
  },
  computed: {
    ...mapGetters(['permissionsPromise', 'canAny', 'permittedRoutes']),
    userHasAccessToApp() {
      return this.permittedRoutes.length > 0;
    },
  },
  watch: {
    $route() {
      this.checkAccessToRoutedView();
    },
  },
  methods: {
    ...mapActions([
      'getLines',
      'getAreas',
      'getLabelingPoints',
      'getSkuPropertyTypes',
      'getMeUserData',
      'getGlobalSettings',
      'getOrderTypes',
      'getDictionaries',
      'getOrdersBaseProps',
      'getOrdersProperties',
    ]),
    checkAccessToRoutedView() {
      this.accessToRoutedView = false;
      if (!this.$route.meta || !this.$route.meta.permittedFor) {
        this.accessToRoutedView = true;
        return;
      }

      this.permissionsPromise.then(() => {
        const accessToRoute = this.$route.meta.permittedFor.some(perm => this.canAny(perm));
        if (accessToRoute) {
          this.accessToRoutedView = true;
          return;
        }

        const firstPageWithAccess = this.permittedRoutes[0];
        if (firstPageWithAccess) {
          this.$router.push(firstPageWithAccess.route);
          this.accessToRoutedView = true;
        } else {
          this.$router.push('/noAccess');
        }
      });
    },
  },
  created() {
    this.checkAccessToRoutedView();
    this.permissionsPromise.then(() => {
      if (!this.userHasAccessToApp) {
        this.pagePending = false;
        return;
      }
      Promise.all([
        this.getLines(),
        this.getAreas(),
        this.getLabelingPoints(),
        this.getSkuPropertyTypes(),
        this.getGlobalSettings(),
        this.getOrderTypes(),
        this.getDictionaries(),
        this.getOrdersBaseProps(),
        this.getOrdersProperties(),
      ]).then(() => {
        this.pagePending = false;
      });
    });
  },
};
</script>

<style>

</style>
