<script>
import CockpitMatryoshkaItemAuditList
from '@/components/cockpit/matryoshka/audit/CockpitMatryoshkaItemAuditList.vue';

export default {
  name: 'CockpitMatryoshkaItemAuditTab',
  components: { CockpitMatryoshkaItemAuditList },
};
</script>

<template>
  <div class="is-placeholder">
    <CockpitMatryoshkaItemAuditList />
  </div>
</template>

<style scoped lang="scss">

</style>
