<template>
  <div>
    <h5
      class="mb-0 mx-3"
    >
      <span v-if="!lineId">
        <i class="ion ion-md-add pr-2" />
        {{ $t('line.addLine') }}
      </span>

      <span v-else>
        <i class="fas fa-pen pr-2" />
        {{ $t('line.editLine') }}
      </span>
    </h5>

    <div class="mx-3">
      <div
        v-if="useAreas"
        class="my-3"
      >
        <label class="mb-1 table-title">
          {{ $t('area.selectArea') }}
        </label>
        <BFormSelect
          v-model="area"
          :options="mappedAreas"
          :state="area && !id ? true : null"
        />
      </div>
      <div
        v-if="lineId"
        class="my-3"
      >
        <label class="mb-1 table-title">
          {{ $t('general.id') }}
        </label>
        <BFormInput
          v-model="lineId"
          disabled
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('general.name') }}
        </label>
        <BFormInput
          v-model="localName"
          :placeholder="$t('general.name') +'...'"
          :disabled="!!lineId"
          :state="stateOfNameField"
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('general.code') }}
        </label>
        <BFormInput
          v-model="localCode"
          :placeholder="$t('general.code') +'...'"
          :state="stateOfCodeField"
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          <span>
            {{ $t('general.description') }}
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </label>
        <BFormInput
          id="textarea"
          v-model="localDescription"
          :placeholder="$t('general.description') +'...'"
          rows="1"
          :state="((localDescription && !id ? localDescription.length : null)
            && !lineDescError) || (!lineDescError && id?.length)"
        />
      </div>
      <div class="my-3">
        <label
          class="table-title"
        >
          <span>
            {{ $t('line.orderStartValidationRule') }}
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </label>
        <LineRuleEditor
          v-if="!validationRulePending"
          :value.sync="validationRuleBody"
        />
        <Loader v-else />
      </div>
      <hr v-if="lineId">
      <div
        v-if="lineId"
        class="d-flex row my-3"
      >
        <div class="col-6">
          <label class="mb-1 table-title">
            {{ $t('general.created') }}
          </label>
          <BFormInput
            disabled
            :value="created"
          />
        </div>
        <div class="col-6">
          <label class="mb-1 table-title">
            {{ $t('general.modified') }}
          </label>
          <BFormInput
            disabled
            :value="modified"
          />
        </div>
      </div>
      <hr>
    </div>

    <div>
      <Error
        v-if="displayedError"
        class="mb-2 ml-3"
        :message="displayedError"
      />
      <SaveButton
        class="save-buttons"
        :pending="pending"
        :show-remove="!!lineId"
        :disabled="!id && !errorCheck"
        @cancel="$emit('close')"
        @save="save()"
        @remove="remove()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import LineRuleEditor from './LineRuleEditor';

export default {
  props: {
    id: Number,
    name: String,
    code: String,
    description: String,
    modified: String,
    created: String,
    areaId: Number,
  },
  data: () => ({
    pending: false,
    localName: '',
    localCode: null,
    localDescription: '',
    area: null,
    validationRuleId: null,
    validationRuleBody: null,
    validationRuleName: 'Start Order Validation Rule',
    validationRulePending: false,
    lineId: null,
    error: null,
  }),
  components: {
    LineRuleEditor,
  },
  computed: {
    ...mapState({
      useAreas: state => state.settings.data?.useAreas || false,
      lines: state => state.lines.list || [],
      areas: state => state.areas.list || [],
    }),
    ...mapGetters([
      'timeOfNotification',
      'maxInputLength',
      'maxDescriptionLength',
    ]),
    mappedAreas() {
      return this.areas.map(a => ({
        ...a,
        value: a.id,
        text: a.name,
      }));
    },
    lineNameExist() {
      if (!this.localName) return false;
      return this.lines.find(l => l.name?.toUpperCase() === this.localName?.toUpperCase()) ? true : null;
    },
    lineCodeExists() {
      if (!this.localCode) return false;
      return this.lines.find(l => l.id !== this.id && l.code?.toUpperCase() === this.localCode?.toUpperCase())
        ? true
        : null;
    },
    stateOfNameField() {
      if (this.lineNameExist && !this.id) return false;
      if (this.lineNameExist && this.id && this.name !== this.localName) return false;
      if (this.lineNameError) return false;
      return this.localName && !this.id ? true : null;
    },
    stateOfCodeField() {
      if (this.lineCodeError || this.lineCodeExists) return false;
      return this.localCode && !this.id ? true : null;
    },
    lineNameError() {
      if (!this.localName) return false;
      return this.localName.length >= this.maxInputLength;
    },
    lineCodeError() {
      if (!this.localCode) return false;
      return this.localCode.length >= this.maxInputLength;
    },
    lineDescError() {
      if (!this.localDescription) return false;
      return this.localDescription.length >= this.maxDescriptionLength;
    },
    displayedError() {
      if (this.lineNameExist && !this.id) return this.$t('error.nameAlreadyExists');
      if (this.lineNameExist && this.id && this.name !== this.localName) return this.$t('error.nameAlreadyExists');
      if (this.lineDescError) return this.$t('error.descriptionIsTooLong');
      if (this.lineCodeExists) return this.$t('error.codeAlreadyExists');
      if (this.lineCodeError) return this.$t('error.codeIsTooLong');
      if (this.lineNameError) return this.$t('error.nameIsTooLong');
      return this.error;
    },
    errorCheck() {
      if (
        this.localName
        && (this.useAreas ? this.area : true)
        && !this.lineNameExist
        && !this.lineDescError
        && !this.lineCodeExists
        && !this.lineCodeError
        && !this.lineNameError
      ) return true;
      return false;
    },
  },
  methods: {
    ...mapActions([
      'getLines',
      'getAreas',
      'createLine',
      'updateLine',
      'deleteLine',
      'getStartOrderOnLineRules',
      'createStartOrderOnLineRules',
      'updateStartOrderOnLineRules',
    ]),
    getRule() {
      this.validationRulePending = true;
      this.getStartOrderOnLineRules({
        params: {
          lineId: this.id,
        },
      }).then(({ data }) => {
        if (data != null && data.length > 0) {
          this.validationRuleBody = data[0].rule;
          this.validationRuleId = data[0].id;
        }
      }).finally(() => {
        this.validationRulePending = false;
      });
    },
    async save() {
      try {
        if (this.lineId != null) {
          await this.update();
        } else {
          await this.create();
        }

        if (this.validationRuleId != null) {
          await this.updateValidationRule();
        } else {
          await this.createValidationRule();
        }

        this.$emit('successMsg', this.$t('general.saved'));
        this.$emit('close');
        this.request();
      } catch ({ response: { data } }) {
        this.error = data?.message ?? data?.detail ?? this.$t('general.error');
      }
    },
    async create() {
      await this.createLine({
        data: {
          name: this.localName,
          code: this.localCode,
          description: this.localDescription,
          areaId: this.area,
        },
      }).then(({ data }) => {
        this.lineId = data.id;
      });
    },
    async update() {
      await this.updateLine({
        params: {
          id: this.lineId,
        },
        data: {
          name: this.localName,
          code: this.localCode ? this.localCode : null,
          description: this.localDescription,
          areaId: this.area,
        },
      });
    },
    remove() {
      this.deleteLine({
        params: {
          id: this.lineId,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('form.lineSuccessfullyRemoved'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    async createValidationRule() {
      await this.createStartOrderOnLineRules({
        data: {
          lineId: this.lineId,
          rule: this.validationRuleBody,
          name: this.validationRuleName,
        },
      });
    },
    async updateValidationRule() {
      await this.updateStartOrderOnLineRules({
        params: {
          ruleId: this.validationRuleId,
        },
        data: {
          lineId: this.lineId,
          rule: this.validationRuleBody,
          name: this.validationRuleName,
        },
      });
    },
    request() {
      this.getLines();
    },
  },
  created() {
    this.getAreas();
    this.getRule();
    if (this.id) {
      this.localName = this.name;
      this.localCode = this.code;
      this.localDescription = this.description;
      this.area = this.areaId;
      this.lineId = this.id;
    }
  },
};
</script>

<style lang="scss" scoped>

  .table-title {
    font-weight: 700;
    font-size: 14px;
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
  }

</style>
