<template>
  <div class="sku-details-offcanvas">
    <div
      class="d-flex justify-content-between align-items-center mb-2"
      :style="editForm ? 'padding-bottom: 40px' : ''"
    >
      <h3
        class="align-self-center mb-0"
        style="font-size: 1.7em"
      >
        <i
          class="pr-2"
          :class="editForm ? 'fas fa-pen' : 'fas fa-info-circle'"
        />
        <span>
          {{ editForm ? $t('sku.editSku') : $t('sku.skuDetails') }}
        </span>
      </h3>
      <div class="h3 mb-0 text-black-50">
        <i
          class="fas fa-times cursor-pointer p-1"
          @click="$emit('close'); tab = 0"
        />
      </div>
    </div>

    <BTabs
      v-model="tab"
      pills
      content-class="pt-2"
    >
      <BTab
        key="general"
        :title="$t('general.general')"
        :title-item-class="editForm ? 'd-none' : ''"
      >
        <Loader
          v-if="pendingSku
            || pendingUpdateSku
            || pendingSkuPropertyTypeLabelUsages"
        />
        <GeneralSkuTab
          v-else
          :edit-form="editForm"
          :sku="sku"
          @update:editForm="editForm = $event"
          @update:description="sku.description = $event"
          @update:property="propertyUpdated($event)"
        />
      </BTab>

      <BTab
        key="restrictions"
        :title="$t('labelTemplates')"
        :title-item-class="editForm ? 'd-none' : ''"
      >
        <Loader
          v-if="pendingSku
            || pendingUpdateSku
            || pendingSkuPropertyTypeLabelUsages"
        />
        <SkuLabelTemplatesTab
          v-else
          :sku="sku"
        />
      </BTab>
    </BTabs>
    <div
      v-if="tab === 0"
      class="offcanvas-footer d-flex align-items-center justify-content-center mt-2"
    >
      <div v-if="editForm">
        <BButton
          pill
          class="px-5 mr-3"
          size="sm"
          @click="cancel()"
        >
          <i
            class="ion ion-md-close"
          />
          {{ $t('general.cancel') }}
        </BButton>
        <BButton
          pill
          class="px-5 mr-3"
          size="sm"
          variant="primary"
          :disabled="!dataValid"
          @click="save()"
        >
          <i
            class="fas fa-check pr-2"
          />
          {{ $t('general.save') }}
        </BButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GeneralSkuTab from './tabs/GeneralSkuTab';
import SkuLabelTemplatesTab from './tabs/SkuLabelTemplatesTab';

export default {
  props: {
    skuId: Number,
  },
  data: () => ({
    tab: 0,
    editForm: false,
    pendingSku: false,
    pendingSkuPropertyTypeLabelUsages: false,
    pendingUpdateSku: false,
    sku: null,
  }),
  components: {
    GeneralSkuTab,
    SkuLabelTemplatesTab,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    editHeight() {
      return this.editForm || this.tab === 1 ? '240px' : '180px';
    },
    dataValid() {
      return this.sku.skuProperties.every(prop => prop.isValid === undefined
        || prop.isValid === true);
    },
  },
  methods: {
    ...mapActions([
      'getSku',
      'getSkuPropertyTypeLabelUsages',
      'updateSku',
    ]),
    propertyUpdated(property) {
      const existingProperty = this.sku.skuProperties
        .find(prop => prop.skuPropertyTypeId === property.skuPropertyTypeId);

      if (existingProperty) {
        existingProperty.value = property.value?.toString();
        existingProperty.editable = property.editable;
        existingProperty.isValid = property.isValid;
        return;
      }

      const newProperty = {
        skuId: this.sku.id,
        skuPropertyTypeId: property.skuPropertyTypeId,
        value: property.value?.toString(),
        editable: property.editable,
        isValid: property.isValid,
      };

      this.sku.skuProperties.push(newProperty);
    },
    save() {
      this.pendingUpdateSku = true;
      this.updateSku({
        params: {
          skuId: this.skuId,
        },
        data: {
          externalId: this.sku.externalId,
          name: this.sku.name,
          description: this.sku.description,
          skuProperties: this.sku.skuProperties,
        },
      }).then(() => {
        this.$bvToast.toast(this.$t('general.saved'), {
          title: this.$t('general.saved'),
          variant: 'success',
          autoHideDelay: this.timeOfNotification,
        });
      }).catch(({ response: { data } }) => {
        this.$bvToast.toast(data.message, {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      }).finally(() => {
        this.pendingUpdateSku = false;
        this.editForm = false;
      });
    },
    cancel() {
      this.requestSku();
      this.editForm = false;
    },
    requestSku() {
      this.pendingSku = true;
      this.getSku({
        params: {
          skuId: this.skuId,
        },
      }).then(({ data }) => {
        this.sku = data;
      }).finally(() => {
        this.pendingSku = false;
      });
    },
    requestSkuPropertyTypeLabelUsages() {
      this.pendingSkuPropertyTypeLabelUsages = true;
      this.getSkuPropertyTypeLabelUsages()
        .finally(() => {
          this.pendingSkuPropertyTypeLabelUsages = false;
        });
    },
  },
  created() {
    this.requestSku();
    this.requestSkuPropertyTypeLabelUsages();
  },
};
</script>

<style lang="scss" scoped>
  .sku-details-offcanvas {
    position: relative;
    font-size: 12px;
    font-weight: 600;

    .offcanvas-footer {
      border-top: 1px solid #ECECEC;
      min-height: 60px;
      max-height: 60px;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    &:deep() {
      .tabs {
        .tab-content {
          height: calc(100vh - v-bind(editHeight));
          overflow-y: auto;
        }

        .nav {
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          border-bottom: 1px solid #ECECEC;
          margin-bottom: 1rem;
          font-size: 14px;

          .nav-item {
            width: 25%;
            text-align: center;

            .nav-link {
              background: transparent;
              color: rgba(#000, 0.5);
              border-radius: 0;
              margin-bottom: -3px;

              &.active {
                color: black;
                font-weight: 600;
                border-bottom: 5px solid rgba(118, 176, 51, 1);
              }
            }
          }
        }
      }
    }
  }
</style>
