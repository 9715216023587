<script>

export default {
  name: 'CockpitMatryoshkaItemAuditListItem',
  props: {
    user: String,
    action: String,
    date: String,
  },
};
</script>

<template>
  <div class="audit-list-item">
    <div class="user-avatar">
      <i class="fas fa-user-circle" />
    </div>

    <div>
      <div>
        <span class="font-weight-bold">
          {{ user }}
        </span>

        {{ action }}
      </div>
      <div class="date">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.audit-list-item {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;

  .user-avatar {
    font-size: 26px;
    color: #D4D4D4;
    margin-right: 10px;
  }

  .date {
    font-size: 10px;
    color: #686868;
  }
}
</style>
