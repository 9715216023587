<template>
  <div>
    <div class="mb-1">
      <small class="text-secondary">
        ({{ $t('mapper.useSkuOrOrderDot') }})
      </small>
    </div>
    <div
      v-if="error"
      class="text-danger small my-3"
      style="white-space: pre-line; max-height: 10vh; overflow: auto"
    >
      {{ error }}
    </div>
    <div
      class="my-3"
    >
      <CodeEditor
        class="custom-editor"
        :value.sync="code"
        :sku-fields="propertyTypes"
        :orders-fileds="orderProperties"
        :base-properties="baseProperties"
      />
    </div>
    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-secondary mx-2 btn-sm"
        style="width: 120px"
        :disabled="pending"
        @click="$emit('close')"
      >
        <i class="ion ion-ios-arrow-round-back pr-2" />
        {{ $t('general.cancel') }}
      </button>
      <button
        class="btn btn-primary mx-2 btn-sm"
        style="width: 120px"
        :disabled="pending"
        @click="saveCode"
      >
        <span>
          <BSpinner
            v-if="pending"
            variant="light"
            small
            class="pr-2"
          />
          <i
            v-else
            class="ion ion-md-checkmark pr-2"
          />
          {{ $t('general.save') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CodeEditor from './CodeEditor';

export default {
  props: {
    value: String,
    fieldId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    code: '',
    error: null,
    pending: false,
    baseProperties: null,
  }),
  components: {
    CodeEditor,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      propertyTypes: state => state.skuProperties.types || [],
      ordersPropertyTypes: state => state.orders.properties || [],
    }),
    orderProperties() {
      const basePropType = { name: 'BaseProperties', valueType: 'String' };
      return this.ordersPropertyTypes.concat(basePropType);
    },
  },
  watch: {
    value(v) {
      this.code = v;
    },
    fieldId() {
      if (!this.value) {
        this.code = '';
      }
    },
  },
  methods: {
    ...mapActions([
      'setLabelPropertyRule',
      'getOrdersBaseProps',
    ]),
    saveCode() {
      this.error = null;
      this.pending = true;
      this.setLabelPropertyRule({
        params: {
          propId: this.fieldId,
        },
        data: {
          rule: this.code,
        },
      })
        .then(() => {
          this.$bvToast.toast(this.$t('mapper.ruleSavedComment'), {
            title: this.$t('mapper.ruleSaved'),
            variant: 'success',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .catch(({ response }) => {
          this.error = response.data?.detail
            ?? response.data?.message;
          if (!this.error?.length) {
            this.error = this.$t('mapper.ruleNotSavedComment');
          }
        })
        .finally(() => {
          this.$emit('reload');
          this.pending = false;
        });
    },
    getBaseProperties() {
      this.getOrdersBaseProps()
        .then(({ data }) => {
          this.baseProperties = data;
        });
    },
  },
  created() {
    this.code = this.value;
    this.getBaseProperties();
  },
};
</script>

<style lang="scss" scoped>

  .custom-editor::v-deep .CodeMirror {
    height: calc(60vh - 125px);
  }

</style>
