<template>
  <div>
    <h5
      class="mb-0 mx-3"
    >
      <span v-if="!fieldId">
        <i class="ion ion-md-add pr-2" />
        {{ $t('order.addField') }}
      </span>

      <span v-else>
        <i class="fas fa-pen pr-2" />
        {{ $t('order.editField') }}
      </span>
    </h5>

    <div class="mx-3">
      <div
        v-if="fieldId"
        class="my-3"
      >
        <label class="mb-1 table-title">
          {{ $t('general.id') }}
        </label>
        <BFormInput
          v-model="fieldId"
          disabled
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('general.name') }}
        </label>
        <BFormInput
          v-model="localName"
          :placeholder="$t('general.name') +'...'"
          :disabled="!!fieldId"
          :state="stateOfNameField"
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          <span>
            {{ $t('general.description') }}
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </label>
        <BFormInput
          id="textarea"
          v-model="localDescription"
          :placeholder="$t('general.description') +'...'"
          rows="1"
          :state="((localDescription && !id ? localDescription.length : null)
            && !orderFieldDescError) || (!orderFieldDescError && id)"
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('general.valueType') }}
        </label>
        <BFormSelect
          v-model="localValueType"
          :options="valueTypes"
          :state="localValueType && !id ? true : null"
        />
      </div>
      <div
        v-if="localValueType === propertyValueTypes.dictionary"
        class="my-3"
      >
        <label class="mb-1 table-title">
          {{ $t('dictionary.dictionaryName') }}
          <span
            v-if="valueType
              && valueType === propertyValueTypes.dictionary
              && localDictionaryId !== dictionaryId"
            class="text-danger"
          >
            - {{ $t('mapper.thisCanBreakTheRules') }}
          </span>
        </label>
        <BFormSelect
          v-model="localDictionaryId"
          :options="dictionariesToDisplay"
          value-field="id"
          text-field="name"
          :placeholder="$t('dictionary.addDictionary')"
          :state="localDictionaryId && !id ? true : null"
        />
      </div>
      <hr v-if="fieldId">
      <div
        v-if="fieldId"
        class="d-flex row my-3"
      >
        <div class="col-6">
          <label class="mb-1 table-title">
            {{ $t('general.created') }}
          </label>
          <BFormInput
            disabled
            :value="created"
          />
        </div>
        <div class="col-6">
          <label class="mb-1 table-title">
            {{ $t('general.modified') }}
          </label>
          <BFormInput
            disabled
            :value="modified"
          />
        </div>
      </div>
      <hr>
    </div>

    <div>
      <Error
        v-if="displayedError"
        class="mb-2 ml-3"
        :message="displayedError"
      />
      <SaveButton
        class="save-buttons"
        :pending="pending"
        :show-remove="!!fieldId"
        :disabled="(!id && !errorCheck) || displayedError"
        @cancel="$emit('close')"
        @save="!fieldId ? addProperty() : updateProperty()"
        @remove="removeProperty()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { propertyValueTypes } from '@/utils/dictionary';

export default {
  props: {
    id: Number,
    name: String,
    description: String,
    valueType: String,
    dictionaryId: Number,
    type: String,
    modified: String,
    created: String,
  },
  data: () => ({
    localName: '',
    localValueType: '',
    localDescription: '',
    localDictionaryId: null,
    pending: false,
    fieldId: null,
    propertyValueTypes,
  }),
  computed: {
    ...mapState({
      properties: state => state.orders.properties || [],
      valueTypes: state => (!state.discovery.dict ? [] : state.discovery.dict.PropertyValueTypes),
      dictionaries: state => state.dictionaries.list || [],
    }),
    ...mapGetters([
      'timeOfNotification',
      'maxInputLength',
      'maxDescriptionLength',
    ]),
    orderFieldNameExist() {
      if (!this.localName) return false;
      return this.properties.find(l => l.name?.toUpperCase() === this.localName?.toUpperCase()) ? true : null;
    },
    stateOfNameField() {
      if (this.orderFieldNameExist && !this.id) return false;
      if (this.orderFieldNameExist && this.id && this.name !== this.localName) return false;
      if (this.orderFieldNameError) return false;
      return this.localName && !this.id ? true : null;
    },
    orderFieldNameError() {
      if (!this.localName) return false;
      return this.localName.length >= this.maxInputLength;
    },
    orderFieldDescError() {
      if (!this.localDescription) return false;
      return this.localDescription.length >= this.maxDescriptionLength;
    },
    displayedError() {
      if (this.orderFieldNameExist && !this.id) {
        return this.$t('error.nameAlreadyExists');
      }
      if (this.orderFieldNameExist && this.id && this.name !== this.localName) {
        return this.$t('error.nameAlreadyExists');
      }
      if (this.orderFieldDescError) {
        return this.$t('error.descriptionIsTooLong');
      }
      return this.orderFieldNameError ? this.$t('error.nameIsTooLong') : null;
    },
    errorCheck() {
      if (
        this.localName
        && this.localValueType
        && !this.orderFieldNameExist
        && !this.orderFieldNameError
        && !this.orderFieldDescError
        && (this.localValueType !== propertyValueTypes.dictionary || this.localDictionaryId)
      ) return true;
      return false;
    },
    dictionariesToDisplay() {
      return this.dictionaries.slice().sort((a, b) => (a.name).localeCompare(b.name));
    },
  },
  methods: {
    ...mapActions([
      'getOrdersProperties',
      'deleteOrderProperty',
      'createOrderProperty',
      'updateOrderProperty',
    ]),
    addProperty() {
      this.pending = true;
      this.createOrderProperty({
        data: {
          name: this.localName,
          type: this.localValueType,
          description: this.localDescription,
          dictionaryId: this.localValueType === propertyValueTypes.dictionary ? this.localDictionaryId : null,
        },
      })
        .then(() => {
          this.request();
          this.$emit('successMsg', this.$t('form.orderFieldSuccessfullyCreated'));
          this.$emit('close');
        })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        }).finally(() => {
          this.pending = false;
        });
    },
    removeProperty() {
      this.deleteOrderProperty({
        params: {
          name: this.name,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('form.orderFieldSuccessfullyRemoved'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    updateProperty() {
      this.updateOrderProperty({
        params: {
          name: this.name,
        },
        data: {
          description: this.localDescription,
          valueType: this.localValueType,
          dictionaryId: this.localValueType === propertyValueTypes.dictionary ? this.localDictionaryId : null,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('general.modified'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    request() {
      this.getOrdersProperties();
    },
  },
  created() {
    if (this.id) {
      this.localName = this.name;
      this.localDescription = this.description;
      this.localValueType = this.valueType;
      this.localType = this.type;
      this.fieldId = this.id;
      this.localDictionaryId = this.dictionaryId;
    }
  },
};
</script>

<style lang="scss" scoped>

  .table-title {
    font-weight: 700;
    font-size: 14px;
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
  }

</style>
