<template>
  <div class="modal-style">
    <div class="order-style">
      <OrderExecution
        :key="orderId"
        :order="order"
        :no-labeling-point="true"
      />
    </div>
    <div class="pt-4 box-style">
      <h5 style="text-align: center">
        <span class="font-weight-bold pl-3">
          {{ $t('order.stopOrderOn') }}
        </span>
      </h5>
      <div>
        <div
          v-if="labelingPointsList.length"
          class="parent"
        >
          <div
            v-if="labelingPointsForOrder.length"
            v-tippy="{
              arrow: true,
              placement: 'bottom',
              trigger: blockedByRequestsLabelingPoints.length > 0 ? 'mouseenter' : ''
            }"
            class="px-3 all-stop mb-4"
            :content="$t('request.pendingBlockingRequests')"
          >
            <button
              class="btn btn-secondary btn-circle btn-xl"
              style="margin: 10px; width: 100px; height: 100px; border-radius: 60px;"
              :disabled="inProgressLabelingPoints?.length === 0 || blockedByRequestsLabelingPoints.length > 0"
              @click="stopOrderButton(inProgressLabelingPoints)"
            >
              <i
                class="fa fa-pause"
                style="font-size: 2.5em"
              />
              <br>
              <span class="button-text">
                {{ $t('general.all') }}
              </span>
            </button>
          </div>
          <div
            v-for="lp in labelingPointsForOrder"
            :key="lp.id"
            class="px-3 mb-4"
          >
            <div
              v-tippy="{
                arrow: true,
                placement: 'bottom',
                trigger: inProgressLabelingPoints.includes(lp.id) && blockedByRequestsLabelingPoints.includes(lp.id)
                  ? 'mouseenter'
                  : ''
              }"
              :content="$t('request.pendingBlockingRequests')"
            >
              <button
                class="btn btn-secondary btn-circle btn-xl"
                style="margin: 10px; width: 100px; height: 100px; border-radius: 60px;"
                :disabled="!inProgressLabelingPoints.includes(lp.id) || blockedByRequestsLabelingPoints.includes(lp.id)"
                @click="stopOrderButton(lp.id)"
              >
                <i
                  class="fa fa-pause"
                  style="font-size: 2.5em"
                />
                <br>
                <span class="button-text">
                  {{ lp.description }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="text-center py-2 mx-2 mb-3">
          <div
            v-if="!isOrderMapped"
            class="info-box row"
          >
            <div>
              <i class="fas fa-info-circle mr-1" />
              <span class="font-weight-bold">
                {{ $t('general.info') }}:
              </span>
              <span>
                {{ $t('settings.checkMappedOrders', {value1: order.lineName, value2: order.type}) }}.
              </span>
            </div>
          </div>
          <div
            v-else-if="!labelingPointsForOrder.length"
            class="info-box row"
          >
            <div>
              <i class="fas fa-info-circle mr-1" />
              <span class="font-weight-bold">
                {{ $t('general.info') }}:
              </span>
              <span>
                {{ $t('settings.checkLabellingPoints', {value: order.lineName}) }}.
              </span>
            </div>
          </div>
          <div
            v-else-if="!labelingPointsList.length"
            class="info-style"
          >
            <span>
              {{ $t('settings.check') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import loopRequest from '@core/mixins/loopRequest';
import OrderExecution from './OrderExecution';

export default {
  props: {
    orderId: Number,
    lineId: Number,
    order: Object,
  },
  data: () => ({
    where: [],
    labelingPointsForOrder: [],
    inProgressLabelingPoints: [],
    blockedByRequestsLabelingPoints: [],
    orderTypeMappings: null,
  }),
  components: {
    OrderExecution,
  },
  mixins: [loopRequest('getActiveRequestsOnActiveLabelingPoints', 5000)],
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      mappings: state => state.settings.list || [],
      orderTypes: state => state.orderTypes.list,
      orderTypeMappingsOnLabellingPoints: state => state.labelingPoints.data || [],
      labelingPointsList: state => state.labelingPoints.list || [],
    }),
    isOrderMapped() {
      const orderTypeId = this.orderTypes?.find(o => o.name === this.order.type)?.id;

      const isOrderTypeMapped = this.orderTypeMappingsOnLabellingPoints
        ?.find(m => m.orderTypeIds.find(o => o === orderTypeId))
        && (!this.mappings?.find(m => m.lines.find(l => l.id === this.lineId))
        || this.labelingPointsForOrder?.length);

      return this.order.type ? isOrderTypeMapped : true;
    },
  },
  methods: {
    ...mapActions([
      'stopOrder',
      'getBlockingRequests',
      'getLineLabelingPoint',
      'getInProgressOrderLabelingPoints',
    ]),
    stopOrderButton(lpId) {
      this.$bvModal.msgBoxConfirm(this.$t('general.areYouSure'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        okTitle: this.$t('general.yes'),
        cancelTitle: this.$t('general.no'),
        centered: true,
      })
        .then(value => {
          if (value) {
            this.stopOrder({
              params: {
                orderId: this.orderId,
                query: {
                  where: [lpId],
                },
              },
            })
              .then(() => {
                this.$bvToast.toast(this.$t('order.stop'), {
                  title: this.$t('general.saved'),
                  variant: 'success',
                  autoHideDelay: this.timeOfNotification,
                });
                this.$emit('refresh');
              })
              .catch(({ response: { data } }) => {
                this.$bvToast.toast(data.message, {
                  title: this.$t('general.error'),
                  variant: 'danger',
                  autoHideDelay: this.timeOfNotification,
                });
              });
            this.$emit('refresh');
            this.$emit('close');
          }
        });
    },
    getActiveRequestsOnActiveLabelingPoints() {
      if (!this.inProgressLabelingPoints
          || this.inProgressLabelingPoints.length === 0) {
        this.blockedByRequestsLabelingPoints = [];
        return;
      }
      this.getBlockingRequests({
        params: {
          query: {
            labelingPointIds: this.inProgressLabelingPoints,
          },
        },
      })
        .then(({ data }) => {
          this.blockedByRequestsLabelingPoints = [...new Set(data
            .map(req => req.labellingPointId))];
        });
    },
  },
  created() {
    if (this.lineId) {
      this.getLineLabelingPoint({
        params: {
          lineId: this.lineId,
          query: {
            orderTypeName: this.order.type,
          },
        },
      }).then(({ data }) => {
        this.labelingPointsForOrder = data;
      }).catch(({ response: { data } }) => {
        this.$bvToast.toast(data.message, {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      });

      this.getInProgressOrderLabelingPoints({
        params: {
          orderId: this.orderId,
        },
      }).then(({ data }) => {
        this.inProgressLabelingPoints = data.map(lp => lp.id);
        this.getActiveRequestsOnActiveLabelingPoints();
      }).catch(({ response: { data } }) => {
        this.$bvToast.toast(data.message, {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
  .parent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .button-text {
    font-size: 1.1em;
    font-weight: 500;
  }

  .info-style {
    height: 200px;
    opacity: 60%;
    font-weight: bold;
    font-size: 1.2em;
    padding-top: 50px;
    text-align: center;
  }

  .order-style {
    padding-inline: 60px;
    padding-block: 20px
  }

  .all-stop {
    border-right: 1px solid;
    border-color: rgb(216, 212, 212) !important;
  }

  .modal-style {
    background-color: #F4F4F4;
    border-radius: 15px;
  }

</style>
