import Vapi from 'vuex-rest-api';
import axios from '../api/axios';

const store = new Vapi({
  axios,
  state: {},
})
  .get({
    action: 'getUser',
    path: ({ userId }) => `user/${userId}`,
  })
  .getStore();

export default store;
