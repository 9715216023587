import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getLines',
    property: 'list',
    path: () => '/lines',
  })
  .get({
    action: 'getLineLabelingPoint',
    path: ({ lineId }) => `/labelingpoints/line/${lineId}`,
  })
  .post({
    action: 'createLine',
    path: () => '/Line',
  })
  .put({
    action: 'updateLine',
    path: ({ id }) => `/line/${id}`,
  })
  .delete({
    action: 'deleteLine',
    path: ({ id }) => `/line/${id}`,
  })
  .getStore();

store.getters = {
  linesFromArea: s => areaId => {
    if (!areaId) return s.list;
    return s.list.filter(l => areaId === l.areaId);
  },
};

export default store;
