import Vapi from 'vuex-rest-api';
import moment from 'moment';
import axios from '@/api/axios';
import lang from '@/lang';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getRequests',
    property: 'data',
    path: () => '/edge/requests',
  })
  .get({
    action: 'getBlockingRequests',
    path: () => '/edge/requests/blocking',
  })
  .get({
    action: 'createGetLabelPreviewRequestForPrintRequest',
    path: ({ prId }) => `/labeling/preview/printrequest/${prId}`,
  })
  .put({
    action: 'resendRequest',
    path: ({ requestId }) => `/edge/request/${requestId}/resend`,
  })
  .put({
    action: 'changeRequestStatusToFailed',
    path: ({ requestId }) => `/edge/request/${requestId}/fail`,
  })
  .getStore();

store.getters = {
  printRequests(state, getters, rootState) {
    if (!rootState.labels.templates
              || !rootState.lines.list
              || !rootState.labelingPoints.list
              || !state.data.list) return [];

    return state.data.list.map(pr => {
      const line = rootState.lines.list.find(l => l.id === pr.lineId);
      const labelingPoint = rootState.labelingPoints.list.find(lp => lp.id === pr.labelingPointId);
      const label = rootState.labels.templates.find(l => l.id === pr.labelId);

      return {
        ...pr,
        labelName: label ? label.name : '',
        lineName: line ? line.name : '',
        labelingPointName: labelingPoint ? labelingPoint.name : '',
        startDate: moment.utc(pr.startDate).local().format('ll LTS'),
        endDate: pr.endDate ? moment.utc(pr.endDate).local().format('ll LTS') : '',
      };
    });
  },
  requestsStatuses: () => [
    {
      status: 'SCHEDULED',
      icon: 'fas fa-check',
      text: `${lang.t('printRequest.status.SCHEDULED')}`,
      color: '#FFAE48',
    },
    {
      status: 'INPROGRESS',
      icon: 'fas fa-play',
      text: `${lang.t('printRequest.status.INPROGRESS')}`,
      color: '#3E8DC0',
    },
    {
      status: 'INPROGRESSWARMINGUP',
      icon: 'fas fa-angles-up',
      text: `${lang.t('printRequest.status.INPROGRESSWARMINGUP')}`,
      color: '#FCBA03',
    },
    {
      status: 'FAILED',
      icon: 'fas fa-exclamation-triangle',
      text: `${lang.t('printRequest.status.FAILED')}`,
      color: '#ff7448',
    },
    {
      status: 'COMPLETED',
      icon: 'fas fa-check',
      text: `${lang.t('printRequest.status.COMPLETED')}`,
      color: '#82AC48',
    },
  ],
  requestsTypes: () => [
    {
      type: 'ClearPrinter',
      icon: 'fas fa-broom',
      text: `${lang.t('request.types.clear')}`,
      color: '#FFCC0B',
      previewAvailable: false,
    },
    {
      type: 'Print',
      icon: 'fas fa-print',
      text: `${lang.t('request.types.print')}`,
      color: '#5886C1',
      previewAvailable: true,
    },
    {
      type: 'Finish',
      icon: 'fas fa-flag-checkered',
      text: `${lang.t('request.types.finish')}`,
      color: '#2BAF9D',
      previewAvailable: false,
    },
    {
      type: 'GetReport',
      icon: 'fas fa-file',
      text: `${lang.t('request.types.getReport')}`,
      color: '#A985DE',
      previewAvailable: false,
    },
  ],
};

export default store;
