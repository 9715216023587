<template>
  <div
    class="broadcast-box my-2"
  >
    <div>
      <div>
        <div class="d-flex">
          <div class="col-1 mt-2 pt-1">
            <div
              class="circle-box position-relative"
            />
            <div
              v-if="orderExecutionsInTime.length > 0"
              class="nav-style"
            />
          </div>

          <div class="d-flex col-10 pr-0 justify-content-between">
            <div
              style="text-align: left"
            >
              <span class="title-style">
                {{ labelingPointName || ' ' }}
              </span>
              <div
                v-tippy
                style="font-size: 0.9em"
                :content="$t('date.print')"
              >
                <span style="color: #797979; font-weight: 400">
                  {{ formatData(status.status === 'ACTIVE'
                    ? currentStateOrderExecution.startDate : currentStateOrderExecution.endDate) || '-' }}
                </span>
              </div>
            </div>

            <div
              class="pb-3 px-0 mt-2"
              style="align-self: center; text-align: left; font-size: 1.25em"
            >
              <div
                :style="{ 'background-color': status?.color || 'green' }"
                class="badge badge-danger text-white"
              >
                <div
                  class="status-text-style px-1"
                >
                  <i
                    :class="status?.icon || ''"
                    class="pr-1"
                  />
                  {{ status?.name || '-' }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="orderExecutionsInTime.length > 0"
            class="col-1"
            style="align-self: center"
          >
            <div
              class="broadcast-style pr-3"
              @click="openHistory = !openHistory"
            >
              <i
                :class="openHistory ? 'ion ion-ios-arrow-up' : 'ion ion-ios-arrow-down'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="openHistory">
      <div
        v-for="(o, index) in orderExecutionsInTime"
        :key="o.id"
        class="justify-content-between"
      >
        <div
          v-if="o.endDate && (index > 0 || status.status !== 'INACTIVE')"
          class="d-flex"
        >
          <div
            class="col-1 px-3"
            style="margin-left: -1px"
          >
            <div
              class="circle-box position-relative"
            />
            <div
              class="nav-style"
            />
          </div>
          <div
            class="d-flex col-10 pr-0 justify-content-between"
            style="margin-top: -2px"
          >
            <div
              style="text-align: right"
            >
              <div
                v-tippy
                style="font-size: 0.9em"
                :content="$t('date.print')"
              >
                <span style="color: #797979; font-weight: 400">
                  {{ formatData(o.endDate) || '-' }}
                </span>
              </div>
            </div>

            <div
              class="px-0"
              style="text-align: left; font-size: 1.25em"
            >
              <div
                :style="{ 'background-color': rightStatus('INACTIVE')?.color || 'green' }"
                class="badge badge-danger text-white"
              >
                <div
                  class="status-text-style px-1"
                >
                  <i
                    :class="rightStatus('INACTIVE')?.icon || ''"
                    class="pr-1"
                  />
                  {{ rightStatus('INACTIVE')?.name || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="o.startDate"
          class="d-flex"
        >
          <div
            class="col-1 px-3"
            style="margin-left: -1px"
          >
            <div
              class="circle-box position-relative"
            />
            <div
              v-if="index !== (orderExecutionsInTime.length - 1)"
              class="nav-style"
            />
          </div>
          <div
            class="d-flex col-10 pr-0 justify-content-between"
            style="margin-top: -2px"
          >
            <div
              style="text-align: right"
            >
              <div
                v-tippy
                style="font-size: 0.9em"
                :content="$t('date.print')"
              >
                <span style="color: #797979; font-weight: 400">
                  {{ formatData(o.startDate) || '-' }}
                </span>
              </div>
            </div>

            <div
              class="px-0"
              style="text-align: left; font-size: 1.25em"
            >
              <div
                :style="{ 'background-color': rightStatus('ACTIVE')?.color || 'green' }"
                class="badge badge-danger text-white"
              >
                <div
                  class="status-text-style px-1"
                >
                  <i
                    :class="rightStatus('ACTIVE')?.icon || ''"
                    class="pr-1"
                  />
                  {{ rightStatus('ACTIVE')?.name || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    orderExecutions: Array,
  },
  data: () => ({
    openHistory: false,
  }),
  computed: {
    ...mapGetters(['orderExecutionStatuses']),
    ...mapState({
      labelingPoints: state => state.labelingPoints.list || [],
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    labelingPointName() {
      const lp = this.labelingPoints.find(x => x.id === this.currentStateOrderExecution.labelingPointId);
      return lp ? lp.description : '';
    },
    currentStateOrderExecution() {
      if (this.orderExecutions.find(o => !o.endDate)) {
        return this.orderExecutions.find(o => !o.endDate);
      }
      return this.orderExecutions[this.orderExecutions.length - 1];
    },
    orderExecutionsInTime() {
      return this.orderExecutions.filter(o => o.endDate).reverse();
    },
    status() {
      const statuses = this.orderExecutionStatuses.map(x => ({ ...x, name: x.name.toUpperCase() }));
      if (this.currentStateOrderExecution.endDate) {
        return statuses.find(x => x.status.toUpperCase() === 'INACTIVE');
      }
      return statuses.find(x => x.status.toUpperCase() === 'ACTIVE');
    },
  },
  methods: {
    formatData(date) {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return date ? moment.utc(date).add(plantUtcOffset, 'minutes').format('DD MMM YYYY, HH:mm') : '-';
    },
    rightStatus(status) {
      const statuses = this.orderExecutionStatuses.map(x => ({ ...x, name: x.name.toUpperCase() }));
      if (status === 'INACTIVE') {
        return statuses.find(x => x.status.toUpperCase() === 'INACTIVE');
      }
      return statuses.find(x => x.status.toUpperCase() === 'ACTIVE');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

  .broadcast-box {
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #F0F1F3;
  }

  .broadcast-style {
    align-self: center;
    font-size: 0.65em;
    font-size: 1.5em;
    opacity: 0.40;

    &:hover {
        cursor: pointer;
    }
  }

  .circle-box {
    margin-right: 7px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    opacity: 0.8;
    background-color: #D9D9D9;
  }

  .nav-style {
    border-left: 2px solid;
    height: 25px;
    margin-left: 5.00px;
    opacity: 0.8;
    color: #D9D9D9;
  }

</style>
