import { Buffer } from 'buffer';

export function convertPreviewResultToBlob(preview) {
  const byteCharacters = Buffer.from(preview, 'base64').toString('binary');
  if (byteCharacters.length !== 0) {
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Blob([new Uint8Array(byteNumbers)], { type: 'image/png' });
  }
  return null;
}

export default convertPreviewResultToBlob;
