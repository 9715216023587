import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getLabelTemplates',
    property: 'templates',
    path: () => '/labels',
  })
  .get({
    action: 'getLabelBySkuId',
    path: ({ skuId }) => `/label/sku/${skuId}`,
  })
  .get({
    action: 'getLabelTemplate',
    path: ({ labelId }) => `/label/${labelId}`,
  })
  .get({
    action: 'createGetLabelPreviewRequest',
    path: ({ skuId, labelId }) => `/labeling/preview/${skuId}/${labelId}`,
  })
  .get({
    action: 'createGetLabelPreviewTemplateRequest',
    path: ({ labelId }) => `/labeling/preview/template/${labelId}`,
  })
  .put({
    action: 'setLabelCulture',
    path: ({ labelId }) => `label/${labelId}/culture`,
    onSuccess: (s, { data }) => {
      const t = s.templates.find(x => x.id === data.id);
      if (t) {
        t.culture = data.culture;
      }
    },
  })
  .post({
    action: 'createGetLabelsRequest',
    path: 'labeling/reload',
  })
  .post({
    action: 'importLabelsFromModeller',
    path: '/labels/modeller/import',
  })
  .get({
    action: 'getLabelsSkusMappings',
    property: 'mappings',
    path: () => '/skulabelmappings',
  })
  .post({
    action: 'getLabelMappingsStates',
    property: 'states',
    path: () => '/skulabelmapping/mappingstates',
  })
  .get({
    action: 'getSelectedLabelMappings',
    path: ({ selectedId }) => `/skulabelmapping/${selectedId}`,
  })
  .get({
    action: 'getSkusLabelsLists',
    path: () => '/skulabelmapping/skusLabels',
  })
  .put({
    action: 'putLabelsMapping',
    path: () => '/skulabelmapping/bulk',
  })
  .put({
    action: 'addLabelMapping',
    path: ({ skuId, labelId }) => `skulabelmapping/add/sku/${skuId}/label/${labelId}`,
    onSuccess: (s, { data }) => {
      s.mappings.push(data);
    },
  })
  .delete({
    action: 'removeLabelMapping',
    path: ({ skuLabelMappingId }) => `skulabelmapping/remove/${skuLabelMappingId}`,
    onSuccess: (s, _, a, { params }) => {
      s.mappings = s.mappings.filter(map => map.id !== params.skuLabelMappingId);
    },
  })
  .put({
    action: 'setLabelMappings',
    path: '/skulabelmapping',
    onSuccess: (s, { data }, a, { data: requestData }) => {
      s.mappings = s.mappings
        .filter(map => !requestData.skuIds.some(skuId => skuId === map.skuId))
        .concat(data);
    },
  })
  .delete({
    action: 'deleteLabelTemplates',
    path: () => '/labels',
  })
  .put({
    action: 'restoreLabelTemplates',
    path: () => '/labels/restore',
  })
  .put({
    action: 'updateLabelTemplate',
    path: ({ labelId }) => `/label/${labelId}/update`,
  })
  .put({
    action: 'cloneLabelTemplates',
    path: ({ labelId }) => `/label/${labelId}/clone`,
  })
  .post({
    action: 'createLabelTemplate',
    path: '/label',
  })
  .getStore();

export default store;
