import { render, staticRenderFns } from "./LabelingLevelsSelector.vue?vue&type=template&id=4d081ae6&scoped=true"
import script from "./LabelingLevelsSelector.vue?vue&type=script&lang=js"
export * from "./LabelingLevelsSelector.vue?vue&type=script&lang=js"
import style0 from "./LabelingLevelsSelector.vue?vue&type=style&index=0&id=4d081ae6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d081ae6",
  null
  
)

export default component.exports