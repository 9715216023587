export const blobToJson = async blob => {
  try {
    const resText = await new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.addEventListener('abort', reject);
      reader.addEventListener('error', reject);
      reader.addEventListener('loadend', () => {
        resolve(reader.result);
      });
      reader.readAsText(blob);
    });

    return JSON.parse(resText);
  } catch (err) {
    //
  }
  return '';
};

export const blobToUrl = blob => new Promise((resolve, reject) => {
  const reader = new window.FileReader();
  reader.readAsDataURL(blob);
  reader.addEventListener('abort', reject);
  reader.addEventListener('error', reject);
  reader.onload = () => {
    resolve(reader.result);
  };
});

export default {};
