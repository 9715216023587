<script>
export default {
  name: 'OrderProgressBar',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="d-flex align-items-center w-100">
    <div class="progress-bar">
      <div
        class="progress-bar-fill"
        :style="{
          width: `${(value / total || 1) * 100}%`,
        }"
      />
    </div>

    <div class="progress-label">
      {{ value }}/{{ total }} {{ unit }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.progress-bar {
  width: 100%;
  height: 8px;
  background-color: #F0F1F3;
  border-radius: 4px;
  overflow: hidden;

  .progress-bar-fill {
    width: 50%;
    height: 100%;
    background-color: #E9A043;
  }
}

.progress-label {
  font-size: 11px;
  margin-left: 8px;
  white-space: nowrap;
  color: #7E7E7E;
}
</style>
