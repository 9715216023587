<script>
import CockpitMatryoshkaItemHistoryList
from '@/components/cockpit/matryoshka/history/CockpitMatryoshkaItemHistoryList.vue';

export default {
  name: 'CockpitMatryoshkaItemHistoryTab',
  props: {
    labelArchiveId: Number,
  },
  components: {
    CockpitMatryoshkaItemHistoryList,
  },
};
</script>

<template>
  <div>
    <CockpitMatryoshkaItemHistoryList
      :label-archive-id="labelArchiveId"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
