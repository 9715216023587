<template>
  <div class="col-12 pt-2">
    <div>
      <Loader v-if="pendingHistory" />
      <OrderHistoryBox
        v-for="o in history"
        v-else
        :key="o[0].id"
        :order-executions="o"
      />
    </div>

    <div
      v-if="pendingHistory"
      class="text-center mt-5"
    >
      <BSpinner
        variant="secondary"
        large
      />
    </div>

    <div
      v-if="orderHistory.length === 0"
      class="text-center mt-5 text-black-50"
    >
      <h2 class="fas fa-ban" />
      <p class="mb-0">
        {{ $t('form.noData') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import OrderHistoryBox from './OrderHistoryBox';

export default {
  props: {
    selected: Object,
  },
  data: () => ({
    pendingHistory: false,
    orderHistory: [],
    history: [],
  }),
  components: {
    OrderHistoryBox,
  },
  watch: {
    selected: {
      deep: true,
      handler(value) {
        if (value) {
          this.getHistory();
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'getOrderDetails',
    ]),
    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    },
    historicOrders() {
      this.history = this.groupBy(this.orderHistory, 'labelingPointId') || null;
    },
    getHistory() {
      if (!this.selected?.id) {
        return;
      }

      this.pendingHistory = true;

      this.getOrderDetails({
        params: {
          orderId: this.selected?.id,
        },
      }).then(({ data }) => {
        this.orderHistory = data;
        this.historicOrders();
      })
        .finally(() => {
          this.pendingHistory = false;
        });
    },
  },
  created() {
    this.getHistory();
  },
};
</script>

<style lang="scss" scoped>

  hr {
    margin-top: 1px;
    margin-bottom: 1px;
  }

</style>
