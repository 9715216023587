<template>
  <div>
    <div
      class="small-label"
    >
      {{ $t('form.title') }}
    </div>
    <div class="d-flex">
      <BFormInput
        v-model="label"
        class="label-style"
        :placeholder="`${$t('form.label')}...`"
      />
      <div class="ml-1">
        <i
          v-tippy="{ placement : 'right' }"
          :content="$t('form.labelValue')"
          class="ion ion-md-information-circle-outline icon-hoverable"
          style="width: 25px"
        />
      </div>
    </div>
    <div
      class="d-flex mt-3"
    >
      <div class="small-label">
        {{ $t('form.orderField') }}
      </div>
      <i
        v-if="selectedOrderFieldIsOfTypeDictionary"
        v-tippy="{ placement : 'right' }"
        :content="$t('form.selectedPropertyIsOfTypeDictionary')"
        class="fas fa-book ml-1 icon-hoverable"
        style="width: 20px"
      />
    </div>
    <div class="d-flex">
      <BFormSelect
        v-model="selectedOrderFieldName"
        class="label-style"
        :options="orderPropertiesOptions"
        value-field="name"
        text-field="name"
        @change="selectedOrderFieldUpdated"
      />
      <i
        v-tippy="{ placement : 'right' }"
        :content="$t('form.orderFieldInfo')"
        class="ion ion-md-information-circle-outline ml-1 mt-1 icon-hoverable"
        style="width: 23px"
      />
    </div>
    <div
      class="small-label mt-3"
    >
      {{ $t('form.type') }}
    </div>
    <div class="d-flex">
      <Tippy
        ref="popup"
        interactive
        placement="right"
        append-to="parent"
        distance="7"
        theme="light"
        trigger="click"
        arrow
        class="flex-grow-1"
        :on-show="onShow"
      >
        <template #trigger>
          <slot>
            <div>
              <div
                class="select-box hoverable bg-white"
                style="border-radius: 3px"
              >
                <div
                  v-if="type"
                  class="label-style mt-1"
                  style="margin-left: -5px"
                >
                  {{ typeName(type) }}
                </div>
                <div
                  v-else
                  class="label-style"
                  style="margin-left: -5px"
                >
                  {{ `${$t('form.fieldType')}...` }}
                </div>
              </div>
            </div>
          </slot>
        </template>

        <div>
          <div
            class="d-flex justify-content-between align-items-center pb-1"
            style="min-width: 150px;"
          >
            <div>
              {{ $t('form.fieldType') }}
            </div>
            <div
              class="close"
              @click="$refs.popup.tippy().hide()"
            >
              <span aria-hidden="true">×</span>
            </div>
          </div>
          <div style="margin-left: -5px; margin-right: -5px">
            <div
              class="mt-3"
              style="min-height: 200px; min-width: 200px; overflow: auto"
            >
              <div
                v-for="(w, id) in fieldTypesSelectableList"
                :key="id"
                class="type-row"
                :class="{ selected: w.type === field.type }"
                @click="select(w.type)"
              >
                <span
                  v-if="w.type === field.type"
                  style="margin-left: -8px;"
                >
                  <i class="ion ion-md-checkmark pr-1" />
                </span>
                {{ w.name }}
              </div>
            </div>
          </div>
        </div>
      </Tippy>
      <i
        v-tippy="{ placement : 'right' }"
        :content="$t('form.selectFieldType')"
        class="ion ion-md-information-circle-outline ml-1 mt-1 icon-hoverable"
        style="width: 24px"
      />
    </div>
    <!--TODO <div
      v-if="inTypes(['checkbox'])"
      class="mt-2 mb-3"
    >
      <BFormCheckbox
        v-model="defaultValue"
        switch
      >
        {{ $t('form.checkedByDefault') }}
      </BFormCheckbox>
    </div> -->
    <div v-if="inTypes(['label'])">
      <div class="small-label mt-3">
        {{ $t('general.value') }}
      </div>
      <div class="d-flex mt-1">
        <BFormInput
          v-model="defaultValue"
        />
      </div>
    </div>
    <div
      v-if="inTypes(['select', 'radios', 'checklist'])
        || selectedOrderFieldIsOfTypeDictionary"
      class="mt-3"
    >
      <div
        class="d-flex mt-3"
      >
        <div class="small-label">
          {{ $t('form.selectValuesSource') }}
        </div>
        <i
          v-if="selectedOrderFieldIsOfTypeDictionary"
          v-tippy="{ placement : 'right' }"
          :content="$t('form.fieldValuesFromFieldDictionary')"
          class="fas fa-book ml-1 icon-hoverable"
          style="width: 20px"
        />
      </div>
      <div class="d-flex">
        <BFormSelect
          v-model="selectEntriesDataSource"
          class="label-style"
          :options="selectableListDataSourceOptions"
          :disabled="selectedOrderFieldIsOfTypeDictionary"
        />
        <i
          v-tippy="{ placement : 'right' }"
          :content="$t('form.fieldValuesSource')"
          class="ion ion-md-information-circle-outline ml-1 mt-1 icon-hoverable"
          style="width: 23px"
        />
      </div>
      <FormSelectableListEditor
        :key="selectEntriesDataSource"
        class="mt-3"
        :select-entries.sync="selectEntries"
        :disabled="selectEntriesDataSource !== null"
      />
    </div>
    <div
      v-if="inTypes(['input'])"
    >
      <div class="small-label mt-3">
        {{ $t('form.inputType') }}

        <BFormSelect
          id="input-type"
          v-model="inputType"
          class="label-style"
          :options="inputTypeOptions"
        />
      </div>
    </div>
    <div v-if="inputType === 'number' && field.type === 'input'">
      <div
        class="small-label mt-3"
      >
        {{ $t('form.dataSource') }}
      </div>
      <div class="d-flex">
        <BFormInput
          v-model="urlData"
          switch
          class="label-style"
        />
        <i
          v-tippy="{ placement : 'right' }"
          :content="$t('form.url')"
          class="ion ion-md-information-circle-outline ml-1 mt-1 icon-hoverable"
          style="width: 23px"
        />
      </div>
    </div>
    <div
      v-if="type === '' && !inTypes(['label'])"
      class="d-flex mt-5 "
    >
      <BFormCheckbox
        v-model="required"
        switch
      >
        {{ $t('form.required') }}
      </BFormCheckbox>
    </div>
    <hr class="mx-auto w-50">
    <button
      class="btn btn-secondary icon-btn button"
      type="button"
      @click="$emit('remove')"
    >
      <i class="ion ion-md-trash" />
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { propertyValueTypes } from '@/utils/dictionary';
import FormSelectableListEditor from './FormSelectableListEditor';

export default {
  props: {
    field: Object,
    allFields: Array,
  },
  data: () => ({
    type: '',
    label: '',
    required: false,
    defaultValue: null,
    urlData: '',
    selectedOrderFieldName: null,
    listBox: true,
    inputType: 'text',
    inputTypeOptions: ['text', 'number'],
    selectEntries: [],
    selectEntriesDataSource: null,
  }),
  components: {
    FormSelectableListEditor,
  },
  computed: {
    ...mapState({
      orderProperties: state => state.orders.properties || [],
      dictionaries: state => state.dictionaries.list || [],
    }),
    fieldTypes() {
      return [
        {
          name: this.$t('form.textField'),
          type: 'label',
          predefinedPossibleValues: false,
        },
        {
          name: this.$t('form.input'),
          type: 'input',
          predefinedPossibleValues: false,
        },
        {
          name: this.$t('form.select'),
          type: 'select',
          predefinedPossibleValues: true,
        },
        {
          name: this.$t('form.textarea'),
          type: 'textArea',
          predefinedPossibleValues: false,
        },
        {
          name: this.$t('form.checkbox'),
          type: 'checkbox',
          predefinedPossibleValues: false,
        },
        {
          name: this.$t('form.checklist'),
          type: 'checklist',
          predefinedPossibleValues: true,
        },
        {
          name: this.$t('form.radios'),
          type: 'radios',
          predefinedPossibleValues: true,
        },
      ];
    },
    fieldTypesSelectableList() {
      return this.fieldTypes.filter(type => !this.selectedOrderFieldIsOfTypeDictionary
        || type.predefinedPossibleValues);
    },
    selectableListDataSourceOptions() {
      const defaultList = [
        {
          value: null,
          text: '-',
        },
      ];
      const fromDictionaries = this.dictionaries
        .map(dict => ({
          value: dict.id,
          text: dict.name,
        }))
        .sort((a, b) => (a.text).localeCompare(b.text));
      return defaultList.concat(fromDictionaries);
    },
    orderPropertiesOptions() {
      return this.orderProperties
        .filter(prop => !this.allFields.some(f => f.order === prop.name)
          || prop.name === this.selectedOrderFieldName)
        .map(prop => ({
          name: prop.name,
        }));
    },
    result() {
      const common = {
        type: this.type,
        label: this.label,
        required: this.required,
        featured: true,
        default: this.defaultValue,
        url: this.urlData,
        order: this.selectedOrderFieldName,
        orderPropertyType: this.selectedOrderField?.valueType,
        model: this.label,
        dictionaryId: this.selectEntriesDataSource,
      };
      if (this.type === 'input') {
        common.inputType = this.inputType;
      }
      if (this.type === 'checklist') {
        common.listBox = true;
      }
      if ((this.type === 'select' || this.type === 'radios' || this.type === 'checklist') && this.selectEntries) {
        common.values = this.selectEntries.map(entry => entry.key);
        common.entries = this.selectEntries;
      }
      return common;
    },
    selectedOrderField() {
      return this.orderProperties.find(prop => prop.name === this.selectedOrderFieldName);
    },
    selectedOrderFieldIsOfTypeDictionary() {
      return this.selectedOrderField?.valueType === propertyValueTypes.dictionary;
    },
  },
  watch: {
    result(r) {
      this.$emit('update:field', {
        ...this.field,
        ...r,
      });
      this.$emit('update');
    },
    selectEntriesDataSource() {
      if (this.selectEntriesDataSource === null) {
        this.selectEntries = [];
        return;
      }
      const dictionary = this.dictionaries
        .find(dict => dict.id === this.selectEntriesDataSource);
      this.selectEntries = dictionary?.entries || [];
    },
  },
  methods: {
    inTypes(types) {
      return types.some(t => t === this.type);
    },
    hide() {
      this.$refs.popup.tippy().hide();
    },
    select(id) {
      if (id === this.type) {
        this.type = null;
      } else {
        this.type = id;
      }
    },
    typeName(t) {
      const n = this.fieldTypes.find(x => x.type === t);
      return n ? n.name : '';
    },
    onShow() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
          this.$refs.search.select();
        }
      });
    },
    selectedOrderFieldUpdated() {
      this.selectEntriesDataSource = null;

      if (this.selectedOrderField?.valueType !== propertyValueTypes.dictionary) {
        return;
      }

      const dictionary = this.dictionaries
        .find(dict => dict.id === this.selectedOrderField.dictionaryId);
      this.selectEntries = dictionary.entries;
      this.selectEntriesDataSource = dictionary.id;
      if (!this.fieldTypesSelectableList.some(t => t.type === this.type)) {
        this.type = null;
      }
    },
  },
  created() {
    if (this.field) {
      this.selectedOrderFieldName = this.field.order;
      this.type = this.field.type;
      this.label = this.field.label;
      this.required = this.field.required || false;
      this.defaultValue = this.field.default || null;
      this.urlData = this.field.url;
      this.inputType = this.field.inputType || 'text';
      this.selectEntriesDataSource = this.field.dictionaryId || null;
      this.selectEntries = this.field.entries || [];
      this.model = this.field.model || this.field.hint;
      this.listBox = this.field.listBox;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

  .small-label {
    font-size: 12px;
    color:#495057;
  }

  .label-style {
    color:#495057;
    padding-left: 10px;
    margin-top: 3px;
  }

  #owner-select {
    .box {
      border: 1px solid #ced4da;
      padding: 2px 10px;
      min-height: 28px;
      cursor: pointer;

      &:hover {
        border: 1px solid rgba(100, 100, 100, 0.5);
        border-radius: .2rem;
      }
    }
  }

  .type-row {
    padding: 2px 15px;
    cursor: pointer;
    text-align: left;

    &:hover {
      background-color: rgba(39, 33, 33, 0.1);
    }

    &.selected {
      background-color: $ilabo;
      color: white;
      &:hover {
        background-color: rgba($ilabo, 0.8);
      }
    }
  }

  .close {
    cursor: pointer;
    font-size: 20px;
  }
  .icon-hoverable {
    transition: transform 300ms;
    cursor: pointer;
    color: rgba(#333, 0.5);

    &:hover {
      transform: scale(1.2);
      color: rgba(#333, 1);
    }
  }
  .button {
    margin: 0 auto;
    display: block;
  }

</style>
