<template>
  <div>
    <form @submit.prevent="submit">
      <div
        class="d-flex justify-content-between flex-wrap mb-2"
      >
        <span class="title pl-2">
          <i class="fas fa-clipboard-list pr-1" />
          {{ $t('label.changeLabel') }}
        </span>
      </div>
      <div
        v-for="labellingLevelTemplates in labelsGroupedByLabellingLevels"
        :key="labellingLevelTemplates.labellingLevelName"
        class="labelling-level-labels-row"
      >
        <div class="labelling-level pl-2 pt-2">
          {{ labellingLevelTemplates.labellingLevelName }}
        </div>
        <div class="labels">
          <div
            v-for="labelTemplate in labellingLevelTemplates.labelTemplates"
            :key="labelTemplate.id"
            class="labels-row"
          >
            <BFormCheckbox
              :checked="labelTemplate.checkValue === 'MappedToAll'"
              :indeterminate="labelTemplate.checkValue === 'MappedToPartOf'"
              @change="labelTemplate.checkValue = $event"
            >
              {{ labelTemplate.name }}
              <span v-if="labelTemplate.checkValue === 'MappedToPartOf'">*</span>
            </BFormCheckbox>
          </div>
        </div>
      </div>
      <div
        v-if="isMappedToPartOfSkus"
        class="d-flex justify-content-end"
      >
        <div class="label-info">
          * {{ $t('label.labelInfo') }}
        </div>
      </div>
      <hr>
      <SaveButton
        :pending="pending"
        @cancel="$emit('close')"
        @save="updateLabels()"
      />
    </form>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  props: {
    checkedSkus: Array,
  },
  data: () => ({
    pending: false,
    labelsMappingStates: null,
    labelsGroupedByLabellingLevels: [],
  }),
  computed: {
    ...mapGetters(['timeOfNotification']),
    ...mapState({
      labelTemplates: state => state.labels.templates,
      labelingLevels: state => state.settings.data?.labelingLevels || [],
    }),
    isMappedToPartOfSkus() {
      return this.labelsFlatList?.find(x => x.checkValue === 'MappedToPartOf') || null;
    },
    labelsFlatList() {
      return this.labelsGroupedByLabellingLevels
        .flatMap(byLevel => byLevel.labelTemplates);
    },
  },
  methods: {
    ...mapActions([
      'getLabelMappingsStates',
      'putLabelsMapping',
    ]),
    requestLabelsMappingStates() {
      this.getLabelMappingsStates({
        data: this.checkedSkus,
      })
        .then(({ data }) => {
          this.labelsMappingStates = data;
          this.formatList();
        });
    },
    formatList() {
      const templates = this.labelTemplates
        .map(temp => ({
          ...temp,
          checkValue: this.labelsMappingStates?.find(l => l.labelId === temp.id)?.mappingState || 'MappedToNone',
        }))
        .sort((a, b) => (a.name).localeCompare(b.name));

      const byLevels = this.labelingLevels
        .map(lev => ({
          labellingLevelName: lev.name,
          ordinal: lev.ordinal,
          labelTemplates: templates
            .filter(temp => temp.labelType?.toUpperCase() === lev.name.toUpperCase()),
        }))
        .sort((a, b) => a.ordinal - b.ordinal);

      const labelsWithoutLabellingLevel = templates
        .filter(temp => !this.labelingLevels.some(lev => temp.labelType?.toUpperCase() === lev.name.toUpperCase()));
      this.labelsGroupedByLabellingLevels = [{
        labellingLevelName: this.$t('general.uncategorized'),
        labelTemplates: labelsWithoutLabellingLevel,
      }].concat(byLevels);
    },
    updateLabels() {
      this.pending = true;
      this.putLabelsMapping({
        data: {
          skuIds: this.checkedSkus,
          labelIdsToAdd: this.labelsFlatList
            .filter(x => x.checkValue === true)
            ?.map(x => x.id) || [],
          labelIdsToRemove: this.labelsFlatList
            .filter(x => x.checkValue === false)
            ?.map(x => x.id) || [],
        },
      })
        .then(() => {
          this.$bvToast.toast(this.$t('label.labels'), {
            title: this.$t('general.saved'),
            variant: 'success',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.message, {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: this.timeOfNotification,
          });
        })
        .finally(() => {
          this.pending = false;
          this.$emit('reload');
        });
    },
    setType(type) {
      this.type = type;
    },
  },
  created() {
    this.requestLabelsMappingStates();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .title {
    font-size: 1.5em;
    font-weight: 625;
  }

  .label-info {
    font-size: 0.9em;
    opacity: 0.7;
    width: 65%;
    padding-left: 15px;
  }

  .labelling-level-labels-row {
    display: flex;
    margin-bottom: 2rem;

    .labelling-level {
      width: 35%;
      font-size: 13px;
      font-weight: 450;
      color: #979797;
    }

    .labels {
      width: 65%;
      font-size: 15px;
      padding-left: 15px;

      .labels-row {
        font-weight: 500;
        padding-top: 10px;
      }

      &:deep() {
        .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
            background-color: gray !important;
            border-color: gray !important;
            border-radius: 3px;
            opacity: 0.8;
          }
        }
    }
  }
</style>
