<template>
  <div class="d-flex flex-column h-100">
    <div class="mb-3 d-flex justify-content-between">
      <h3
        style="font-size: 20px"
        class="mb-0"
      >
        <i class="icon-sku pr-2" />
        {{ $t('mapper.mappedSKU') }}
      </h3>
      <button
        class="btn download-button-style"
        :disabled="selectedCount === 0"
        @click="showMultiplePreviewDownloadModal = true"
      >
        <i class="ion ion-md-download mr-1" />
        {{ $t('general.download') }} ({{ selectedCount }})
      </button>
    </div>
    <div class="mt-2">
      <div class="form-group mb-2">
        <SearchInput @input="search = $event" />
      </div>
      <div
        v-if="checkAllOnPage"
        class="select-all-box mb-2"
        @click="checkAllSkusClicked"
      >
        <span v-if="checkAll">
          {{ $t('label.allForThisLabelSelected') }} ({{ totalRows }})
        </span>
        <span v-else>
          {{ $t('label.selectAllForThisLabel') }} ({{ totalRows }})
        </span>
      </div>
      <Loader
        v-if="pendingSkus"
        style="height: 65vh; padding-top: 20vh"
      />
      <SimpleTable
        v-else
        class="extended-table"
        :items="skus"
        :fields="skuColumns"
        @selected="selected = $event"
        @row-clicked="handleRowClick"
      >
        <template #head(left-checkbox)>
          <tr>
            <th
              variant="secondary"
              style="padding-right: 0px !important"
            >
              <CustomCheckbox
                id="checkbox_for_all"
                :value="checkAllOnPage"
                @update:value="checkAllSkusOnPageClicked"
              />
            </th>
          </tr>
        </template>
        <template #cell(left-checkbox)="{item}">
          <CustomCheckbox
            :id="`checkbox_for_${item.id}`"
            class="pl-2"
            style="padding-right: 0px !important"
            :value="checked[item.id]"
            @update:value="check(item.id, $event)"
          />
        </template>
        <template #cell(externalId)="{item}">
          <div
            style="word-break: break-word"
            class="pl-1"
          >
            {{ item.externalId }}
          </div>
        </template>
        <template #cell(name)="{item}">
          <div style="word-break: break-word">
            {{ item.name }}
          </div>
        </template>
        <template #cell(right-options)="{item}">
          <div class="button-style">
            <button
              v-if="selectedPreview === item"
              v-tippy="{ arrow: true }"
              :content="$t('label.offPreview')"
              class="btn btn-secondary btn-sm button-style"
              @click="$emit('clear'); selectedPreview = null"
            >
              <i class="pr-1 fas fa-eye-slash" />
              {{ $t('general.off') }}
            </button>
            <button
              v-else
              v-tippy="{ arrow: true }"
              :content="$t('mapper.selectToPreview')"
              class="btn btn-outline-secondary btn-sm button-style"
              @click="$emit('show', item); selectedPreview = item"
            >
              <i class="pr-1 fas fa-eye" />
              {{ $t('general.preview') }}
            </button>
          </div>
        </template>
      </SimpleTable>
      <div>
        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          style="justify-content: center"
          pills
          limit="5"
          first-number
          last-number
        />
      </div>
    </div>

    <Offcanvas
      :show="skuForm"
      no-bottom-padding
      @update:show="skuForm = $event"
    >
      <SkuDetails
        v-if="skuForm"
        :key="skuSelected.id"
        :sku-id="skuSelected.id"
        @close="skuForm = false"
      />
    </Offcanvas>

    <LModal
      :show.sync="showMultiplePreviewDownloadModal"
    >
      <MultiplePreviewDownload
        :label="label"
        :sku-ids="checkAll ? null : checkedIds"
        :sku-search="search"
        @close="showMultiplePreviewDownloadModal = false"
      />
    </LModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import SimpleTable from '@/components/tables/SimpleTable';
import SkuDetails from '@/components/sku/SkuDetails';
import MultiplePreviewDownload from './MultiplePreviewDownload.vue';

export default {
  props: {
    label: Object,
  },
  data: () => ({
    skus: [],
    selected: {},
    pending: false,
    skuForm: false,
    skuSelected: {},
    selectedPreview: null,
    search: null,
    currentPage: 1,
    totalRows: 0,
    perPage: 10,
    pendingSkus: false,
    debouncedRequestTimer: null,
    checked: {},
    checkAllOnPage: false,
    checkAll: false,
    showMultiplePreviewDownloadModal: false,
  }),
  components: {
    SkuDetails,
    SimpleTable,
    MultiplePreviewDownload,
  },
  computed: {
    ...mapState({
      labelMappings: state => state.labels.mappings,
      labels: state => state.labels.templates || [],
    }),
    ...mapGetters(['timeOfNotification']),
    skuColumns() {
      return [
        {
          key: 'left-checkbox',
          label: '',
        },
        {
          key: 'externalId',
          text: 'Sku Id',
          class: 'text-left',
        },
        {
          key: 'name',
          text: this.$t('general.name'),
          groupable: false,
          class: 'text-left',
        },
        {
          key: 'right-options',
          label: '',
          class: 'right-options',
        },
      ];
    },
    checkedIds() {
      return Object.keys(this.checked)
        .filter(id => this.checked[id]);
    },
    selectedCount() {
      if (this.checkAll) {
        return this.totalRows;
      }
      return this.checkedIds.length;
    },
  },
  watch: {
    currentPage() {
      this.clearChecked();
      this.reloadSkus();
    },
    search() {
      this.clearChecked();
      this.reloadSkusDebounced();
    },
  },
  methods: {
    ...mapActions([
      'getSkusMappedToLabel',
      'getLabelsSkusMappings',
    ]),
    async handleRowClick(selected, index, ev) {
      const tagetClassList = ev.target.classList;
      if (
        tagetClassList.contains('square-box')
        || tagetClassList.contains('color-box-container')
        || tagetClassList.contains('clickable')
      ) {
        return;
      }

      this.editSku(selected);
    },
    filteredMapping(id) {
      const mapping = this.labelMappings?.filter(m => m.skuId === id);

      return this.labels?.filter(el => mapping?.some(f => f.labelId === el.id));
    },
    editSku(sku) {
      this.skuSelected = sku;
      this.skuForm = true;
    },
    reloadSkusDebounced() {
      if (this.debouncedRequestTimer) {
        clearTimeout(this.debouncedRequestTimer);
      }
      this.debouncedRequestTimer = setTimeout(() => {
        this.reloadSkus();
      }, 500);
    },
    reloadSkus() {
      this.pendingSkus = true;
      const query = {};
      if (this.search) {
        this.$set(query, 'search', this.search);
      }

      this.getSkusMappedToLabel({
        params: {
          labelId: this.label.id,
          query: {
            page: this.currentPage - 1,
            count: this.perPage,
            ...query,
          },
        },
      }).then(({ data }) => {
        this.skus = data.items || [];
        this.totalRows = data.totalCount;
        this.getLabelsSkusMappings({
          params: {
            query: {
              skuIds: this.skus.map(sku => sku.id),
            },
          },
        }).finally(() => {
          this.pendingSkus = false;
        });
      });
    },
    check(id, v) {
      this.checkAll = false;
      this.$set(this.checked, id, v);
    },
    clearChecked() {
      this.checked = {};
      this.checkAllOnPage = false;
      this.checkAll = false;
    },
    checkAllSkusOnPageClicked() {
      this.checkAllOnPage = !this.checkAllOnPage;
      if (!this.checkAllOnPage) {
        this.checkAll = false;
      }
      this.checkAllSkusOnPage();
    },
    checkAllSkusClicked() {
      this.checkAll = !this.checkAll;
      this.checkAllSkusOnPage();
    },
    checkAllSkusOnPage() {
      if (this.checkAllOnPage === false) {
        this.clearChecked();
      } else {
        for (let i = 1; i <= this.skus.length; i += 1) {
          this.$set(this.checked, this.skus[i - 1]?.id, true);
        }
      }
    },

  },
  created() {
    this.reloadSkus();
  },
};
</script>

<style scoped lang="scss">
  @import "~@/styles/vars.icss";

  .extended-table :deep(th) {
    padding: 0 0.50rem;
  }
  .extended-table :deep(td) {
    padding: 0 0.50rem;
  }

  .extended-table :deep(tr) {
    transition: 0.2s all;
    cursor: pointer;

    .color-box.square-box {
      transition: 0.2s all;
    }

    &:hover {
      background-color: #ECECEC;

      .color-box.square-box {
        background-color: #D4D4D4 !important;
      }
    }
  }

  .extended-table :deep(thead) {
    tr {
      height: 40px !important;
    }
  }

  .extended-table :deep(tbody) {
    tr {
      height: 70px;
    }
  }

  .extended-table :deep(.externalId) {
    min-width: 350px;
    max-width: 350px;

    div {
      text-align: left;
    }
  }

  .button-style {
   font-size: 1em;
   min-width: 115px;
  }

  .download-button-style {
    border-radius: 18px;
    color: #7F7F7F;
    background-color: #EFEFEF;
    font-size: 13px;
    font-weight: 500;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .search {
    border-radius: .25rem;
  }

  .select-all-box {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 430;
    background-color: #F5F5F5;
    color: #82AC48;
    word-break: break-all;
    text-align: center;
    cursor: pointer;
  }

</style>
