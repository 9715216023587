var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"keydown":_vm.handleKeyDown}},[(_vm.initLoading)?_c('Loader'):_c('div',[_c('div',{staticClass:"cockpit-root"},[_c('CockpitLineVerticalSelector',{attrs:{"lines-collapsed":_vm.linesCollapsed},on:{"update:linesCollapsed":function($event){_vm.linesCollapsed=$event},"update:lines-collapsed":function($event){_vm.linesCollapsed=$event}},model:{value:(_vm.selectedLineId),callback:function ($$v) {_vm.selectedLineId=$$v},expression:"selectedLineId"}}),_c('div',{staticClass:"cockpit-wrapper"},[_vm._v(" "+_vm._s(_vm.scannedSerialNumber)+" "),(_vm.loadingActiveLineOrder)?_c('div',{staticClass:"loading"},[_c('Loader')],1):_vm._e(),(!_vm.loadingActiveLineOrder && !_vm.activeLineOrder)?_c('div',{staticClass:"no-active-order-info"},[_c('i',{staticClass:"fas fa-info-circle"}),_vm._v(" Brak aktywnego zlecenia ")]):_vm._e(),(_vm.activeLineOrder && !_vm.loadingActiveLineOrder)?_c('CockpitActiveOrderCard',{attrs:{"active-order":_vm.activeLineOrder},on:{"select-order":_vm.handleSelectOrder}}):_vm._e(),(_vm.activeLineOrder && !_vm.loadingActiveLineOrder)?_c('CockpitMatryoshka',{attrs:{"selected-line-id":_vm.selectedLineId,"active-order":_vm.activeLineOrder,"lines-collapsed":_vm.linesCollapsed},on:{"select-item":_vm.handleSelectQueueItem,"print-manually":_vm.handleSelectPrintManually}}):_vm._e()],1)],1),_c('CockpitOffcanvas',{attrs:{"show":!!_vm.offcanvasVisible,"tabs-key":_vm.offcanvasVisible || '',"tab-index":_vm.offcanvasTabIndex,"tabs":_vm.offcanvasTabs,"title":_vm.offcanvasTitle,"icon":_vm.offcanvasIcon,"show-audit-button":_vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS,"show-back-button":_vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
          || _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT},on:{"update:tabIndex":function($event){_vm.offcanvasTabIndex=$event},"update:tab-index":function($event){_vm.offcanvasTabIndex=$event},"audit-button-clicked":_vm.handleAuditButtonClicked,"back-button-clicked":_vm.handleBackButtonClicked,"update:show":function($event){_vm.offcanvasVisible = $event}},scopedSlots:_vm._u([{key:"default",fn:function(){return [(
            _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.ORDER_DETAILS
              && _vm.offcanvasTabIndex === 0
          )?_c('CockpitActiveOrderDetailsTab',{attrs:{"active-order":_vm.activeLineOrder}}):_vm._e(),(
            _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.ORDER_DETAILS
              && _vm.offcanvasTabIndex === 1
          )?_c('CockpitActiveOrderHistoryTab',{attrs:{"active-order":_vm.activeLineOrder}}):_vm._e(),(
            _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.PRINT_MANUALLY
              && _vm.offcanvasTabIndex === 0
          )?_c('CockpitMatryoshkaPrintManuallyTab'):_vm._e(),(
            _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && _vm.offcanvasTabIndex === 0
          )?_c('CockpitMatryoshkaItemDetailsTab',{attrs:{"label-archive-id":_vm.selectedLabelArchiveId}}):_vm._e(),(
            _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && _vm.offcanvasTabIndex === 1
          )?_c('CockpitMatryoshkaItemContentsTab',{attrs:{"label-archive-id":_vm.selectedLabelArchiveId}}):_vm._e(),(
            _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && _vm.offcanvasTabIndex === 2
          )?_c('CockpitMatryoshkaItemHistoryTab',{attrs:{"label-archive-id":_vm.selectedLabelArchiveId}}):_vm._e(),(
            _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && _vm.offcanvasTabIndex === 3
          )?_c('CockpitMatryoshkaItemLabelTab',{attrs:{"label-archive-id":_vm.selectedLabelArchiveId}}):_vm._e(),(_vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
            && _vm.offcanvasTabIndex === 0)?_c('CockpitMatryoshkaItemOperationsTab'):_vm._e(),(_vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT
            && _vm.offcanvasTabIndex === 0)?_c('CockpitMatryoshkaItemAuditTab'):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [(
            _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.ORDER_DETAILS
              && _vm.offcanvasTabIndex === 0
          )?_c('CockpitActiveOrderActionButtons'):_vm._e(),(
            _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && _vm.offcanvasTabIndex === 0
          )?_c('CockpitMatryoshkaItemActionButtons',{on:{"open-operations":_vm.handleOpenOperations}}):_vm._e()]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }