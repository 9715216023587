import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getSkuPropertyTypeLabelUsages',
    property: 'list',
    path: () => '/labels/skuPropertyTypesInUse',
  })
  .getStore();

export default store;
