import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    labelProperties: [],
  },
})
  .get({
    action: 'getLabelProperties',
    property: 'labelProperties',
    path: ({ labelId }) => `/label/${labelId}/properties`,
  })
  .put({
    action: 'setLabelPropertyRule',
    path: ({ propId }) => `/label/property/${propId}/rule`,
    onSuccess: (s, { data }) => {
      const prop = s.labelProperties.find(x => x.id === data.id);
      if (prop) {
        Vue.set(prop, 'rule', data.rule);
      }
    },
  })
  .getStore();

export default store;
