<template>
  <div>
    <div @click="preview = rightRequestType.previewAvailable">
      <div
        class="request-list-item row no-gutters"
        :class="{
          'border-0': hideBorder,
        }"
        :style="`
          --hover-cursor: ${rightRequestType.previewAvailable ? 'pointer' : 'default'};
          borderLeftColor: ${status.color}`"
      >
        <div class="col-7 d-flex flex-column justify-content-between">
          <div class="row mr-0">
            <div
              v-tippy
              :content="$t('general.status')"
              style="font-size: 0.8em; white-space: pre-wrap"
              :style="{ 'background-color': status.color }"
              class="badge badge-success text-white mt-1 ml-2"
            >
              <div
                class="status-badge text-uppercase font-weight-bold"
              >
                <i
                  :class="status.icon"
                />
                <span style="padding-left: 5px">
                  {{ status.text }}
                </span>
              </div>
            </div>

            <div
              class="mt-1 mx-2"
              style="align-self: center"
            >
              <span
                class="badge badge-light"
                style="border-radius: 12px; font-size: 14px !important; padding: 5px 10px"
              >
                <i
                  :style="{ 'color': rightRequestType ? rightRequestType.color : null }"
                  :class="rightRequestType.icon"
                />
                {{ rightRequestType ? rightRequestType.text : $t('general.type') }}
              </span>
            </div>
          </div>

          <div class="mt-1">
            <div
              v-tippy
              style="font-size: 14px; font-weight: 650"
              :content="$t('time.fromPrint')"
            >
              <span>
                {{ duration }}
              </span>
            </div>
            <div
              v-tippy
              style="font-size: 13px"
              :content="$t('date.print')"
            >
              <span style="color: #797979; font-weight: 400">
                {{ requestDate.format('DD MMM YYYY, HH:mm') }}
              </span>
            </div>
          </div>
        </div>

        <div class="col-5 info-box">
          <p class="mb-1">
            <span class="title">
              {{ $t('labellingPoint.labellingPoint') }}:
            </span>
            <span class="value">
              {{ labellingPointName || '-' }}
            </span>
          </p>

          <p class="mb-1">
            <span class="title">
              {{ $t('order.order') }}:
            </span>
            <span class="value">
              {{ orderNumber }}
            </span>
          </p>

          <p class="mb-1">
            <span class="title">
              {{ $t('printer.printer') }}:
            </span>
            <span class="value">
              {{ printerName }}
            </span>
          </p>

          <p
            v-if="requestStatusInfo"
            class="mb-1"
          >
            <span class="title">
              {{ $t('general.info') }}:
            </span>
            <span class="value">
              {{ requestStatusInfo }}
            </span>
          </p>
        </div>
      </div>
    </div>
    <LModal
      :show.sync="preview"
      size="lg"
      style="display: inline-grid"
    >
      <LabelPreview
        :print-request-id="id"
      />
    </LModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import LabelPreview from '../label/LabelPreview';

export default {
  props: {
    id: Number,
    labelId: Number,
    labellingPointId: Number,
    labellingPointName: String,
    requestStatus: String,
    requestType: String,
    printerId: Number,
    printerName: String,
    created: String,
    orderNumber: String,
    isFirst: Boolean,
    summary: String,
    responseDetails: String,
    requestStatusDetails: String,
    duration: String,
  },
  data: () => ({
    preview: false,
  }),
  components: {
    LabelPreview,
  },
  computed: {
    ...mapGetters([
      'requestsStatuses',
      'requestsTypes',
    ]),
    ...mapState({
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    rightRequestType() {
      return this.requestsTypes?.find(req => req.type === this.requestType) || [];
    },
    requestDate() {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return moment.utc(this.created).add(plantUtcOffset, 'minutes');
    },
    requestStatusInfo() {
      if (this.requestStatusDetails) return this.requestStatusDetails;
      const serializedDetails = this.summary ?? this.responseDetails;
      if (!serializedDetails) return null;
      const parsedSummary = JSON.parse(serializedDetails);
      return parsedSummary?.ErrorMessage ?? parsedSummary.Message;
    },
    status() {
      return this.requestsStatuses.map(x => ({
        ...x,
        backgroundColor: tinycolor(x.color).setAlpha(0.2).toString(),
      }))
        .find(x => x.status.toUpperCase() === this.requestStatus.toUpperCase());
    },
    hideBorder() {
      return ![
        'FAILED',
      ].includes(this.requestStatus);
    },
  },
};
</script>

<style lang="scss" scoped>

  .request-list-item {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    border-left: 3px solid;
    font-size: 14px;
    background: #FFF;
    position: relative;
    margin-bottom: 8px;

    &:hover {
      background-color: rgb(236, 236, 236);
      cursor: var(--hover-cursor);
    }

    &.withError {
      border-left: 3px solid #DD5D3E;
      border-left-color: #DD5D3E !important;
    }

    .info-box {
      font-size: 11.5px;
      color: #797979;
      .title {
        font-weight: 600;
      }
      .value {
        font-weight: 400;
      }
    }

    .request-delete {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      opacity: 0.7;
    }
  }

  .print-request {
    cursor: pointer;
    border-bottom: 1px solid;
    border-color: rgb(216, 212, 212) !important;
  }

  .status-badge {
    padding-block: 2px;
    padding-inline: 4px;
    inline-size: max-content;
    max-width: 100px;
    line-height: 1.3em;
  }

</style>
