<template>
  <form @submit.prevent="submit">
    <h5
      class="mb-0"
    >
      <span>
        <i class="fas fa-upload pr-2" />
        {{ $t('label.importRule') }}
      </span>
    </h5>
    <div class="mt-4">
      <ModelSelect
        v-model="labelId"
        :options="templateOptions"
        :placeholder="`${$t('labelTemplate')}...`"
      />
    </div>

    <div class="mt-2">
      <ModelSelect
        v-model="fieldId"
        :disabled="!labelId"
        :options="fieldOptions"
        :placeholder="`${$t('template.field')}...`"
      />
    </div>

    <div class="mt-4">
      <CodeEditor
        :value="code"
        read-only="nocursor"
        style="max-height: 300px; height: 300px; overflow: auto"
      />
    </div>

    <div class="mt-2">
      <SaveButton
        :disabled="!fieldId"
        :save-label="$t('general.load')"
        save-icon="ion ion-md-download"
        @cancel="$emit('close')"
      />
    </div>
  </form>
</template>

<script>
import { ModelSelect } from 'vue-search-select';
import { mapState, mapActions } from 'vuex';
import CodeEditor from './CodeEditor';

export default {
  data: () => ({
    labelId: null,
    fieldId: null,
    fields: null,
  }),
  components: {
    ModelSelect,
    CodeEditor,
  },
  computed: {
    ...mapState({
      templates: state => state.labels.templates || [],
    }),
    code() {
      if (!this.fieldId) return '';
      const field = this.fields.find(x => x.id === this.fieldId);
      return field ? (field.rule || '') : '';
    },
    templateOptions() {
      if (!this.templates) return [];
      return this.templates.map(t => ({
        value: t.id,
        text: t.name,
      }));
    },
    fieldOptions() {
      if (!this.fields) return [];
      return this.fields.map(f => ({
        value: f.id,
        text: f.name,
      }));
    },
  },
  watch: {
    labelId(id) {
      if (!id) return;
      this.getLabelProperties({
        params: { labelId: id },
      })
        .then(({ data }) => {
          this.fields = data;
        });
    },
  },
  methods: {
    ...mapActions(['getLabelProperties']),
    submit() {
      this.$emit('load', this.code);
      this.$emit('close');
    },
  },
};
</script>

<style>

</style>
