<script>

import { mapGetters, mapState } from 'vuex';

export default {
  name: 'MarkingsAssignListFilters',
  props: {
    value: Object,
  },
  data: () => ({
    orderOptions: [],
  }),
  emits: [
    'input',
  ],
  computed: {
    ...mapState({
      lines: state => state.lines.list || [],
    }),
    ...mapGetters('labelArchive', [
      'labelArchiveStatuses',
    ]),
    lineOptions() {
      return this.lines
        .map(l => ({
          ...l,
          label: l.name,
          value: l.id,
          iconClass: 'fas fa-layer-group app-color',
        })) || [];
    },
    statusOptions() {
      return this.labelArchiveStatuses.map(s => ({
        ...s,
        label: s.name,
        value: s.id,
        iconClass: s.icon,
      }));
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<template>
  <div class="markings-filters">
    <div class="search-input-box">
      <BInput
        v-model="innerValue.searchQuery"
        size="sm"
        placeholder="Wyszukaj ID..."
        class="search-input"
      />
    </div>

    <div>
      <ProSelect
        v-model="innerValue.orderId"
        :options="orderOptions"
        show-all-option
        label="ID zlecenia"
        :box-height="'26px'"
        :dropdown-width="'300px'"
        icon-class="fas fa-layer-group"
        icon-color-class="app-color"
      />
    </div>

    <div>
      <ProSelect
        v-model="innerValue.lineId"
        :options="lineOptions"
        show-all-option
        label="Linia"
        :box-height="'26px'"
        :dropdown-width="'150%'"
        icon-class="fas fa-layer-group"
        icon-color-class="app-color"
      />
    </div>

    <div>
      <ProSelect
        v-model="innerValue.status"
        :options="statusOptions"
        show-all-option
        label="Status"
        :box-height="'26px'"
        :dropdown-width="'150%'"
        icon-class="fas fa-layer-group"
        icon-color-class="app-color"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.markings-filters {
  display: flex;
  gap: 8px;
  align-items: flex-end;

  .search-input-box {
    width: 250px;
  }

  .search-input {
    border: 1px solid #CED3D8;
    padding: 14px 8px;
    font-size: 13px;
    width: 100%;
    min-width: 20px;
  }
}
</style>
