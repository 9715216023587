<template>
  <div class="request-details-offcanvas">
    <div
      class="d-flex justify-content-between align-items-center mb-2"
    >
      <div class="d-flex justify-content-start">
        <h3
          class="align-self-center mb-0"
          style="font-size: 1.7em"
        >
          <i
            class="pr-2 fas fa-info-circle"
          />
          <span>
            {{ $t('request.requestDetails') }}
          </span>
        </h3>
        <button
          class="action-button btn btn-primary btn-sm"
          type="submit"
          :disabled="resendPending"
          @click="resendModal = true"
        >
          <BSpinner
            v-if="resendPending"
            variant="light"
            small
            class="pr-2"
          />
          <i
            v-else
            class="ion ion-md-refresh pr-2"
          />
          {{ $t('request.retryTheRequest') }}
        </button>
        <button
          v-if="status.status == 'SCHEDULED'
            || status.status == 'INPROGRESS'
            || status.status == 'INPROGRESSWARMINGUP'"
          class="action-button btn btn-secondary btn-sm"
          type="submit"
          @click="forceFailStatus()"
        >
          <BSpinner
            v-if="forceFailPending"
            variant="light"
            small
            class="pr-2"
          />
          <i
            v-else
            class="fas fa-exclamation-triangle pr-2"
          />
          {{ $t('request.forceFailStatusRequest') }}
        </button>
      </div>
      <div class="h3 mb-0 text-black-50">
        <i
          class="fas fa-times cursor-pointer p-1"
          @click="$emit('close'); tab = 0"
        />
      </div>
    </div>

    <BTabs
      v-model="tab"
      pills
      content-class="pt-2"
    >
      <BTab
        key="general"
        :title="$t('general.general')"
      >
        <RequestGeneralTab
          :request="selected"
          :type="type"
          :status="status"
        />
      </BTab>
      <BTab
        key="payload"
        :title="$t('request.payload')"
      >
        <RequestPayloadTab
          :request="selected"
        />
      </BTab>
    </BTabs>

    <LModal
      :show.sync="resendModal"
      class="p-5"
      size="lg"
    >
      <div class="text-center py-2 mx-2">
        <div class="info-box row">
          <div>
            <i class="fas fa-info-circle mr-1" />
            <span class="font-weight-bold">
              {{ $t('general.info') }}:
            </span>
            <span>
              {{ $t('request.resendInfoWarning') }}.
            </span>
          </div>
        </div>
        <div>
          <button
            class="btn btn-secondary btn-sm mx-2"
            type="submit"
            @click="resendModal = false"
          >
            <i class="ion ion-md-close pr-2" />
            {{ $t('general.no') }}
          </button>
          <button
            class="btn btn-primary btn-sm mx-2"
            type="submit"
            @click="resend()"
          >
            <i class="ion ion-md-checkmark pr-2" />
            {{ $t('general.yes') }}
          </button>
        </div>
      </div>
    </LModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RequestGeneralTab from './tabs/RequestGeneralTab';
import RequestPayloadTab from './tabs/RequestPayloadTab';

export default {
  props: {
    selected: Object,
    type: Object,
    status: Object,
  },
  data: () => ({
    tab: 0,
    forceFailPending: false,
    resendPending: false,
    resendModal: false,
  }),
  components: {
    RequestGeneralTab,
    RequestPayloadTab,
  },
  computed: {
    ...mapGetters(['timeOfNotification']),
  },
  methods: {
    ...mapActions([
      'resendRequest',
      'changeRequestStatusToFailed',
    ]),
    forceFailStatus() {
      this.forceFailPending = true;
      this.changeRequestStatusToFailed({
        params: {
          requestId: this.selected.id,
        },
      }).then(({ data }) => {
        this.$emit('update:request', data);
        this.$bvToast.toast(this.$t('request.requestSucceed'), {
          title: this.$t('general.success'),
          variant: 'success',
          autoHideDelay: this.timeOfNotification,
        });
      }).catch(() => {
        this.$bvToast.toast(this.$t('request.requestFailed'), {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      })
        .finally(() => {
          this.$emit('reload');
          this.forceFailPending = false;
        });
    },
    resend() {
      this.resendPending = true;

      this.resendRequest({
        params: {
          requestId: this.selected.id,
        },
      }).then(() => {
        this.$bvToast.toast(this.$t('request.requestSucceed'), {
          title: this.$t('general.success'),
          variant: 'success',
          autoHideDelay: this.timeOfNotification,
        });
        this.$emit('reload');
        this.$emit('close');
      }).catch(({ response: { data } }) => {
        this.$bvToast.toast(data?.message || this.$t('request.requestFailed'), {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      })
        .finally(() => {
          this.resendModal = false;
          this.resendPending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

  .action-button{
    margin-left: 20px;
  }

  .info-box {
    padding: 10px;
    margin: 50px 25px;
    border-radius: 7px;
    background-color: #DDECFF;
    color: #3D69A3;
    flex-flow: row;
  }

  .request-details-offcanvas {
    position: relative;
    font-size: 12px;
    font-weight: 600;

    &:deep() {
      .tabs {
        .tab-content {
          height: 75vh;
          overflow-y: auto;
        }

        .nav {
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          border-bottom: 1px solid #ECECEC;
          margin-bottom: 1rem;
          font-size: 14px;

          .nav-item {
            width: 25%;
            text-align: center;

            .nav-link {
              background: transparent;
              color: rgba(#000, 0.5);
              border-radius: 0;
              margin-bottom: -3px;

              &.active {
                color: black;
                font-weight: 600;
                border-bottom: 5px solid rgba(118, 176, 51, 1);
              }
            }
          }
        }
      }
    }
  }
</style>
