<script>
import MarkingsAssignListItem from '@/components/markings/assign-list/MarkingsAssignListItem.vue';

export default {
  name: 'MarkingsAssignList',
  emits: [
    'select-item',
  ],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabledIds: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    MarkingsAssignListItem,
  },
};
</script>

<template>
  <div class="markings-list">
    <MarkingsAssignListItem
      v-for="item in list"
      :key="item.id"
      :item="item"
      :disabled="disabledIds.includes(item.id)"
      @select-item="$emit('select-item', $event)"
    />
  </div>
</template>

<style scoped lang="scss">
.markings-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
