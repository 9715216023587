<template>
  <div
    class="d-flex flex-column"
    style="max-height: 100%;"
  >
    <div
      v-if="!pending"
      style="overflow: auto; position: relative"
      class="py-2 pl-2"
    >
      <div class="d-flex align-items-start justify-content-between">
        <div class="column-style">
          <div
            v-for="(prop, index) in localValues"
            :key="prop.name"
          >
            <div
              v-if="index % 2 == 0"
              class="row-style px-1"
            >
              <OrderFieldEdit
                :property-name="prop.name"
                :property-value="prop.value"
                @update:propertyValue="propertyValueUpdated(prop.name, $event)"
              />
            </div>
          </div>
        </div>

        <div class="column-style">
          <div
            v-for="(prop, index) in localValues"
            :key="prop.name"
          >
            <div
              v-if="index % 2 != 0"
              class="row-style px-1"
            >
              <OrderFieldEdit
                :property-name="prop.name"
                :property-value="prop.value"
                @update:propertyValue="propertyValueUpdated(prop.name, $event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import OrderFieldEdit from './OrderFieldEdit';

export default {
  props: {
    orderId: {
      type: Number,
    },
  },
  data: () => ({
    pending: false,
    localValues: [],
  }),
  components: {
    OrderFieldEdit,
  },
  watch: {
    orderId() {
      this.debouncedRefreshPropertiesValues();
    },
  },
  methods: {
    ...mapActions([
      'getOrderPropertiesValues',
    ]),
    propertyValueUpdated(propName, { value, isValid }) {
      const editedProperty = this.localValues
        .find(v => v.name === propName);
      editedProperty.value = value;
      editedProperty.isValid = isValid;
      this.$emit('values', this.localValues);
    },
    debouncedRefreshPropertiesValues() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.pending = true;
      this.timeout = setTimeout(() => {
        this.getOrderPropertiesValues({
          params: {
            orderId: this.orderId,
            query: {
              orderExecutionId: null,
            },
          },
        }).then(({ data }) => {
          this.localValues = data.sort((a, b) => (a.name).localeCompare(b.name));
        }).finally(() => {
          this.pending = false;
        });
      }, 100);
    },
  },
  created() {
    this.debouncedRefreshPropertiesValues();
  },

};
</script>

<style lang="scss" scoped>
  .column-style {
    width: 48%;
  }

  .row-style {
    height: 70px;
    margin-top: 12px;
  }

</style>
