<template>
  <div
    style="height: 65vh; overflow-y:auto"
    class="flex-fill"
  >
    <SimpleTable
      ref="simpleTable"
      class="extended-table"
      :items="list"
      :fields="tableFields"
      :selectable="true"
      :select-mode="'range'"
      @selected="selected = $event"
      @row-clicked="handleRowClick"
    >
      <template #head(left-options)>
        <tr>
          <th variant="secondary">
            <CustomCheckbox
              id="checkbox_for_all"
              :value="checkAll"
              @update:value="checkAllFields"
            />
            <BPopover
              v-if="suggestFor"
              :show.sync="checkAll"
              container="post-list"
              :target="`checkbox_for_all`"
              custom-class="checkbox-popover"
              triggers=""
              placement="left"
            >
              <div class="bg-white">
                <button
                  class="btn btn-block btn-sm btn-primary mt-1"
                  @click.stop="form = true; suggestFor = null"
                >
                  <i class="ion ion-ios-clipboard pr-2 " />
                  {{ $t('label.changeLabel') }}
                </button>
                <button
                  class="btn btn-block btn-sm btn-outline-secondary mt-1"
                  @click.stop="editList(showDeleted ? 'restore' : 'delete')"
                >
                  <i
                    class="pr-2"
                    :class="showDeleted ? 'fas fa-eye' : 'fas fa-eye-slash '"
                  />
                  {{ showDeleted ? $t('general.activate') : $t('general.inactivate') }}
                </button>
              </div>
            </BPopover>
          </th>
        </tr>
      </template>
      <template #cell(left-options)="{item}">
        <CustomCheckbox
          v-if="selectMany"
          :id="`checkbox_for_${item.id}`"
          class="pl-2"
          :value="checked[item.id]"
          @update:value="check(item.id, $event)"
        />
        <BPopover
          v-if="suggestFor && suggestFor == item.id"
          :show.sync="checkboxPopover"
          container="post-list"
          :target="`checkbox_for_${item.id}`"
          custom-class="checkbox-popover"
          triggers=""
          placement="left"
        >
          <div class="bg-white">
            <button
              class="btn btn-block btn-sm btn-primary mt-1"
              @click.stop="form = true; suggestFor = null"
            >
              <i class="ion ion-ios-clipboard pr-2 " />
              {{ $t('label.changeLabel') }}
            </button>
            <button
              class="btn btn-block btn-sm btn-outline-secondary mt-1"
              @click.stop="editList(showDeleted ? 'restore' : 'delete')"
            >
              <i
                class="pr-2"
                :class="showDeleted ? 'fas fa-eye' : 'fas fa-eye-slash '"
              />
              {{ showDeleted ? $t('general.activate') : $t('general.inactivate') }}
            </button>
          </div>
        </BPopover>
      </template>
      <template #cell(externalId)="{item}">
        {{ item.externalId }}
      </template>
      <template #cell(labelName)="data">
        <div>
          <div
            class="row px-2"
          >
            <i
              v-if="data.item.labelName[0]"
              class="fas fa-clipboard pr-2"
              style="place-self: center"
            />
            {{ data.item.labelName[0] || '-' }}
            <span v-if="data.item.labelName[1]">
              {{ ',' }}  {{ data.item.labelName[1] }}
            </span>
            <span
              v-if="data.item.labelName[2]"
              class="pl-3 row"
            >
              <span class="pr-1">
                {{ '...' }}
              </span>
              <span>
                <Tippy
                  theme="dark"
                  arrow
                  interactive
                  placement="bottom"
                >
                  <template #trigger>
                    <span
                      class="badge badge-success pb-1"
                      style="border-radius: 10px"
                    >
                      {{ $t('general.seeMore').toLowerCase() }}
                    </span>
                  </template>
                  <span
                    v-for="(value, prop) in data.item.labelName"
                    :key="prop"
                  >
                    {{ value }} <br>
                  </span>
                </Tippy>
              </span>
            </span>
          </div>
        </div>
      </template>
      <template #cell(modified)="{item}">
        <div>
          <span class="date-style">
            <i class="far fa-calendar pr-1" />
            {{ formatDate(item?.modified) }}
          </span>
        </div>
      </template>
    </SimpleTable>
    <div>
      <LModal
        :show.sync="form"
      >
        <SkuLabelForm
          :element="selected"
          :checked-skus="checkedIds"
          @close="form = false; suggestFor = selected.id"
          @reload="reloadSkus()"
        />
      </LModal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import SkuLabelForm from '@/components/sku/SkuLabelForm';
import SimpleTable from '@/components/tables/SimpleTable';

export default {
  props: {
    list: Array,
    tableFields: Array,
    selectMany: Boolean,
    noDataInfo: String,
    showDeleted: Boolean,
  },
  data: () => ({
    checkboxPopover: false,
    suggestFor: null,
    checked: {},
    form: false,
    checkAll: false,
    selected: null,
  }),
  components: {
    SimpleTable,
    SkuLabelForm,
  },
  computed: {
    ...mapState({
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
    }),
    checkedIds() {
      return Object.keys(this.checked)
        .filter(id => this.checked[id]);
    },
  },
  watch: {
    form(v) {
      if (v === false) {
        this.suggestFor = this.selected?.id;
      }
    },
    showDeleted() {
      this.selected = null;
      this.checked = {};
      this.checkboxPopover = false;
    },
    checkedIds(ids) {
      if (ids.length > 0) {
        this.checkboxPopover = true;
      }
      if (this.list.length && ids.length === this.list.length) {
        this.checkAll = true;
        this.suggestFor = -1;
      } else if (ids.length !== this.list.length) {
        this.checkAll = false;
      }
    },
  },
  methods: {
    ...mapActions([
      'deleteSkus',
      'restoreSkus',
    ]),
    formatDate(date) {
      if (!date) return '-';
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return date ? moment.utc(date).add(plantUtcOffset, 'minutes').format('ll LTS') : '-';
    },
    reloadSkus() {
      this.$emit('reload');
      this.checked = {};
      this.form = false;
      this.checkboxPopover = false;
    },
    checkAllFields() {
      this.checkAll = !this.checkAll;
      if (this.checkAll === false) {
        this.clearChecked();
      } else {
        for (let i = 1; i <= this.list.length; i += 1) {
          this.$set(this.checked, this.list[i - 1]?.id, true);
        }
        this.suggestFor = -1;
      }
    },
    check(id, v) {
      this.$set(this.checked, id, v);
      if (v) {
        this.suggestFor = id;
      } else if (id === this.suggestFor) {
        [this.suggestFor] = this.checkedIds;
      } else {
        this.suggestFor = null;
      }
    },
    clearChecked() {
      this.checked = {};
      this.checkboxPopover = false;
      this.suggestFor = null;
    },
    async handleRowClick(selected, index, ev) {
      const tagetClassList = ev.target.classList;
      if (
        tagetClassList.contains('square-box')
        || tagetClassList.contains('color-box-container')
        || tagetClassList.contains('clickable')
      ) {
        this.$emit('checkboxClicked');
        return;
      }

      this.checkboxPopover = false;
      this.$emit('selected', selected);
    },
    editList(type) {
      const skuId = this.checkedIds
        .map(x => parseInt(x, 10))
        .filter(x => x);
      this.suggestFor = null;
      this.$bvModal.msgBoxConfirm(this.$t('general.areYouSure'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        okTitle: this.$t('general.yes'),
        cancelTitle: this.$t('general.no'),
        centered: true,
      })
        .then(value => {
          if (value) {
            this.pending = true;
            let request = Promise.resolve();
            if (type === 'delete') {
              request = this.deleteSkus({
                params: {
                  query: {
                    skuId,
                  },
                },
              });
            } else {
              request = this.restoreSkus({
                params: {
                  query: {
                    skuId,
                  },
                },
              });
            }

            request
              .then(() => {
                this.$emit('successMsg', this.$t('sku.edit'));
              })
              .catch(({ response: { data } }) => {
                this.$emit('failedMsg', data.message);
              })
              .finally(() => {
                this.selected = null;
                this.checked = {};
                this.checkboxPopover = false;
                this.$emit('reload');
                this.pending = false;
              });
          }
        })
        .finally(() => {
          this.suggestFor = this.selected.id;
          if (this.checkAll) {
            this.suggestFor = -1;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~@/styles/vars.icss";

  .extended-table :deep(th) {
    padding: 0 0.75rem !important;
  }

  .extended-table :deep(tr) {
    transition: 0.2s all;
    cursor: pointer;

    .color-box.square-box {
      transition: 0.2s all;
    }

    &:hover {
      background-color: #ECECEC;

      .color-box.square-box {
        background-color: #D4D4D4 !important;
      }
    }
  }

  .extended-table :deep(thead) {
    tr {
      height: 40px !important;
    }
  }

  .extended-table :deep(tbody) {
    tr {
      height: 50px;
    }
  }

  .extended-table :deep(.left-options) {
    min-width: 40px;
    max-width: 40px;
  }

  .extended-table :deep(externalId) {
    text-align: left !important;
  }

  .checkbox-popover {
    z-index: 10;
  }

  .date-style {
    color: #8A8A8A;
    font-size: 11px;
    font-weight: 400;
  }

</style>
