import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getOrderTypes',
    property: 'list',
    path: () => '/order/types',
  })
  .post({
    action: 'addOrderType',
    path: () => '/order/type',
  })
  .put({
    action: 'updateOrderType',
    path: ({ id }) => `order/type/${id}`,
  })
  .delete({
    action: 'deleteOrderType',
    path: ({ id }) => `/order/type/${id}`,
  })
  .delete({
    action: 'deleteOrderTypeMapping',
    path: ({ labelingPointId }) => `/order/type/labelingPoint/${labelingPointId}`,
  })
  .put({
    action: 'mapLabelingPointToOrderType',
    path: ({ labelingPointId }) => `/order/type/labelingPoint/${labelingPointId}`,
  })
  .getStore();

store.getters = {
  OrderTypeByName(state) {
    return name => state.list.find(type => type.name === name);
  },
};

export default store;
