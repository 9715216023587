<template>
  <div
    :class="{ mobile: isMobile }"
    class="py-2"
  >
    <div class="mt-1">
      <div
        class="menu-close"
        @click="$emit('close')"
      >
        <i class="ion ion-md-close" />
      </div>

      <!-- <div class="setting-category">
        <RouterLink
          :class="{
            active: selected === 'setupIssues',
          }"
          :to="{ path: '/settings/setupIssues' }"
          @click.native="selectType('setupIssues')"
        >
          <div
            class="setting-subcategory d-flex"
            :class="setupIssues > 0 ? 'border border-danger text-danger' : ''"
          >
            <div class="flex-grow-1">
              <i
                v-if="setupIssues > 0"
                class="fas fa-exclamation-circle pr-2 text-danger"
              />
              <i
                v-else
                class="fas fa-check pr-2"
              />
              {{ $t('settings.setupIssues') }}
            </div>
            <div>
              {{ setupIssues }}
            </div>
          </div>
        </RouterLink>
      </div> -->

      <div
        v-for="(t, index) in types"
        :key="index"
        class="setting-category text-nowrap"
      >
        <div>
          <span class="title">{{ t.text }}</span>
        </div>
        <div
          v-for="cat in t.children.filter(c => c.condition)"
          :key="cat.name"
        >
          <RouterLink
            :class="{
              active: selected === cat.key,
              disabled: cat.disabled,
            }"
            :to="cat.route"
            class="setting-subcategory d-block"
            @click="selectType(cat); $emit('selected', cat.key)"
          >
            <i :class="cat.icon" />
            {{ cat.text }}
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    selected: String,
  },
  data() {
    return {
      isMobile: false,
      setupIssues: null,
    };
  },
  components: {
  },
  computed: {
    ...mapState({
      useAreas: state => state.settings.data?.useAreas || false,
    }),
    types() {
      const settings = [
        {
          order: 0,
          key: 'plant',
          text: this.$t('general.plant'),
          children: [
            {
              key: 'generalSettings',
              condition: true,
              text: `${this.$t('settings.generalSettings')}`,
              route: '/settings/plant/generalSettings',
              icon: 'fas fa-sliders',
            },
          ],
        },
        {
          order: 1,
          text: this.$t('settings.plantAssets'),
          children: [
            {
              key: 'areas',
              condition: this.useAreas,
              icon: 'fas fa-border-none',
              text: this.$t('area.areas'),
              route: '/settings/config/areas',
            },
            {
              key: 'lines',
              condition: true,
              icon: 'ion ion-ios-apps',
              text: this.$t('line.lines'),
              route: '/settings/config/lines',
            },
            {
              key: 'labelingpoints',
              condition: true,
              icon: 'icon-orders',
              text: this.$t('labellingPoint.labellingPoints'),
              route: '/settings/config/labelingpoints',
            },
            {
              key: 'printers',
              condition: true,
              icon: 'icon-print-request',
              text: `${this.$t('printer.printers')} / ${this.$t('general.devices')}`,
              route: '/settings/config/printers',
            },
            {
              key: 'mappings',
              condition: true,
              icon: 'fas fa-code-pull-request',
              text: this.$t('settings.mappings'),
              route: '/settings/config/mappings',
            },
          ],
        },
        {
          order: 2,
          key: 'order',
          text: this.$t('settings.ordersAndSku'),
          children: [
            {
              key: 'fields',
              condition: true,
              text: `${this.$t('order.fields')}`,
              route: '/settings/order/fields',
              icon: 'icon-orders',
            },
            {
              key: 'skuFields',
              condition: true,
              text: `${this.$t('sku.fields')}`,
              route: '/settings/order/skuFields',
              icon: 'fas fa-boxes',
            },
            {
              key: 'dictionaries',
              condition: true,
              text: `${this.$t('dictionary.dictionaries')}`,
              route: '/settings/order/dictionaries',
              icon: 'fas fa-book',
            },
          ],
        },
      ];

      return settings
        .filter(cat => cat.children.findIndex(c => c.condition) !== -1)
        .sort((a, b) => a.order - b.order);
    },
  },
  watch: {
    selected(key) {
      const page = this.types.reduce(
        (acc, curr) => acc || curr.children.find(c => c.key === key),
        null,
      );

      this.$emit('page', page);
    },
  },
  methods: {
    selectType({ disabled }) {
      if (disabled) return;
      this.$emit('close');
    },
  },
  created() {
    this.$emit('selected', this.$route.name);
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/vars.icss';

  .mobile {
    position: absolute;
    z-index: 100;
    width: 100%;
    left: 0;
    background-color: rgba(#fff, 0.95);
  }

  .menu-close {
    position: absolute;
    top: 15px;
    right: 25px;
    font-size: 30px;
    display: none;
  }

  .setting-category {
    font-size: 23px;
    font-weight: 300;
    padding: 10px 20px;
  }

  .setting-subcategory {
    padding-left: 20px;
    text-transform: uppercase;
    padding: 5px 15px;
    margin: 4px 0;
    font-size: 15px;
    font-weight: bold;
    color: #212529;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 200ms, box-shadow 200ms;

    &.active {
      box-shadow: 0 0 5px rgba($grey, 1);
    }

    &.disabled {
      text-decoration: line-through;
    }

    i {
      font-size: 20px;
      padding-right: 10px;
      color: rgba(100, 100, 100, 0.8);
      text-shadow: 0 0 5px rgba(100, 100, 100, 0.5);
    }

    &:hover {
      background-color: rgba(150, 150, 150, 0.1);
    }
  }

  .mobile {
    .menu-close {
      display: block;
    }
  }

</style>
