<script>
import OrderProgressBar from '@/components/cockpit/activeOrder/OrderProgressBar.vue';
import CockpitStatusBadge from '@/components/cockpit/CockpitStatusBadge.vue';
import OrderGeneralTab from '@/components/order/tabs/OrderGeneralTab.vue';

export default {
  name: 'CockpitActiveOrderDetailsTab',
  props: {
    activeOrder: Object,
  },
  components: {
    OrderGeneralTab,
    CockpitStatusBadge,
    OrderProgressBar,
  },
};
</script>

<template>
  <div class="position-relative order-data">
    <div
      v-if="false"
      class="refresh-button"
    >
      <i class="fas fa-refresh" />
      Odśwież
    </div>

    <OrderGeneralTab
      v-if="true"
      :selected="activeOrder.order"
    />

    <!--   MOCK  -->
    <div v-if="false">
      <div class="info-row">
        <div class="label">
          ID zlecenia
        </div>
        <div class="value">
          1101100
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Opis
        </div>
        <div class="value">
          Zlecenie nr. 1101100
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          SKU
        </div>
        <div class="value">
          13774
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Linia
        </div>
        <div class="value">
          Linia 2
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Sublinia
        </div>
        <div class="value">
          Sublinia C2_2
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Batch ID
        </div>
        <div class="value">
          12788
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          BBD
        </div>
        <div class="value">
          14.02.2025
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Stan zlecenia
        </div>
        <div class="value">
          <CockpitStatusBadge
            label="STARTED"
            icon="fas fa-play"
            color="#5886C1"
          />
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Postęp
        </div>
        <div class="value order-progress">
          <OrderProgressBar />
        </div>
      </div>

      <div class="line" />

      <div class="info-row">
        <div class="label">
          Linia pakowania
        </div>
        <div class="value">
          Line 1_C1
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Zaplanowana ilość
        </div>
        <div class="value">
          13 000 pcs
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Wyprodukowana ilość
        </div>
        <div class="value">
          12 453 pcs
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Wyprodukowane palety
        </div>
        <div class="value">
          20 pal
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Termin ważności
        </div>
        <div class="value">
          30 Sep 2025, 12:00:00
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.refresh-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 26px;
  color: #7F7F7F;
  background-color: #EFEFEF;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50px;
  font-weight: 500;

  &:hover {
    background-color: #D9D9D9;
  }
}

.info-row {
  display: flex;
  width: 100%;
  font-size: 12px;
  margin-bottom: 28px;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    width: 30%;
    color: #979797;
  }

  .value {
    width: 70%;
    color: #3F3F3F;

    &.order-progress {
      width: 50%;
    }

    .status-badge {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      padding: 2px 16px;
      border-radius: 4px;
      background-color: #5886C1;
      font-size: 10px;
      color: white;

      i {
        font-size: 12px;
      }
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ECECEC;
  margin-bottom: 28px;
}

.order-data {
  &:deep() {
    .label-value-row {
      margin-bottom: 28px;

      .label {
        font-size: 12px;
        font-weight: 500;
      }

      .value {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
</style>
