<template>
  <div>
    <div>
      <div>
        <div
          class="pr-3"
        >
          <div class="d-flex">
            <Tippy
              v-if="edited"
              ref="popup"
              class="popup"
              style="cursor: pointer"
              placement="bottom"
              theme="light"
              interactive
              :distance="15"
              append-to="parent"
              trigger="click"
            >
              <template #trigger>
                <div
                  :style="{ 'background-color': colorCodeEdited }"
                  class="color-box ml-2 mt-2"
                />
              </template>
              <div style="width: 140px">
                <div class="d-flex justify-content-between">
                  <div class="select-color-info">
                    {{ $t('order.selectColor') }}
                  </div>
                  <i
                    class="far fa-x select-color-icon"
                    @click="$refs.popup.tippy().hide()"
                  />
                </div>
                <ColorSelector
                  style="align-self: center; padding-top: 5px"
                  :color="colorCodeEdited"
                  @select="colorCodeEdited = $event; $refs.popup.tippy().hide()"
                />
              </div>
            </Tippy>
            <div
              v-else
              :style="{ 'background-color': colorCodeEdited }"
              class="color-box ml-2 mt-2"
            />

            <BInput
              v-model="nameEdited"
              class="col-9 mr-2"
              :disabled="!edited"
              :placeholder="$t('general.addName')"
              :state="stateOfNameField"
            />

            <div
              v-if="edited"
              class="delete"
            >
              <i
                class="far fa-times-circle"
                style="cursor: pointer"
                @click="$emit('deleteField')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ColorSelector from '@/components/selectors/ColorSelector';

export default {
  props: {
    name: String,
    colorCode: String,
    edited: Boolean,
    types: Array,
  },
  data: () => ({
    colorCodeEdited: null,
    nameEdited: null,
  }),
  components: {
    ColorSelector,
  },
  computed: {
    ...mapGetters(['maxInputLength']),
    nameLengthError() {
      if (!this.nameEdited) return false;
      return this.nameEdited.length >= this.maxInputLength;
    },
    nameExist() {
      const formattedList = this.types.map(l => l.name);
      const fieldIsDuplicate = formattedList
        .filter(f => f?.toUpperCase() === this.nameEdited?.toUpperCase()).length >= 2;

      if (!this.nameEdited) return false;
      return fieldIsDuplicate ? true : null;
    },
    stateOfNameField() {
      if (this.nameExist && this.edited) return false;
      if (this.nameLengthError) return false;
      return this.nameEdited && this.edited ? true : null;
    },
    displayedError() {
      if (this.nameExist) return this.$t('error.nameAlreadyExists');
      return this.nameLengthError ? this.$t('error.nameIsTooLong') : null;
    },
  },
  watch: {
    nameEdited() {
      this.$emit('update:name', this.nameEdited);
    },
    colorCodeEdited() {
      this.$emit('update:colorCode', this.colorCodeEdited);
    },
    displayedError(v) {
      this.$emit('errorName', v);
    },
    name() {
      this.nameEdited = this.name;
    },
    colorCode() {
      this.colorCodeEdited = this.colorCode;
    },
  },
  mounted() {
    this.nameEdited = this.name;
    this.colorCodeEdited = this.colorCode;
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";
  .color-box {
    margin-right: 10px;
    margin-top: 2px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #CED3D8;
    position: relative;
  }

  .delete {
    margin-top: 6px;
    color: #A6A6A6;
  }

  .select-color-info {
    font-size: 1.1em;
    opacity: 0.6;
    font-weight: 500;
  }

  .select-color-icon {
    font-size: 0.8em;
    opacity: 0.8;
    font-weight: bold;
    cursor: pointer;
    align-self: center;
  }

</style>
