<template>
  <div class="d-flex flex-column">
    <LModal
      size="lg"
      :show.sync="form"
    >
      <div
        class="text-center mt-3"
      >
        <div class="info-text m-2">
          {{ $t('settings.youWantToEditThisSection') }}
        </div>
        <div class="m-3">
          {{ $t('settings.editGeneralInfo') }}
        </div>
      </div>
      <hr>
      <div
        class="d-flex justify-content-center my-2"
      >
        <SaveButton
          :save-label="$t('settings.iWantToProceed')"
          @cancel="form = false"
          @save="isEdited = true; form = false"
        />
      </div>
    </LModal>

    <div class="row justify-content-between mx-0">
      <h3 style="font-size: 1.75em">
        <i class="fas fa-sliders pr-2" />
        {{ $t('settings.generalSettings') }}
      </h3>
      <div
        v-if="!isEdited"
        class="pr-2"
      >
        <button
          class="btn btn-secondary btn-block btn-sm"
          type="submit"
          @click="form = true"
        >
          <i class="fas fa-pen pr-2" />
          {{ $t('settings.editSection') }}
        </button>
      </div>
    </div>

    <div
      style="min-height: 70vh; overflow:auto"
      class="box-style flex-fill my-2 py-4"
      :style="{ 'background-color': isEdited ? 'white' : '#FBFBFC' }"
    >
      <div
        class="col-6 mb-4"
        :style="{ 'color': isEdited ? 'black' : '#969696' }"
      >
        <label class="mb-0 small font-weight-bold required mt-2">
          {{ $t('general.plantCode') }}
        </label>
        <BFormInput
          v-model="plantCode"
          :disabled="!isEdited"
          :placeholder="$t('general.plantCode') + '...'"
          :state="((plantCode && isEdited ? plantCode.length : null)
            && !lengthError(plantCode))"
        />
        <label class="mb-0 small font-weight-bold required mt-3">
          {{ $t('general.plantName') }}
        </label>
        <BFormInput
          v-model="plantName"
          :disabled="!isEdited"
          :placeholder="$t('general.plantName') + '...'"
          :state="((plantName && isEdited ? plantName.length : null)
            && !lengthError(plantName))"
        />
        <label class="mb-0 small font-weight-bold required mt-3">
          {{ $t('settings.timezone') }}
        </label>
        <BFormSelect
          v-model="timeZone"
          :disabled="!isEdited"
          :placeholder="$t('settings.timezone') + '...'"
          :options="timezones"
          :state="timeZone && isEdited ? true : null"
          type="email"
        />
      </div>
      <hr class="mx-3">
      <div
        class="my-4"
        :style="{ 'color': isEdited ? 'black' : '#969696' }"
      >
        <div class="row mx-3 mt-4">
          <BFormCheckbox
            v-model="useAreas"
            :disabled="!isEdited || areas.length > 0"
            class="text-left"
            switch
            size="lg"
          />
          <div style="line-height: 1">
            <div class="mb-0 font-weight-bold">
              {{ $t('settings.useInPlantStructure') }}
            </div>
            <span class="selector-info-text mt-0">
              {{ $t('settings.thisAppearInPlantAssets') }}
            </span>
          </div>
        </div>
        <!-- <div
          v-if="isEdited"
          class="info-box row"
        >
          <div>
            <i class="fas fa-info-circle mr-1" />
            <span class="font-weight-bold">
              {{ $t('general.info') }}:
            </span>
            <span>
              Test info desc.
            </span>
          </div>
        </div> -->
        <!-- <div class="row mx-3 mt-4">
          <BFormCheckbox
            v-model="usePalletizing"
            :disabled="!isEdited"
            class="text-left"
            switch
            size="lg"
          />
          <div style="line-height: 1">
            <div class="mb-0 font-weight-bold">
              Use “Palletizing”
            </div>
            <span class="selector-info-text mt-0">
              Explanation...
            </span>
          </div>
        </div> -->
        <div
          v-if="isEdited"
          class="info-box row"
        >
          <div>
            <i class="fas fa-info-circle mr-1" />
            <span class="font-weight-bold">
              {{ $t('general.info') }}:
            </span>
            <span>
              {{ $t('settings.areaInfo') }}
            </span>
          </div>
        </div>
      </div>
      <hr class="mx-3">
      <div
        class="d-flex row mx-1"
        :style="{ 'color': isEdited ? 'black' : '#969696' }"
      >
        <div
          class="col-6"
          style="min-height: 20vh"
        >
          <LabelingLevelsSelector
            :edited="isEdited"
            @errorName="error = $event"
            @labelingLevels="labelingLevels = $event"
          />
        </div>
        <div
          class="col-6 vl"
          style="min-height: 20vh"
        >
          <OrderTypeSelector
            :edited="isEdited"
            @errorName="error = $event"
            @orderTypes="orderTypes = $event"
          />
        </div>
      </div>
      <hr class="mx-3">
      <div
        class="mb-5"
        :style="{ 'color': isEdited ? 'black' : '#969696' }"
      >
        <h3
          style="font-size: 20px"
          class="row mx-3 mt-4 mb-0 pt-1"
        >
          {{ $t('sku.skuDeactivation') }}
        </h3>
        <div
          v-if="isEdited"
          class="info-box row"
        >
          <div>
            <i class="fas fa-info-circle mr-1" />
            <span class="font-weight-bold">
              {{ $t('general.info') }}:
            </span>
            <span>
              {{ $t('settings.skuDeactivationInfo') }}
            </span>
          </div>
        </div>
        <div class="row mx-3 mt-4">
          <BFormCheckbox
            v-model="skuDeactivationSettings.removeLabelMapping"
            :disabled="!isEdited"
            class="text-left"
            switch
            size="lg"
          />
          <div class="mt-1 font-weight-bold">
            {{ $t('settings.skuDeactivationRemoveMapping') }}
          </div>
        </div>
        <div class="row mx-3 mt-4">
          <BFormCheckbox
            v-model="skuDeactivationSettings.clearCustomFields"
            :disabled="!isEdited"
            class="text-left"
            switch
            size="lg"
          />
          <div class="mt-1 font-weight-bold">
            {{ $t('settings.skuDeactivationClearCustomFields') }}
          </div>
        </div>
        <div class="row mx-3 mt-4">
          <BFormCheckbox
            v-model="skuDeactivationSettings.clearImportFields"
            :disabled="!isEdited"
            class="text-left"
            switch
            size="lg"
          />
          <div class="mt-1 font-weight-bold">
            {{ $t('settings.skuDeactivationClearImportFields') }}
          </div>
        </div>
      </div>
      <div v-if="isEdited">
        <hr class="mx-3">
        <Error
          v-if="displayedError"
          class="mb-2 ml-3"
          :message="displayedError"
        />
        <SaveButton
          class="save-buttons"
          :show-remove="false"
          :disabled="displayedError || !obligatoryFields"
          @cancel="isEdited = false"
          @save="save()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import LabelingLevelsSelector from '@/components/settings/labelingLevels/LabelingLevelsSelector';
import OrderTypeSelector from '@/components/settings/orderType/OrderTypeSelector';

export default {
  data() {
    return {
      form: false,
      plantCode: null,
      plantName: null,
      timeZone: null,
      useAreas: false,
      usePalletizing: false,
      isEdited: false,
      settings: null,
      orderTypes: [],
      labelingLevels: [],
      error: null,
      skuDeactivationSettings: {
        removeLabelMapping: false,
        clearCustomFields: false,
        clearImportFields: false,
      },
    };
  },
  components: {
    LabelingLevelsSelector,
    OrderTypeSelector,
  },
  computed: {
    ...mapState({
      areas: state => state.areas.list || [],
    }),
    ...mapGetters(['maxInputLength']),
    timezones() {
      return moment.tz?.names();
    },
    displayedError() {
      if (this.error) return this.error;
      if (this.lengthError(this.plantCode)) return this.$t('error.codeIsTooLong');
      return this.lengthError(this.plantName) ? this.$t('error.nameIsTooLong') : null;
    },
    obligatoryFields() {
      if (
        this.plantCode
        && this.plantName
        && this.timeZone
        && (this.useAreas !== null)
      ) return true;
      return false;
    },
  },
  watch: {
    isEdited(v) {
      if (!v) {
        this.reloadSettings();
      }
    },
  },
  methods: {
    ...mapActions([
      'getGlobalSettings',
      'setGlobalSettings',
    ]),
    lengthError(text) {
      if (!text) return false;
      return text.length >= this.maxInputLength;
    },
    save() {
      this.pendingSettings = true;
      this.setGlobalSettings({
        data: {
          plantCode: this.plantCode,
          plantName: this.plantName,
          plantTimeZone: this.timeZone,
          useAreas: this.useAreas,
          usePalletizing: this.usePalletizing,
          orderTypes: this.orderTypes,
          labelingLevels: this.labelingLevels,
          skuDeactivationSettings: this.skuDeactivationSettings,
        },
      })
        .then(() => {
          this.reloadSettings();
        })
        .catch(({ response: { data } }) => {
          this.$bvToast.toast(data.detail || this.$t('request.requestFailed'), {
            title: this.$t('general.error'),
            variant: 'danger',
            autoHideDelay: 2000,
          });
          this.pendingSettings = false;
        });
    },
    reloadSettings() {
      this.getGlobalSettings()
        .then(({ data }) => {
          if (this.settings && this.isEdited) {
            this.$bvToast.toast(this.$t('general.saved'), {
              title: this.$t('general.success'),
              variant: 'success',
              autoHideDelay: 2000,
            });
          }
          this.settings = data;
          this.plantCode = data.plantCode;
          this.plantName = data.plantName;
          this.timeZone = data.plantTimeZone;
          this.useAreas = data.useAreas ?? false;
          this.skuDeactivationSettings = data.skuDeactivationSettings;
        })
        .finally(() => {
          this.pendingSettings = false;
        });
    },
  },
  created() {
    this.reloadSettings();
  },
};
</script>

<style lang="scss" scoped>
  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 40px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .info-box {
    padding: 10px;
    margin: 10px 15px;
    border-radius: 7px;
    background-color: #DDECFF;
    color: #3D69A3;
    flex-flow: row;
  }

  .info-text {
    font-size: 1.8em;
    font-weight: 600;
  }

  .selector-info-text {
    font-size: 0.8em;
    opacity: 0.6;
    font-weight: 500;
    font-style: italic;
  }

  .vl {
    border-left: 1px solid rgba(0, 0, 0, 0.1);;
  }

</style>
