<template>
  <div class="row">
    <div>
      <BFormGroup
        class="pl-3"
        :label="$t('general.type').toUpperCase()"
      >
        <BFormRadioGroup
          id="btn-radios-1"
          v-model="selectedView"
          button-variant="outline-success"
          name="radio-btn-outline"
          size="sm"
          buttons
        >
          <template v-for="option in viewOptions">
            <BFormRadio
              :key="option.text"
              :value="option.value"
            >
              <span class="py-2 px-3">
                <i
                  :class="{
                    'fas fa-eye': option.value === 'active',
                    'fas fa-eye-slash': option.value === 'inactive'
                  }"
                  class="pr-1"
                />
                {{ option.text }}
              </span>
            </BFormRadio>
          </template>
        </BFormRadioGroup>
      </BFormGroup>
    </div>

    <div
      class="px-3 pt-3"
      style="align-self: center; flex: auto"
    >
      <SearchInput @input="$emit('updateSearch', $event)" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    view: String,
  },
  data: () => ({
    selectedView: 'active',
  }),
  computed: {
    viewOptions() {
      return [
        { text: this.$t('sku.active'), value: 'active' },
        { text: this.$t('sku.inactive'), value: 'inactive' },
      ];
    },
  },
  watch: {
    selectedView(value) {
      this.$emit('updateView', value);
    },
  },
  created() {
    this.selectedView = this.view;
  },
};
</script>

<style  lang="scss" scoped>

  .search {
    border-radius: .25rem;
  }

</style>
