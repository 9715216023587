import Vapi from 'vuex-rest-api';
import moment from 'moment';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getLabelsLabellingPointsMappings',
    property: 'list',
    path: () => '/labelingpointlabelmappings',
  })
  .getStore();

store.getters = {
  labelsLabellingPointsMappings(state, getters, rootState) {
    if (!state.list || !rootState.labels.templates || !rootState.labelingPoints.list) return [];

    const withName = state.list.map(llp => {
      const label = rootState.labels.templates.find(l => l.id === llp.labelId);
      const labelingPoint = rootState.labelingPoints.list.find(lp => lp.id === llp.labelingPointId);
      return {
        ...llp,
        labelName: label ? label.name : '',
        labelingPointName: labelingPoint ? labelingPoint.name : '',
        created: moment.utc(llp.created).local().format('ll LTS'),
      };
    });

    return withName;
  },
};

export default store;
