<template>
  <div>
    <div class="font-weight-bold">
      {{ templateName }}
    </div>
    <form @submit.prevent="submit">
      <BFormSelect
        v-model="culture"
        class="mt-2"
        :options="cultureOptions"
      />

      <div>
        <SaveButton
          :pending="pending"
          @cancel="$emit('close');"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    templateId: [Number, String],
  },
  data: () => ({
    culture: '',
    pending: false,
  }),
  computed: {
    ...mapState({
      templates: state => state.labels.templates || [],
    }),
    templateName() {
      if (!this.templates) return '';
      const t = this.templates.find(x => x.id === this.templateId);
      return t ? t.name : '';
    },
    cultureOptions() {
      return [
        'pl',
        'en',
        'fr',
        'es',
        'de',
        'fi',
        'lt',
        'ro',
      ]
        .map(x => ({ value: x, text: x }));
    },
  },
  methods: {
    ...mapActions(['setLabelCulture']),
    submit() {
      this.pending = true;
      this.setLabelCulture({
        params: {
          labelId: this.templateId,
        },
        data: {
          culture: this.culture,
        },
      })
        .then(() => {
          this.$emit('close');
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    const t = this.templates.find(x => x.id === this.templateId);
    if (t) {
      this.culture = t.culture;
    }
  },
};
</script>

<style>

</style>
