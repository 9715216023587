import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    units: [],
    packingSettings: [],
  },
})
  .get({
    action: 'getUnits',
    property: 'units',
    path: ({ plantCode }) => `/plant/${plantCode}/units`,
  })
  .get({
    action: 'getPackingSettings',
    property: 'packingSettings',
    path: ({ plantCode }) => `/plant/${plantCode}/packing-settings`,
  })
  .getStore({
    namespaced: true,
  });

store.getters = {
  getHigherLevelUnit: s => (unitId, skuId) => {
    const level = s.packingSettings.find(ps => ps.fromUnit.id === unitId && ps.skuId === skuId);
    return level ? level.toUnit : null;
  },
  getLowerLevelUnit: s => (unitId, skuId) => {
    const level = s.packingSettings.find(ps => ps.toUnit.id === unitId && ps.skuId === skuId);
    return level ? level.fromUnit : null;
  },
  getUnitById: s => unitId => s.units.find(u => u.id === unitId),
  getUnitsForSku: s => skuId => {
    const connections = (s.packingSettings || []).filter(ps => ps.skuId === skuId);
    const levels = connections.flatMap(ps => [ps.fromUnit, ps.toUnit]);
    return levels.filter((unit, index, array) => array.findIndex(u => u.id === unit.id) === index);
  },
};

export default store;
