import { render, staticRenderFns } from "./OrderForm.vue?vue&type=template&id=64822630&scoped=true"
import script from "./OrderForm.vue?vue&type=script&lang=js"
export * from "./OrderForm.vue?vue&type=script&lang=js"
import style0 from "./OrderForm.vue?vue&type=style&index=0&id=64822630&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64822630",
  null
  
)

export default component.exports