<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CockpitMatryoshkaItemLabelTab',
  props: {
    labelArchiveId: Number,
  },
  data: () => ({
    loadingDetails: false,
    itemDetails: {},
  }),
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    labelDataItems() {
      return this.itemDetails?.labelData || [];
    },
  },
  watch: {
    labelArchiveId: {
      handler() {
        this.getDetails();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('labelArchive', [
      'getLabelArchiveItem',
    ]),
    async fetchLabelArchiveItem() {
      if (!this.labelArchiveId) return;

      const { plantCode } = this.globalSettings;
      const { data } = await this.getLabelArchiveItem({
        params: {
          id: this.labelArchiveId,
          plantCode,
        },
      });
      this.itemDetails = { ...data };
    },
    async getDetails() {
      this.loadingDetails = true;

      await this.fetchLabelArchiveItem();

      this.loadingDetails = false;
    },
  },
};
</script>

<template>
  <div class="position-relative">
    <div class="label-preview" />

    <div
      v-for="item in labelDataItems"
      :key="item.varName"
      class="info-row"
    >
      <div class="label">
        {{ item.varName }}
      </div>
      <div class="value">
        {{ item.value }}
      </div>
    </div>

    <div
      v-if="false"
      class="line"
    />
  </div>
</template>

<style scoped lang="scss">
.label-preview {
  position: absolute;
  top: 0;
  right: 0;
  width: 144px;
  height: 200px;
  background-color: #D9D9D9;
  border-radius: 4px;
  cursor: zoom-in;
  transition: 0.3s all;

  &:hover {
    transform: scale(2.5) translate(-25%, 25%);
  }
}

.info-row {
  display: flex;
  width: 100%;
  font-size: 12px;
  margin-bottom: 28px;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    width: 30%;
    color: #979797;
  }

  .value {
    width: 70%;
    color: #3F3F3F;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ECECEC;
  margin-bottom: 28px;
}
</style>
