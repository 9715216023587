<template>
  <div>
    <div
      class="d-flex align-items-center"
    >
      <div class="d-flex align-items-center flex-fill small-label ml-2">
        <div class="flex-fill">
          {{ $t('dictionary.key') }}
        </div>
        <div class="flex-fill">
          {{ $t('general.value') }}
        </div>
      </div>
      <i
        v-tippy="{ placement : 'right' }"
        :content="$t('form.fieldValues')"
        class="ion ion-md-information-circle-outline icon-hoverable"
        style="width: 23px"
      />
    </div>
    <div
      ref="entriesContainer"
      style="max-height: 40vh; overflow-y: auto"
    >
      <div
        v-for="(entry, index) in entriesEdited"
        :key="entry.localIndex"
      >
        <DictionaryEntryEditor
          :entry-key.sync="entry.key"
          :entry-value.sync="entry.value"
          :entry-errors.sync="entryErrors"
          :all-dictionary-entries="entriesEdited"
          :can-key-be-edited="true"
          :disabled="disabled"
          @deleteEntry="deleteEntry(index)"
        />
      </div>
    </div>
    <div
      v-if="!disabled"
      class="d-flex justify-content-center mt-3"
    >
      <BButton
        size="sm"
        class="rounded-pill px-4"
        @click="addNewEntry"
      >
        <i class="fas fa-plus mr-1" /> {{ $t('template.addEntry') }}
      </BButton>
    </div>
  </div>
</template>

<script>
import DictionaryEntryEditor from '@/components/settings/dictionaries/DictionaryEntryEditor';

export default {
  props: {
    selectEntries: Array,
    disabled: Boolean,
  },
  data: () => ({
    entryErrors: false,
    entriesEdited: [],
  }),
  components: {
    DictionaryEntryEditor,
  },
  watch: {
    entriesEdited() {
      this.$emit('update:selectEntries', this.entriesEdited);
    },
  },
  methods: {
    scrollToEntriesEnd() {
      const container = this.$refs.entriesContainer;
      container.scrollTop = container.scrollHeight;
    },
    addNewEntry() {
      this.entriesEdited = [
        ...this.entriesEdited,
        {
          key: null,
          value: null,
          newlyCreated: true,
          localIndex: this.entriesEdited.length > 0 ? Math.max(...this.entriesEdited.map(x => x.localIndex)) + 1 : 0,
        },
      ];
      setTimeout(() => {
        this.scrollToEntriesEnd();
      }, 1);
    },
    deleteEntry(index) {
      this.entriesEdited.splice(index, 1);
    },
  },
  created() {
    this.entriesEdited = this.selectEntries;
  },
};

</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

  .small-label {
    font-size: 12px;
    color:#495057;
  }
  .icon-hoverable {
    transition: transform 300ms;
    cursor: pointer;
    color: rgba(#333, 0.5);

    &:hover {
      transform: scale(1.2);
      color: rgba(#333, 1);
    }
  }

</style>
