<template>
  <div>
    <CodeEditor
      class="custom-editor"
      :style="`--height-var: ${editorHeight};`"
      :value="value"
      :sku-fields="skuPropertyTypes"
      :orders-fileds="ordersPropertyTypesExtended"
      :base-properties="ordersBaseProperties"
      @update:value="$emit('update:value', $event)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CodeEditor from '@/components/mapper/CodeEditor';

export default {
  props: {
    value: String,
  },
  data: () => ({
    baseProperties: null,
    unfolded: false,
  }),
  components: {
    CodeEditor,
  },
  computed: {
    ...mapState({
      skuPropertyTypes: state => state.skuProperties.types || [],
      ordersPropertyTypes: state => state.orders.properties || [],
      ordersBaseProperties: state => state.orders.baseProps || [],
    }),
    ordersPropertyTypesExtended() {
      const basePropType = { name: 'BaseProperties', valueType: 'String' };
      return this.ordersPropertyTypes.concat(basePropType);
    },
    editorHeight() {
      const numberOfLines = this.value?.split('\n').length;
      const height = `${Math.min(50, 10 + Math.floor(numberOfLines / 3) * 10)}vh`;
      return height;
    },
  },
};
</script>

<style lang="scss" scoped>

    .custom-editor::v-deep .CodeMirror {
      height: var(--height-var);
    }

</style>
