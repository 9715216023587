function prepareFileName(name) {
  const nameWithoutForbiddenCharacters = name.replace(/ |\./g, '_');
  return `${nameWithoutForbiddenCharacters}.png`;
}

export function getFileNameForPreview(template, sku, order) {
  const name = `${template}-${order}-${sku}`;
  return prepareFileName(name);
}

export function getFileNameForSkuOnlyPreview(template, sku) {
  const name = `${template}-${sku}`;
  return prepareFileName(name);
}

export function getFileNameForEmptyPreview(template) {
  const name = `${template}`;
  return prepareFileName(name);
}
