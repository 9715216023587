<template>
  <div
    class="box-item pr-3"
  >
    <div class="d-flex align-items-center">
      <BInput
        v-model="entryKeyEdited"
        :disabled="!canKeyBeEdited || disabled"
        :placeholder="$t('general.addKey')"
        class="flex-fill mr-2"
        :state="(entryKeyEdited !== null && canKeyBeEdited && !disabled ? true : null)
          && !entryKeyExists && !entryKeyTooLongError && !entryKeyIsRequiredError"
      />
      <BInput
        v-model="entryValueEdited"
        :disabled="disabled"
        :placeholder="$t('general.addValue')"
        class="flex-fill mr-2"
        :state="entryValueEdited && !disabled ? true : null"
      />
      <div
        v-if="!disabled"
        v-tippy="{
          arrow: true,
          placement: 'left',
          trigger: !canKeyBeEdited ? 'mouseenter' : ''
        }"
        class="flex-fill delete"
        :content="$t('mapper.thisCanBreakTheRules')"
      >
        <i
          class="far fa-times-circle"
          style="cursor: pointer;"
          @click="$emit('deleteEntry')"
        />
      </div>
    </div>
    <Error
      v-if="displayedError"
      class="mt-1"
      :message="displayedError"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    entryKey: String,
    entryValue: String,
    canKeyBeEdited: Boolean,
    allDictionaryEntries: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    entryKeyEdited: null,
    entryValueEdited: null,
  }),
  computed: {
    ...mapGetters(['maxInputLength']),
    displayedError() {
      if (this.canKeyBeEdited && this.entryKeyTooLongError) return this.$t('error.keyIsTooLong');
      if (this.entryKeyIsRequiredError) return this.$t('error.keyIsRequired');
      if (this.entryKeyExists) return this.$t('error.keyAlreadyExists');
      return null;
    },
    entryKeyTooLongError() {
      return this.entryKeyEdited?.length >= this.maxInputLength ? true : null;
    },
    entryKeyIsRequiredError() {
      return this.entryKeyEdited?.length === 0;
    },
    entryKeyExists() {
      if (!this.entryKeyEdited) return false;
      return this.allDictionaryEntries
        .filter(l => l.key?.toUpperCase() === this.entryKeyEdited?.toUpperCase())?.length >= 2;
    },
  },
  watch: {
    entryKeyEdited() {
      this.$emit('update:entryKey', this.entryKeyEdited);
    },
    entryValueEdited() {
      this.$emit('update:entryValue', this.entryValueEdited);
    },
    displayedError() {
      this.$emit('update:entryErrors', this.displayedError);
    },
  },
  created() {
    this.entryKeyEdited = this.entryKey;
    this.entryValueEdited = this.entryValue;
  },
};
</script>

<style lang="scss" scoped>
  .box-item {
    background: #F4F4F4;
    padding: 5px;
    padding-right: 50px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: relative;

    .form-control {
      &:disabled {
        background-color: #F4F4F4 !important;
      }
    }

    &:hover {
      background: #ececec !important;
    }

    .delete {
      color: #A6A6A6;
    }
  }
</style>
