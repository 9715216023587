import Vapi from 'vuex-rest-api';
import Vue from 'vue';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getDictionaries',
    property: 'list',
    path: () => '/dictionaries',
    onSuccess: (s, { data }) => {
      s.list = data.map(dict => ({
        ...dict,
        entries: dict.entries.sort((a, b) => (a.key).localeCompare(b.key)),
      }));
    },
  })
  .post({
    action: 'createDictionary',
    path: () => '/dictionary',
    onSuccess: (s, { data }) => {
      if (s.list) {
        data.entries.sort((a, b) => (a.key).localeCompare(b.key));
        s.list.push(data);
      }
    },
  })
  .put({
    action: 'updateDictionary',
    path: ({ id }) => `/dictionary/${id}`,
    onSuccess: (s, { data }) => {
      if (s.list) {
        data.entries.sort((a, b) => (a.key).localeCompare(b.key));
        const index = s.list.findIndex(x => x.id === data.id);
        Vue.set(s.list, index, data);
      }
    },
  })
  .delete({
    action: 'deleteDictionary',
    path: ({ id }) => `/dictionary/${id}`,
    onSuccess: (s, _, a, { params }) => {
      if (s.list) {
        s.list = s.list.filter(x => x.id !== params.id);
      }
    },
  })
  .getStore();

export default store;
