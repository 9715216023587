<template>
  <div class="d-flex flex-column">
    <LModal
      size="lg"
      :show.sync="form"
    >
      <MappingsEditor
        v-bind.sync="selected"
        @successMsg="showSuccessMsg()"
        @failedMsg="showFailedMsg($event)"
        @close="form = false; getAllLabelingMappings()"
      />
    </LModal>

    <div class="row justify-content-between mx-0">
      <h3 style="font-size: 1.75em">
        <i class="fas fa-code-pull-request pr-2" />
        {{ $t('mappings.mappings') }}
      </h3>
    </div>

    <div
      style="min-height: 70vh; overflow:auto"
      class="box-style flex-fill my-2"
    >
      <div class="row m-0">
        <AreaFilterSelector
          v-if="useAreas"
          :box-height="'26px'"
        />
        <LineFilterSelector
          :box-height="'26px'"
        />
        <div
          class="pt-2"
          style="align-self: center; flex: auto"
        >
          <SearchInput @input="search = $event" />
        </div>
      </div>
      <SimpleTable
        class="table-style"
        :items="searchedMappings"
        :fields="columns"
        @row-clicked="setSelected($event)"
      >
        <template #cell(lines)="data">
          <div>
            <div
              class="row px-2"
            >
              {{ data.item.lines[0] ? data.item.lines[0].name : '-' }}
              <span v-if="data.item.lines[1]">
                {{ ',' }}  {{ data.item.lines[1].name }}
              </span>
              <span
                v-if="data.item.lines[2]"
                class="pl-3 row"
              >
                <span class="pr-1">
                  {{ '...' }}
                </span>
                <span>
                  <Tippy
                    theme="dark"
                    arrow
                    interactive
                    placement="bottom"
                  >
                    <template #trigger>
                      <span
                        class="badge badge-success pb-1"
                        style="border-radius: 10px"
                      >
                        {{ $t('general.seeMore').toLowerCase() }}
                      </span>
                    </template>
                    <span
                      v-for="(value, prop) in data.item.lines"
                      :key="prop"
                    >
                      {{ value.name }} <br>
                    </span>
                  </Tippy>
                </span>
              </span>
            </div>
          </div>
        </template>
        <template #cell(printers)="data">
          <div>
            <div
              class="row px-2"
            >
              {{ data.item.printers[0] ? data.item.printers[0].name : '-' }}
              <span v-if="data.item.printers[1]">
                {{ ',' }}  {{ data.item.printers[1].name }}
              </span>
              <span
                v-if="data.item.printers[2]"
                class="pl-3 row"
              >
                <span class="pr-1">
                  {{ '...' }}
                </span>
                <span>
                  <Tippy
                    theme="dark"
                    arrow
                    interactive
                    placement="bottom"
                  >
                    <template #trigger>
                      <span
                        class="badge badge-success pb-1"
                        style="border-radius: 10px"
                      >
                        {{ $t('general.seeMore').toLowerCase() }}
                      </span>
                    </template>
                    <span
                      v-for="(value, prop) in data.item.printers"
                      :key="prop"
                    >
                      {{ value.name }} <br>
                    </span>
                  </Tippy>
                </span>
              </span>
            </div>
          </div>
        </template>
        <template #cell(labels)="data">
          <div>
            <div
              class="row px-2"
            >
              {{ data.item.labels[0] ? data.item.labels[0].name : '-' }}
              <span v-if="data.item.labels[1]">
                {{ ',' }}  {{ data.item.labels[1].name }}
              </span>
              <span
                v-if="data.item.labels[2]"
                class="pl-3 row"
              >
                <span class="pr-1">
                  {{ '...' }}
                </span>
                <span>
                  <Tippy
                    theme="dark"
                    arrow
                    interactive
                    placement="bottom"
                  >
                    <template #trigger>
                      <span
                        class="badge badge-success pb-1"
                        style="border-radius: 10px"
                      >
                        {{ $t('general.seeMore').toLowerCase() }}
                      </span>
                    </template>
                    <span
                      v-for="(value, prop) in data.item.labels"
                      :key="prop"
                    >
                      {{ value.name }} <br>
                    </span>
                  </Tippy>
                </span>
              </span>
            </div>
          </div>
        </template>
        <template #cell(orderTypes)="data">
          <div>
            <div
              class="row px-2"
            >
              {{ data.item.orderTypes[0] ? data.item.orderTypes[0].name : '-' }}
              <span v-if="data.item.orderTypes[1]">
                {{ ',' }}  {{ data.item.orderTypes[1].name }}
              </span>
              <span
                v-if="data.item.orderTypes[2]"
                class="pl-3 row"
              >
                <span class="pr-1">
                  {{ '...' }}
                </span>
                <span>
                  <Tippy
                    theme="dark"
                    arrow
                    interactive
                    placement="bottom"
                  >
                    <template #trigger>
                      <span
                        class="badge badge-success pb-1"
                        style="border-radius: 10px"
                      >
                        {{ $t('general.seeMore').toLowerCase() }}
                      </span>
                    </template>
                    <span
                      v-for="(value, prop) in data.item.orderTypes"
                      :key="prop"
                    >
                      {{ value.name }} <br>
                    </span>
                  </Tippy>
                </span>
              </span>
            </div>
          </div>
        </template>
      </SimpleTable>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import createSearch from '@core/utils/search';
import SimpleTable from '@/components/tables/SimpleTable';
import AreaFilterSelector from '@/components/selectors/AreaFilterSelector';
import LineFilterSelector from '@/components/selectors/LineFilterSelector';
import MappingsEditor from './MappingsEditor';

export default {
  data() {
    return {
      form: false,
      selected: null,
      search: null,
    };
  },
  components: {
    MappingsEditor,
    SimpleTable,
    AreaFilterSelector,
    LineFilterSelector,
  },
  computed: {
    ...mapGetters([
      'timeOfNotification',
      'selectedFilterAreaId',
      'selectedFilterLineId',
      'linesFromArea']),
    ...mapState({
      useAreas: state => state.settings.data?.useAreas || false,
      lines: state => state.lines.list || [],
      mappings: state => state.settings.list || [],
      orderTypes: state => state.orderTypes.list,
    }),
    filteredLines() {
      const selectedLines = this.linesFromArea(this.selectedFilterAreaId);
      return selectedLines.length ? selectedLines : this.lines;
    },
    searchedMappings() {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      const lines = this.selectedFilterAreaId && !this.selectedFilterLineId
        ? this.filteredLines.map(l => l.id)
        : [this.selectedFilterLineId];
      const search = createSearch(this.search);
      const selectedMappings = this.mappings
        .filter(m => (lines[0] ? m.lines.map(x => x.id).some(l => lines.includes(l)) : m));
      return selectedMappings?.filter(({ name, type }) => search(name) || search(type))
        .map(a => ({
          ...a,
          modified: moment.utc(a.modified).add(plantUtcOffset, 'minutes').format('ll LTS'),
          created: moment.utc(a.created).add(plantUtcOffset, 'minutes').format('ll LTS'),
          orderTypes: a.allowAllOrderTypes ? this.orderTypes : a.orderTypes,
        }));
    },
    columns() {
      return [
        {
          key: 'name',
          label: this.$t('labellingPoint.labellingPoint'),
          sortable: true,
        },
        {
          key: 'lines',
          label: this.$t('line.lines'),
          class: 'text-left',
        },
        {
          key: 'printers',
          label: this.$t('printer.printers'),
          class: 'text-left',
        },
        {
          key: 'labels',
          label: this.$t('labelTemplates'),
          class: 'text-left',
        },
        {
          key: 'orderTypes',
          label: this.$t('order.orderTypes'),
          sortable: true,
        },
      ];
    },
    fields() {
      return this.selected ? Object.entries(this.selected).map(([key, value]) => ({ key, value })) : [];
    },
  },
  methods: {
    ...mapActions([
      'getAllLabelingMappings',
    ]),
    showSuccessMsg() {
      this.$bvToast.toast(this.$t('general.saved'), {
        title: this.$t('general.success'),
        variant: 'success',
        autoHideDelay: this.timeOfNotification,
      });
    },
    showFailedMsg(msg) {
      this.$bvToast.toast(msg || this.$t('request.requestFailed'), {
        title: this.$t('general.error'),
        variant: 'danger',
        autoHideDelay: this.timeOfNotification,
      });
    },
    setSelected(event) {
      this.selected = event;
      this.form = true;
    },
  },
  created() {
    this.getAllLabelingMappings();
  },
};
</script>

<style lang="scss" scoped>
  .table-style :deep(th) {
    padding: 0 0.75rem !important;
  }

  .table-style :deep(thead) {
    tr {
      height: 30px !important;
    }
  }

  .table-style :deep(tbody) {
    tr {
      height: 40px;
    }
  }

  .search {
    border-radius: .25rem;
  }

</style>
