<template>
  <div>
    <div>
      <div>
        <div
          class="pr-3"
        >
          <div class="d-flex">
            <div class="grip">
              <i class="fas fa-grip-lines" />
            </div>
            <BInput
              v-model="nameEdited"
              class="col-9 mr-2"
              :disabled="!edited"
              :placeholder="$t('general.addName')"
              :state="stateOfNameField"
            />

            <div
              v-if="edited"
              class="delete"
            >
              <i
                class="far fa-times-circle"
                style="cursor: pointer"
                @click="$emit('deleteField')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    name: String,
    edited: Boolean,
    levels: Array,
  },
  data: () => ({
    nameEdited: null,
  }),
  computed: {
    ...mapGetters(['maxInputLength']),
    nameLengthError() {
      if (!this.nameEdited) return false;
      return this.nameEdited.length >= this.maxInputLength;
    },
    nameExist() {
      const formattedList = this.levels.map(l => l.name);
      const fieldIsDuplicate = formattedList
        .filter(f => f?.toUpperCase() === this.nameEdited?.toUpperCase()).length >= 2;

      if (!this.nameEdited) return false;
      return fieldIsDuplicate ? true : null;
    },
    stateOfNameField() {
      if (this.nameExist && this.edited) return false;
      if (this.nameLengthError) return false;
      return this.nameEdited && this.edited ? true : null;
    },
    displayedError() {
      if (this.nameExist) return this.$t('error.nameAlreadyExists');
      return this.nameLengthError ? this.$t('error.nameIsTooLong') : null;
    },
  },
  watch: {
    nameEdited() {
      this.$emit('update:name', this.nameEdited);
    },
    displayedError(v) {
      this.$emit('errorName', v);
    },
    name() {
      this.nameEdited = this.name;
    },
  },
  mounted() {
    this.nameEdited = this.name;
  },
};
</script>

<style lang="scss" scoped>

  .delete {
    margin-top: 6px;
    color: #A6A6A6;
  }

  .grip {
    align-self: center;
    margin-right: 10px;
    color: #A6A6A6;
  }

</style>
