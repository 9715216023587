<template>
  <div>
    <h5
      class="mb-0 mx-3"
    >
      <span v-if="!printerId">
        <i class="ion ion-md-add pr-2" />
        {{ $t('printer.addPrinter') }}
      </span>

      <span v-else>
        <i class="fas fa-pen pr-2" />
        {{ $t('printer.editPrinter') }}
      </span>
    </h5>

    <div class="mx-3">
      <div
        v-if="printerId"
        class="my-3"
      >
        <label class="mb-1 table-title">
          {{ $t('general.id') }}
        </label>
        <BFormInput
          v-model="printerId"
          disabled
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('general.name') }}
        </label>
        <BFormInput
          v-model="localName"
          :placeholder="$t('general.name') +'...'"
          :disabled="!!printerId"
          :state="stateOfNameField"
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          <span>
            {{ $t('general.description') }}
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </label>
        <BFormInput
          id="textarea"
          v-model="localDescription"
          :placeholder="$t('general.description') +'...'"
          :state="((localDescription && !id ? localDescription.length : null)
            && !printerDescError) || (!printerDescError && id?.length)"
          rows="1"
        />
      </div>
      <div class="my-3">
        <label class="mb-1 table-title">
          <span>
            IP
          </span>
          <span class="optional-text">
            - {{ $t('form.optional') }}
          </span>
        </label>
        <BFormInput
          v-model="localIp"
          :state="localIp && !id ? true : null"
        />
      </div>
      <hr>
      <div class="my-3">
        <label class="mb-1 table-title">
          {{ $t('printer.type') }}
        </label>
        <BFormSelect
          v-model="localPrinterType"
          :options="printerTypes"
          :state="localPrinterType && !id ? true : null"
        />
      </div>
      <div class="my-3">
        <div class="d-flex my-3">
          <BFormCheckbox
            v-model="localAreUploadedLabelsAvailable"
            class="text-left"
            switch
            size="lg"
          />
          <div class="table-title mt-1">
            <span>
              {{ $t('printer.uploadedLabelsAvailable') }}
            </span>
          </div>
        </div>
        <hr>
        <div class="d-flex my-3">
          <BFormCheckbox
            v-model="advanceMode"
            class="text-left"
            switch
            size="lg"
          />
          <div class="table-title mt-1">
            <span>
              {{ $t('general.showAdvanceMode') }}
            </span>
          </div>
        </div>
        <span v-if="advanceMode">
          <label class="mb-1 table-title">
            {{ $t('general.deviceSettings') }}
          </label>
          <BFormTextarea
            id="textarea"
            v-model="advanceDeviceSettings"
            :placeholder="$t('general.deviceSettings') + '...'"
            rows="1"
            max-rows="6"
          />
        </span>
        <span v-else>
          <BFormGroup
            v-for="(options, propertyName) in deviceSettingsOptions"
            :key="propertyName"
          >
            <label class="mb-1 table-title">
              {{ propertyName }}
            </label>
            <BInputGroup
              v-if="options.length === 0"
            >
              <BFormInput
                v-model="localDeviceSettings[propertyName]"
                :state="localDeviceSettings[propertyName] && !id ? true : null"
              />
            </BInputGroup>
            <BInputGroup
              v-else
            >
              <BFormSelect
                v-model="localDeviceSettings[propertyName]"
                :options="options"
                :state="localDeviceSettings[propertyName] && !id ? true : null"
              />
            </BInputGroup>
          </BFormGroup>
        </span>
      </div>
      <hr v-if="printerId">
      <div
        v-if="printerId"
        class="d-flex row my-3"
      >
        <div class="col-6">
          <label class="mb-1 table-title">
            {{ $t('general.created') }}
          </label>
          <BFormInput
            disabled
            :value="created"
          />
        </div>
        <div class="col-6">
          <label class="mb-1 table-title">
            {{ $t('general.modified') }}
          </label>
          <BFormInput
            disabled
            :value="modified"
          />
        </div>
      </div>
      <hr>
    </div>

    <div>
      <Error
        v-if="displayedError"
        class="mb-2 ml-3"
        :message="displayedError"
      />
      <SaveButton
        class="save-buttons"
        :pending="pending"
        :show-remove="!!printerId"
        :disabled="(!id && !errorCheck) || (displayedError ? true : false)"
        @cancel="$emit('close')"
        @save="!printerId ? create() : update() "
        @remove="remove()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  props: {
    id: Number,
    name: String,
    ip: String,
    modified: String,
    created: String,
    description: String,
    printerType: String,
    printerMode: String,
    deviceSettings: Object,
    areUploadedLabelsAvailable: Boolean,
  },
  data: () => ({
    pending: false,
    localName: '',
    localIp: '',
    localPrinterType: '',
    localPrinterMode: '',
    localDescription: '',
    localDeviceSettings: {},
    localAreUploadedLabelsAvailable: true,
    deviceSettingsOptions: {},
    advanceMode: false,
    printerId: null,
  }),
  computed: {
    ...mapState({
      printers: state => state.printers.list || [],
      printerTypes: state => (!state.discovery.dict ? [] : state.discovery.dict.PrinterTypes),
    }),
    ...mapGetters([
      'timeOfNotification',
      'maxInputLength',
      'maxDescriptionLength',
    ]),
    advanceDeviceSettings: {
      get() {
        return this.serializeJson(this.localDeviceSettings);
      },
      set(value) {
        this.localDeviceSettings = this.parseJson(value);
      },
    },
    printerNameExist() {
      if (!this.localName) return false;
      return this.printers.find(l => l.name?.toUpperCase() === this.localName?.toUpperCase()) ? true : null;
    },
    stateOfNameField() {
      if (this.printerNameExist && !this.id) return false;
      if (this.printerNameExist && this.id && this.name !== this.localName) return false;
      if (this.printerNameError) return false;
      return this.localName && !this.id ? true : null;
    },
    printerNameError() {
      if (!this.localName) return false;
      return this.localName.length >= this.maxInputLength;
    },
    printerDescError() {
      if (!this.localDescription) return false;
      return this.localDescription.length >= this.maxDescriptionLength;
    },
    displayedError() {
      if (this.printerNameExist && !this.id) return this.$t('error.nameAlreadyExists');
      if (this.printerNameExist && this.id && this.name !== this.localName) return this.$t('error.nameAlreadyExists');
      if (this.printerDescError) return this.$t('error.descriptionIsTooLong');
      return this.printerNameError ? this.$t('error.nameIsTooLong') : null;
    },
    errorCheck() {
      if (
        this.localName
        && this.localPrinterType
        && !this.printerNameExist
        && !this.printerNameError
        && !this.printerDescError
      ) return true;
      return false;
    },
  },
  watch: {
    localPrinterType(type) {
      this.getOptions(type);
    },
  },
  methods: {
    ...mapActions([
      'getPrinters',
      'addPrinter',
      'deletePrinter',
      'updatePrinter',
      'getPrinterTypeOptions',
    ]),
    getOptions(printerType) {
      this.getPrinterTypeOptions({
        params: {
          printerType,
        },
      }).then(data => {
        this.deviceSettingsOptions = data.data;
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    create() {
      this.addPrinter({
        data: {
          name: this.localName,
          localName: this.localName,
          description: this.localDescription,
          ip: this.localIp,
          printerType: this.localPrinterType,
          deviceSettings: this.parseJson(this.localDeviceSettings),
          areUploadedLabelsAvailable: this.localAreUploadedLabelsAvailable,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('form.printerSuccessfullyCreated'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    remove() {
      this.deletePrinter({
        params: {
          id: this.printerId,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('form.printerSuccessfullyRemoved'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    update() {
      this.updatePrinter({
        params: {
          id: this.printerId,
        },
        data: {
          name: this.localName,
          localName: this.localName,
          ip: this.localIp,
          printerType: this.localPrinterType,
          printerMode: this.localPrinterMode,
          description: this.localDescription,
          deviceSettings: this.parseJson(this.localDeviceSettings),
          areUploadedLabelsAvailable: this.localAreUploadedLabelsAvailable,
        },
      }).then(() => {
        this.request();
        this.$emit('successMsg', this.$t('general.modified'));
        this.$emit('close');
      })
        .catch(({ response: { data } }) => {
          this.$emit('failedMsg', data?.message);
        });
    },
    parseJson(data) {
      try {
        let json = data;
        if (typeof data === 'object') {
          json = this.serializeJson(data);
        }
        return JSON.parse(json);
      } catch (e) {
        this.$bvToast.toast(e.message, {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
        throw e;
      }
    },
    serializeJson(o) {
      return JSON.stringify(o, null, '\t');
    },
    request() {
      this.getPrinters();
    },
  },
  created() {
    if (this.id) {
      this.localPrinterType = this.printerType.toUpperCase();
      this.localPrinterMode = this.printerMode;
      this.localName = this.name;
      this.localIp = this.ip;
      this.localDescription = this.description;
      this.localDeviceSettings = this.deviceSettings ?? {};
      this.localAreUploadedLabelsAvailable = this.areUploadedLabelsAvailable;
      this.printerId = this.id;

      this.getOptions(this.localPrinterType);
    }
  },
};
</script>

<style lang="scss" scoped>

  .table-title {
    font-weight: 700;
    font-size: 14px;
  }

  .optional-text {
    font-weight: 500;
    opacity: 0.6;
    font-style: italic;
  }

</style>
