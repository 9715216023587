<template>
  <div class="modal-style">
    <div class="order-style">
      <OrderExecution
        :key="orderId"
        :order="order"
        :no-labeling-point="true"
      />
    </div>
    <div class="pt-4 box-style">
      <h5 style="text-align: center">
        <span class="font-weight-bold pl-3">
          {{ $t('order.finishOrder') }}
        </span>
      </h5>
      <div class="d-flex m-3 align-items-center">
        <div
          class="h3 mb-0 mr-2"
          style="opacity: 0.3"
        >
          <i class="fas fa-user-circle" />
        </div>

        <BFormInput
          v-model="newComment"
          size="md"
          :placeholder="$t('form.addComment')"
        />
      </div>
      <div
        class="text-center py-2"
      >
        <span class="warning-box px-3">
          <i class="fas fa-exclamation-circle mr-2" />
          <span class="font-weight-bold">
            {{ $t('general.warning') }}:
          </span>
          <span>
            {{ $t('order.actionCannotBeUndone') }}
          </span>
        </span>
      </div>
      <div class="parent">
        <div
          v-tippy="{
            arrow: true,
            placement: 'bottom',
            trigger: blockedByRequestsLabelingPoints.length > 0 ? 'mouseenter' : ''
          }"
          class="px-3 mb-4"
          :content="$t('request.pendingBlockingRequests')"
        >
          <button
            class="btn btn-secondary btn-circle btn-xl"
            style="margin: 10px; width: 100px; height: 100px; border-radius: 60px;"
            :disabled="blockedByRequestsLabelingPoints.length > 0"
            @click="completeOrderButton()"
          >
            <i
              class="fa fa-stop"
              style="font-size: 2.5em"
            />
            <br>
            <span class="button-text">
              {{ $t('order.finish') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import loopRequest from '@core/mixins/loopRequest';
import OrderExecution from './OrderExecution';

export default {
  props: {
    orderId: Number,
    lineId: Number,
    order: Object,
  },
  data: () => ({
    inProgressLabelingPoints: [],
    blockedByRequestsLabelingPoints: [],
    newComment: null,
  }),
  components: {
    OrderExecution,
  },
  mixins: [loopRequest('getActiveRequestsOnActiveLabelingPoints', 5000)],
  computed: {
    ...mapGetters(['timeOfNotification']),
  },
  methods: {
    ...mapActions([
      'completeOrder',
      'getInProgressOrderLabelingPoints',
      'getBlockingRequests',
    ]),
    completeOrderButton() {
      this.$bvModal.msgBoxConfirm(this.$t('general.areYouSure'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        okTitle: this.$t('general.yes'),
        cancelTitle: this.$t('general.no'),
        centered: true,
      })
        .then(value => {
          if (value) {
            this.completeOrder({
              params: {
                orderId: this.orderId,
              },
              data: {
                orderComment: this.newComment,
              },
            })
              .then(() => {
                this.$bvToast.toast(this.$t('order.orderCompleted'), {
                  title: this.$t('general.saved'),
                  variant: 'success',
                  autoHideDelay: this.timeOfNotification,
                });
              })
              .catch(({ response: { data } }) => {
                this.$bvToast.toast(data.message, {
                  title: this.$t('general.error'),
                  variant: 'danger',
                  autoHideDelay: this.timeOfNotification,
                });
              });
            this.$emit('refresh');
            this.$emit('close');
          }
        });
    },
    getActiveRequestsOnActiveLabelingPoints() {
      if (!this.inProgressLabelingPoints
          || this.inProgressLabelingPoints.length === 0) {
        this.blockedByRequestsLabelingPoints = [];
        return;
      }
      this.getBlockingRequests({
        params: {
          query: {
            labelingPointIds: this.inProgressLabelingPoints,
          },
        },
      })
        .then(({ data }) => {
          this.blockedByRequestsLabelingPoints = [...new Set(data
            .map(req => req.labelingPointId))];
        });
    },
    getLabelingPointsWhereOrderIsInProgress() {
      this.getInProgressOrderLabelingPoints({
        params: {
          orderId: this.orderId,
        },
      }).then(({ data }) => {
        this.inProgressLabelingPoints = data.map(lp => lp.id);
        this.getActiveRequestsOnActiveLabelingPoints();
      }).catch(({ response: { data } }) => {
        this.$bvToast.toast(data.message, {
          title: this.$t('general.error'),
          variant: 'danger',
          autoHideDelay: this.timeOfNotification,
        });
      });
    },
  },
  created() {
    this.getLabelingPointsWhereOrderIsInProgress();
  },
};
</script>

<style lang="scss" scoped>
  .warning-box {
    padding-block: 5px;
    margin-block: 5px;
    border-radius: 7px;
    background-color: #FFEFEE;
    color: #D70819,
  }

  .parent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .button-text {
    font-size: 1.1em;
    font-weight: 500;
  }

  .order-style {
    padding-inline: 60px;
    padding-block: 20px
  }

  .modal-style {
    background-color: #F4F4F4;
    border-radius: 15px;
  }

</style>
