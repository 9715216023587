<template>
  <div
    class="row flex-nowrap table-container mb-3"
    style="min-height: 65vh; overflow-y: scroll"
  >
    <div class="col-6 col-md-12">
      <div>
        <div class="row">
          <h3
            style="font-size: 1.5em"
            class="m-3"
          >
            <i class="fas fa-list-check pr-2" />
            {{ $t('sidebar.requests') }}
          </h3>
        </div>
        <div class="d-flex">
          <div class="col-12 box-style">
            <div class="d-flex justify-content-between row">
              <div class="row ml-3">
                <AreaFilterSelector
                  v-if="useAreas"
                  :box-height="'26px'"
                />
                <LineFilterSelector
                  :box-height="'26px'"
                />
                <ProSelect
                  v-model="selectedType"
                  :options="requestTypesList"
                  show-all-option
                  :label="$t('form.type')"
                  class="pr-3"
                  :box-height="'26px'"
                  icon-class="fas fa-gears"
                  icon-color-class="app-color"
                />
                <ProSelect
                  v-model="selectedStatus"
                  :options="requestStatus"
                  show-all-option
                  :label="$t('general.status')"
                  class="pr-3"
                  :box-height="'26px'"
                  :dropdown-width="'auto'"
                  icon-class="fas fa-bars-progress"
                  icon-color-class="app-color"
                />
                <div class="pr-3">
                  <div class="title-style">
                    {{ $t('general.search') }}
                  </div>
                  <BFormInput
                    ref="search"
                    v-model="search"
                    size="sm"
                    style="min-width: 225px; height: 26px; font-size: 0.8em"
                    :placeholder="`${$t('form.searchByOrderNumber')}...`"
                    type="search"
                  />
                </div>
              </div>
              <div class="pr-3">
                <DatePicker
                  ref="dateItemPicker"
                  :custom-look="true"
                  :custom-height="'26px'"
                  :start-date="dates.startDate"
                  :end-date="dates.endDate"
                  @setDates="dates = $event"
                />
              </div>
            </div>
            <div>
              <div
                class="flex-fill my-2"
              >
                <div @click.stop.prevent>
                  <ExtendedRequestsTable
                    v-if="!pending && requestsList"
                    ref="requestTable"
                    style="min-height: 50vh"
                    :list="requestsList"
                    :table-fields="columns"
                    :no-data-info="$t('sku.noSku')"
                    select-many
                    @selected="openModal($event)"
                  />
                  <Loader
                    v-else
                    style="height: 50vh; padding-block: 20vh"
                  />
                </div>
                <div class="row justify-content-between align-items-center mx-0">
                  <span class="row-text ml-1">
                    {{ $t('form.showing') }}
                    <span style="font-weight: 600">
                      {{ requests.length ? 1 + currentPage*perPage - perPage : 0 }}
                      - {{ currentPage*perPage > totalRows ? totalRows : currentPage*perPage }}
                    </span>
                    {{ $t('general.of') }}
                    <span style="font-weight: 600">
                      {{ totalRows }}
                    </span>
                    {{ $t('form.results' ).toLowerCase() }}
                  </span>
                  <span class="pt-4">
                    <BPagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalRows"
                      size="sm"
                      pills
                      limit="5"
                      first-number
                      last-number
                    />
                  </span>
                  <span class="row align-items-center mr-2">
                    <span class="row-text mr-2">
                      {{ $t('form.rowsOnThePage') }}
                    </span>

                    <BFormSelect
                      v-model="perPage"
                      :options="options"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      style="width: 75px"
                      size="sm"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Offcanvas
      class="request-details-offcanvas"
      :show="showOffcanvas"
      passive
      no-bottom-padding
      @update:show="showOffcanvas = $event"
    >
      <RequestDetails
        v-if="showOffcanvas"
        :selected="selected"
        :type="typeProperties(selected.requestType)"
        :status="statusProperties(selected.requestStatus)"
        @close="showOffcanvas = false"
        @update:request="requestUpdated($event)"
        @reload="reloadRequests()"
      />
    </Offcanvas>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import calcDuration from '@/utils/calcDuration';
import ExtendedRequestsTable from '@/components/requests/ExtendedRequestsTable';
import DatePicker from '@/components/selectors/DatePicker';
import AreaFilterSelector from '@/components/selectors/AreaFilterSelector';
import LineFilterSelector from '@/components/selectors/LineFilterSelector';
import RequestDetails from '@/components/requests/RequestDetails';

export default {
  data() {
    return {
      showOffcanvas: false,
      selected: null,
      pending: false,
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      options: [
        { item: 20, name: '20' },
        { item: 50, name: '50' },
        { item: 100, name: '100' },
      ],
      selectedStatus: null,
      selectedType: null,
      search: null,
      debouncedRequestTimer: null,
      requests: [],
      dates: {
        startDate: null,
        endDate: null,
      },
    };
  },
  components: {
    ExtendedRequestsTable,
    RequestDetails,
    DatePicker,
    AreaFilterSelector,
    LineFilterSelector,
  },
  computed: {
    ...mapGetters(['requestsTypes',
      'requestsStatuses',
      'selectedFilterAreaId',
      'selectedFilterLineId',
      'linesFromArea']),
    ...mapState({
      useAreas: state => state.settings.data?.useAreas || false,
      plantTimezone: state => state.settings.data?.plantTimeZone || null,
      lines: state => state.lines.list || [],
    }),
    filters() {
      return {
        selectedFilterAreaId: this.selectedFilterAreaId,
        selectedFilterLineId: this.selectedFilterLineId,
        selectedStatus: this.selectedStatus,
        selectedType: this.selectedType,
      };
    },
    requestsList() {
      const plantUtcOffset = moment.tz(this.plantTimezone).utcOffset();
      return this.requests.map(x => ({
        ...x,
        typeProperties: this.typeProperties(x.requestType),
        statusProperties: this.statusProperties(x.requestStatus),
        duration: x.created && x.completed ? this.calculateDuration(x.created, x.completed) : '-',
        created: x.created
          ? moment.utc(x.created).add(plantUtcOffset, 'minutes').format('DD/MM/YYYY HH:mm:ss') : '-',
        completed: x.completed
          ? moment.utc(x.completed).add(plantUtcOffset, 'minutes').format('DD/MM/YYYY HH:mm:ss') : '-',
        skuNumber: x.skuNumber || '-',
        labelName: x.labelName || '-',
      }));
    },
    requestTypesList() {
      return this.requestsTypes.map(t => ({
        ...t,
        label: t.text,
        value: t.type,
        iconClass: t.icon,
        color: t.color,
      })) || [];
    },
    requestStatus() {
      return this.requestsStatuses?.map(s => ({
        ...s,
        label: s.text,
        value: s.status.toLowerCase(),
        iconClass: s.icon,
        color: s.color,
      })) || [];
    },
    columns() {
      return [
        {
          key: 'id',
          label: 'Id',
          width: '50px',
        },
        {
          key: 'typeProperties',
          label: this.$t('form.type'),
        },
        {
          key: 'lineName',
          label: this.$t('line.line'),
          groupable: true,
          class: 'text-left',
        },
        {
          key: 'labellingPointName',
          label: this.$t('labellingPoint.labellingPoints'),
          groupable: true,
          class: 'text-left',
        },
        {
          key: 'printerName',
          label: this.$t('printer.printer'),
          class: 'text-left',
        },
        {
          key: 'orderNumber',
          label: this.$t('order.orders'),
          groupable: true,
          class: 'text-left',
        },
        {
          key: 'skuNumber',
          label: 'SKU',
          groupable: true,
          class: 'text-left',
        },
        {
          key: 'labelName',
          label: this.$t('label.labels'),
          groupable: true,
          class: 'text-left',
        },
        {
          key: 'statusProperties',
          label: this.$t('general.statuses'),
        },
        {
          key: 'created',
          label: this.$t('date.startDate'),
          class: 'text-left',
        },
        {
          key: 'duration',
          label: this.$t('time.duration'),
          class: 'text-left',
        },
      ];
    },
  },
  watch: {
    currentPage() {
      this.reloadRequests();
    },
    perPage() {
      this.reloadRequests();
    },
    dates(v) {
      if (v && (v.endDate >= v.startDate)) {
        this.reloadRequests();
      } else if (v && v.startDate) {
        this.dates.startDate = null;
      }
    },
    search() {
      this.reloadRequestsDebounced();
    },
    filters() {
      this.reloadRequests();
    },
    showOffcanvas(show) {
      if (!show) {
        this.clearSelected();
      }
    },
  },
  methods: {
    calcDuration,
    ...mapActions(['getRequests']),
    calculateDuration(start, end) {
      const timeDifference = new Date(end).getTime() - new Date(start).getTime();
      const duration = calcDuration(1, Math.round(timeDifference / 1000) + 1);
      if (timeDifference < 10) return `0,00${timeDifference} s`;
      if (timeDifference < 100) return `0,0${timeDifference} s`;
      if (timeDifference < 1000) return `0,${timeDifference} s`;
      return duration;
    },
    openModal(event) {
      this.selected = event;
      this.showOffcanvas = true;
    },
    clearSelected() {
      this.selected = null;
      this.$refs.requestTable
        ?.$refs.simpleTable
        ?.$refs.table
        ?.clearSelected();
    },
    typeProperties(type) {
      return this.requestsTypes.find(x => x.type?.toUpperCase() === type?.toUpperCase())
      || {
        type: 'UNKNOWN',
        icon: 'fas fa-exclamation-triangle',
        text: 'UNKNOWN',
        color: '#ff7448',
      };
    },
    statusProperties(status) {
      return this.requestsStatuses.map(x => ({
        ...x,
        backgroundColor: tinycolor(x.color).setAlpha(0.2).toString(),
      }))
        .find(x => x.status?.toUpperCase() === status?.toUpperCase());
    },
    requestUpdated(request) {
      const existingRequestId = this.requests.findIndex(req => req.id === request.id);
      if (existingRequestId >= 0) {
        this.requests[existingRequestId] = request;
      }
      if (this.selected.id === request.id) {
        this.selected = request;
      }
    },
    reloadRequestsDebounced() {
      if (this.debouncedRequestTimer) {
        clearTimeout(this.debouncedRequestTimer);
      }
      this.debouncedRequestTimer = setTimeout(() => {
        this.reloadRequests();
      }, 500);
    },
    reloadRequests() {
      this.pending = true;
      const lines = this.selectedFilterAreaId && !this.selectedFilterLineId
        ? this.linesFromArea(this.selectedFilterAreaId).map(l => l.id)
        : [this.selectedFilterLineId];
      const query = {};

      if (this.dates?.startDate) {
        this.$set(query, 'from', this.dates.startDate);
      }

      if (this.dates?.endDate) {
        this.$set(query, 'to', this.dates.endDate);
      }

      this.getRequests({
        params: {
          query: {
            types: this.selectedType || ['ClearPrinter', 'Print', 'Finish', 'GetReport'],
            page: this.currentPage - 1,
            count: this.perPage,
            searchOrderNumber: this.search,
            statuses: this.selectedStatus || [],
            lineIds: lines || [],
            ...query,
          },
        },
      })
        .then(({ data }) => {
          this.requests = data.items;
          this.totalRows = data?.totalCount;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.reloadRequests();
  },
};
</script>

<style lang="scss" scoped>
  .table::v-deep .list-content {
    font-size: 14px;
  }

  .table-container {
    max-height: 100vh;
    height: 100vh;
    margin-top: -65px;
    padding-top: 60px;
  }

  .custom-picker::v-deep .field .field-input {
    background-color: #F2F2F2 !important;
    cursor: pointer;
    width: 125px;
    min-height: 26px !important;
    height: 26px;
    font-size: 12px;
    font-weight: 625;
    border: 0px;
    padding: 4px 16px;
  }

  .custom-picker::v-deep .field .custom-button {
    background-color: #808080 !important;
    width: 12px;
    height: 12px;
    opacity: 0.9;
  }

  .custom-picker::v-deep .field .custom-button .custom-button-content {
    font-size: 5.5px !important;
    color: white !important;
    font-weight: 900 !important;
  }

  .title-style {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #848484;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .table {
    margin-top: -50px;
    padding-top: 50px;
    height: 100%;
  }

  .row-text {
    font-size: 0.8em;
    color: #595959;
  }

  .request-details-offcanvas::v-deep .offcanvas-content {
    min-width: 50vw;
  }

</style>
