<script>
import CockpitMatryoshkaInheritanceTree
from '@/components/cockpit/matryoshka/inheritance-tree/CockpitMatryoshkaInheritanceTree.vue';

export default {
  name: 'CockpitMatryoshkaItemContentsTab',
  props: {
    labelArchiveId: Number,
  },
  emits: [
    'connections-updated',
  ],
  components: {
    CockpitMatryoshkaInheritanceTree,
  },
};
</script>

<template>
  <div>
    <CockpitMatryoshkaInheritanceTree
      :label-archive-id="labelArchiveId"
      @connections-updated="$emit('connections-updated', $event)"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
