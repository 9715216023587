import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getAreas',
    property: 'list',
    path: () => '/areas',
  })
  .get({
    action: 'getArea',
    path: ({ id }) => `/area/${id}`,
  })
  .get({
    action: 'getAreaLines',
    path: ({ id }) => `/area/${id}/lines`,
  })
  .post({
    action: 'createArea',
    path: () => '/area',
  })
  .put({
    action: 'updateArea',
    path: ({ id }) => `/area/${id}`,
  })
  .delete({
    action: 'deleteArea',
    path: ({ id }) => `/area/${id}`,
  })
  .getStore();

export default store;
